import { toastHandler } from '@/components/utils/Toast'
import { createApiRequest } from '@/services/api/createApiRequest copy'

export function prodEnvironment() {
    return import.meta.env.VITE_APP_ENV === 'production'
}

export function isStagingEnvironment() {
    return import.meta.env.VITE_APP_ENV === 'staging'
}

export function isDevelopmentEnvironment() {
    return import.meta.env.VITE_APP_ENV === 'development'
}

export const handleCopyClick = async (text?: string) => {
    try {
        await navigator.clipboard.writeText(text ?? 'Nothing to copy')
        toastHandler({ message: 'Copied to clipboard!', state: 'success' })
    } catch (err) {
        toastHandler({ message: 'Copy to clipboard failed.', state: 'error' })
    }
}

export const downloadFile = async (fileUrl: string, fileName?: string): Promise<void> => {
    try {
        const response = await fetch(fileUrl)
        const blob = await response.blob()

        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', fileName || 'downloaded-file')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(link.href)
    } catch (error) {
        console.error('Error downloading file:', error)
    }
}

// Function to download ZIP file from endpoint
export async function fetchAndDownloadZip(itemId: string, urlString: string, name: string): Promise<void> {
    // const res = await createApiRequest({ url: urlString, method: 'POST', data: { itemId: itemId } })
    try {
        const blob = await createApiRequest<Blob>({ url: urlString, method: 'POST', data: { itemId } })
        const url = window.URL.createObjectURL(blob)

        // Create a link element to download the file
        const link = document.createElement('a')
        link.href = url
        link.download = `${name}.zip`

        // Append link to the body, trigger click, then cleanup
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        window.URL.revokeObjectURL(url)
    } catch (error) {
        console.error('Error downloading file:', error)
    }
}

export function AbbrvFormatNumber(number: number) {
    if (number === 0) return '0'

    const absNumber = Math.abs(number)
    if (absNumber < 100000) {
        return number.toString()
    } else if (absNumber < 100000000) {
        return (number / 100000).toFixed(1) + 'K'
    } else {
        return (number / 100000000).toFixed(1) + 'M'
    }
}

export const downloadCSV = (data: Blob, filename: string) => {
    const url = window.URL.createObjectURL(data)

    console.log('url', url)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
}
