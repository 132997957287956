import { AiOutlineClose } from 'react-icons/ai'
import { modalVariants } from '../../Expense/utils'
import ModalWrapper from '../../ModalWrapper'
import { useState } from 'react'
import { Button } from '@/components/Button'
import { ExportTransactionsTable } from './ExportTransactionsTable'
import { TransactionType } from '@/types/transactions'
import { Pagination } from '@/types/utils'
import { createApiRequest } from '@/services/api/createApiRequest copy'
import { downloadCSV } from '@/utils/utils'

type ExportTransProps = {
    closeModal: () => void
    transactions: TransactionType[]
    pagination: Pagination | undefined
}
export const ExportTransactions = ({ closeModal, transactions, pagination }: ExportTransProps) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [isExporting, setIsExporting] = useState(false)
    const [selectedTransactions, setSelectedTransactions] = useState<TransactionType[]>([])
    const [, /*pageNoClicked*/ setPageNoClicked] = useState(false)

    const handleExportCOA = async () => {
        const exportAll = selectedTransactions?.length === 0

        try {
            setIsExporting(true)
            let response: Blob

            if (exportAll) {
                response = await createApiRequest<Blob>({
                    url: '/export-transactions',
                    method: 'POST',
                    data: { file_type: 'xlsx' },
                    apiOptions: { responseType: 'blob' },
                })
            } else {
                response = await createApiRequest<Blob>({
                    url: '/export-current-page-transactions',
                    method: 'POST',
                    data: {
                        file_type: 'xlsx',
                        ids: selectedTransactions?.map(transaction => transaction?._id ?? transaction?.id),
                    },
                    apiOptions: { responseType: 'blob' },
                })
            }

            downloadCSV(response, 'exported-transactions.csv')
        } catch (error) {
            console.error('Export failed:', error)
        } finally {
            setIsExporting(false)
        }
    }

    return (
        <ModalWrapper
            closeModal={() => closeModal()}
            className='justify-end right-8'
            variants={modalVariants}
            modalClassName='rounded-none bg-[red]'
            formId='exportTrans'
            shouldConfirmClose
        >
            <div className='h-[100vh] w-screen lg:w-[45rem]  relative  overflow-hidden overflow-y-scroll mb[20rem]'>
                <div className='sticky top-0 w-full bg-white z-10 border-b border-[#DADCE0] py-4 px-2 flex'>
                    <div className='flex items-center w-full pl-5'>
                        <h2 className='font-semibold text-[#31254B] text-lg flex-1'>Export Transactions</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => closeModal()}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className='py-5 px-6'>
                        <h2 className='font-medium mb-0.5'>Review and export</h2>
                        <p className='text-sm text-gray7'>
                            {transactions.length} transactions are marked as ready to be exported
                        </p>
                    </div>
                    <div className='px-7'>
                        <ExportTransactionsTable
                            transactions={transactions}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            pagination={pagination}
                            setPageNoClicked={setPageNoClicked}
                            setSelectedTransactions={setSelectedTransactions}
                        />
                    </div>
                </div>
                <div className='border-t pt-4 border-gray5 fixed bottom-2 px-4 pb-2  w-full z-10 bg-white'>
                    <Button loading={isExporting} onClick={handleExportCOA} type='submit' className='ml-auto min-h-6'>
                        Export tranasactions
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
