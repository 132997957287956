import React from 'react'
import ModalWrapper from '../../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { useMutationCreateTax } from '@/services/taxes/mutations'
import { formatAmountNoSymbol, formatAmountWithoutSymbols } from '@/utils/money'

type Props = {
    closeModal: () => void
}
const CreateNewTax = ({ closeModal }: Props) => {
    const [taxDetails, setTaxDetails] = React.useState({
        name: '',
        rate: '',
    })

    const { mutate: createTax, isLoading } = useMutationCreateTax({
        onSuccess: closeModal,
    })

    const handleSubmit = () => {
        createTax({ tax_name: taxDetails?.name, tax_rate: Number(taxDetails?.rate) / 100 })
    }

    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[25rem] max-h-[90vh] overflow-scroll pb-8 bg-gray8/10'>
                <div className='border-b border-gray5/80 py-2 px-5  flex justify-between items-center'>
                    <h2 className='font-semibold opacity-80'>Add new tax</h2>
                    <button
                        title='Close'
                        className='bg-gray6 rounded-full flex items-center justify-center text-gray7/80 h-7 w-7 p-1.5'
                        onClick={closeModal}
                    >
                        <AiOutlineClose fontSize={25} />
                    </button>
                </div>
                <form className='mt-6 mx-4 space-y-4 mb-10'>
                    <div className='flex gap-3 '>
                        <div className='flex flex-col flex-1 gap-1.5  h-full '>
                            <span className='opacity-80 text-sm font-medium'>Title</span>
                            <Input
                                type='text'
                                className='border border-gray5 rounded-md h-10 placeholder:text-xs w-full '
                                placeholder='Give this tax a name or title'
                                bodyClassName='flex-1 !min-w-full'
                                onChange={e => setTaxDetails({ ...taxDetails, name: e.target.value })}
                            />
                        </div>
                        <div className='flex flex-col flex-1 gap-1.5 max-w-[6rem]'>
                            <span className='opacity-80 text-sm font-medium'>Rate</span>

                            <Input
                                type='text'
                                leftIcon={<span className='text-gray7'>%</span>}
                                className='border border-gray5 rounded-md h-10 placeholder:text-xs w-full '
                                placeholder='0.00'
                                bodyClassName='flex-1 !min-w-full'
                                min={0}
                                value={formatAmountNoSymbol(taxDetails.rate)}
                                onChange={e => {
                                    const value_entered = formatAmountWithoutSymbols(e.target.value)
                                    if (Number(value_entered) > 10000) return
                                    setTaxDetails({ ...taxDetails, rate: value_entered })
                                }}
                            />
                        </div>
                    </div>
                    <div className='flex pt-2 items-center justify-end gap-4 px-6 pb-2 py-2  absolute bottom-0 right-0 left-0 bg-white w-full rounded-b-lg border-t border-gray5'>
                        <Button
                            onClick={handleSubmit}
                            size={'lg'}
                            type='button'
                            loading={isLoading}
                            className='bg-brand text-white px-8 rounded-md min-w-[90px] text-xs'
                        >
                            Add new tax
                        </Button>
                    </div>
                </form>
            </section>
        </ModalWrapper>
    )
}

export default CreateNewTax
