import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import InvoicePreview from '../../components/InvoicePreview'
import CreateInvoiceForm from '../../components/CreateInvoiceForm'
import { FileUploadFormatProps, fileEmptyState } from '@/hooks/useSingleFileUpload'
import { useEffect, useState } from 'react'
import { AddItemProps, FormStateType, PaymentMethod } from '../../components/utils'
import { useAuthContext, useCurrencyContext, useCustomerContext, useInvoiceContext } from '@/context'
import { Eye, EyeOff } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { invoiceSidebarPermissions } from '@/layout/LayoutComponents/utils'
import NotAuthorized from '@/layout/NotAuthorized'

export default function CreateInvoice() {
    const { invoiceDetails } = useInvoiceContext()
    const { selectedCurrencyInfo } = useCurrencyContext()
    const { selectedAccount } = useAuthContext()
    const { invoice_id } = useParams()

    const kyb_completed = selectedAccount?.org?.kyb_completed

    const hasPermission = useHasAccessPermisson(invoiceSidebarPermissions)

    const { orgCustomers } = useCustomerContext()

    const [preview, setPreview] = useState(false)
    const navigate = useNavigate()
    const [logoFile, setLogoFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)
    const [formState, setFormState] = useState<FormStateType>({
        currency: selectedCurrencyInfo?.()?.id as string,
        payment_method: kyb_completed ? PaymentMethod.BOTH : PaymentMethod.OFFLINE,
        bear_application_fee: false,
    } as FormStateType)

    const sub_total = formState?.item?.reduce((acc, cur) => acc + Number(cur?.quantity) * Number(cur?.unit_cost), 0)
    useEffect(() => {
        if (invoice_id) {
            setFormState({
                ...formState,
                item: invoiceDetails?.items.map(item => ({
                    name: item?.name,
                    description: item?.description,
                    quantity: item?.quantity,
                    unit_cost: item?.unit_cost,
                    type: item?.type,
                    uniq_id: uuidv4(),
                })) as AddItemProps[],
                customer: invoiceDetails?.customer?.id as string,
                due_date: invoiceDetails?.due_date ? (new Date(invoiceDetails?.due_date) as unknown as string) : '',
                issue_date: invoiceDetails?.issue_date
                    ? (new Date(invoiceDetails?.issue_date) as unknown as string)
                    : '',
                message: invoiceDetails?.message as string,
                tax: invoiceDetails?.taxes ?? [],
                asPDF: true,
                copy: true,
                logo: invoiceDetails?.logo as string,
                payment_method: invoiceDetails?.payment_method ?? '',
                part_payment: invoiceDetails?.accept_part_payment ?? false,
                footer: invoiceDetails?.footer ?? '',
                account: invoiceDetails?.payout_bank_account?._id ?? invoiceDetails?.payout_bank_account?.id ?? '',
                part_payment_percent: 0,
                currency: selectedCurrencyInfo?.(invoiceDetails?.currency?._id ?? invoiceDetails?.currency?.id)
                    ?.id as string,
                paymentProcessor: invoiceDetails?.payment_gateway ?? '',
                bear_application_fee: invoiceDetails?.bear_application_fee ?? false,
            })
            return
        }
        setFormState({ ...formState })
    }, [invoiceDetails])

    if (!hasPermission) return <NotAuthorized />
    return (
        <div>
            <div className='bg-[#F9F9F9] px-[15px] lg:px-[40px]  py-5'>
                <div
                    className='mb-8 inline-flex items-center cursor-pointer'
                    onClick={() => {
                        preview ? setPreview(prev => !prev) : navigate(-1)
                    }}
                >
                    <BsArrowLeft />
                    <p className='ml-2 font-semibold text-lg'>back</p>
                </div>
                <div className=''>
                    <div className='bg-white lg:px-5 py-6 mb-4 rounded-xl '>
                        <div className='flex justify-between border-b border-[#DADCE0] py-3 mb-[10px] p-3'>
                            <h1 className='text-lg font-semibold' id='invoice-header'>
                                Create new invoice
                            </h1>
                            <button
                                className='text-brand flex items-center gap-1 bg-[#EFEFF9] rounded-md p-1 text-sm px-2'
                                onClick={() => setPreview(prev => !prev)}
                            >
                                {!preview ? <Eye size={20} /> : <EyeOff size={20} />} {preview && 'Hide'} Preview
                            </button>
                        </div>
                        <div className='flex gap-7 justify-center'>
                            {!preview ? (
                                <CreateInvoiceForm
                                    formState={formState as FormStateType}
                                    setFormState={setFormState}
                                    setLogoFile={setLogoFile}
                                    logoFile={logoFile}
                                    orgCustomers={orgCustomers}
                                    sub_total={sub_total}
                                />
                            ) : (
                                <div className='lg:w-1/2 pt-3'>
                                    <InvoicePreview
                                        formState={formState}
                                        logoFile={logoFile}
                                        orgCustomers={orgCustomers}
                                        sub_total={sub_total}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
