import { useRef } from 'react'
import { MissingReceipt, ReceiptPresent } from '@/assets/assets.export'
import { useSignedUrlUpload } from '@/hooks/useUploadFileToS3'
import { DownloadCloud, X, RotateCcw } from 'lucide-react'
import { ExtendedRow } from '@/context/accounting'
import { ACCOUNTING_STATUS } from '@/types/accounting'
import { useUpdateTransactions } from '@/services/transactions/mutations'
import { useAccountingContext } from '@/context'

const ReceiptUpload = ({ idx, row }: { idx: number; row: ExtendedRow }) => {
    const ref = useRef<HTMLInputElement | null>(null)
    const { setRows } = useAccountingContext()
    const { isLoading: isUploadingReceipt, mutate } = useUpdateTransactions({})

    const { handleGetSignedURLs, isLoading } = useSignedUrlUpload({
        name: `receipt-${idx}`,
        onUploadComplete(urls) {
            mutate({
                receipt_urls: urls?.map(receipt => receipt?.url),
                id: row?.id,
            })
            setRows(prev =>
                prev.map(r =>
                    (r?._id ?? r?.id) === (row?._id ?? row?.id) ? { ...r, receipt_urls: urls?.map(url => url.url) } : r
                )
            )
        },
    })

    const loading = isLoading || isUploadingReceipt

    return (
        <span
            className={`text-xs text-gray7 flex flex-col gap-0.5 items-center relative group w-full overflow-hidden ${loading ? 'opacity-30' : ''}`}
        >
            {(!row?.receipt_urls || Number(row?.receipt_urls?.length) < 1) && (
                <button
                    type='button'
                    className='flex flex-col gap-0.5 items-center disabled:opacity-50 disabled:cursor-not-allowed'
                    onClick={() => ref.current?.click()}
                    disabled={loading || row?.accounting_status === ACCOUNTING_STATUS.READY}
                >
                    <span>{<MissingReceipt />}</span>
                    <span className='text-[0.65rem]'>Missing</span>
                </button>
            )}
            <input
                hidden
                type='file'
                accept='.pdf, .jpeg, .jpg, .png'
                multiple
                onChange={handleGetSignedURLs}
                ref={ref}
            />

            {Number(row?.receipt_urls?.length) > 0 && (
                <button
                    disabled={loading || row?.accounting_status === ACCOUNTING_STATUS.READY}
                    type='button'
                    className='flex flex-col gap-0.5 items-center disabled:opacity-50 disabled:cursor-not-allowed'
                >
                    <span>{<ReceiptPresent />}</span>
                    <span className='text-[0.65rem]'>Present</span>
                </button>
            )}

            {Number(row?.receipt_urls?.length) > 0 && (
                <span className=' flex justify-center items-center gap-3 absolute transition-all group-hover:bottom-[1.5px] -bottom-full bg-white w-full'>
                    <button
                        className='disabled:opacity-50 disabled:cursor-not-allowed'
                        aria-label='change'
                        onClick={() => ref.current?.click()}
                        disabled={loading || row?.accounting_status === ACCOUNTING_STATUS.READY}
                    >
                        <RotateCcw size={15} strokeWidth={2} />
                    </button>
                    <button
                        onClick={() => {
                            row?.receipt_urls?.map((recpt: string) => window.open(recpt, '_blank'))
                        }}
                        className='disabled:opacity-50 disabled:cursor-not-allowed'
                        disabled={loading}
                        aria-label='eye'
                    >
                        <DownloadCloud size={15} strokeWidth={2} />
                    </button>
                    <button
                        className='disabled:opacity-50 disabled:cursor-not-allowed'
                        aria-label='change'
                        disabled={loading || row?.accounting_status === ACCOUNTING_STATUS.READY}
                        onClick={() =>
                            mutate({
                                receipt_urls: [],
                                id: row?.id,
                            })
                        }
                    >
                        <X size={15} strokeWidth={2} />
                    </button>
                </span>
            )}
        </span>
    )
}

export default ReceiptUpload
