import { Accounting_Account_Type } from '@/utils/enum'
import { OrgType, UserOrgAccount } from './org'

export type GLcodeType = {
    code: string
    id: string
}

export enum ACCOUNTING_STATUS {
    NEEDS_REVIEW = 'NEEDS_REVIEW',
    READY = 'READY',
    SYNCED = 'SYNCED',
}

export type OrgGlCodeType = {
    codes: GLcodeType[]
    createdAt: string
    id: string
    org: OrgType
    updatedAt: string
}

export type ChartOfAccountTypeImport = {
    Account_Name: string
    Sub_Account_Type: string
    Account_Type: string
    Code: string
    Description: string
}

export type ChartOfAccountType = {
    _id: string
    org: OrgType
    account_type: {
        name: string
        type: string
        description: string
        parent: {
            name: Accounting_Account_Type
            type: Accounting_Account_Type
            description: string
            slug: string
            createdAt: string
            updatedAt: string
            id: string
        }
        slug: string
        createdAt: string
        updatedAt: string
        id: string
    }
    account_name: string
    created_by: UserOrgAccount
    code: string
    description: string
    is_mutable: boolean
    createdAt: string
    updatedAt: string
    id: string
    is_subaccount: boolean
    parent_account?: ChartOfAccountType
}

export type ChartOfAccountingAccountType = {
    _id: string
    name: string
    type: string
    description: string
    children: {
        id: string
        _id: string
        name: string
        type: string
        description: string
    }[]
}

export type CustomFieldsType = {
    org: string
    field_name: string
    created_by: string
    possible_values: string[]
    createdAt: string
    updatedAt: string
    id: string
    description: string
}
