import { useGetInvoiceByID } from '@/services/invoice/queries'
import { useGetTaxes } from '@/services/taxes/queries'
import { Invoice } from '@/types/invoice'
import { Tax } from '@/types/taxes'
import { Pagination } from '@/types/utils'
import { ReactNode, createContext, useState } from 'react'
import { useParams } from 'react-router-dom'

type SortColumn = { id: string | undefined; desc: boolean | undefined }

export type defaultInvoiceProps = {
    sort: SortColumn | undefined
    setSort: React.Dispatch<React.SetStateAction<SortColumn | undefined>> | undefined
    editModal: boolean
    setEditModal: React.Dispatch<React.SetStateAction<boolean>>
    invoiceDetails: Invoice | undefined
    setInvoiceDetails: React.Dispatch<React.SetStateAction<Invoice | undefined>>
    filterInvoices: formProps
    setFilterInvoices: React.Dispatch<React.SetStateAction<formProps>>
    pageNumberClicked: boolean
    setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
    pagination_details?: Pagination
    setPaginationDetails: React.Dispatch<React.SetStateAction<Pagination | undefined>>
    itemPageNumber: number
    setItemPageNumber: React.Dispatch<React.SetStateAction<number>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
    itemPageNumberClicked: boolean
    setItemPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
    orgTaxes: Tax[]
    isLoadingTaxes: boolean
}
export type formProps = {
    status: string
    period: { from: Date | null; to: Date | null; time_in_words: string }
    filter_now?: boolean
}

export const advancedFilteringProps = {
    status: '',
    period: { from: null, to: null, time_in_words: '' },
}

const defaultInvoiceProps: defaultInvoiceProps = {
    sort: undefined,
    setSort: () => undefined,
    editModal: false,
    setEditModal: () => undefined,
    invoiceDetails: undefined,
    setInvoiceDetails: () => undefined,
    filterInvoices: advancedFilteringProps,
    setFilterInvoices: () => undefined,
    pageNumberClicked: false,
    setPageNumberClicked: () => undefined,
    pagination_details: undefined,
    setPaginationDetails: () => undefined,
    pageNumber: 1,
    setPageNumber: () => undefined,
    itemPageNumber: 1,
    setItemPageNumber: () => undefined,
    itemPageNumberClicked: false,
    setItemPageNumberClicked: () => undefined,
    orgTaxes: [],
    isLoadingTaxes: true,
}

export const InvoiceContext = createContext(defaultInvoiceProps)
type Props = { children: ReactNode }

export const InvoiceContextProvider = ({ children }: Props) => {
    const { invoice_id } = useParams()

    const [sort, setSort] = useState<SortColumn>()
    const [editModal, setEditModal] = useState(false)
    const [invoiceDetails, setInvoiceDetails] = useState<Invoice>()
    const [filterInvoices, setFilterInvoices] = useState<formProps>(advancedFilteringProps)

    const [pagination_details, setPaginationDetails] = useState<Pagination | undefined>()
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [pageNumberClicked, setPageNumberClicked] = useState(false)

    const [itemPageNumber, setItemPageNumber] = useState<number>(1)
    const [itemPageNumberClicked, setItemPageNumberClicked] = useState(false)

    useGetInvoiceByID({
        queryParams: {
            invoice_id: invoice_id as string,
        },
        onSuccess: details => {
            setInvoiceDetails(details)
        },
        enabled: !!invoice_id || !!invoiceDetails?._id,
    })

    const { data: org_taxes, isLoading: isLoadingTaxes } = useGetTaxes({})
    const orgTaxes = org_taxes || []
    const values = {
        sort,
        setSort,
        editModal,
        filterInvoices,
        setEditModal,
        invoiceDetails,
        setInvoiceDetails,
        setFilterInvoices,
        pagination_details,
        setPaginationDetails,
        pageNumber,
        setPageNumber,
        pageNumberClicked,
        setPageNumberClicked,
        itemPageNumber,
        setItemPageNumber,
        itemPageNumberClicked,
        setItemPageNumberClicked,
        orgTaxes,
        isLoadingTaxes,
    }

    return <InvoiceContext.Provider value={values}>{children}</InvoiceContext.Provider>
}
