import { createMutation } from '../api/mutation'
import { keyUser, orgInsightkey } from '../auth/queries'
import { orgTransactionKey } from '../transactions/queries'
import { ResolvedBankProps } from './types'

export const keyGetBankDetails = ['get-bank-details']

export const useMutateSaveAccountDetails = createMutation({
    url: 'save-account-details',
    keysToRefetch: [keyGetBankDetails, keyUser],
    method: 'POST',
})

export const useResolveBankMutation = createMutation<ResolvedBankProps>({
    url: '/resolve-institution-account',
    method: 'POST',
})

export const useDeleteUserAccountById = createMutation({
    url: '/delete-account-by-id',
    method: 'DELETE',
    keysToRefetch: [keyGetBankDetails],
})

export const useUpdateAccountDetails = createMutation({
    url: '/update-account-details',
    method: 'PATCH',
    keysToRefetch: [keyGetBankDetails],
})

export const useTransferFunds = createMutation({
    url: '/transfer',
    method: 'POST',
    keysToRefetch: [orgInsightkey, orgTransactionKey],
})

export const useSendReminderToAddBank = createMutation({
    url: '/send-reminder-to-add-bank',
    method: 'POST',
    keysToRefetch: [],
})

export const useSendTransferOtp = createMutation({
    url: '/send-transfer-otp',
    method: 'POST',
    keysToRefetch: [],
})
