import AccountingContextProvider from '@/context/accounting'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { accountingSidebarPermissions } from '@/layout/LayoutComponents/utils'
import NotAuthorized from '@/layout/NotAuthorized'
import Accounting from '@/modules/dashboard/Accounting/Accounting'
import { Outlet, Route } from 'react-router-dom'

export const accountingRoutes = {
    baseUrl: 'accounting',
    subRoute: 'accounting/:accountingSubRoute',
    // preview : 'accounting/coa'
}
export const useAccountingRoutes = () => {
    const hasPermission = useHasAccessPermisson(accountingSidebarPermissions)

    if (!hasPermission) return <Route path='not-authorized' element={<NotAuthorized />} />
    return (
        <Route
            path={accountingRoutes.subRoute}
            element={
                <AccountingContextProvider>
                    <Outlet />
                </AccountingContextProvider>
            }
        >
            <Route index element={<Accounting />} />
        </Route>
    )
}
