import { CustomFieldsType } from '@/types/accounting'
import { createQuery } from '../api/query'
import { QueryOrgAccountingTypes, QueryOrgCOARes, QueryOrgGlCodeRes } from './type'

export const glcodeKey = ['gl-code']
export const chartOfAccountKey = ['coa']
export const customFields = ['custom-fields']
export const accountingTypes = ['accounting-types']
export const parentAccounts = ['parent-accounts']
export const accountingTransactionAnalytics = ['accounting-transaction-analytics']

export const coAKey = (id: string) => ['coa', id]

export const useGetGLCode = createQuery<QueryOrgGlCodeRes>({
    url: '/gl-code',
    key: glcodeKey,
})

export const useGetCOA = createQuery<QueryOrgCOARes>({
    url: '/accounting/accounts',
    key: coAKey,
})

export const useGetCustomFields = createQuery<CustomFieldsType[]>({
    url: '/accounting/custom-fields',
    key: customFields,
})

export const useGetAccountingTypes = createQuery<QueryOrgAccountingTypes>({
    url: '/accounting/account-types',
    key: accountingTypes,
})
export const useGetParentAccounts = createQuery<QueryOrgAccountingTypes>({
    url: '/accounting/parent-accounts',
    key: parentAccounts,
})

export const useGetAccountingTransactionAnalytics = createQuery<{
    total_count: 2764
    needs_review_count: 2764
    ready_count: 0
}>({
    url: '/accounting-transaction-analytics',
    key: accountingTransactionAnalytics,
})
