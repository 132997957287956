import { ChevronRight, X } from 'lucide-react'
import PageContent from '@/layout/PageContent'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/components/Button'
import { LucideEdit, Plus, Save } from 'lucide-react'
import React, { useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Input } from '@/components/Input'
import { TaxItemType } from '@/types/invoice'
import { TrashIcon } from '@/assets/assets.export'
import { useMutationCreateTax, useMutationUpdateTax } from '@/services/taxes/mutations'
import DeleteTaxPrompt from '@/components/Modals/Tax/DeleteTaxPrompt'
import { formatAmountNoSymbol, formatAmountWithoutSymbols } from '@/utils/money'
import { useGetTaxes } from '@/services/taxes/queries'
import ProgressBar from '@/components/ProgressBar'

const tableHead = ['Tax title', 'Rate', 'Actions']
type FormType = {
    item: TaxItemType[]
}

export default function InvoiceTaxItemsSettings() {
    const navigate = useNavigate()
    const [editRowIndex, setEditRowIndex] = useState<number>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedTaxId, setSelectedTaxId] = useState('')

    const form = useForm<FormType>()
    const { fields, append } = useFieldArray({
        name: 'item',
        control: form.control,
    })

    const { isLoading } = useGetTaxes({
        onSuccess: orgTaxes =>
            form.setValue(
                'item',
                orgTaxes?.map(tax => ({
                    taxId: tax?.id,
                    name: tax?.tax_name,
                    rate: tax?.tax_rate * 100,
                    id: '',
                }))
            ),
    })

    const { mutate: createTax, isLoading: isCreating } = useMutationCreateTax({
        onSuccess: () => setEditRowIndex(undefined),
    })
    const { mutate: updateTax, isLoading: isUpdating } = useMutationUpdateTax({
        onSuccess: () => setEditRowIndex(undefined),
    })

    const handleTax = (data: TaxItemType) => {
        const update = !!data?.taxId
        const dataToBE = {
            tax_name: data?.name,
            tax_rate: Number(data?.rate) / 100,
        }

        if (update) {
            updateTax({ ...dataToBE, id: data?.taxId })
            return
        }
        createTax({ ...dataToBE })
    }

    const formValues = form.watch('item')

    if (isLoading) return <ProgressBar />
    return (
        <PageContent>
            <div className='flex items-center gap-2 mt-5'>
                <button onClick={() => navigate(-1)} className='font-medium '>
                    Settings
                </button>
                <ChevronRight size={18} className='text-gray7' />
                <span className='text-sm text-gray1 '>Tax items</span>
            </div>
            <section className='px-4 lg:px-0 mt-10'>
                <div className='flex justify-between items-center'>
                    <div className='mb-2 lg:mb-6'>
                        <h1 className='text-dark font-medium text-lg'>Tax items</h1>
                        <p className='text-gray7 text-sm'>Create and manage invoice taxes</p>
                    </div>
                    <button
                        title='action'
                        className='inline-flex items-center bg-[#EFEFF9] text-[#454ADE] text-sm rounded-md gap-1 px-5 py-1 h-[2.5rem] whitespace-nowrap'
                        onClick={() => {
                            append({ name: '', rate: undefined, id: `${fields?.length + 1}` })
                            setEditRowIndex(fields?.length)
                        }}
                    >
                        <Plus size={17} />
                        Add new
                    </button>
                </div>

                {/* tax items table */}
                {fields.length > 0 && (
                    <div className={`max-w-xl mt-5 ${isLoading ? 'opacity-50' : ''}`}>
                        <table className='border-separate border-spacing-x-0 w-full'>
                            <thead className='bg-light-primary [&_tr:first-child]:rounded-md '>
                                <tr className='[&_tr:first-child]:rounded-md'>
                                    {tableHead.map(head => (
                                        <th
                                            key={head}
                                            className={
                                                '[&:first-child]:rounded-tl-xl border-y [&:last-child]:border-r [&:first-child]:border-l [&:last-child]:w-[20%]  [&:last-child]:rounded-tr-xl h-12 border-gray5 pl-3 text-left font-medium text-sm w-36 &:last-child]:w-auto text-gray7'
                                            }
                                        >
                                            {head}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='bg-white'>
                                {fields.map((data, idx) => (
                                    <tr className='hover:bg-transparent' key={idx}>
                                        <td
                                            className={`${fields.length === idx + 1 ? 'rounded-bl-xl border-t-0' : ''} ${fields.length !== idx + 1 ? 'border-b' : 'border-y'} px-3 h-12  text-gray7  border-gray5 border-l `}
                                        >
                                            {fields.length === idx + 1}
                                            {editRowIndex === idx ? (
                                                <Controller
                                                    control={form.control}
                                                    name={`item.${idx}.name`}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            disabled={isCreating || isUpdating}
                                                            className='h-10 border-gray5 w-60'
                                                            onChange={field.onChange}
                                                            bodyClassName='!min-w-[240px]'
                                                        />
                                                    )}
                                                />
                                            ) : (
                                                data?.name
                                            )}
                                        </td>
                                        <td
                                            className={`${fields.length !== idx + 1 ? 'border-b' : 'border-y'} ${fields.length === idx + 1 ? 'border-t-0' : ''} h-12 px-3  text-gray7  border-gray5`}
                                        >
                                            {editRowIndex === idx ? (
                                                <Controller
                                                    control={form.control}
                                                    name={`item.${idx}.rate`}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            disabled={isCreating || isUpdating}
                                                            type='number'
                                                            className='h-10 border-gray5 w-20'
                                                            bodyClassName='!min-w-[5rem]'
                                                            min={0}
                                                            value={formatAmountNoSymbol(field.value)}
                                                            onChange={e => {
                                                                const value_entered = formatAmountWithoutSymbols(
                                                                    e.target.value
                                                                )
                                                                if (Number(value_entered) > 10000) return
                                                                field.onChange(value_entered)
                                                            }}
                                                        />
                                                    )}
                                                />
                                            ) : (
                                                <span>
                                                    {formatAmountNoSymbol(data?.rate)}
                                                    {!!data?.rate && '%'}
                                                </span>
                                            )}
                                        </td>

                                        <td
                                            className={`${fields.length !== idx + 1 ? 'border-b' : 'border-y'} items-center border-l h-[4.1rem] flex gap-4 lg:gap-8 pl-5 p-4  py-3.5  text-gray-600 border-r border-gray5 ${fields.length === idx + 1 ? 'rounded-br-xl border-t-0' : ''}`}
                                        >
                                            {editRowIndex !== idx ? (
                                                <>
                                                    <LucideEdit
                                                        size={16}
                                                        className='cursor-pointer text-gray7'
                                                        onClick={() => {
                                                            setEditRowIndex(idx)
                                                        }}
                                                    />
                                                    <button
                                                        aria-label='delete'
                                                        onClick={() => {
                                                            setOpenDeleteModal(true)
                                                            setSelectedTaxId(data?.taxId ?? '')
                                                        }}
                                                        className='disabled:opacity-40'
                                                    >
                                                        <TrashIcon />
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        className='border-gray5 text-xs gap-2 px-3 min-w-[90px]'
                                                        size={'sm'}
                                                        onClick={() => {
                                                            handleTax(formValues[idx])
                                                        }}
                                                        loading={isCreating || isUpdating}
                                                    >
                                                        Save <Save size={12} />
                                                    </Button>
                                                    <button
                                                        aria-label='delete'
                                                        onClick={() => {
                                                            setEditRowIndex(undefined)
                                                        }}
                                                        className='disabled:opacity-40 ml-2'
                                                    >
                                                        <X color='#cf491e' size={18} />
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </section>
            {openDeleteModal && <DeleteTaxPrompt closeModal={() => setOpenDeleteModal(false)} id={selectedTaxId} />}
        </PageContent>
    )
}
