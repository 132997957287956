import { ToggledColumn } from '@/context/accounting'
import { ChartOfAccountingAccountType } from '@/types/accounting'

export const staticField = [
    {
        name: 'Destination / Merchant',
        desc: 'Where the transaction is directed.',
    },
    {
        name: 'Amount',
        desc: 'Total value of the transaction.',
    },
    {
        name: 'Date and Time',
        desc: 'When the transaction occurred.',
    },
    {
        name: 'Initiated By',
        desc: 'Person initiating the transaction.',
    },
    {
        name: 'Transaction Type',
        desc: 'Nature of the transaction (e.g., payment, refund).',
    },
    {
        name: 'Category ',
        desc: 'Related accounting account.',
    },
]

export const columnHelperInContext: ToggledColumn[] = [
    { id: 'destination', label: 'Destination / Merchant', isCompulsory: true, editable: false },
    { id: 'date', label: 'Date & time', isCompulsory: true, editable: false },
    { id: 'amount', label: 'Amount', isCompulsory: true, editable: false },
    { id: 'type', label: 'Type', isCompulsory: true, editable: false },
    {
        id: 'category',
        label: 'Category',
        isCompulsory: true,
        editable: true,
        type: 'select',
    },
    {
        id: 'affected_account',
        label: 'Account',
        isCompulsory: true,
        editable: true,
        type: 'select',
    },
]
export const exportCOAFormats = [
    { name: 'Export as CSV', id: 'csv' },
    { name: 'Export as xlsx', id: 'xlsx' },
    { name: 'Export as xls', id: 'xls' },
]

export function mapSubAccountToParentType(
    accountingTypes: ChartOfAccountingAccountType[],
    subAccountTypeId: string
): string {
    const findParentInType = (types: ChartOfAccountingAccountType[]): string | null => {
        for (const parentType of types) {
            // Check if the ID is in the children
            const matchingChild = parentType.children.find(child => child._id === subAccountTypeId)

            if (matchingChild) {
                return parentType.name
            }
        }

        return null
    }

    // Return the found parent type or 'Unclassified'
    return findParentInType(accountingTypes) || 'Unclassified'
}
