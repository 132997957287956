import React, { useEffect, useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { Button } from '@/components/Button'
import IntegrateStepOne from './IntegrateStepOne'
import { useConnectQuickBooks, useSyncQuickBooksCOA } from '@/services/accounting/mutations'
import { useSearchParams } from 'react-router-dom'
import Lottie from 'react-lottie'
import animationData from '@/assets/upgrade-animation.json'

type Props = {
    closeModal: () => void
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

const QuickbooksIntegration = ({ closeModal }: Props) => {
    const [quickbook_params] = useSearchParams()
    const [step, setStep] = useState(1)

    const { mutate: connectQuickBooksFn } = useConnectQuickBooks({
        onSuccess: () => {
            setStep(3)
        },
        onError: () => {
            setStep(1)
        },
    })
    const { mutate: syncCOA, isLoading: isSyncingCOA } = useSyncQuickBooksCOA({
        onSuccess: () => {
            setStep(4)
        },
    })
    useEffect(() => {
        if (quickbook_params.get('step')) {
            setStep(2)
        }
    }, [])

    useEffect(() => {
        if (step === 2) {
            connectQuickBooksFn({
                code: quickbook_params.get('code'),
                state: quickbook_params.get('state'),
                realm_id: quickbook_params.get('realmId'),
            })
        }
    }, [step])

    return (
        <ModalWrapper>
            <section className='min-h-[15rem] w-screen lg:w-[35rem] overflow-scroll '>
                {step === 1 && <IntegrateStepOne closeModal={closeModal} />}
                {step === 2 && <BarHorizontalLoader status='Connecting quickbooks...' />}
                {step === 3 && (
                    <>
                        <Lottie options={defaultOptions} height={150} width={150} />

                        <div className='mx-4 mt-5 text-center'>
                            <h1 className='font-medium text-lg'>Integration was successful</h1>
                            <h3 className='mb-2 text-sm text-gray7 text-center mt-1'>
                                Quickbook has been integrated successfully. You can now import Chart of Accounts from
                                Quickbooks
                            </h3>
                        </div>
                        <div className='flex justify-center my-5 mt-7'>
                            <Button
                                onClick={() => {
                                    syncCOA({})
                                }}
                                size={'lg'}
                                className=' p-3 rounded-lg'
                                loading={isSyncingCOA}
                                disabled={isSyncingCOA}
                            >
                                Import chart of accounts from Quickbooks
                            </Button>
                        </div>
                    </>
                )}
                {step === 4 && <BarHorizontalLoader status={'Importing and updating up chart of accounts'} />}
            </section>
        </ModalWrapper>
    )
}

const BarHorizontalLoader = ({ status }: { status: string }) => {
    return (
        <div className='flex items-center flex-col'>
            <div className='border-t border-[#A8A8A8] w-[400px] h-[184px] flex justify-center  mt-[3.7rem] rotate-180'>
                <div
                    className='w-16 h-[184px] bg-[#9FDA96] rounded-b-lg animate-animate-up-down'
                    style={{ animationDelay: '600ms' }}
                ></div>
                <div
                    className='w-16 h-[184px] bg-[#FFAB90] rounded-b-lg animate-animate-up-down'
                    style={{ animationDelay: '400ms' }}
                ></div>
                <div
                    className='w-16 h-[184px] bg-[#7E82FB] rounded-b-lg animate-animate-up-down delay-[400ms]'
                    style={{ animationDelay: '200ms' }}
                ></div>
                <div className='w-16 h-[184px] bg-[#F5D484] rounded-b-lg animate-animate-up-down delay-[600ms]'></div>
            </div>

            <p className='text-center my-6'>{status}</p>
        </div>
    )
}
export default QuickbooksIntegration
