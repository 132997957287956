import { Pagination as PaginationType } from '@/types/utils'
import { MoveRight } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../Select'
import React from 'react'

interface PaginationProps {
    setCurrentPageNumber: (arg: number) => void
    paginationOption?: number[]
    pagination: PaginationType
    setIsPageNumberClicked?: React.Dispatch<React.SetStateAction<boolean>>
    totalPages?: number
}

const V2Pagination = ({
    setCurrentPageNumber,
    pagination,
    setIsPageNumberClicked,
    paginationOption,
    totalPages,
}: PaginationProps) => {
    const { currentPage } = pagination
    const total = totalPages ?? pagination.totalPages ?? 1

    return (
        <div className='flex justify-between items-center gap-3 sm:gap-5'>
            <div>
                <Select
                    name='page number'
                    value={currentPage?.toString()}
                    onValueChange={val => {
                        setIsPageNumberClicked?.(true)
                        setCurrentPageNumber(Number(val))
                    }}
                >
                    <SelectTrigger className='border-none text-xs h-8 w-24 bg-[#F9F9F9] text-[#5E5E5E]'>
                        <SelectValue placeholder={''} />
                    </SelectTrigger>
                    <SelectContent className='w-20 text-xs h-64'>
                        {(paginationOption ?? Array.from({ length: total }, (_, i) => i + 1)).map((page: number) => (
                            <SelectItem value={page.toString()} className='text-sm' key={page}>
                                Page {page}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div className='flex gap-2 justify-center text-gray7'>
                {currentPage > 1 && (
                    <button
                        className='w-8 h-8 rounded-md border border-[#A8A8A8]'
                        onClick={() => {
                            setCurrentPageNumber(1)
                            setIsPageNumberClicked?.(true)
                        }}
                    >
                        1
                    </button>
                )}
                {currentPage > 3 && <span className='w-8 h-8 flex items-center justify-center'>...</span>}
                {currentPage > 2 && (
                    <button
                        className='w-8 h-8 rounded-md border border-[#A8A8A8]'
                        onClick={() => {
                            setCurrentPageNumber(currentPage - 1)
                            setIsPageNumberClicked?.(true)
                        }}
                    >
                        {currentPage - 1}
                    </button>
                )}
                <button className='w-8 h-8 rounded-md bg-[#454ADE] text-white'>{currentPage}</button>
                {currentPage < total - 1 && (
                    <button
                        className='w-8 h-8 rounded-md border border-[#A8A8A8]'
                        onClick={() => {
                            setCurrentPageNumber(currentPage + 1)
                            setIsPageNumberClicked?.(true)
                        }}
                    >
                        {currentPage + 1}
                    </button>
                )}
                {currentPage < total - 2 && <span className='w-8 h-8 flex items-center justify-center'>...</span>}
                {currentPage < total && (
                    <button
                        className='w-8 h-8 rounded-md border border-[#A8A8A8]'
                        onClick={() => {
                            setCurrentPageNumber(total)
                            setIsPageNumberClicked?.(true)
                        }}
                    >
                        {total}
                    </button>
                )}
            </div>
            <div>
                {total > 1 && (
                    <div className='flex gap-5'>
                        <button
                            className='flex items-center justify-center gap-1 text-xs rounded-md disabled:opacity-40 disabled:cursor-not-allowed disabled:text-gray2'
                            disabled={currentPage === 1}
                            onClick={() => {
                                setCurrentPageNumber(currentPage - 1)
                                setIsPageNumberClicked?.(true)
                            }}
                        >
                            <MoveRight className='rotate-180' />
                            Previous
                        </button>
                        <button
                            className='flex items-center justify-center gap-1 text-xs rounded-md disabled:opacity-40 disabled:cursor-not-allowed disabled:text-gray2'
                            disabled={currentPage === total}
                            onClick={() => {
                                setCurrentPageNumber(currentPage + 1)
                                setIsPageNumberClicked?.(true)
                            }}
                        >
                            Next
                            <MoveRight />
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default V2Pagination
