import { cn } from '@/lib/styling'
import { ChartOfAccountType } from '@/types/accounting'
import { Pagination } from '@/types/utils'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion/index2'
import React, { useState } from 'react'
import { ChevronDown } from 'lucide-react'
import { LucideEdit } from 'lucide-react'
import { DeleteIcon, Trash2 } from '@/assets/assets.export'
import AddNewAccountModal from '@/components/Modals/Accounts/AddNewAccountModal'
import CheckBox from '@/components/Table/CheckBox'
import { Lock } from 'lucide-react'
import DeleteCOANoTransaction from '@/components/Modals/Accounting/DeleteCOAPrompt/DeleteCOANoTransaction'
import DeleteMultipleCOA from '@/components/Modals/Accounting/DeleteCOAPrompt/DeleteMultipleCOA'

type Props = {
    coa: ChartOfAccountType[]
    pagination: Pagination
}
const NewChartsOfAccountTable = ({ coa }: Props) => {
    const [selectedCOA, setSelectedCOA] = useState<ChartOfAccountType[]>([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const toggleCOA = (coa: ChartOfAccountType) => {
        const rowId = coa._id ?? coa.id
        const isSelected = selectedCOA.some(coa => (coa._id ?? coa.id) === rowId)

        if (isSelected) {
            setSelectedCOA(selectedCOA.filter(allCoa => (allCoa._id ?? allCoa.id) !== rowId))
        } else {
            setSelectedCOA([...selectedCOA, coa])
        }
    }

    const subAccounts = coa.filter(account => account?.is_subaccount)

    return (
        <div className='mt-2'>
            {selectedCOA?.length > 0 && (
                <section className='flex justify-between items-center my-2'>
                    <div className='flex items-center gap-6'>
                        <span className='text-xs text-gray7'>{selectedCOA?.length} Accounts are selected</span>
                        <button
                            onClick={() => setSelectedCOA([])}
                            aria-label='Clear selection'
                            className='text-xs font-medium text-brand'
                        >
                            Clear selection
                        </button>
                    </div>
                    <div>
                        <button
                            aria-label='Delete'
                            onClick={() => setOpenDeleteModal(true)}
                            className='flex items-center gap-1 text-xs font-medium text-errorRed'
                        >
                            <DeleteIcon className='w-4 h-4' />
                            Delete
                        </button>
                    </div>
                </section>
            )}
            <div className='rounded-lg border border-gray5 overflow-hidden mt-2'>
                <table className='w-full leading-normal border-collapse text-xs'>
                    <thead>
                        <tr className='grid grid-cols-10'>
                            {['Sub-type', 'Account name', 'ID', 'Description'].map((col, i) => (
                                <th
                                    key={i}
                                    className={cn(
                                        'flex items-center border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90',
                                        col === 'ID' ? 'col-span-1' : 'col-span-2',
                                        col === 'Description' && 'col-span-4'
                                    )}
                                >
                                    {col}
                                </th>
                            ))}
                            <th className='flex col-span-1 items-center border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {coa
                            ?.filter(item => !item.is_subaccount)
                            .map((acct, i) => (
                                <SingleParentAccountAccordion
                                    acct={acct}
                                    key={i}
                                    toggleCOA={toggleCOA}
                                    selectedCOA={selectedCOA}
                                    subAccounts={subAccounts}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
            {openDeleteModal && (
                <DeleteMultipleCOA closeModal={() => setOpenDeleteModal(false)} selectedCoAs={selectedCOA} />
            )}
        </div>
    )
}

const SingleParentAccountAccordion = ({
    acct,
    toggleCOA,
    selectedCOA,
    subAccounts,
}: {
    acct: ChartOfAccountType
    toggleCOA: (coa: ChartOfAccountType) => void
    selectedCOA: ChartOfAccountType[]
    subAccounts: ChartOfAccountType[]
}) => {
    const [show, setShow] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [selectedAcct, setSelectedAcct] = useState<ChartOfAccountType>()

    const isSelected = selectedCOA.some(coa => (coa._id ?? coa.id) === (acct?._id ?? acct?.id))
    const hasSubAccounts = subAccounts.some(coa => coa?.parent_account?.id === (acct?._id ?? acct?.id))
    const internalSubAccounts = subAccounts.filter(coa => coa?.parent_account?.id === (acct?._id ?? acct?.id))

    return (
        <>
            <tr>
                <td colSpan={6}>
                    <Accordion type='single' collapsible>
                        <AccordionItem value='item-1' className='border-none'>
                            <AccordionTrigger
                                className={cn('w-full grid grid-cols-10 gap-0', show && 'bordr-b border-gray5')}
                                onClick={() => setShow(!show)}
                            >
                                <>
                                    <td className='col-span-2  border-gray5 px-5 h-12 border-t  font-medium text-gray7 flex items-center'>
                                        <div className='flex items-center gap-3'>
                                            {acct?.is_mutable && (
                                                <CheckBox
                                                    checked={isSelected}
                                                    onChange={() => toggleCOA(acct)}
                                                    className='h-4 w-4'
                                                    // disabled={!row.getCanSelect()}
                                                />
                                            )}{' '}
                                            {acct?.account_type?.name}{' '}
                                        </div>
                                    </td>
                                    <td className='border-gray5 col-span-2 px-5 h-12 border-t font-semibold text-gray7 flex items-center justify-between'>
                                        <div className='flex items-center gap-2'>
                                            {!acct?.is_mutable && (
                                                <Lock className='text-gray7 w-4 h-4 cursor-not-allowed' />
                                                // <MdLockOutline className='text-gray7 cursor-not-allowed' />
                                            )}{' '}
                                            {acct.account_name}{' '}
                                        </div>

                                        <ChevronDown
                                            color='#5E5E5E'
                                            size={18}
                                            className={cn(
                                                'transition-all',
                                                show && 'rotate-180',
                                                !hasSubAccounts && 'hidden'
                                            )}
                                        />
                                    </td>
                                    <td className='border-gray5 col-span-1 px-5 h-12 border-t  font-medium text-gray7 flex items-center'>
                                        {acct.code}
                                    </td>
                                    <td className='col-span-4 border-gray5 px-5 h-12 border-t  font-medium text-gray7 flex items-center text-left'>
                                        {acct.description}
                                    </td>
                                    <td
                                        className={cn(
                                            'border-gray5 col-span-1 px-5 h-12 border-t font-medium text-gray7 flex items-center',
                                            acct?.is_mutable && 'border-l'
                                        )}
                                        onClick={e => e.preventDefault()}
                                    >
                                        {acct?.is_mutable && (
                                            <div className='flex items-center gap-7 justify-end'>
                                                <LucideEdit
                                                    size={18}
                                                    color='#5E5E5E'
                                                    onClick={() => {
                                                        setSelectedAcct(acct)
                                                        setShowEdit(true)
                                                    }}
                                                />{' '}
                                                <Trash2
                                                    className='w-5 h-5'
                                                    onClick={() => {
                                                        setSelectedAcct(acct)
                                                        setShowDelete(true)
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </td>
                                </>
                            </AccordionTrigger>
                            {hasSubAccounts && (
                                <AccordionContent className='border-none'>
                                    {internalSubAccounts?.map((subAcct, i) => (
                                        <div className='grid grid-cols-10 gap-0'>
                                            <td className='col-span-2  border-gray5 px-5 h-12 border-t  font-medium text-gray7 flex items-center'></td>
                                            {/* <td className='border-gray5 col-span-2 px-5 h-12 border-t font-medium text-gray7 flex items-center justify-between'>
                                                <div className='flex items-center gap-2'>
                                                    {!subAcct?.is_mutable && (
                                                        <Lock className='text-gray7 w-4 h-4 cursor-not-allowed' />
                                                        // <MdLockOutline className='text-gray7 cursor-not-allowed' />
                                                    )}{' '}
                                                    {subAcct.account_name}{' '}
                                                </div>
                                            </td> */}
                                            <td className='border-gray5 col-span-2 px-5 h-12 border-t font-medium text-[#838383] flex items-center relative pl-12'>
                                                <p
                                                    className={cn(
                                                        'absolute left-5 -top-8 h-14 border-l-2 border-b-2 border-[#71787e98] bg-transparent w-4 mr-2',
                                                        i === internalSubAccounts?.length - 1 && 'rounded-bl-md'
                                                    )}
                                                ></p>{' '}
                                                {subAcct.account_name}
                                            </td>
                                            <td className='border-gray5 col-span-1 px-5 h-12 border-t  font-medium text-gray7 flex items-center'>
                                                {subAcct.code}
                                            </td>
                                            <td className='col-span-4 border-gray5 px-5 h-12 border-t  font-medium text-gray7 flex items-center text-left'>
                                                {subAcct.description}
                                            </td>
                                            <td
                                                className={cn(
                                                    'border-gray5 col-span-1 px-5 h-12 border-t font-medium text-gray7 flex items-center',
                                                    subAcct?.is_mutable && 'border-l'
                                                )}
                                                onClick={e => e.preventDefault()}
                                            >
                                                {subAcct?.is_mutable && (
                                                    <div className='flex items-center gap-7 justify-end'>
                                                        <LucideEdit
                                                            size={18}
                                                            color='#5E5E5E'
                                                            onClick={() => {
                                                                setSelectedAcct(subAcct)
                                                                setShowEdit(true)
                                                            }}
                                                        />{' '}
                                                        <Trash2
                                                            className='w-5 h-5'
                                                            onClick={() => {
                                                                setSelectedAcct(subAcct)
                                                                setShowDelete(true)
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </td>
                                        </div>
                                    ))}
                                </AccordionContent>
                            )}
                        </AccordionItem>
                    </Accordion>
                </td>
            </tr>
            {showEdit && (
                <AddNewAccountModal closeModal={() => setShowEdit(false)} isEdit={true} selectedCoa={selectedAcct} />
            )}
            {showDelete && (
                <DeleteCOANoTransaction selectedCoa={selectedAcct} closeModal={() => setShowDelete(false)} />
            )}
        </>
    )
}

export default NewChartsOfAccountTable
