import { Select, SelectItem, SelectTrigger, SelectContent, SelectValue } from '@/components'
import CheckBox from '@/components/Table/CheckBox'
import { useAccountingContext } from '@/context'
import { ExtendedRow } from '@/context/accounting'
import { useUpdateTransactions } from '@/services/transactions/mutations'
import { ACCOUNTING_STATUS } from '@/types/accounting'
import { TransactionTypeType } from '@/types/transactions'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'

type Props = {
    row: ExtendedRow
    idx: number
    loading: boolean
}
const AccountingRow = ({ row, loading, idx }: Props) => {
    const { setRows, columns, categoriesMappedToAccountData } = useAccountingContext()

    const { isLoading, mutate } = useUpdateTransactions({})

    const handleSelect = (checked: boolean) => {
        setRows(prev => prev.map(r => ((r?._id ?? r?.id) === (row?._id ?? row?.id) ? { ...r, selected: checked } : r)))
    }

    const vendorName = capitalizeText(row?.metadata?.recipient?.name)
    const userName = row?.credit?.account?.user
        ? capitalizeText(row?.credit?.account?.user?.first_name) +
          ' ' +
          capitalizeText(row?.credit?.account?.user?.last_name?.[0])
        : 'N/A'
    const orgName = capitalizeText(row?.owner?.org_name)
    const creditDetails = row?.credit
    const transactionType = row?.type

    const handleSelectDropdown = (colId: string, data: string) => {
        const updatedRow = {
            ...row,
            [colId]: data,
        }

        let objToServer = {
            [colId]: data,
            id: row?.id,
        }

        if (colId === 'category') {
            const foundCategoryAccountMapped = categoriesMappedToAccountData?.find(
                mappedCategory => mappedCategory.category.id === data
            )

            updatedRow.affected_account = row?.affected_account ?? foundCategoryAccountMapped?.account?.id
            objToServer = {
                ...objToServer,
                affected_account: row?.affected_account ?? foundCategoryAccountMapped?.account?.id,
            }
        }

        setRows(prev => prev.map(r => ((r?._id ?? r?.id) === (row?._id ?? row?.id) ? updatedRow : r)))

        mutate(objToServer)
    }

    loading = loading || isLoading

    return (
        <tr className={`border-t border-gray4/60 ${loading ? 'opacity-30' : ''}`}>
            <td className=' px-5 h-14 text-center'>
                <CheckBox
                    checked={!!row.selected}
                    onChange={e => handleSelect((e.target as HTMLInputElement).checked)}
                />
            </td>
            <td className=' px-5 h-14 text-gray7 text-sm font-medium'>
                {transactionType === TransactionTypeType.INVOICE ||
                transactionType === TransactionTypeType.WALLET_FUNDING
                    ? orgName
                    : transactionType === TransactionTypeType.SUBSCRIPTION ||
                        transactionType === TransactionTypeType.WALLET_DEDUCTION
                      ? 'Bizpend Technologies'
                      : transactionType === TransactionTypeType.TRANSFER ||
                          transactionType === TransactionTypeType.ACCOUNT_FUNDING
                        ? row?.recipient_type === 'vendors'
                            ? vendorName
                            : creditDetails?.financial_account_details?.account_name
                              ? `${creditDetails?.financial_account_details?.account_name} (${creditDetails?.financial_account?.nickname ?? creditDetails?.financial_account?.provider})`
                              : 'N/A'
                        : userName}
            </td>
            <td className=' px-5 h-14 text-gray7 text-sm font-medium'>
                {row.createdAt && `${format(new Date(row.createdAt), 'dd/MM/yyyy, hh:mm a')}`}
            </td>
            <td className=' px-5 h-14 text-gray7 text-sm font-medium'>
                {row.ownerTransactionType === 'DEBIT' ? (
                    <span className='text-[#CF491E]'>{formatAmount(row.debit.amount)}</span>
                ) : (
                    <span className='text-[#19C37D]'>{formatAmount(row.credit.amount)}</span>
                )}
            </td>

            <td className=' px-5 h-14 text-gray7 text-sm font-medium'>{row?.type}</td>

            {columns
                ?.filter(col => col.editable)
                .map(col => (
                    <td className=' px-5 h-14 text-gray7 text-sm' key={col.id}>
                        {col?.type === 'select' ? (
                            <Select
                                name={`${col.id}-${idx}`}
                                onValueChange={data => {
                                    handleSelectDropdown(col.id, data)
                                }}
                                disabled={row?.accounting_status === ACCOUNTING_STATUS.READY}
                                value={row?.[col.id as keyof typeof row] as string}
                            >
                                <SelectTrigger className='font-medium text-xs h-10 border border-gray2/50 text-gray7 my-1 max-w-[220px] truncate'>
                                    <SelectValue placeholder={`Enter ${col?.label}`} />
                                </SelectTrigger>
                                <SelectContent className='w-56 h-80'>
                                    {col?.data?.map(data => (
                                        <SelectItem key={data.id} value={data.id}>
                                            {data?.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        ) : (
                            <></>
                        )}
                    </td>
                ))}
        </tr>
    )
}

export default AccountingRow
