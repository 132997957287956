import { TransactionType } from '@/types/transactions'
import { createQuery } from '../api/query'
import { NewMonthlySummary, QueryOrgTransactionSummary, QueryOrgTransactions, QueryRecentActivities } from './types'

export const orgTransactionKey = ['/org-transaction']
export const orgTransactionByMonthKey = ['/org-transaction-by-month']
export const orgTransactionIdKey = (arg: string) => ['/org-transaction', arg]
export const useGetTransactions = createQuery<QueryOrgTransactions>({
    url: '/get-transactions',
    key: orgTransactionKey,
})

export const useGetTransactionsSummary = createQuery<QueryOrgTransactionSummary>({
    url: '/get-transaction-summary',
    key: ['org-transaction-summary'],
})
export const useGetSpecificMonthTransact = createQuery<NewMonthlySummary[]>({
    url: '/get-transactions-by-month',
    key: orgTransactionByMonthKey,
})

export const useGetUserTransactions = createQuery<TransactionType[]>({
    url: '/get-user-transactions',
    key: orgTransactionKey,
})
export const useGetOrgTransactions = createQuery<QueryOrgTransactions>({
    url: '/get-org-transactions',
    key: orgTransactionKey,
})

export const useGeTransactionById = createQuery<TransactionType>({
    url: '/get-transaction-by-id',
    key: orgTransactionIdKey,
})

export const useGetVendorTransactions = createQuery<TransactionType[]>({
    url: '/get-vendor-transactions',
    key: orgTransactionKey,
})

export const useGetCustomerTransactions = createQuery<TransactionType[]>({
    url: '/customer-transactions',
    key: orgTransactionKey,
})

export const useGetRecentActivities = createQuery<QueryRecentActivities>({
    url: '/events',
    key: ['/events'],
})
