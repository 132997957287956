import ModalWrapper from '../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { Controller, useForm } from 'react-hook-form'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/Select'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/Textarea'
import { Button } from '@/components/Button'
import { ChartOfAccountType } from '@/types/accounting'
import { useAccountingContext } from '@/context'
import { SelectSeparator } from '@radix-ui/react-select'
import { useCreateSingleCOA, useUpdateSingleCOA } from '@/services/accounting/mutations'
import { Switch } from '@headlessui/react'
import { cn } from '@/lib/styling'
import { useGetParentAccounts } from '@/services/accounting/queries'
import { useEffect } from 'react'

type FormType = {
    acct_type: string
    acct_name: string
    description: string
    acct_code?: number
    is_subaccount: boolean
    parent_account?: string
}
type Props = {
    closeModal: () => void
    selectedCoa?: ChartOfAccountType
    isEdit?: boolean
}

const AddNewAccountModal = ({ closeModal, isEdit, selectedCoa }: Props) => {
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isValid },
    } = useForm<FormType>({
        defaultValues: {
            acct_code: Number(selectedCoa?.code),
            acct_name: selectedCoa?.account_name,
            acct_type: selectedCoa?.account_type?.id ?? selectedCoa?.account_type?._id,
            description: selectedCoa?.description,
            is_subaccount: selectedCoa?.is_subaccount,
            parent_account: selectedCoa?.parent_account?.id,
        },
    })

    const { accounttingTypes } = useAccountingContext()
    const { data: parentAccounts, isLoading: isLoadingParents } = useGetParentAccounts({})

    const acctType = watch('acct_type')
    const parentAcct = watch('parent_account')
    const descValue = watch('description')

    const { mutate: createSingleFn, isLoading } = useCreateSingleCOA({ onSuccess: closeModal })

    const { mutate: updateSingleFn, isLoading: isUpdating } = useUpdateSingleCOA({
        queryParams: { id: (selectedCoa?._id ?? selectedCoa?.id) as string },
        onSuccess: closeModal,
    })

    const onSubmit = (values: FormType) => {
        const data = {
            account_name: values?.acct_name,

            code: values?.acct_code?.toString(),
            description: values?.description,
        }
        if (isEdit) {
            updateSingleFn({
                ...data,
            })
            return
        }

        createSingleFn({
            ...data,
            account_type: values?.acct_type,
            is_subaccount: values?.is_subaccount,
            ...(isSubAccount && {
                parent_account: parentAccounts?.results.find(item => item.id === parentAccountVal)?.id,
            }),
        })
    }

    const isSubAccount = watch('is_subaccount')
    const parentAccountVal = watch('parent_account')

    useEffect(() => {
        setValue(
            'acct_type',
            parentAccounts?.results.find(item => item.id === parentAccountVal)?.account_type?.id as string,
            {
                shouldDirty: true,
                shouldValidate: true,
            }
        )
    }, [parentAccountVal, isSubAccount])

    return (
        <ModalWrapper>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className=' min-h-[18rem] w-[calc(100vw-25px)] md:w-[28rem] overflow-scroll'
            >
                <div className='flex items-center border-b border-[#DADCE0] p-3  justify-between'>
                    <h2 className='text-center text-lg font-semibold opacity-80'>
                        {isEdit ? 'Edit' : 'Add new'} account
                    </h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='px-5 space-y-4 mt-5'>
                    <Controller
                        name='is_subaccount'
                        rules={{
                            required: false,
                        }}
                        control={control}
                        render={({ field }) => (
                            <div className='flex gap-3 justify-between items-center'>
                                <p className='text-sm text-gray7 font-medium py-1'>Create as a sub-account</p>
                                <Switch
                                    type='button'
                                    onChange={field.onChange}
                                    checked={field.value}
                                    className={cn(
                                        `relative inline-flex h-6 w-11 items-center rounded-full`,
                                        field.value ? 'bg-brand' : 'bg-[#EFEFF9]'
                                    )}
                                >
                                    <span
                                        className={`${
                                            field.value ? 'translate-x-6 bg-[#D5D5F5]' : 'translate-x-1 bg-brand'
                                        } inline-block h-4 w-4 transform rounded-full transition`}
                                    />
                                </Switch>
                            </div>
                        )}
                    />
                    {isSubAccount && (
                        <fieldset className='flex flex-col gap-1.5'>
                            <label className='text-sm' htmlFor='funding_method'>
                                Parent account <span className='text-errorRed'>*</span>
                            </label>
                            <Controller
                                name='parent_account'
                                rules={{
                                    required: isSubAccount,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        onValueChange={e => {
                                            console.log('first')
                                            field.onChange(e)
                                        }}
                                        value={field.value}
                                    >
                                        <SelectTrigger
                                            disabled={isEdit}
                                            className=' text-sm  h-10  border-gray8 flex disabled:opacity-70'
                                            variant={parentAcct ? 'selected' : 'new'}
                                        >
                                            <SelectValue className='' placeholder={'Select parent account'} />
                                        </SelectTrigger>
                                        <SelectContent className='w-[24.5rem] h-56'>
                                            {parentAccounts?.results.map((type, idx) => (
                                                <SelectItem value={type?.id} key={idx}>
                                                    <div className='flex items-center gap-2'>{type?.account_name}</div>
                                                </SelectItem>
                                            ))}
                                            {parentAccounts?.results?.length === 0 && !isLoadingParents && (
                                                <p className='py-1 pl-2 text-sm'>There are no parents account</p>
                                            )}
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                        </fieldset>
                    )}

                    <fieldset className='flex flex-col gap-1.5'>
                        <label className='text-sm' htmlFor='funding_method'>
                            Account type <span className='text-errorRed'>*</span>
                        </label>
                        <Controller
                            name='acct_type'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Select onValueChange={field.onChange} value={field.value} disabled={isSubAccount}>
                                    <SelectTrigger
                                        disabled={isEdit}
                                        className=' text-sm  h-10  border-gray8 flex disabled:opacity-70'
                                        variant={acctType ? 'selected' : 'new'}
                                    >
                                        <SelectValue className='' placeholder={'Select account type'} />
                                    </SelectTrigger>
                                    <SelectContent className='w-[24.5rem] h-56'>
                                        {accounttingTypes?.map(type => (
                                            <SelectGroup className='my-1'>
                                                <SelectLabel className='text-black pl-2'>{type?.name}</SelectLabel>
                                                {type?.children.map(child => (
                                                    <SelectItem
                                                        className='text-gray7 pl-6'
                                                        value={child?._id ?? child?.id}
                                                    >
                                                        {child?.name}
                                                    </SelectItem>
                                                ))}
                                                <SelectSeparator />
                                            </SelectGroup>
                                        ))}
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </fieldset>

                    <fieldset className='flex flex-col gap-1.5'>
                        <label className='text-sm ' htmlFor='funding_method'>
                            Account name <span className='text-errorRed'>*</span>
                        </label>
                        <Controller
                            name='acct_name'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type='text'
                                    placeholder='Enter account name'
                                    className='border-gray8 text-sm  h-10 placeholder:text-sm'
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset className='flex flex-col gap-1.5'>
                        <label className='text-sm flex items-center justify-between' htmlFor='funding_method'>
                            <p>
                                Description <span className='text-errorRed'>*</span>
                            </p>
                            <span className=' text-xs'>{descValue?.length ?? '0'}/120</span>
                        </label>
                        <Controller
                            name='description'
                            rules={{
                                required: true,
                                maxLength: 120,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Textarea
                                    {...field}
                                    value={field.value as string}
                                    className='border border-[#DADCE0] pt-2 min-h-[2rem] text-sm resize-none placeholder:text-sm'
                                    maxLength={120}
                                    placeholder='Enter description'
                                />
                            )}
                        />
                    </fieldset>

                    <fieldset className='mt-5 flex flex-col gap-1.5'>
                        <label className='text-sm' htmlFor='funding_method'>
                            Account code
                        </label>
                        <Controller
                            name='acct_code'
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type='number'
                                    placeholder='Enter account code'
                                    className=' border-gray8 text-sm w-56 h-10 placeholder:text-sm'
                                />
                            )}
                        />
                        <span className='text-[10px] leading-[12px] -mt-1 text-gray7 font-semibold'>
                            Account code is optional but recommended for easier reference
                        </span>
                    </fieldset>
                </div>
                <div className='border-t border-gray4 flex mt-6 mb-3 justify-end items-center pt-2.5 px-4'>
                    <Button
                        disabled={!isValid}
                        loading={isLoading || isUpdating}
                        type='submit'
                        className='text-sm min-h-[2.5rem] py-4'
                    >
                        {isEdit ? 'Edit' : ' Save new'} account
                    </Button>
                </div>
            </form>
        </ModalWrapper>
    )
}

export default AddNewAccountModal
