import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Header from './LayoutComponents/Header'
import { isAdmin } from '@/utils/permissions'
import { useAuthContext, useExpenseContext } from '@/context'
import UpgradePlanModal from '@/components/Modals/Upgrade-plan'
import UserViewUpgradeModal from '@/components/Modals/Upgrade-plan/UserViewUpgradeModal'
import CurrencyContextProvider from '@/context/currency'
import NewSidebar from './LayoutComponents/NewSideBar'
import { useIsMobile } from '@/hooks/useIsMobile'
import { cn } from '@/lib/styling'
import { BackIcon } from '@/assets/assets.export'

const DashboardLayout = () => {
    const location = useLocation()

    const { showUpgradeModal, setShowUpgradeModal, currentModule, selectedAccount } = useAuthContext()
    const { headerText, setHeaderText } = useExpenseContext()

    const [showSidebar, setShowSidebar] = useState(true)

    const isMobile = useIsMobile()

    const handleRouteChange = (newHeaderText: string) => {
        setHeaderText(newHeaderText)
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1024) setShowSidebar(true)
        }
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    if (location.pathname === '/dashboard') {
        return <Navigate to='/dashboard/overview' />
    }
    const showOnlyIcon = !showSidebar && !isMobile
    return (
        <CurrencyContextProvider>
            <div className='h-screen overflow-hidden bg-[#FAFAFD] flex '>
                <div
                    className={`fixed lg:relative z-[50] transition-all ${showSidebar || !isMobile ? 'left-0 ' : '-left-full'}  `}
                >
                    <NewSidebar
                        onRouteChange={handleRouteChange}
                        isMobile={isMobile}
                        showSidebar={showSidebar}
                        setShowSidebar={setShowSidebar}
                    />
                </div>
                <div className='bg-white px-1 md:px-4 lg:px-0  flex-1 w-full lg:w-[calc(100vw-240px)]'>
                    <Header headerText={headerText} setShowSidebar={setShowSidebar} />
                    <button
                        aria-label='close-sidebar'
                        className={cn(
                            'bg-slate-50 rounded-full border border-gray3 p-0.5 absolute top-8 left-[52px] z-50',
                            !showOnlyIcon && 'hidden'
                        )}
                    >
                        <BackIcon
                            strokeWidth={2}
                            className={`cursor-pointer rotate-180 ${showOnlyIcon ? 'w-4 h-4' : 'w-5 h-5'} stroke-2`}
                            onClick={() => setShowSidebar?.(!showSidebar)}
                        />
                    </button>

                    <div className='bg-[#FAFAFD] h-[calc(100vh-81px)]  overflow-scroll'>
                        <div className='scroll-smooth'>{<Outlet />}</div>
                    </div>
                </div>
                {showUpgradeModal &&
                    (isAdmin(selectedAccount!) ? (
                        <UpgradePlanModal module={currentModule} closeModal={() => setShowUpgradeModal(false)} />
                    ) : (
                        <UserViewUpgradeModal module={currentModule!} closeModal={() => setShowUpgradeModal(false)} />
                    ))}
            </div>
        </CurrencyContextProvider>
    )
}

export default DashboardLayout
