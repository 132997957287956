import { useGetRecentActivities } from '@/services/transactions/queries'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { capitalizeText } from '@/utils/string'
import { formatRelative } from 'date-fns'
import { RecentExpensesIcon } from '@/assets/assets.export'
import { activityDescription } from './utils'

export const RecentActivities = () => {
    const { data: events, isLoading: isLoadingEvents } = useGetRecentActivities({})

    return (
        <div className='border border-gray8 rounded-[20px] py-5 px-3 lg:px-6 bg-white'>
            <p className='text-lg font-semibold'>Recent activities</p>
            <p className='text-gray7 font-medium text-sm'>These are your most recent activities</p>
            <div className='mt-5 space-y-3'>
                {isLoadingEvents
                    ? [1, 2, 3, 4].map(num => (
                          <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={93} key={num}>
                              <Skeleton className='my-2' />
                          </SkeletonTheme>
                      ))
                    : events?.results?.slice(0, 5)?.map(event => {
                          const eventToShow = event?.events?.[event?.events?.length - 1]
                          return (
                              <div className='py-3 px-2 lg:px-4 bg-offwhite border border-gray8 rounded-[8px]'>
                                  <div className='flex w-full justify-between items-start font-semibold'>
                                      <p className='max-w-[68%]'>{event?.module_type}</p>
                                      <p className='text-gray7 text-sm'>
                                          {capitalizeText(
                                              formatRelative(
                                                  new Date(eventToShow?.date ?? event?.createdAt),
                                                  new Date()
                                              )
                                          )
                                              ?.replaceAll('at', '@')
                                              .replace(/am|pm/gi, match => match.toUpperCase())}
                                          {/* @ {`${format(new Date(trans?.createdAt), 'hh:mm aa')}`} */}
                                      </p>
                                  </div>
                                  <div className='mt-2'>
                                      <p className='text-sm'>
                                          <span
                                              className='font-medium text-gray7'
                                              dangerouslySetInnerHTML={{ __html: activityDescription(event) }}
                                          ></span>{' '}
                                      </p>
                                  </div>
                              </div>
                          )
                      })}
                {events?.results?.length === 0 && !isLoadingEvents && (
                    <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                        <RecentExpensesIcon />
                        <h2 className='text-gray7 font-medium mt-5'>No activities yet</h2>
                        {/* <p className='text-sm'>No transaction has been made yet</p> */}
                    </div>
                )}
            </div>
        </div>
    )
}
