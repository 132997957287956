import { Button } from '@/components'

import ModalWrapper from './ModalWrapper'

type Props = {
    closeModal: () => void
    loading: boolean
    action: () => void
}

const ConfirmationPrompt = ({ closeModal, loading, action }: Props) => {
    return (
        <ModalWrapper>
            <main className='h-[14rem] w-screen lg:w-[34rem]'>
                <div className='pt-10'>
                    <p className='text-[#5E5E5E] font-semibold text-lg mb-10 w-[85%] mx-auto'>
                        Are you sure you want to perform this action? This action is irreversible.
                    </p>
                    <div className='flex gap-5 justify-end pr-10'>
                        <Button
                            className='bg-transparent text-[#202020] px-5 h-[3.5rem] rounded-lg text-xs disabled:bg-transparent hover:bg-transparent min-w-[100px]'
                            onClick={closeModal}
                            disabled={loading}
                        >
                            Cancel
                        </Button>

                        <Button
                            className='bg-errorRed text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] hover:bg-errorRed hover:opacity-90 disabled:bg-errorRed disabled:opacity-30'
                            onClick={action}
                            loading={loading}
                        >
                            Yes, continue
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default ConfirmationPrompt
