import React, { useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { BizpendAccounts, CancelIcon } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { Button } from '@/components/Button'
import { calculateTransferFee, formatAmount } from '@/utils/money'
import { useGetAccounts } from '@/services/bill-payment/queries'
import { useAuthContext } from '@/context'
import { storagePlan } from './utils'
import { usePurchaseVaultStorage } from '@/services/vault/mutations'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    setStep: React.Dispatch<React.SetStateAction<number>>
    storagePlanId?: string
}
export const PayForStorage = ({ closeModal, storagePlanId, setStep }: Props) => {
    const selectedStorage = storagePlan?.[Number(storagePlanId) - 1]
    const { selectedAccount } = useAuthContext()

    const [formState, setFormState] = useState({
        account: '',
    })
    const { data: accounts } = useGetAccounts({
        queryParams: {
            entity_id: selectedAccount?.org?.id as string,
        },
    })

    const { mutate: purchaseStorageFn, isLoading: isPurchasing } = usePurchaseVaultStorage({
        onSuccess: () => {
            closeModal(false)
            setStep(2)
        },
    })
    const handleStoragePurchase = () => {
        purchaseStorageFn({
            id: selectedStorage.id,
            storage_quantity: Number(selectedStorage.name.slice(0, 1)),
            source_type: formState.account === 'Wallet' ? 'Wallet' : 'DepositAccount',
            source_id: formState.account === 'Wallet' ? '' : formState.account,
            amount: Number(selectedStorage?.price),
        })
    }
    const disableButton = () => {
        const res = Object.values({ ...formState })
            .map(value => !!value)
            .some(data => data === false)

        return res
    }

    return (
        <ModalWrapper variants={undefined}>
            <main className='max-h-[80vh] h-fit w-screen lg:w-[36rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium'>Get more storage - {selectedStorage.name} </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='p-7 pt-5 max-h-[calc(80vh-148px)] h-fit overflow-y-scroll'>
                    <div className='rounded-2xl p-5 bg-[#EFEFF9] flex flex-col gap-2 mb-6'>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Storage size</p>
                            <p className='font-semibold'>{selectedStorage.name}</p>
                        </div>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Amount</p>{' '}
                            <p className='font-semibold'>{formatAmount(selectedStorage.price)}</p>
                        </div>
                    </div>

                    <div className='mt-5 flex flex-col flex-1 '>
                        <label className='mb-3 text-sm' htmlFor='funding_method'>
                            Pay with this account
                        </label>
                        <Select
                            name='fund_source'
                            onValueChange={value => {
                                setFormState(prevState => ({
                                    ...prevState,
                                    account: value,
                                }))
                            }}
                            value={formState.account}
                        >
                            <SelectTrigger className=' text-base h-[3.3rem]  text-[#5E5E5E] border-gray3 flex '>
                                {formState?.account ? <SelectValue /> : <span>Select account</span>}
                            </SelectTrigger>
                            <SelectContent className='max-h-[12rem] overflow-y-scroll'>
                                <SelectItem value={'Wallet'} className='pl-3'>
                                    <div className='flex items-center gap-3'>
                                        <BizpendAccounts
                                            className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`}
                                        />
                                        <div className='flex flex-col items-start'>
                                            <h6 className='text sm'>Main Wallet</h6>
                                        </div>
                                    </div>
                                </SelectItem>
                                {accounts?.accounts?.map((acct: any) => (
                                    <SelectItem
                                        value={acct?._id}
                                        className='pl-3'
                                        key={acct.id}
                                        disabled={Number(selectedStorage.price) > acct.balance}
                                    >
                                        <div className='flex items-center gap-3'>
                                            <BizpendAccounts
                                                className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`}
                                            />
                                            <div className='flex flex-col justify-start items-start'>
                                                <h6 className='text sm'>{acct.nickname ?? acct.account_name}</h6>
                                                <p className='text-xs text-gray1 font-medium'>
                                                    {formatAmount(acct.balance)}
                                                </p>
                                            </div>
                                        </div>
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className='rounded-2xl px-4 py-3 bg-[#EFFFED] border border-[#19C37D] flex flex-col gap-2 mb-2 mt-6'>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Fee</p>
                            <p className='font-semibold'>0.5%</p>
                        </div>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Total debit amount</p>{' '}
                            <p className='font-semibold'>
                                {formatAmount(
                                    calculateTransferFee(`${selectedStorage.price}`) * 100 +
                                        Number(selectedStorage.price)
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <section className='flex justify-end gap-6 border-t-[1px] border-[#DADCE0] py-4 pr-7 h-[83px]'>
                    <Button
                        className='bg-[#19C37D] hover:bg-[#255541] min-w-[100px] px-4'
                        type='submit'
                        disabled={disableButton() || isPurchasing}
                        loading={isPurchasing}
                        onClick={() => {
                            handleStoragePurchase()
                        }}
                    >
                        Make payment
                    </Button>
                </section>
            </main>
        </ModalWrapper>
    )
}
