import { Tabs, TabTriggersContainer, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import AccountingTransactions from './partials/AccountingTransactions'
import AccountingHistory from './partials/AccountingHistory'
import AccountingSettings from './partials/AccountingSettings'
import AccountingChartOfAccount from './partials/AccountingChartOfAccount'

const route_const = {
    coa: 'coa',
    transactions: 'transactions',
    history: 'history',
    settings: 'settings',
}

const Accounting = () => {
    const { accountingSubRoute } = useParams()
    const navigate = useNavigate()
    const currentRoute = location.pathname.split('/').slice(0, -1).join('/')

    return (
        <section className='mt-4'>
            <Tabs defaultValue={accountingSubRoute} value={accountingSubRoute}>
                <TabsList className='border-none flex justify-between mx-auto overflow-scroll items-center'>
                    <TabTriggersContainer>
                        <TabsTrigger
                            value={route_const.coa}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.coa}`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.coa === accountingSubRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.coa === accountingSubRoute && (
                                <motion.div
                                    layoutId='accounting'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative whitespace-nowrap'>
                                Chart of Accounts
                            </motion.span>
                        </TabsTrigger>
                        <TabsTrigger
                            value={route_const.transactions}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.transactions}?type=all`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.transactions === accountingSubRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.transactions === accountingSubRoute && (
                                <motion.div
                                    layoutId='accounting'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                Transactions
                            </motion.span>
                        </TabsTrigger>
                        <TabsTrigger
                            value={route_const.history}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.history}`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.history === accountingSubRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.history === accountingSubRoute && (
                                <motion.div
                                    layoutId='accounting'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                History
                            </motion.span>
                        </TabsTrigger>
                        <TabsTrigger
                            value={route_const.settings}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.settings}`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.settings === accountingSubRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.settings === accountingSubRoute && (
                                <motion.div
                                    layoutId='accounting'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                Settings
                            </motion.span>
                        </TabsTrigger>
                    </TabTriggersContainer>
                </TabsList>
                <TabsContent value={route_const.coa}>
                    <PageContent>
                        <AccountingChartOfAccount />
                    </PageContent>
                </TabsContent>
                <TabsContent value={route_const.transactions}>
                    <PageContent>
                        <AccountingTransactions />
                    </PageContent>
                </TabsContent>
                <TabsContent value={route_const.history}>
                    <PageContent>
                        <AccountingHistory />
                    </PageContent>
                </TabsContent>
                <TabsContent value={route_const.settings}>
                    <PageContent>
                        <AccountingSettings />
                    </PageContent>
                </TabsContent>
            </Tabs>
        </section>
    )
}

export default Accounting
