import { Button, Input } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import usePersistedState from '@/hooks/usePersistedState'
import { useResendOtp, useVerifyAdminEmail } from '@/services/auth/mutations'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

interface Step2Props {
    toggleStep?: (step: number) => void
    emailForOtp: string
}

const Step2 = ({ toggleStep, emailForOtp }: Step2Props) => {
    const [timer, setTimer] = usePersistedState({ key: 'otpTimer', defaultValue: 120 })

    const [, searchParamDetails] = useAddQueryParamsToURL()
    const loggedInEmail = decodeURIComponent(searchParamDetails.get('email') as string)
    const verifyFromLoginpath = window.location.pathname.includes('verify-email')
    const navigate = useNavigate()

    const {
        formState: { isValid },
        control,
        handleSubmit,
    } = useForm<{ otp: number }>()

    const { mutate: verifyAdminEmail, isLoading } = useVerifyAdminEmail({
        onSuccess: () => {
            toastHandler({ message: 'Email verified', state: 'success' })
            if (verifyFromLoginpath) {
                navigate('/login')
            }
            toggleStep?.(3)
        },
    })
    const { mutate: resendOtpFn } = useResendOtp({})

    const submitEmailVerification = (values: { otp: number }) => {
        verifyAdminEmail({
            code: values.otp,
            email: verifyFromLoginpath ? loggedInEmail : emailForOtp,
        })
    }

    const previousStep = () => {
        toggleStep?.(1)
    }
    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1)
            }
            if (timer === 0) {
                setTimer(0)
                clearInterval(interval)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })
    useEffect(() => {
        if (verifyFromLoginpath) {
            resendOtpFn({})
            setTimer(120)
        }
    }, [])
    return (
        <form
            onSubmit={handleSubmit(submitEmailVerification)}
            className='w-[90%] md:w-[75%] max-w-[1100px] mx-auto my-12'
        >
            {!verifyFromLoginpath && (
                <div className='flex justify-end mb-8'>
                    <span className='py-2 px-4 bg-[#EFEFF9] rounded-2xl flex items-center gap-2'>
                        2 / 2 <span className='font-semibold'> steps</span>
                    </span>
                </div>
            )}

            <div className='md:w-[65%] mx-auto'>
                <h2 className='text-[#31254B] text-3xl mb-4 font-semibold'>Verify Your Email</h2>
                <p className='text-[#202020] mb-6 text-[18px]'>
                    Simply enter the OTP sent to verify your email address
                </p>

                <div className='flex flex-col md:flex-row mb-6'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Enter OTP
                        </label>
                        <Controller
                            name='otp'
                            rules={{
                                required: true,
                                minLength: 4,
                            }}
                            control={control}
                            render={({ field }) => <Input type='number' {...field} placeholder='Enter your OTP' />}
                        />
                    </div>
                </div>

                <div className='text-center text-[14px] mt-6'>
                    <span className='flex items-center justify-center gap-2'>
                        I did not receive OTP.
                        <button
                            className='text-[#454ADE] font-semibold disabled:text-[#A8A8A8]'
                            onClick={() => {
                                resendOtpFn({})
                                setTimer(120)
                            }}
                            type='button'
                            disabled={timer > 0}
                        >
                            Resend
                        </button>
                        {timer !== 0 && (
                            <span className='text-[#A8A8A8]'>in {timer < 10 ? `0${timer}` : timer} secs</span>
                        )}
                    </span>
                </div>
            </div>

            <div className='flex items-center gap-6 justify-end mt-[160px]'>
                {!verifyFromLoginpath && (
                    <button type='button' onClick={previousStep} className='flex items-center gap-3 text-[14px]'>
                        <AiOutlineArrowLeft fontSize={15} className='mt-1' />
                        go back
                    </button>
                )}

                <Button type='submit' disabled={!isValid} loading={isLoading}>
                    Complete verification
                </Button>
            </div>
        </form>
    )
}

export default Step2
