import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion/index2'
import { ExpenseApprovalPolicy } from '@/services/taxes/types'
import { SupportedPolicyConsts } from '@/types/approval-policy'
import { ArrowRight } from 'lucide-react'
import { useState } from 'react'
import ApprovalPolicyCardDetails from './ApprovalPolicyCardDetails'

type Props = {
    policies: ExpenseApprovalPolicy[]
    module: SupportedPolicyConsts
}

const ApprovalPolicyListCard = ({ policies, module }: Props) => {
    const [showPrev, setShowPrev] = useState(false)
    const activePolicy = policies?.filter(policy => policy?.active)?.[0]
    const allVersions = policies.filter(policy => policy._id !== (activePolicy?._id ?? policies?.[0]?._id))

    return (
        <div
            className={`w-full rounded-2xl mb-4 ${activePolicy?.active ? 'bg-gradient-to-r from-[#19C37D] via-[#45B0DE69] to-[#78EFE8B8] p-[3px]' : ''} `}
        >
            <div className={`p-[19px] pl-4 bg-white rounded-2xl ${activePolicy?.active ? 'bg-[#DEEBDC]' : ''}`}>
                <ApprovalPolicyCardDetails module={module} policy={activePolicy ?? policies?.[0]} />
                {policies?.length > 1 && (
                    <Accordion type='single' collapsible>
                        <AccordionItem value='item-1' className='border-none'>
                            <AccordionTrigger className='w-[234px]'>
                                <span
                                    className='flex justify-between bg-[#EFEFF9] text-brand px-4 py-2 rounded-[64px] w-[234px] mt-5 cursor-pointer'
                                    onClick={() => setShowPrev(!showPrev)}
                                >
                                    {showPrev ? 'Hide' : 'See'} all versions{' '}
                                    <div className={`${showPrev ? '-rotate-90' : ''} transition-all`}>
                                        <ArrowRight />
                                    </div>
                                </span>
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className='mt-3'>
                                    <div className='py-3 border-b border-[#DADCE0] mb-5'>
                                        <h1>All Versions</h1>
                                    </div>
                                    <div>
                                        {allVersions.map((policy, i) => (
                                            <ApprovalPolicyCardDetails
                                                prevVersion={true}
                                                module={module}
                                                policy={policy}
                                                key={i}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                )}
            </div>
        </div>
    )
}

export default ApprovalPolicyListCard
