import { ReactNode, createContext, useMemo } from 'react'
import { useAuthContext } from '.'
import { useGetSupportedCurrency } from '@/services/currency/queries'
import { CurrencyNames, CurrencySymbols, CurrencyType } from '@/types/currency'
import { ReactComponent as NigeriaIcon } from '@/assets/countries/nigeria.svg'
import { ReactComponent as UKIcon } from '@/assets/countries/uk.svg'
import { ReactComponent as USAicon } from '@/assets/countries/usa.svg'
import { ReactComponent as EuropeIcon } from '@/assets/countries/europe.svg'
import { TbCurrencyDollar, TbCurrencyEuro, TbCurrencyNaira, TbCurrencyPound } from 'react-icons/tb'

type CurrencyIconType = {
    [key: string]: JSX.Element
}
export type currencyDefaultTypes = {
    currencies: CurrencyType[]
    CurrencyIcon: CurrencyIconType
    selectedCurrencyInfo: (id?: string) => Partial<CurrencyType> & {
        Icon: ReactNode
    }
}

const defaultValues: currencyDefaultTypes = {
    currencies: [],
    CurrencyIcon: {},
    selectedCurrencyInfo: () => ({
        Icon: null,
    }),
}

const CurrencyIcon = {
    [CurrencySymbols.NGN]: <TbCurrencyNaira size={19} />,
    [CurrencyNames.NGN]: <TbCurrencyNaira size={19} />,
    [CurrencySymbols.USD]: <TbCurrencyDollar size={19} />,
    [CurrencyNames.USD]: <TbCurrencyDollar size={19} />,
    [CurrencySymbols.EUR]: <TbCurrencyEuro size={19} />,
    [CurrencyNames.EUR]: <TbCurrencyEuro size={19} />,
    [CurrencySymbols.GBP]: <TbCurrencyPound size={19} />,
    [CurrencyNames.GBP]: <TbCurrencyPound size={19} />,
}
const CurrencyFlag = {
    [CurrencyNames.NGN]: <NigeriaIcon className='w-3.5 h-3.5' />,

    [CurrencyNames.USD]: <USAicon className='w-3.5 h-3.5' />,

    [CurrencyNames.EUR]: <EuropeIcon className='w-3.5 h-3.5' />,

    [CurrencyNames.GBP]: <UKIcon className='w-3.5 h-3.5' />,
}

export const CurrencyContext = createContext(defaultValues)

export default function CurrencyContextProvider({ children }: { children: ReactNode }) {
    const { loggedIn, selectedAccount } = useAuthContext()
    const { data } = useGetSupportedCurrency({ enabled: !!loggedIn })

    const currencies = useMemo(
        () => data?.map(currency => ({ ...currency, flag: CurrencyFlag?.[currency?.name] })) ?? [],
        [data]
    )

    const selectedCurrencyInfo = (id?: string) => {
        const defaultCurrency = selectedAccount?.org?.currency
        const selectedCurrency = id
            ? currencies?.find(currency => currency?.id === id)
            : currencies?.find(currency => currency?.id === defaultCurrency?.id)

        return { ...selectedCurrency, Icon: CurrencyIcon?.[selectedCurrency?.symbol as string] }
    }

    return (
        <CurrencyContext.Provider value={{ currencies, CurrencyIcon, selectedCurrencyInfo }}>
            {children}
        </CurrencyContext.Provider>
    )
}
