import { cn } from '@/lib/styling'
import { ArrowRight, TrendingDown, TrendingUp } from 'lucide-react'
import { Link } from 'react-router-dom'

type CardsType = {
    card: {
        heading: string
        amount: string
        action?: string
        change?: string
        subText: string
        options: string[]
        miniText?: string
    }
}
export const RevenueCard = ({ card }: CardsType) => {
    return (
        <div className='bg-white border border-gray8 rounded-[12px] p-4 bg-gradient-to-br from-[#8583E1] from-[15%] to-[#3C26B5] to-[90%]'>
            <div>
                <div className='flex justify-between items-start'>
                    <p className='text-white font-semibold mb-1'>{card?.heading}</p>
                </div>
                <p className='text-[2rem] text-white font-medium'>{card?.amount}</p>
            </div>
            <div className='w-full h-px bg-gradient-to-r from-transparent from-10% via-white to-90% to-transparent mb-4 mt-3'></div>
            <div className=''>
                {card?.change ? (
                    <p className='text-sm text-white font-semibold flex gap-2 items-center'>
                        <span
                            className={cn(
                                'mr-2 flex items-center gap-0.5',
                                card?.change?.[0] !== '-' ? 'text-[#19C37D]' : 'text-errorRed'
                            )}
                        >
                            {card?.change?.[0] !== '-' ? (
                                <TrendingUp size={18} />
                            ) : (
                                <TrendingDown className='transform scale-x-[-1]' size={18} />
                            )}
                            {card?.change?.[0] === '-' ? card?.change?.slice(1) : card?.change}
                        </span>
                        {card?.subText}
                    </p>
                ) : (
                    <Link
                        to={card?.action ? card?.action : ''}
                        className='flex w-full justify-between items-center text-sm font-semibold cursor-pointer'
                    >
                        <p className='text-brand'>View accounts</p>
                        <ArrowRight color='#454ade' />
                    </Link>
                )}
            </div>
        </div>
    )
}
