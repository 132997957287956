import { useState } from 'react'
import blueTick from '@/assets/blue-tick.svg'
import greyTick from '@/assets/grey-tick.svg'
import signupImage from '@/assets/sidebar sign up image.svg'
import Step1 from './Step1'
import Step2 from './Step2'
import SignupSuccess from './SignupSuccess'
import { useAuthContext } from '@/context'
import Logo from '@/assets/bizpendlogo.png'

const SignUpRoot = () => {
    const { setSignUpStep, signUpStep } = useAuthContext()
    const [emailForOtp, setEmailForOtp] = useState('')

    return (
        <div className='w-full h-full min-h-[100vh] grid grid-cols-4'>
            <div
                className={
                    signUpStep === 3
                        ? `hidden`
                        : `lg:col-span-1 border-r border-[#DADCE0] h-full hidden lg:block bg-[#EFEFF9]`
                }
            >
                <div className='p-8 py-3 border-b border-[#DADCE0]'>
                    <div className=''>
                        <img src={Logo} alt='logo' className='w-[137px]' />
                    </div>
                </div>

                <div className='pt-12 flex flex-col justify-between ' style={{ height: 'calc(100% - 81px)' }}>
                    {signUpStep !== 4 && (
                        <div className='px-8 pb-12'>
                            <div className='flex gap-3 items-start mb-8'>
                                <img src={signUpStep === 1 ? `${blueTick}` : `${greyTick}`} alt='tick' />
                                <div className='flex flex-col gap-2'>
                                    <span
                                        className={
                                            signUpStep === 1
                                                ? `text-[20px] font-bold text-[#202020]`
                                                : `text-[20px] font-bold text-[#838383]`
                                        }
                                    >
                                        Join us today
                                    </span>
                                    <p
                                        className={
                                            signUpStep === 1
                                                ? `text-[#5E5E5E] pr-8 text-[18px] font-medium`
                                                : `text-[#5E5E5E] pr-8 text-[18px]`
                                        }
                                    >
                                        Enter your organization email and create password
                                    </p>
                                </div>
                            </div>

                            <div className='flex gap-3 items-start mb-8'>
                                <img src={signUpStep === 2 ? `${blueTick}` : `${greyTick}`} alt='tick' />
                                <div className='flex flex-col gap-2'>
                                    <span
                                        className={
                                            signUpStep === 2
                                                ? `text-[20px] font-bold text-[#202020]`
                                                : `text-[20px] font-bold text-[#838383]`
                                        }
                                    >
                                        Verify your email
                                    </span>
                                    <p
                                        className={
                                            signUpStep === 2
                                                ? `text-[#5E5E5E] pr-8 text-[18px] font-medium`
                                                : `text-[#5E5E5E] pr-8 text-[18px]`
                                        }
                                    >
                                        Enter OTP sent to your email address
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='pr-8'>
                        <img src={signupImage} alt='sign-up image' className='w-full' />
                    </div>
                </div>
            </div>

            <div
                className={
                    signUpStep === 3 ? `col-span-4 h-full bg-[#FAFAFD]` : `col-span-4 lg:col-span-3 h-full bg-[#FAFAFD]`
                }
            >
                {signUpStep === 1 && <Step1 toggleStep={setSignUpStep} setEmailForOtp={setEmailForOtp} />}

                {signUpStep === 2 && <Step2 toggleStep={setSignUpStep} emailForOtp={emailForOtp} />}

                {signUpStep === 3 && <SignupSuccess />}
            </div>
        </div>
    )
}

export default SignUpRoot
