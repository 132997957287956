import ExpenseListTable from '../components/ExpenseListTable'
import notFound from '@/assets/not-found.svg'
import { CiSearch } from 'react-icons/ci'
import React, { useEffect, useMemo, useState } from 'react'
import { useQueryOrgExpenses } from '@/services/expenses/queries'
import { useMutationAuthorizeExpense } from '@/services/expenses/mutations'
import { useAuthContext, useExpenseContext } from '@/context'
import filter from '@/assets/filter.svg'
import CreateScanExpenseModal from '@/components/Modals/Expense/CreateScanExpenseModal'
import ProgressBar from '@/components/ProgressBar'
import { ExpenseStatus, ModalType } from '../const'
import { getSortString } from '@/components/Table/utils'
import { useGetIfHasApprovalPolicy } from '@/utils/permissions'
import { debounce } from 'lodash'
import { ColumnSort } from '@tanstack/react-table'
import { filter_consts } from '@/components/Modals/Expense/utils'
import FilterExpenseChip from '../utils/FilterExpenseChip'
import { useGetOrgMembers } from '@/services/employees/queries'
import { QueryOrgMembers } from '@/services/employees/types'
import { Menu } from '@headlessui/react'
import { toastHandler } from '@/components/utils/Toast'
import { useGetAccountsForUser } from '@/services/payout/queries'
import ExpenseFormRoot from '@/components/Modals/Expense/CreateExpense/ExpenseFormRoot'
import { AnimatePresence } from 'framer-motion'
import FilterExpense from '@/components/Modals/Expense/FilterExpense'
import { useParams, useSearchParams } from 'react-router-dom'
import useAccessToModule from '@/hooks/useAccessToModule'
import { ModuleNameEnum } from '@/types/billing'
import { useGetOrgCategories } from '@/services/categories/queries'
import { CategoryType } from '@/types/categories'
import { SupportedPolicyConsts } from '@/types/approval-policy'
import { ADMIN_EXPENSE_PERMISSIONS } from '@/utils/permisson-helper'
import { CurrencySelectFilter } from '@/components/CurrencyFilter'
import { NoPolicyWarning } from '@/components/Modals/Expense/CreateExpense/NoPolicyWarning'

const ExpenseList = () => {
    const { expense_subRoute } = useParams()
    const { selectedAccount, setShowUpgradeModal, setCurrentModule } = useAuthContext()

    const orgExpensePermissions = ADMIN_EXPENSE_PERMISSIONS.every(perm =>
        selectedAccount?.role?.permissions?.includes(perm)
    )
    const viewOrgExpense = location.pathname.includes('company') && orgExpensePermissions

    const [currency, setCurrency] = useState('all')
    const { showModal, setShowModal } = useExpenseContext()
    const [searchQuery, setSearchQuery] = useState('')
    const [showScanModal, setShowScanModal] = useState(false)
    const [showParams, setShowParams] = useState(true)
    const [showNoPolicyWarning, setShowNoPolicyWarning] = useState(false)
    const [openCreateDropDown, setOpenCreateDropDown] = useState(false)
    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)
    const [filterExpenseModal, setFilterExpenseModal] = useState(false)
    const [rowToBeModified, setRowToBeModified] = useState('')

    const [queryParameter] = useSearchParams()
    const status = queryParameter.get('status') as string

    const { enabled: isOCREnabled } = useAccessToModule({ module: ModuleNameEnum.OCR_SCAN })
    const { enabled: isManualExpenseEnabled } = useAccessToModule({ module: ModuleNameEnum.EXPENSES })

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }

    //context api to tell the page number we're currently at on the expense table
    const {
        pageNumber,
        sort,
        setPaginationDetails,
        setExpenses,
        setCategories,
        filterExpense,
        setFilterExpense,
        setOrgMembers,
        setPageNumberClicked,
        pageNumberClicked,
        orgMembers,
    } = useExpenseContext()

    //========gets all the categories=========
    const { data: categories } = useGetOrgCategories()

    const { data: orgMembersData } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
        onSuccess: members => {
            setOrgMembers(members as QueryOrgMembers)
        },
    })

    const allCategories = useMemo(() => categories ?? [], [categories])

    const { data: submitterAccount } = useGetAccountsForUser({
        queryParams: {
            account: selectedAccount?.id as string,
        },
    })

    const handleCheckPrimaryBank = () => {
        const bank_details = submitterAccount?.accounts?.filter(bankDetails => bankDetails.is_primary) ?? []
        if (bank_details?.length < 1) {
            return true
        }
        return false
    }

    const { mutate: authorizeExpenseRowFn, isLoading: isExpenseAuthorized } = useMutationAuthorizeExpense({
        queryParams: {
            id: rowToBeModified as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Expense Approved', state: 'success' })
        },
    })

    const handleDeclineRowExpense = async (rowId: string) => {
        setRowToBeModified(rowId)
        authorizeExpenseRowFn(
            { approve: ExpenseStatus.denied && false },
            {
                onSuccess: () => {
                    toastHandler({
                        message: 'Expense Declined',
                        state: 'success',
                    })
                },
            }
        )
    }
    const handleApproveRowExpense = async (rowId: string) => {
        setRowToBeModified(rowId)

        authorizeExpenseRowFn(
            { approve: ExpenseStatus.approved && true },
            {
                onSuccess: () => {
                    toastHandler({
                        message: 'Expense Approved',
                        state: 'success',
                    })
                },
            }
        )
    }
    const { hasPolicy, loading } = useGetIfHasApprovalPolicy(
        SupportedPolicyConsts.expense,
        selectedAccount?.org?.id as string
    )
    const {
        data,
        isLoading,
        isRefetching: isExpenseFetched,

        refetch: refetchAllOrgExpense,
    } = useQueryOrgExpenses({
        queryParams: {
            id: `${selectedAccount?.org.id as string}`,
            page: pageNumber as unknown as string,
            limit: '12',
            currency: currency === 'all' ? '' : currency,
            sort: getSortString(sort as ColumnSort),
            search: searchQuery,
            submitter: !viewOrgExpense
                ? (selectedAccount?.id as string)
                : filterExpense?.created_by !== filter_consts.initial
                  ? filterExpense?.created_by
                  : '',
            approver: filterExpense?.approved_by !== filter_consts.initial ? filterExpense?.approved_by : '',
            status: showParams ? status : filterExpense?.status,
            reimbursed:
                filterExpense?.expense_type?.length > 0
                    ? ((filterExpense?.expense_type === filter_consts.reimbursable ? true : false) as unknown as string)
                    : '',
            start_date: (filterExpense?.period?.from ?? filterExpense?.period?.time_in_words) as unknown as string,
            end_date: (filterExpense?.period?.to ?? new Date()) as unknown as string,
            min_amount: filterExpense?.amount?.min?.replaceAll(',', ''),
            max_amount: filterExpense?.amount?.max?.replaceAll(',', ''),
            category: filterExpense?.expense_category,
        },
        onSuccess: () => {
            if (data?.pagination) {
                setPaginationDetails(data?.pagination)
            }
            if (data?.expenses) {
                setExpenses(data?.expenses)
            }
            setBackSpace(false)
            setShowParams(false)
            setFilterExpense(prev => ({ ...prev, filter_now: false }))
            return orgMembersData
        },
    })
    const pagination_data = data?.pagination

    useEffect(() => {
        //===========without this if statement, network calls happen about 5 times for same request=========
        if (pageNumber && pageNumberClicked) {
            refetchAllOrgExpense()
            setPageNumberClicked(false)
        }
        if (sort || currency) {
            refetchAllOrgExpense()
        }
    }, [sort, pageNumber, pageNumberClicked, currency])

    useEffect(() => {
        refetchAllOrgExpense()
    }, [viewOrgExpense])

    //=======making a query search after every 3 seconds provided there's a searched query======//
    const debouncedSearch = React.useRef(
        debounce(() => {
            refetchAllOrgExpense()
        }, 300)
    ).current

    //=====refetches the expense when the advanced filter is used
    useEffect(() => {
        if (backspaceClicked) {
            refetchAllOrgExpense()
            setBackSpaceClicked(false)
        }
        if (filterExpense.filter_now) {
            refetchAllOrgExpense()
        }
        if (searchQuery) {
            debouncedSearch()
        }

        return () => {
            debouncedSearch.cancel()
        }
    }, [filterExpense.filter_now, refetchAllOrgExpense, backspaceClicked, searchQuery, debouncedSearch])

    //sets the  categories into the context
    useEffect(() => {
        if (categories) {
            setCategories(allCategories as CategoryType[])
        }
    }, [categories])

    useEffect(() => {
        if (status) {
            setFilterExpense(prev => ({ ...prev, status, filter_now: true }))
        }
    }, [queryParameter])

    const filteredExpenses = useMemo(() => data?.expenses ?? [], [data?.expenses])
    const handleClick = (type: string) => {
        if (type === ModalType.scannedModal) {
            setShowScanModal(true)
        } else {
            setShowModal(true)
        }
    }

    const advanceFilterTriggered = Object.values(filterExpense).some(value => {
        if (typeof value === 'string') {
            return value.length > 0
        }
        if (typeof value === 'object') {
            return Object.values(value).some(el => typeof el === 'string' && el.length > 0)
        }
    })

    useEffect(() => {
        if (expense_subRoute === 'quick_actions') {
            if (!hasPolicy || loading) {
                setShowNoPolicyWarning(true)
                return
            }
            if (!isManualExpenseEnabled) {
                setShowUpgradeModal(true)
                return
            }
            setShowModal(true)
        }
    }, [])

    if (isLoading) return <ProgressBar />

    return (
        <section className={`h-screen  ${filteredExpenses?.length > 0 ? '' : 'pb-6'} `}>
            <div className='my-1 mb-4 flex flex-col md:flex-row justify-between md:items-end mx-2'>
                <div>
                    <h1 className='text-dark font-medium text-lg w-full'>Expense lists</h1>
                    <p className='text-gray7 text-sm'>
                        {viewOrgExpense
                            ? 'View and manage expense reports submitted by employees'
                            : 'View and manage your personal expense reports'}
                    </p>
                </div>
                <Menu as='div' id='create-expense'>
                    <Menu.Button
                        onClick={() => {
                            if (!hasPolicy || loading) {
                                setShowNoPolicyWarning(true)
                                return
                            }
                            setOpenCreateDropDown(true)
                        }}
                        className='bg-[#454ADE] mt-2 md:mt-0 text-white px-4 py-3 rounded md:rounded-lg text-sm min-w-full text-center'
                    >
                        Create expense
                    </Menu.Button>
                    {openCreateDropDown && (
                        <Menu.Items className='w-[172.56px] flex flex-col absolute py-[13px] px-0 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'>
                            <Menu.Item>
                                <span
                                    className='manual-expense px-4 py-2 cursor-pointer hover:bg-[#454ADE] hover:text-white'
                                    onClick={() => {
                                        setOpenCreateDropDown(false)
                                        if (!isManualExpenseEnabled) {
                                            setShowUpgradeModal(true)
                                            setCurrentModule(ModuleNameEnum.EXPENSES)
                                            return
                                        }
                                        handleClick(ModalType.manualModal)
                                    }}
                                >
                                    Manually
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className='scan-expense px-4 py-2 cursor-pointer hover:bg-[#454ADE] hover:text-white'
                                    onClick={() => {
                                        setOpenCreateDropDown(false)
                                        if (!isOCREnabled) {
                                            setShowUpgradeModal(true)
                                            setCurrentModule(ModuleNameEnum.OCR_SCAN)
                                            return
                                        }
                                        handleClick(ModalType.scannedModal)
                                    }}
                                >
                                    Scan a receipt
                                </span>
                            </Menu.Item>
                        </Menu.Items>
                    )}
                </Menu>
            </div>
            <div className='w-full block bg-white rounded-2xl p-4'>
                <div className='flex items-center justify-between mb-3 flex-wrap gap-y-2.5'>
                    <div className='border border-[#DADCE0] h-[45px] w-[60%] md:w-[30%] rounded-lg flex items-center overflow-hidden'>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            placeholder='Search expense list by name or category'
                            className='h-full w-[80%] md:w-[60%] outline-none placeholder:text-xs'
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className='flex items-center gap-2.5'>
                        <CurrencySelectFilter currency={currency} setCurrency={setCurrency} />
                        <div
                            className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1.5 cursor-pointer'
                            onClick={() => setFilterExpenseModal(true)}
                        >
                            <img src={filter} alt='button' />
                        </div>
                    </div>
                </div>

                <section className='my-4'>
                    <FilterExpenseChip categories={allCategories} orgMembers={orgMembers!} />
                </section>
                <AnimatePresence mode='wait'>
                    {showModal && (
                        <ExpenseFormRoot
                            setShowModal={setShowModal}
                            categories={allCategories as CategoryType[]}
                            hasAPrimaryBank={handleCheckPrimaryBank()}
                        />
                    )}
                </AnimatePresence>
                {showScanModal && (
                    <CreateScanExpenseModal
                        setShowModal={setShowScanModal}
                        hasAPrimaryBank={handleCheckPrimaryBank()}
                    />
                )}

                {/* Checks if items exist  */}
                {/* searchQuery && filteredExpenses.length > 0 */}
                {filteredExpenses.length < 1 && isExpenseFetched ? (
                    <>
                        <ProgressBar />
                    </>
                ) : (filteredExpenses.length === 0 &&
                      searchQuery?.length < 1 &&
                      !backspace &&
                      !advanceFilterTriggered) ||
                  (filteredExpenses.length > 0 && searchQuery?.length >= 0 && !backspace) ? (
                    <div
                        className={` transition-all rounded-lg ${
                            isExpenseFetched || isExpenseAuthorized ? 'opacity-30' : 'opacity-100'
                        } overflow-x-scroll`}
                    >
                        <ExpenseListTable
                            expenses={filteredExpenses}
                            pagination_data={pagination_data}
                            hasAPrimaryBank={handleCheckPrimaryBank()}
                            setDeclineExpense={userId => handleDeclineRowExpense(userId)}
                            setApproveExpense={userId => handleApproveRowExpense(userId)}
                        />
                    </div>
                ) : (
                    <div className='h-[450px] flex flex-col text-center justify-center items-center pb-10'>
                        {searchQuery && filteredExpenses.length < 1 ? (
                            <>
                                <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                    {searchQuery && filteredExpenses.length < 1
                                        ? 'Expense not found in this record. Please try a different filter or contact support for assistance.'
                                        : "Oops! It seems we couldn't find any matching results for your search. Please try again with different keywords or refine your filter criteria for better results."}
                                </span>
                            </>
                        ) : filteredExpenses.length === 0 && advanceFilterTriggered ? (
                            <>
                                <img src={notFound} alt='Not found' className='mt-6 h-[200px]' />
                                <span className='text-2xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                    {filteredExpenses &&
                                        'Expense not found in this record. Please try a different filter or contact support for assistance.'}
                                </span>
                            </>
                        ) : (
                            <>
                                <ProgressBar />
                            </>
                        )}
                    </div>
                )}
            </div>
            {filterExpenseModal && <FilterExpense closeModal={setFilterExpenseModal} />}
            {showNoPolicyWarning && <NoPolicyWarning setShowModal={setShowNoPolicyWarning} />}
        </section>
    )
}

export default ExpenseList
