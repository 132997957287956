import { CiSearch } from 'react-icons/ci'
import { useEffect, useMemo, useRef, useState } from 'react'
import ProgressBar from '@/components/ProgressBar'
import notFound from '@/assets/not-found.svg'
import InvoiceItemTable from '../../components/InvoiceItemTable'
import { useGetInvoiceItem } from '@/services/invoice/queries'
import { useAuthContext, useInvoiceContext } from '@/context'
import { debounce } from 'lodash'
import { Pagination } from '@/types/utils'
import { CurrencySelectFilter } from '@/components/CurrencyFilter'
import CreateInvoiceItem from '@/components/Modals/Invoice/InvoiceItem/CreateInvoiceItem'

const InvoiceItemPartial = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const [currency, setCurrency] = useState('all')
    const [openAddItem, setOpenAddItem] = useState(false)
    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)
    const { selectedAccount } = useAuthContext()
    const { setItemPageNumberClicked, itemPageNumber, itemPageNumberClicked } = useInvoiceContext()

    const {
        data,
        isLoading,
        isRefetching,
        refetch: refetchAllOrgInvoiceItems,
    } = useGetInvoiceItem({
        queryParams: {
            page: itemPageNumber?.toString(),
            org_id: selectedAccount?.org?.id as string,
            search: searchQuery,
            currency: currency === 'all' ? '' : currency,
        },
        onSuccess: () => {
            setBackSpace(false)
        },
    })

    const allOrgInvoiceItem = useMemo(() => data?.results ?? [], [data])
    const pagination = data?.pagination as Pagination

    useEffect(() => {
        if (itemPageNumber && itemPageNumberClicked) {
            refetchAllOrgInvoiceItems()
            setItemPageNumberClicked(false)
        }
        if (currency) {
            refetchAllOrgInvoiceItems()
        }
    }, [itemPageNumber, itemPageNumberClicked, currency])

    //=======making a query search after every 3 seconds provided there's a searched query======//
    const debouncedSearch = useRef(
        debounce(() => {
            refetchAllOrgInvoiceItems()
        }, 300)
    ).current

    //=====refetches the expense when the advanced filter is used
    useEffect(() => {
        if (backspaceClicked) {
            refetchAllOrgInvoiceItems()
            setBackSpaceClicked(false)
        }

        if (searchQuery) {
            debouncedSearch()
        }

        return () => {
            debouncedSearch.cancel()
        }
    }, [refetchAllOrgInvoiceItems, backspaceClicked, searchQuery, debouncedSearch])

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }

    if (isLoading) return <ProgressBar />
    return (
        <section className={`h-screen overflow-scroll ${allOrgInvoiceItem.length > 0 ? '' : 'pb-6'} `}>
            <div className='block lg:flex justify-between mb-6 lg:mb-0'>
                <div className='mb-2 lg:mb-6'>
                    <h1 className='text-dark font-medium text-lg'>Invoice items</h1>
                    <p className='text-gray7 text-sm'>Manage saved invoice items, create and delete invoice items</p>
                </div>
                <button
                    className='bg-[#454ADE] text-white px-2 h-[40px] rounded-lg text-sm flex items-center gap-2 min-w-full md:min-w-[140px] justify-center'
                    onClick={() => setOpenAddItem(true)}
                >
                    New invoice item
                </button>
            </div>
            <div className='w-full py-4 block bg-white rounded-2xl p-4 '>
                <div className='flex items-center justify-between mb-6'>
                    <div className='border border-[#DADCE0] h-[45px] w-[60%] lg:w-[30%] rounded-lg flex items-center overflow-hidden'>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mx-1 md:mx-0'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            className='h-full w-[60%] outline-none placeholder:text-xs'
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder='Search by name'
                        />
                    </div>
                    <CurrencySelectFilter currency={currency} setCurrency={setCurrency} />
                </div>

                <div>
                    {allOrgInvoiceItem.length < 1 && isRefetching ? (
                        <>
                            <ProgressBar />
                        </>
                    ) : (allOrgInvoiceItem.length === 0 && searchQuery?.length < 1 && !backspace) ||
                      (allOrgInvoiceItem.length > 0 && searchQuery?.length >= 0 && !backspace) ? (
                        <div
                            className={` transition-all rounded-lg ${
                                isRefetching || isLoading ? 'opacity-30' : 'opacity-100'
                            } overflow-x-scroll`}
                        >
                            <InvoiceItemTable pagination={pagination} data={allOrgInvoiceItem} />
                        </div>
                    ) : (
                        <div className='h-[450px] flex flex-col text-center justify-center items-center pb-10'>
                            {searchQuery && allOrgInvoiceItem.length < 1 ? (
                                <>
                                    <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                    <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                    <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                        {searchQuery && allOrgInvoiceItem.length < 1
                                            ? 'Item not found in this record. Please try a different filter or contact support for assistance.'
                                            : "Oops! It seems we couldn't find any matching results for your search. Please try again with different keywords or refine your filter criteria for better results."}
                                    </span>
                                </>
                            ) : allOrgInvoiceItem.length === 0 ? (
                                <>
                                    <img src={notFound} alt='Not found' className='mt-6 h-[200px]' />
                                    <span className='text-2xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                    <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                        {allOrgInvoiceItem &&
                                            'item not found in this record. Please try a different filter or contact support for assistance.'}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <ProgressBar />
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {openAddItem && <CreateInvoiceItem closeModal={() => setOpenAddItem(false)} createItem={true} />}
        </section>
    )
}

export default InvoiceItemPartial
