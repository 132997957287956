import React, { useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion'
import { cn } from '@/lib/styling'
import { TrendingUp } from 'lucide-react'
import { TrendingDown } from 'lucide-react'
import MinimalBarChart from './MinimalBarChart'
import { MinimalAreaChart } from './MinimalAreaChat'
import { useGetOutstandingAmountByMonth, useGetPaidInvoiceByMonth } from '@/services/invoice/queries'
import { NewMonthlySummary } from '@/services/transactions/types'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const ProjectedRevenue = ({ currency }: { currency?: string }) => {
    const [duration, setDuration] = useState('year')
    const [percentage] = useState('+12%')

    const {
        data: outstandingData,
        isLoading: isLoadingOutstanding,
        refetch: refetchOutstanding,
        isRefetching: isRefetchingOutstanding,
    } = useGetOutstandingAmountByMonth({
        queryParams: {
            year: new Date().getFullYear().toString() as string,
            currency: currency as string,
        },
    })
    const outstandingSummaryData = useMemo(
        () => outstandingData?.map(item => ({ name: item?.name, total: item?.amount })) ?? ([] as NewMonthlySummary[]),
        [outstandingData]
    )

    const {
        data: invoicesData,
        isLoading: isLoadingInvoices,
        refetch: refetchInvoices,
        isRefetching: isRefetchingInvoices,
    } = useGetPaidInvoiceByMonth({
        queryParams: {
            year: new Date().getFullYear().toString() as string,
            currency: currency as string,
        },
    })
    const invoicesSummaryData = useMemo(
        () => invoicesData?.map(item => ({ name: item?.name, total: item?.count })) ?? ([] as NewMonthlySummary[]),
        [invoicesData]
    )

    useEffect(() => {
        refetchOutstanding()
        refetchInvoices()
    }, [currency])

    if (isLoadingInvoices || isLoadingOutstanding) {
        return (
            <section className='p-6 rounded-[20px] w-full lg:w-[55%] bg-white'>
                <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={'100%'}>
                    <Skeleton />
                </SkeletonTheme>
            </section>
        )
    }

    return (
        <section className='p-4 rounded-[20px] w-full lg:w-[55%] bg-white'>
            <div className=''>
                <div className='flex gap-4 p-1 w-[6rem] rounded-lg border border-gray8'>
                    <div
                        className={`${'bg-#F9F9F9 text-brand'}bg-[#EFEFF9] px-2 py-1 rounded cursor-pointer relative`}
                        onClick={() => {
                            setDuration('year')
                        }}
                    >
                        {duration === 'year' && (
                            <motion.span
                                layoutId='expense_timeline__animation'
                                className='bg-[#EFEFF9] rounded-md z-0 text-[#202020] inset-0 absolute inline-block w-full h-full '
                            />
                        )}
                        <motion.span
                            layout='position'
                            className={`z-20 sticky flex items-center gap-2 font-semibold whitespace-nowrap ${duration === 'year' ? 'text-[#202020]' : 'text-gray7'}`}
                        >
                            This year
                        </motion.span>
                    </div>
                    <div
                        className={`${'bg-#F9F9F9 text-[#5E5E5E]'}bg-[#EFEFF9] px-2 py-1 rounded cursor-pointer relative w-full hidden`}
                        onClick={() => {
                            setDuration('month')
                        }}
                    >
                        {duration === 'month' && (
                            <motion.span
                                layoutId='expense_timeline__animation'
                                className='bg-[#EFEFF9] rounded-md inset-0 absolute inline-block w-full h-full '
                            />
                        )}
                        <motion.span
                            layout='position'
                            className={`${duration === 'month' ? 'text-[#202020]' : 'text-gray7'} inset-0 absolute w-full h-full px-2 py-1 font-semibold`}
                        >
                            <p className='flex items-center gap-2'>This month</p>
                        </motion.span>
                    </div>
                </div>
            </div>
            <div className={cn('', (isRefetchingInvoices || isRefetchingOutstanding) && 'opacity-40')}>
                {/* Outstanding payments */}
                <div className=''>
                    <div className='mt-3 flex justify-between items-center'>
                        <p className='font-semibold'>Outstanding payments</p>
                        <span
                            className={cn(
                                'mr-2 items-center gap-1 hidden',
                                percentage?.[0] === '+' ? 'text-[#19C37D]' : 'text-errorRed'
                            )}
                        >
                            {percentage?.[0] === '+' ? (
                                <TrendingUp size={18} />
                            ) : (
                                <TrendingDown className='transform scale-x-[-1]' size={18} />
                            )}
                            {percentage?.slice(1)}
                        </span>
                    </div>
                    <div className='mt-5'>
                        <MinimalAreaChart
                            chartData={outstandingSummaryData as unknown as NewMonthlySummary[]}
                            currency={currency as string}
                            size='S'
                            colors={{ thick: '#FFCD1633', light: '#FFE01600', stroke: '#deb145' }}
                        />
                    </div>
                </div>
                <p className='h-[1px] w-full bg-gray8 my-3'></p>

                {/* Paid Invoices */}
                <div className=''>
                    <div className='mt-3 flex justify-between items-center'>
                        <p className='font-semibold'>Paid Invoices</p>
                        <span
                            className={cn(
                                'mr-2 hidden items-center gap-1',
                                percentage?.[0] === '+' ? 'text-[#19C37D]' : 'text-errorRed'
                            )}
                        >
                            {percentage?.[0] === '+' ? (
                                <TrendingUp size={18} />
                            ) : (
                                <TrendingDown className='transform scale-x-[-1]' size={18} />
                            )}
                            {percentage?.slice(1)}
                        </span>
                    </div>
                    <div className='mt-5'>
                        <MinimalBarChart
                            emptyText='No paid invoices yet'
                            chartData={invoicesSummaryData}
                            currency=''
                            type='count'
                            size='S'
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectedRevenue

// const chartData = [
//     { name: 'Jan', total: 0 },
//     { name: 'Feb', total: 0 },
//     { name: 'Mar', total: 0 },
//     { name: 'Apr', total: 0 },
//     { name: 'May', total: 0 },
//     { name: 'Jun', total: 0 },
//     { name: 'Jul', total: 0 },
//     { name: 'Aug', total: 0 },
//     { name: 'Sep', total: 0 },
//     { name: 'Oct', total: 0 },
//     { name: 'Nov', total: 0 },
//     { name: 'Dec', total: 0 },
// ]
