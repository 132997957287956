import { Button } from '@/components'
import { useMutateDeactivateEmployee } from '@/services/employees/mutations'

import { toastHandler } from '@/components/utils/Toast'
import ModalWrapper from '../../ModalWrapper'
import { EmployeeType } from '@/types/org'
import { AiOutlineClose } from 'react-icons/ai'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { useState } from 'react'
import { ExpenseApprovalPolicy } from '@/services/taxes/types'
import { ChevronDown, EditIcon } from 'lucide-react'
import CreatePolicyModal from '../../ApprovalPolicy/CreatePolicyModal/CreatePolicyModal'
import { SupportedPolicyConsts } from '@/types/approval-policy'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion'
import { capitalizeText } from '@/utils/string'

interface DeactivateEmployeeProps {
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    employeeDetails: EmployeeType | undefined
    employeeName?: string
    setEmployeeDetails: React.Dispatch<React.SetStateAction<EmployeeType | undefined>>
    userApprovalPolicies: ExpenseApprovalPolicy[]
}

export default function DeactivateEmployee({
    setIsDeleteDialogOpen,
    employeeDetails,
    setEmployeeDetails,
    userApprovalPolicies,
}: DeactivateEmployeeProps) {
    const [reason, setReason] = useState('')
    const [note, setNote] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [selectePolicy, setSelectedPolicy] = useState<ExpenseApprovalPolicy>()
    const checkNote = note.length > 20 ? false : true

    const { mutate: makeEmployeeActionableFn, isLoading: isDeleteMemberLoading } = useMutateDeactivateEmployee({
        queryParams: {
            org_account_id: employeeDetails?.id as string,
        },
        onSuccess: data => {
            setEmployeeDetails(data as EmployeeType)
            setIsDeleteDialogOpen(false)
            toastHandler({ message: 'User Deactivated', state: 'success' })
        },
    })

    const disableBtn = (reason === 'other' ? checkNote : reason.length === 0) || userApprovalPolicies?.length > 0

    return (
        <>
            <ModalWrapper variants={undefined}>
                <div className='w-screen lg:w-[30rem] rounded-[20px]'>
                    <div className='border-b border-[#DADCE0] py-3 p-2  flex justify-end'>
                        <div className='flex items-center  justify-between h-full flex-1'>
                            <h2 className='font-semibold text-[#31254B] text-lg text-center flex-1'>
                                Deactivate account
                            </h2>
                            <div
                                className='bg-[#F2F2F2] w-6 h-6 p-1.5 rounded-full flex items-center justify-center text-[#838383] cursor-pointer'
                                onClick={() => setIsDeleteDialogOpen(false)}
                            >
                                <AiOutlineClose fontSize={25} />
                            </div>
                        </div>
                    </div>
                    <div className='bg-errorRed/10 text-errorRed rounded-lg p-3 text-sm mx-4 mt-5'>
                        This user won’t be able to access their payroll and account again
                    </div>
                    <form onSubmit={(e: React.FormEvent) => e.preventDefault()} className=''>
                        <div className='px-[1rem]  pb-3  max-h-[400px] lg:max-h-[80vh] overflow-scroll'>
                            <div className={`flex flex-col gap-8 mb-12`}>
                                <div className='mt-5 flex flex-col gap-1.5 '>
                                    <label htmlFor='flag' className='text-[#202020] text-sm font-medium'>
                                        Reason <span className=''>*</span>
                                    </label>
                                    <Select name='flag' onValueChange={e => setReason(e)}>
                                        <SelectTrigger className='w-full h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'>
                                            <SelectValue placeholder={`Why are you deactivating this user`} />
                                        </SelectTrigger>
                                        <SelectContent className='w-[23rem] lg:w-[28.3rem] max-h-[200px] overflow-scroll'>
                                            <SelectItem value='charges'>Suspicious activities</SelectItem>
                                            <SelectItem value='other'>Others</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>

                                <div className='flex flex-col flex-1 gap-1.5'>
                                    <label className='text-[#202020] text-sm font-medium'>
                                        Additional note (Optional)
                                    </label>
                                    <Textarea
                                        name='note'
                                        placeholder='Explain further if you wish to.'
                                        className='border border-[#DADCE0]  resize-none placeholder:text-sm text-sm'
                                        value={note}
                                        onChange={e => {
                                            const { value } = e.target
                                            setNote(value)
                                        }}
                                    />
                                    {reason === 'other' && (
                                        <p className='text-sm text-gray7'>Note must have a minimum of 20 characters</p>
                                    )}
                                </div>

                                {userApprovalPolicies?.length > 0 && (
                                    <Accordion type='single' collapsible>
                                        <AccordionItem
                                            value='deactivate-1'
                                            className='border-gray5  data-[state=closed]:bg-brand/10 border rounded-lg p-3.5 px-3 data-[state=closed]:border-brand'
                                        >
                                            <AccordionTrigger righticon={<ChevronDown className='w-4 h-4' />}>
                                                This user is an approver - kindly assign their replacement
                                            </AccordionTrigger>
                                            <AccordionContent>
                                                {
                                                    <div className=' text-gray7 rounded-lg p-3 text-sm '>
                                                        {userApprovalPolicies?.map(policy => (
                                                            <div
                                                                key={policy._id ?? policy.id}
                                                                className='mt-2 text-sm font-medium flex items-center justify-between'
                                                            >
                                                                {capitalizeText(policy?.module)} module:{' '}
                                                                {policy?.policy_name}
                                                                <Button
                                                                    onClick={() => {
                                                                        setIsEdit(true)
                                                                        setSelectedPolicy(policy)
                                                                    }}
                                                                    icon={
                                                                        <EditIcon
                                                                            color='#838383'
                                                                            className='cursor-pointer'
                                                                            size={13}
                                                                        />
                                                                    }
                                                                    className='min-w-[100px] border-none'
                                                                    size={'sm'}
                                                                    variant={'outline'}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </AccordionContent>
                                        </AccordionItem>
                                    </Accordion>
                                )}
                            </div>
                            <Button
                                className={`w-full  text-sm font-normal mb-3 disabled:opacity-30 ${`bg-errorRed hover:bg-errorRed disabled:bg-errorRed`}`}
                                loading={isDeleteMemberLoading}
                                onClick={() => {
                                    makeEmployeeActionableFn({})
                                }}
                                disabled={disableBtn}
                            >
                                Deactivate account
                            </Button>
                        </div>
                    </form>
                </div>
            </ModalWrapper>
            {isEdit && (
                <CreatePolicyModal
                    module={selectePolicy?.module as SupportedPolicyConsts}
                    edit={true}
                    closeModal={() => setIsEdit(false)}
                    updatePolicy={selectePolicy}
                />
            )}
        </>
    )
}
