import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { useRef, useState } from 'react'
import * as XLSX from 'xlsx'
import { ChartOfAccountTypeImport } from '@/types/accounting'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import PreviewUploadedCOAModal from './PreviewUploadedCOAModal'
import AccountingSetupSuccessModal from './AccountingSetupSuccessModal'
import importCoa from '@/assets/uploadCoa.svg'
import { cn } from '@/lib/styling'
import { useAccountingContext } from '@/context'
import { useNavigate } from 'react-router-dom'

type Props = {
    closeModal: () => void
}
enum DuplicateActionKeys {
    skip = 'skip',
    overwrite = 'overwrite',
}

const ImportChartOfAccount = ({ closeModal }: Props) => {
    const { setUploadedCoa } = useAccountingContext()
    const [duplicateAction, setDuplicateAction] = useState<DuplicateActionKeys>()
    const [importedCoA, setImportedCoA] = useState<ChartOfAccountTypeImport[]>([])
    const [step, setStep] = useState(1)
    const navigate = useNavigate()

    const chartOfAccountRef = useRef<HTMLInputElement>(null)

    const handleFileUpload = (selectedFile: File): void => {
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target && e.target.result) {
                const data = new Uint8Array(e.target.result as ArrayBuffer)
                const workbook = XLSX.read(data, { type: 'array' })
                const worksheet = workbook.Sheets[workbook.SheetNames[0]]
                const jsonData: ChartOfAccountTypeImport[] = XLSX.utils.sheet_to_json(worksheet)

                setImportedCoA(jsonData)
            }
        }
        reader.readAsArrayBuffer(selectedFile)
    }

    const disableBtn = importedCoA.length < 1 || !duplicateAction

    if (step === 2)
        return (
            <PreviewUploadedCOAModal
                closeModal={closeModal}
                overwriteData={duplicateAction === DuplicateActionKeys.overwrite}
                uploadedCOA={importedCoA}
                nextStep={() => setStep(3)}
                prevStep={() => setStep(1)}
            />
        )

    if (step === 3) return <AccountingSetupSuccessModal closeModal={closeModal} />

    return (
        step === 1 && (
            <section className='min-h-[15rem] w-screen lg:w-[35rem] overflow-scroll '>
                <div className='flex items-center border-b border-[#DADCE0] p-2 px-4 justify-between'>
                    <h2 className='text-center text-base font-medium opacity-80'>Import Chart of Accounts</h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='p-4'>
                    <h3 className='text-sm mb-1 font-medium'>Start with our standard template</h3>
                    <p className='text-gray7 text-sm mt-1'>
                        Download the file below to ensure your Chart of Accounts matches the required format. Once
                        you've filled it out, upload it to proceed.
                    </p>
                    <h4 className='text-sm border-b border-gray5 pb-2 font-medium mt-6'>Instructions</h4>
                    <ul className='text-gray7 list-decimal list-inside space-y-1 mt-3 text-sm'>
                        <li>
                            Download csv template{' '}
                            <a
                                className='font-medium text-brand'
                                href='/charts_of_account.csv'
                                download='Chart-Of-Accounts.csv'
                                target='_blank'
                                rel='noreferrer'
                            >
                                here
                            </a>
                        </li>

                        <li>Open the template and fill in the Chart of Accounts.</li>
                        <li>Upload the completed file once you’ve filled in the template.</li>
                    </ul>

                    <label
                        htmlFor={'gl-code'}
                        className={cn(
                            'cursor-pointer upload flex items-center justify-center border border-dashed rounded-lg border-[#DADCE0] mt-5',
                            importedCoA?.length > 0 ? 'h-[5rem]' : 'h-[8rem] '
                        )}
                    >
                        <span className='text-center text-base text-[#A8A8A8] font-medium my-auto'>
                            {importedCoA?.length === 0 && (
                                <img src={importCoa} alt='importCoa' className='h-10 w-10 mx-auto mb-3' />
                            )}
                            {importedCoA.length > 0 ? 'chart-of-accounts.xlsx' : 'Click here to upload completed file'}
                            <br />
                            {importedCoA.length > 0 ? (
                                <span className='text-sm'>
                                    <button
                                        className='font-semibold text-xs text-brand'
                                        onClick={() => chartOfAccountRef?.current?.click()}
                                    >
                                        Change document
                                    </button>
                                </span>
                            ) : (
                                <span className='text-xs'>{'Max 2MB | Accepts .xls and .xlsx file only'}</span>
                            )}
                        </span>
                        <input
                            type='file'
                            id={'gl-code'}
                            ref={chartOfAccountRef}
                            onChange={e => {
                                handleFileUpload(e.target.files?.[0] as File)
                            }}
                            accept='.xls, .xlsx, .csv'
                            hidden
                        />
                    </label>
                </div>

                {importedCoA.length > 0 && (
                    <div className='p-4'>
                        <h3 className='border-b border-gray4/70 pb-1.5 text-sm font-medium'>Handling Duplicates</h3>

                        <RadioGroup
                            onValueChange={(data: DuplicateActionKeys) => setDuplicateAction(data)}
                            className='flex gap-2 flex-col mt-3'
                            value={duplicateAction}
                        >
                            <label
                                htmlFor={DuplicateActionKeys.skip}
                                className='flex gap-2 items-center text-sm cursor-pointer'
                            >
                                <RadioGroupItem value={DuplicateActionKeys.skip} id={DuplicateActionKeys.skip} />
                                <span className='flex flex-col'>
                                    <span className=''>Skip duplicates</span>
                                    <span className='text-sm text-gray7/80'>
                                        Retains the accounts in Bizpend and does not import the duplicates in the import
                                        file.
                                    </span>
                                </span>
                            </label>
                            <label
                                htmlFor={DuplicateActionKeys.overwrite}
                                className='flex gap-2 items-center text-sm cursor-pointer'
                            >
                                <RadioGroupItem
                                    value={DuplicateActionKeys.overwrite}
                                    id={DuplicateActionKeys.overwrite}
                                />
                                <span className='flex flex-col'>
                                    <span className=''>Overwrite accounts</span>
                                    <span className='text-sm text-gray7/80'>
                                        Imports the duplicates in the import file and overwrites the existing accounts
                                        in Bizpend.
                                    </span>
                                </span>
                            </label>
                        </RadioGroup>
                    </div>
                )}

                <div className='border-t border-gray4 flex mt-6 justify-end items-center p-4 gap-4'>
                    <Button
                        onClick={() => {
                            setUploadedCoa(importedCoA)
                            navigate('/dashboard/accounting/preview-imported-coa')
                            setStep(2)
                        }}
                        disabled={disableBtn}
                        size={'lg'}
                        className='text-xs px-4'
                    >
                        Import Chart of Accounts
                    </Button>
                </div>
            </section>
        )
    )
}

export default ImportChartOfAccount
