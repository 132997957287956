import emptyExpense from '@/assets/empty-state-expense.svg'
import EmptyStates from '@/components/EmptyStates'

import emptyTransaction from '@/assets/empty-state-transactions.svg'
import { Button } from '@/components'
import KYCInfoContainer from '@/components/Modals/Employee/KYCForInvitedEmployees/KYCInfoContainer'
import FundWalletRoot from '@/components/Modals/FundWallet/FundWalletRoot'
import AddNewBankContainer from '@/components/Modals/UserSettings/AddNewBank/AddNewBankContainer'
import { useAuthContext } from '@/context'
import PageContent from '@/layout/PageContent'
import { useGetAllOrgCards } from '@/services/card/queries'
import { useQueryOrgPendingExpenses } from '@/services/expenses/queries'
import { useGetAccountsForUser, useGetListOfBanks } from '@/services/payout/queries'
import { useGetTransactions } from '@/services/transactions/queries'
import { isAdmin } from '@/utils/permissions'
import { ArrowRight } from 'lucide-react'
import { useMemo, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import { ExpenseStatus } from '../Expenses/const'
import { AdminHelperSteps, UserHelperSteps } from './components/HelperSteps'
import OverviewExpenseListTable from './components/OverviewExpenseListTable'
import OverviewTransactionsView from './components/OverviewTransactionsView'
import OverviewInsights from './components/partials/admin/OverviewInsights'
import UserOverviewInsights from './components/partials/user/UserOverviewInsights'

const Overview = () => {
    const { selectedAccount, setShowKYCModalOnce, showKYCModalOnce, bankDetails, setBankDetails } = useAuthContext()

    const adminRoute = isAdmin(selectedAccount!)
    const navigate = useNavigate()

    const { data, isLoading, isRefetching } = useQueryOrgPendingExpenses({
        queryParams: {
            id: `${selectedAccount?.org.id as string}`,
            limit: '6',
            submitter: !adminRoute ? (selectedAccount?.id as string) : '',
            status: ExpenseStatus.pending,
        },
    })

    //====PAYstack kyc required that all users have their bank details added====
    const { data: all_banks } = useGetListOfBanks()
    const allBankList = useMemo(() => all_banks ?? [], [all_banks])

    const { data: userAccount } = useGetAccountsForUser({
        queryParams: {
            account: selectedAccount?.id as string,
        },
    })
    const userBankDetails = useMemo(() => userAccount?.accounts ?? [], [userAccount])
    //======================================

    //==============Org card overview insights ===================
    const { data: cardData } = useGetAllOrgCards({
        enabled: adminRoute,
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess() {
            // setActiveCards(data.filter(cards => cards.status === 'ACTIVE'))
        },
    })
    const orgCards = useMemo(() => cardData ?? [], [cardData])
    console.warn({ orgCards })
    //========================================================

    //=============overview insights======================
    const { data: overview_data, isLoading: isoadingTransactions } = useGetTransactions({
        queryParams: {
            account_type: adminRoute ? 'Org' : 'UserOrgAccount',
            id: adminRoute ? (selectedAccount?.org?.id as string) : (selectedAccount?.id as string),
        },
        // onSuccess: () => {},
    })
    const overviewTransaction = useMemo(() => overview_data?.results ?? [], [overview_data])
    //==============================================

    const expenses_overview = useMemo(() => data?.expenses ?? [], [data])

    const [showModal, setShowModal] = useState(false)

    return (
        <PageContent>
            {adminRoute && <AdminHelperSteps setShowModal={setShowModal} />}
            {!adminRoute && <UserHelperSteps />}
            <section className='w-full pb-[6rem] py-6'>
                {adminRoute && <OverviewInsights />}
                {!adminRoute && <UserOverviewInsights />}
                {showModal && <FundWalletRoot setShowModal={setShowModal} />}

                <section className='overflow-y-scroll'>
                    <section className='w-full mb-14'>
                        <div className='border  border-[#DADCE0] bg-[#ffffff] rounded-lg'>
                            {isoadingTransactions ? (
                                <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                                    <Skeleton />
                                </SkeletonTheme>
                            ) : overviewTransaction.length > 0 ? (
                                <>
                                    <div className='flex items-center justify-between p-3 lg:p-4 rounded border-b  border-[#DADCE0]'>
                                        <div className='flex flex-col gap-2'>
                                            <span className='text-[#202020] font-semibold text-[18px]'>
                                                Recent Transactions
                                            </span>
                                            <p className='text-[14px] text-[#5E5E5E]'>
                                                See latest transactions in real time
                                            </p>
                                        </div>

                                        <Button
                                            className='bg-transparent hover:bg-transparent text-[#202020] min-w-[100px] ml-auto'
                                            icon={<ArrowRight />}
                                            onClick={() => navigate('/dashboard/transactions/transactions_history')}
                                        >
                                            View all
                                        </Button>
                                    </div>
                                    <div className='overflow-y-scroll'>
                                        <div className='min-w-[50rem] lg:min-w-full rounded-lg pt-[5px] mb-1'>
                                            {overviewTransaction.slice(0, 4).map((transaction, index) => (
                                                <OverviewTransactionsView
                                                    transaction={transaction}
                                                    key={index}
                                                    last={index == overviewTransaction.length - 1}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className='mb-8 px-3'>
                                    <div className='border-b border-[#DADCE0] mb-2'>
                                        <p className='py-3 text-[#5E5E5E] border-b-2 border-[#5E5E5E] w-fit font-medium text-[16px]'>
                                            Recent transactions
                                        </p>
                                    </div>
                                    <EmptyStates
                                        title='No Recent Transactions'
                                        description="You haven't made any transactions yet. Start exploring our services and
        make your first transaction to see it listed here."
                                        image={emptyTransaction}
                                    />
                                </div>
                            )}
                        </div>
                    </section>
                </section>
                {/* mv */}

                {/*==== Expenses section ====*/}
                <section className='overflow-y-scroll'>
                    <div className='w-full  mb-10 px-1 lg:px-0'>
                        {/*==== Expense header ====*/}
                        {isLoading ? (
                            <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                                <Skeleton />
                            </SkeletonTheme>
                        ) : (
                            <>
                                <div className='flex items-center justify-between p-3 lg:p-4 border mb-2 border-[#DADCE0] bg-[#ffffff] rounded-lg '>
                                    <div className='flex flex-col gap-2'>
                                        <span className='text-[#202020] font-semibold text-[18px]'>
                                            {expenses_overview.length > 0 && 'Pending'} Expenses
                                        </span>
                                        <p className='text-[14px] text-[#5E5E5E]'>
                                            {expenses_overview.length > 0
                                                ? 'A list of recent expenses that are meant to be approved'
                                                : '    Monitor expense habits and requests from teams and users.'}
                                        </p>
                                    </div>

                                    {expenses_overview.length > 0 ? (
                                        <Button
                                            className='bg-transparent hover:bg-transparent text-[#202020] px-2 lg:min-w-[100px] justify-end'
                                            icon={<ArrowRight />}
                                            onClick={() => navigate('/dashboard/expenses/expense_list')}
                                        >
                                            View all
                                        </Button>
                                    ) : (
                                        <button
                                            className='bg-[#202020] p-3 text-[#F9F6FF] rounded-lg text-[14px]'
                                            onClick={() => navigate('/dashboard/expenses/expense_list')}
                                        >
                                            Create new expense
                                        </button>
                                    )}
                                </div>

                                {/*==== Expense Table ====*/}
                                {expenses_overview.length > 0 ? (
                                    <section
                                        className={`${
                                            isLoading || isRefetching ? 'opacity-60' : 'opacity-100'
                                        } border-[#DADCE0] bg-[#ffffff] overflow-y-scroll`}
                                    >
                                        <OverviewExpenseListTable
                                            expenses={expenses_overview}
                                            adminRoute={adminRoute}
                                        />
                                    </section>
                                ) : (
                                    <EmptyStates
                                        title='No Expense Details Available'
                                        description="You haven't recorded any expense details yet. A summary of expense
        habits and expenses over time will be shown here when expenses are created"
                                        image={emptyExpense}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </section>
            </section>

            {showKYCModalOnce && <KYCInfoContainer setShowKYCModalOnce={setShowKYCModalOnce} />}
            {bankDetails && (
                <AddNewBankContainer
                    closeModal={setBankDetails}
                    allBankList={allBankList}
                    userBankDetails={userBankDetails}
                />
            )}
        </PageContent>
    )
}

export default Overview
