import { useCurrencyContext } from '@/context'
import { NewMonthlySummary } from '@/services/transactions/types'
import { AbbrvFormatNumber } from '@/utils/utils'
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, AreaChart } from 'recharts'
import { CustomBarTooltipProps } from './MinimalBarChart'
import { formatAmountNoSymbol } from '@/utils/money'
import { findMonthWithHighestTotal } from '@/modules/dashboard/Transactions/components/utils'
import { OverAPeriodIcon } from '@/assets/assets.export'

type Props = {
    chartData: NewMonthlySummary[]
    currency: string
    size: string
    colors: {
        thick: string
        light: string
        stroke: string
    }
}

export const defaultHighest = {
    monthWithHighestOutgoingTotal: { name: 'Jan', total: 0 },
    monthWithHighestIncomingTotal: { name: 'Jan', total: 0, incomingTotal: '0' },
}

export const MinimalAreaChart = ({ chartData, currency, size, colors }: Props) => {
    const { selectedCurrencyInfo } = useCurrencyContext()
    const total = chartData.reduce(
        (accumulator, currentValue) => accumulator + (Number(currentValue?.total ?? 0) ?? 0),
        0
    )

    const highestMonth = total > 0 ? findMonthWithHighestTotal(chartData) : defaultHighest

    const CustomTooltip = ({ active, payload, label }: CustomBarTooltipProps) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className='bg-offwhite p-3 shadow-md rounded-xl'
                    style={{ boxShadow: '0px 2px 100px 0px #2F2F2F6B ' }}
                >
                    <p className='text-center text-xs font-bold text-[#838383]'>{label} 2025</p>
                    <p className='text-center font-semibold text-sm text-[#202020] mt-1'>
                        {`${selectedCurrencyInfo(currency)?.symbol}${formatAmountNoSymbol(payload[0]?.payload?.total)}`}
                    </p>
                </div>
            )
        }
        return null
    }

    return (
        <>
            {total === 0 ? (
                <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                    <OverAPeriodIcon />
                    <p className='text-sm mt-6'>No outstanding payments yet</p>
                </div>
            ) : (
                <ResponsiveContainer height={size === 'L' ? 281 : 221} width={'100%'} className={'mx-auto -mr2'}>
                    <AreaChart
                        data={chartData}
                        margin={{
                            left: 40,
                        }}
                    >
                        <defs>
                            <linearGradient id={`colorUv${size}`} x1='0' y1='0' x2='0' y2='1'>
                                <stop offset='5%' stopColor={colors?.thick} stopOpacity={1} />
                                <stop offset='95%' stopColor={colors?.light} stopOpacity={1} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke='#DADCE0' vertical={false} strokeDasharray='5 5' fill='white' />
                        <XAxis dataKey='name' stroke='#888888' fontSize={12} tickLine={false} axisLine={false} />
                        <YAxis
                            stroke='#888888'
                            fontSize={12}
                            tickLine={false}
                            axisLine={false}
                            tickFormatter={value =>
                                `${selectedCurrencyInfo(currency)?.symbol}${AbbrvFormatNumber(value)}`
                            }
                            hide={false}
                            label={{
                                value: selectedCurrencyInfo(currency)?.name,
                                angle: -90,
                                position: 'insideRight',
                                offset: 90,
                            }}
                            offset={10}
                            type='number'
                            domain={[0, Number(highestMonth?.monthWithHighestIncomingTotal?.incomingTotal)]}
                        />
                        <Tooltip
                            filterNull={true}
                            cursor={false}
                            wrapperStyle={{ borderRadius: '15px', zIndex: '10' }}
                            content={<CustomTooltip />}
                        />
                        <Area
                            type='monotone'
                            dataKey='total'
                            stroke={colors?.stroke}
                            fill={`url(#colorUv${size})`}
                            strokeWidth={2}
                            dot={false}
                            activeDot={false}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            )}
        </>
    )
}
