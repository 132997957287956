import { useState } from 'react'
import ContractPrompt from './ContractPrompt'
import ModalWrapper from '../../ModalWrapper'
import { BizpendAccounts, CancelIcon } from '@/assets/assets.export'
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { TbCurrencyNaira } from 'react-icons/tb'
import { ContractPayrollType, MilestoneType } from '@/types/payroll'
import { capitalizeText } from '../../../../utils/string'
import { calculateTransferFee, formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { formatDate } from '@/modules/dashboard/Payroll/helper'
import { useMilestonePaymentOtp } from '@/services/payroll/mutations'

import { Budget } from '@/types/budget'
import PayrollProcess2 from '../PayrollProcess/PayrollProcess2'
import RequestBudgetIncrease from '../PayrollProcess/RequestBudgetIncrease'
import { useGetAccountsForUser } from '@/services/payout/queries'
import { UserAccount } from '@/services/payout/types'
import { useGetAccounts } from '@/services/bill-payment/queries'
import { useAuthContext } from '@/context'

type PaymentProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    mileStoneData?: MilestoneType
    contract?: ContractPayrollType
    next?: React.Dispatch<React.SetStateAction<number>>
    bankDetails?: UserAccount[]
}

const ContractPaymentRoot = ({ closeModal, mileStoneData, contract }: PaymentProps) => {
    const [page, setPage] = useState(1)

    const budgetId = ''
    const [selectedBudget, setSelectedBudget] = useState<Budget>()
    const [accountId, setAccountId] = useState('')

    //=====to get the user's prefered bank
    const { data: submitterAccount } = useGetAccountsForUser({
        queryParams: {
            account: contract?.employee.id ?? (contract?.employee._id as string),
        },
    })

    console.warn(typeof setSelectedBudget)
    const bank_details = submitterAccount?.accounts?.filter(bankDetails => bankDetails.is_primary)
    return (
        <ModalWrapper>
            <div className=''>
                {page == 1 && <ContractPrompt closeModal={closeModal} next={setPage} />}
                {page == 2 && (
                    <ContractPaymentMain
                        contract={contract}
                        mileStoneData={mileStoneData}
                        closeModal={closeModal}
                        next={setPage}
                        bankDetails={bank_details!}
                        accountId={accountId}
                        setAccountId={setAccountId}
                    />
                )}

                {page === 3 && (
                    <PayrollProcess2
                        setSteps={setPage}
                        closeModal={closeModal}
                        formState={{ budgetId, account: accountId }}
                        contract={contract}
                        mileStoneData={mileStoneData}
                        isMileStone={true}
                    />
                )}
                {page === 4 && (
                    <RequestBudgetIncrease closeModal={closeModal} setSteps={setPage} selectedBudget={selectedBudget} />
                )}
            </div>
        </ModalWrapper>
    )
}

export default ContractPaymentRoot

const ContractPaymentMain = ({
    closeModal,
    mileStoneData,
    contract,
    next,
    bankDetails,
    accountId,
    setAccountId,
}: PaymentProps & { accountId: string; setAccountId: (val: string) => void }) => {
    const [amount, setAmount] = useState<string | undefined>(mileStoneData?.amount)
    const { mutate: mileStoneOtpFn } = useMilestonePaymentOtp({})
    const { selectedAccount } = useAuthContext()

    const { data: accounts } = useGetAccounts({
        queryParams: {
            entity_id: selectedAccount?.org?.id as string,
        },
    })

    return (
        <main className='min-h-[35rem] w-screen lg:w-[38rem]'>
            <section className='flex items-center border-b border-[#DADCE0] p-4 '>
                <span className='flex-1 text-center text-xl font-medium'>Make contract payment</span>
                <CancelIcon
                    className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                    onClick={() => closeModal(false)}
                />
            </section>
            <div className='px-[23px] lg:px-[46px] mt-8 mb-9'>
                <div className='bg-[#EFEFF9] p-5 rounded-xl text-[#202020] flex flex-col gap-1 mb-6'>
                    <p className='text-base font-semibold'>
                        User{' '}
                        <span className='font-medium'>
                            : {capitalizeText(contract?.employee?.user?.first_name)}{' '}
                            {capitalizeText(contract?.employee?.user?.last_name)}
                        </span>
                    </p>
                    <p className='text-base font-semibold'>
                        Bank name<span className='font-medium'>: {bankDetails && bankDetails[0].bank_name} </span>
                    </p>
                    <p className='text-base font-semibold'>
                        Account number
                        <span className='font-medium'>: {bankDetails && bankDetails[0].account_number} </span>
                    </p>
                    <p className='text-base font-semibold'>
                        Total pay<span className='font-medium'>: {formatAmount(amount)} </span>
                    </p>
                    <p className='text-base font-semibold'>
                        Total debit
                        <span className='font-medium'>
                            : {formatAmount(Number(amount) + calculateTransferFee(amount!) * 100)}
                        </span>
                    </p>
                    <p className='text-base font-medium truncate'>
                        {/* formatDate */}
                        Payment for:{' '}
                        {contract?.milestones?.map((milestone, idx) => {
                            if (mileStoneData?.title === milestone?.title) {
                                return `${idx + 1}${formatDate(idx + 1)}`
                            }
                            return null
                        })}{' '}
                        milestone <span className='font-semibold truncate'>({mileStoneData?.title})</span>
                    </p>
                </div>
                <div className='flex flex-col flex-1 gap-4'>
                    <label className='flex items-center gap-2'>
                        Payout amount
                        <span className='text-red'>*</span>
                    </label>
                    <Input
                        type='text'
                        placeholder='Enter amount'
                        name='amount'
                        defaultValue={'0'}
                        className='border border-[#DADCE0] h-[3rem] disabled:bg-slate-100'
                        leftIcon={<TbCurrencyNaira />}
                        value={formatAmount(amount).replaceAll('₦', '')}
                        disabled
                        onChange={e => {
                            const value_entered = formatAmountWithoutSymbols(e.target.value)
                            setAmount(value_entered)
                        }}
                    />
                </div>
                <span className='text-[12px] text-[#838383]'>
                    Fee: {formatAmount(calculateTransferFee(amount!) * 100)}
                </span>
                <div className='mt-8 flex flex-col flex-1'>
                    <label className='mb-2' htmlFor='funding_method'>
                        Disburse payout with this account
                    </label>
                    <Select name='fund_source' onValueChange={setAccountId} value={accountId}>
                        <SelectTrigger className=' text-base h-[3.3rem]  text-[#5E5E5E] border-gray3 flex '>
                            {accountId ? <SelectValue /> : <span>Select account</span>}
                        </SelectTrigger>
                        <SelectContent className='max-h-[12rem] overflow-y-scroll'>
                            <SelectItem value={'Wallet'} className='pl-3'>
                                <div className='flex items-center gap-3'>
                                    <BizpendAccounts className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`} />
                                    <div className='flex flex-col items-start'>
                                        <h6 className='text sm'>Main Wallet</h6>
                                    </div>
                                </div>
                            </SelectItem>
                            {accounts?.accounts?.map(acct => (
                                <SelectItem value={acct?._id} className='pl-3' key={acct.id}>
                                    <div className='flex items-center gap-3'>
                                        <BizpendAccounts
                                            className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`}
                                        />
                                        <div className='flex flex-col justify-start items-start'>
                                            <h6 className='text sm'>{acct.nickname ?? acct.account_name}</h6>
                                            <p className='text-xs text-gray1 font-medium'>
                                                {formatAmount(acct.balance)}
                                            </p>
                                        </div>
                                    </div>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>
            <section className='flex items-center justify-end border-t border-[#DADCE0] p-4 pr-12 '>
                <Button
                    className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                    onClick={() => {
                        mileStoneOtpFn({})
                        next?.(3)
                    }}
                    disabled={!accountId}
                >
                    Proceed
                </Button>
            </section>
        </main>
    )
}
