import { ACCESS_PERMISSIONS } from '@/utils/permisson-helper'
import { ReactComponent as AccountingIcon } from '@/assets/accounting.svg'
import { ReactComponent as ArrowLeftRight } from '@/assets/arrowrightleft.svg'
import { ReactComponent as PayRollIcon } from '@/assets/payroll.svg'
import { ReactComponent as ContractsIcon } from '@/assets/contracts.svg'
import { ReactComponent as VaultIcon } from '@/assets/vault.svg'
import { ReactComponent as EmployeesIcon } from '@/assets/sidebar-employees.svg'
import { ReactComponent as MoneyIcon } from '@/assets/sidebar-expense.svg'
import { AccountsIcon, BudgetIcon, InvoiceIcon, VendorsIcon } from '@/assets/assets.export'
import { Dispatch, SetStateAction } from 'react'
import { ReactComponent as DashboardIcon } from '@/assets/dashboard-icon.svg'
import { ReactComponent as SettingsIcon } from '@/assets/settings.svg'
import { FeatureFlagType } from '@/lib/feature-flags/types'

/**
 * Each permission contains 3 parts. module_action_scope.
 *
 * e.g EXPENSE_CREATE_OWN means the user can create an expense for themselves.
 * EXPENSE_CREATE_ALL means the user can create an expense for any user.
 */

export const accountSidebarPermissions = [
    ACCESS_PERMISSIONS.BANK_CREATE_ALL,
    ACCESS_PERMISSIONS.BANK_READ_ALL,
    ACCESS_PERMISSIONS.BANK_UPDATE_ALL,
    ACCESS_PERMISSIONS.BANK_DELETE_ALL,

    ACCESS_PERMISSIONS.BANK_CREATE_OWN,
    ACCESS_PERMISSIONS.BANK_READ_OWN,
    ACCESS_PERMISSIONS.BANK_UPDATE_OWN,
    ACCESS_PERMISSIONS.BANK_DELETE_OWN,
]

export const transactionsSidebarPermissions = [
    ACCESS_PERMISSIONS.TRANSACTION_CREATE_ALL,
    ACCESS_PERMISSIONS.TRANSACTION_READ_ALL,
    ACCESS_PERMISSIONS.TRANSACTION_UPDATE_ALL,
    ACCESS_PERMISSIONS.TRANSACTION_DELETE_ALL,

    ACCESS_PERMISSIONS.TRANSACTION_CREATE_OWN,
    ACCESS_PERMISSIONS.TRANSACTION_READ_OWN,
    ACCESS_PERMISSIONS.TRANSACTION_UPDATE_OWN,
    ACCESS_PERMISSIONS.TRANSACTION_DELETE_OWN,
]

export const invoiceSidebarPermissions = [
    ACCESS_PERMISSIONS.INVOICE_CREATE_ALL,
    ACCESS_PERMISSIONS.INVOICE_READ_ALL,
    ACCESS_PERMISSIONS.INVOICE_UPDATE_ALL,
    ACCESS_PERMISSIONS.INVOICE_DELETE_ALL,
]

export const expenseSidebarPermissions = [
    ACCESS_PERMISSIONS.EXPENSE_CREATE_ALL,
    ACCESS_PERMISSIONS.EXPENSE_READ_ALL,
    ACCESS_PERMISSIONS.EXPENSE_UPDATE_ALL,
    ACCESS_PERMISSIONS.EXPENSE_DELETE_ALL,

    ACCESS_PERMISSIONS.EXPENSE_CREATE_OWN,
    ACCESS_PERMISSIONS.EXPENSE_READ_OWN,
    ACCESS_PERMISSIONS.EXPENSE_UPDATE_OWN,
    ACCESS_PERMISSIONS.EXPENSE_DELETE_OWN,
]
export const budgetSidebarPermissions = [
    ACCESS_PERMISSIONS.BUDGET_CREATE_ALL,
    ACCESS_PERMISSIONS.BUDGET_READ_ALL,
    ACCESS_PERMISSIONS.BUDGET_UPDATE_ALL,
    ACCESS_PERMISSIONS.BUDGET_DELETE_ALL,
]
export const accountingSidebarPermissions = [
    ACCESS_PERMISSIONS.ACCOUNTING_CREATE_ALL,
    ACCESS_PERMISSIONS.ACCOUNTING_READ_ALL,
    ACCESS_PERMISSIONS.ACCOUNTING_UPDATE_ALL,
    ACCESS_PERMISSIONS.ACCOUNTING_DELETE_ALL,
]

export const customersSidebarPermissions = [
    ACCESS_PERMISSIONS.CUSTOMER_CREATE_ALL,
    ACCESS_PERMISSIONS.CUSTOMER_READ_ALL,
    ACCESS_PERMISSIONS.CUSTOMER_UPDATE_ALL,
    ACCESS_PERMISSIONS.CUSTOMER_DELETE_ALL,
]
export const payrollSidebarPermissions = [
    ACCESS_PERMISSIONS.PAYROLL_CREATE_ALL,
    ACCESS_PERMISSIONS.PAYROLL_READ_ALL,
    ACCESS_PERMISSIONS.PAYROLL_UPDATE_ALL,
    ACCESS_PERMISSIONS.PAYROLL_DELETE_ALL,

    ACCESS_PERMISSIONS.PAYROLL_CREATE_OWN,
    ACCESS_PERMISSIONS.PAYROLL_READ_OWN,
    ACCESS_PERMISSIONS.PAYROLL_DELETE_OWN,
    ACCESS_PERMISSIONS.PAYROLL_UPDATE_OWN,
]

export const contractSidebarPermissions = [
    ACCESS_PERMISSIONS.CONTRACT_CREATE_ALL,
    ACCESS_PERMISSIONS.CONTRACT_READ_ALL,
    ACCESS_PERMISSIONS.CONTRACT_UPDATE_ALL,
    ACCESS_PERMISSIONS.CONTRACT_DELETE_ALL,

    ACCESS_PERMISSIONS.CONTRACT_CREATE_OWN,
    ACCESS_PERMISSIONS.CONTRACT_READ_OWN,
    ACCESS_PERMISSIONS.CONTRACT_UPDATE_OWN,
    ACCESS_PERMISSIONS.CONTRACT_DELETE_OWN,
]

export const vendorSidebarPermissions = [
    ACCESS_PERMISSIONS.VENDOR_CREATE_ALL,
    ACCESS_PERMISSIONS.VENDOR_READ_ALL,
    ACCESS_PERMISSIONS.VENDOR_UPDATE_ALL,
    ACCESS_PERMISSIONS.VENDOR_DELETE_ALL,
]
export const peopleSidebarPermissions = [
    ACCESS_PERMISSIONS.PEOPLE_CREATE_ALL,
    ACCESS_PERMISSIONS.PEOPLE_READ_ALL,
    ACCESS_PERMISSIONS.PEOPLE_UPDATE_ALL,
    ACCESS_PERMISSIONS.PEOPLE_DELETE_ALL,
]
export const vaultPermissons = [
    // ACCESS_PERMISSIONS.VAULT_CREATE_ALL,
    ACCESS_PERMISSIONS.VAULT_READ_ALL,
    // ACCESS_PERMISSIONS.VAULT_UPDATE_ALL,
    // ACCESS_PERMISSIONS.VAULT_DELETE_ALL,
]
export const vaultOperationPermissons = [
    ACCESS_PERMISSIONS.VAULT_CREATE_ALL,
    ACCESS_PERMISSIONS.VAULT_READ_ALL,
    ACCESS_PERMISSIONS.VAULT_UPDATE_ALL,
    ACCESS_PERMISSIONS.VAULT_DELETE_ALL,
]

export type SidebarItemType = {
    name: string
    key?: string
    icon?: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string
        }
    >
    route: string
    parentRoute?: string[]
    permission?: ACCESS_PERMISSIONS[]
    children?: SidebarItemType[]
    enabled?: keyof FeatureFlagType
}

export type SideBarProps = {
    onRouteChange: (routeName: string) => void
    setShowSidebar?: Dispatch<SetStateAction<boolean>>
    isMobile: boolean
    showSidebar: boolean
}

export const dashboardRoute = {
    name: 'Dashboard',
    key: 'dashboard',
    icon: DashboardIcon,
    route: 'overview',
    permission: [ACCESS_PERMISSIONS.ACCESS_DASHBOARD],
}
export const settingsRoute = {
    name: 'Settings',
    key: 'settings',
    icon: SettingsIcon,
    route: 'settings/company',
    permission: [ACCESS_PERMISSIONS.ACCESS_SETTINGS],
}

export const sidebarRoute: SidebarItemType[] = [
    {
        name: 'Accounts',
        key: 'accounts',
        icon: AccountsIcon,
        parentRoute: ['accounts'],
        route: 'accounts/linked_accounts',
        permission: accountSidebarPermissions,
        enabled: 'show_bank_accounts',
    },
    {
        name: 'Transactions',
        key: 'transactions',
        icon: ArrowLeftRight,
        route: 'transactions/transactions_history/company',
        parentRoute: ['transactions'],
        permission: transactionsSidebarPermissions,
    },
    {
        name: 'Invoices',
        key: 'invoices',
        icon: InvoiceIcon,
        route: 'invoice/invoice_list',
        parentRoute: ['invoice'],
        permission: invoiceSidebarPermissions,
    },

    {
        name: 'Expenses',
        key: 'expenses',
        icon: MoneyIcon,
        route: 'expenses/expense_list/company',
        parentRoute: ['expenses'],
        permission: expenseSidebarPermissions,
        enabled: 'show_expense',
    },
    {
        name: 'Budgets',
        key: 'budgets',
        icon: BudgetIcon,
        parentRoute: ['budget'],
        route: 'budget',
        permission: budgetSidebarPermissions,
        enabled: 'show_budgets',
    },
    {
        name: 'Accounting',
        key: 'accounting',
        parentRoute: ['accounting'],
        icon: AccountingIcon,
        route: `accounting/coa`,
        permission: accountingSidebarPermissions,
    },

    {
        name: 'Payroll',
        key: 'payroll',
        icon: PayRollIcon,
        route: 'payroll/payroll_list?accountType=company',
        parentRoute: ['payroll'],
        permission: payrollSidebarPermissions,
        children: [
            {
                name: 'Salary',
                key: 'payroll',
                route: 'payroll/payroll_list?accountType=company',
                parentRoute: [
                    'payroll/payroll_list',
                    'payroll/run_payroll',
                    'payroll/pay_history',
                    'payroll/analytics',
                    'payroll/setings',
                ],
            },
            {
                name: 'Leave',
                key: 'leave',
                route: 'payroll/leave/requests?accountType=company',
                parentRoute: ['payroll/leave'],
            },
        ],
        enabled: 'show_payroll',
    },
    {
        name: 'Contracts',
        key: 'contracts',
        icon: ContractsIcon,
        route: 'contract/contract_list?accountType=company',
        parentRoute: ['contract'],
        permission: contractSidebarPermissions,
        enabled: 'show_contract',
    },
    {
        name: 'Vendor Management',
        key: 'vendor',
        icon: VendorsIcon,
        route: 'vendor_management/vendors',
        parentRoute: ['vendor_management'],
        permission: vendorSidebarPermissions,
        enabled: 'show_vendor_management',
    },
    {
        name: 'People',
        key: 'people',
        icon: EmployeesIcon,
        route: 'employees/members',
        parentRoute: ['employees'],
        permission: peopleSidebarPermissions,
    },

    {
        name: 'Vault',
        key: 'vault',
        icon: VaultIcon,
        parentRoute: ['vault'],
        route: 'vault/documents',
        permission: vaultPermissons,
        enabled: 'show_vault',
    },
]
