import CheckBox from '@/components/Table/CheckBox'
import { useAccountingContext } from '@/context'
import AccountingRow from './Accounting-Row'
import { Check, X } from 'lucide-react'
import { FaEllipsisVertical } from 'react-icons/fa6'
import { ExtendedRow } from '@/context/accounting'
import ReceiptUpload from './ReceiptUpload'
import { useUpdateTransactions } from '@/services/transactions/mutations'
import { useState } from 'react'
import { Pagination } from '@/types/utils'
import V2Pagination from '@/components/Table/Non-table-pagination'
import { ACCOUNTING_STATUS } from '@/types/accounting'

type Props = {
    rows: ExtendedRow[]
    pagination: Pagination
    setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
    refetchTransactions: () => void
}
const AccountingTableComponent = ({
    rows,
    pagination,
    pageNumber,
    setPageNumber,
    setPageNumberClicked,
    refetchTransactions,
}: Props) => {
    const { setRows, columns } = useAccountingContext()
    const [activeTransaction, setActiveTransaction] = useState('')

    const tablePaginationNo = Array.from({ length: pagination?.totalPages ?? 0 }, (_, idx) => idx)

    const { isLoading, mutate } = useUpdateTransactions({
        onSuccess: () => {
            refetchTransactions()
        },
    })

    const handleSelectAll = (checked: boolean) => {
        setRows(prev => prev?.map(row => ({ ...row, selected: checked })))
    }

    const onSubmit = (updatedRow: ExtendedRow, ready: boolean) => {
        mutate({
            is_ready: ready,
            id: updatedRow?.id,
        })
    }

    const allSelected = rows?.every(row => row.selected)
    const someSelected = rows?.some(row => row.selected)
    return (
        <div className='relative border border-gray5 rounded-lg'>
            <div className='flex'>
                <div className='overflow-x-scroll flex-grow  scrollbar-thumb-gray7 scrollbar-thin'>
                    <table className='min-w-[1600px] leading-normal rounded-lg border-collapse text-xs'>
                        <thead>
                            <tr>
                                <th className='px-5 h-12 font-light bg-[#f9f9f9] rounded-tl-xl'>
                                    <CheckBox
                                        checked={allSelected}
                                        indeterminate={someSelected && !allSelected}
                                        onChange={e => handleSelectAll((e.target as HTMLInputElement).checked)}
                                    />
                                </th>
                                {columns?.map(col => (
                                    <th
                                        className='px-5 h-12 bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'
                                        key={col.id}
                                    >
                                        {col.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows?.map((row, idx) => (
                                <AccountingRow
                                    loading={activeTransaction === (row?._id ?? row?.id) ? isLoading : false}
                                    idx={idx}
                                    key={row._id}
                                    row={row}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className='flex-none'>
                    <table className=' leading-normal border-collapse text-xs'>
                        <thead>
                            <tr>
                                <th className='border-l border-gray5 px-2 lg:px-5 h-12  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                    Ready
                                </th>
                                <th className='px-2 lg:px-5 h-12 border-gray5 bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                    Receipt
                                </th>
                                <th className='border-l px-4 lg:px-5 h-12 border-gray5 bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                    {''}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows?.map((field, idx) => (
                                <tr
                                    key={idx}
                                    className={`${isLoading && (field?.id ?? field?._id) === activeTransaction ? 'opacity-50' : ''}`}
                                >
                                    <td className='border-l border-gray5 h-14 border-t'>
                                        {field?.accounting_status === ACCOUNTING_STATUS.READY ? (
                                            <button
                                                onClick={() => {
                                                    onSubmit(field, false)
                                                    setActiveTransaction(field._id ?? field?.id)
                                                }}
                                                aria-label='ready'
                                                className='rounded p-1 bg-errorRed/20 text-errorRed mx-auto flex'
                                            >
                                                <X strokeWidth={2} size={16} />
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() => {
                                                    onSubmit(field, true)
                                                    setActiveTransaction(field._id ?? field?.id)
                                                }}
                                                aria-label='ready'
                                                className='rounded p-1 bg-green/20 text-green mx-auto flex'
                                            >
                                                <Check strokeWidth={2} size={16} />
                                            </button>
                                        )}
                                    </td>
                                    <td className='border-r border-gray5 h-14 border-t'>
                                        <ReceiptUpload row={field} idx={idx} />
                                    </td>
                                    <td className='h-14 border-gray5 border-t text-center'>
                                        <button aria-label='more options' className='text-gray7/70'>
                                            <FaEllipsisVertical size={17} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='px-5 py-3 border-t border-gray5'>
                <V2Pagination
                    setCurrentPageNumber={setPageNumber}
                    paginationOption={tablePaginationNo}
                    setIsPageNumberClicked={setPageNumberClicked}
                    pagination={{ ...pagination, currentPage: pageNumber }}
                />
            </div>
        </div>
    )
}

export default AccountingTableComponent
