import ModalWrapper from '../../ModalWrapper'
import { modalVariants } from '../../Expense/utils'
import { ApprovalPolicyArrow, ApprovalPolicyCondition, ApprovalPolicyHierarchy } from '@/assets/assets.export'
import { Button } from '@/components'
import { ExpenseApprovalPolicy } from '@/services/taxes/types'
import { ApporvalConditionOperatorEnum, SupportedPolicyConsts } from '@/types/approval-policy'
import { PlusSquare, X } from 'lucide-react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'

import AccountingApprovalPolicyBox from '@/components/ApprovalPolicyBox/AccountingApprovalPolicyBox'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'

type FormType = {
    policy: {
        initialAmt?: string
        finalAmt?: string
        comparison?: string
        receipt?: string
    }[]

    defaultReceipt: string
}
type Props = {
    closeModal: () => void
    edit?: boolean
    updatePolicy?: ExpenseApprovalPolicy
    module: SupportedPolicyConsts
}

export default function CreateAccountingPolicyModal({ closeModal, edit, module }: Props) {
    const methods = useForm<FormType>({})
    const { fields, append, remove } = useFieldArray<FormType>({
        control: methods.control,
        name: 'policy',
    })

    const onSubmit = (value: FormType) => {
        console.table({ value, module })
    }
    return (
        <ModalWrapper
            closeModal={() => closeModal()}
            className='justify-end right-8'
            variants={modalVariants}
            modalClassName='rounded-none bg-[red]'
            formId='billPolicy'
            shouldConfirmClose
        >
            <div className='h-[100vh] w-screen lg:w-[43rem]  relative  overflow-hidden overflow-y-scroll mb[20rem]'>
                <div className='sticky top-0 w-full bg-white z-10 border-b border-[#DADCE0] py-4 px-2 flex'>
                    <div className='flex items-center w-full'>
                        <h2 className='font-semibold text-[#31254B] text-lg flex-1 text-center'>
                            Advanced receipt rules
                        </h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => closeModal()}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <section className=' bg-white mt-5 h-[85%]'>
                    <FormProvider {...methods}>
                        <form id='billPolicy' onSubmit={methods.handleSubmit(onSubmit)} className='h-full'>
                            <div className='space-y-4 overflow-y-scroll pl-3 lg:pl-5 p-5  h-full'>
                                {fields.map((field, idx) => (
                                    <div className='relative max-w-3xl' key={field.id}>
                                        <button
                                            aria-label='Icon'
                                            type='button'
                                            onClick={() => remove(idx)}
                                            className='backdrop-blur-lg -top-2 -right-2 absolute text-errorRed bg-errorRed/10 rounded-full p-1'
                                        >
                                            <X className=' w-5 h-5 ' />
                                        </button>

                                        <AccountingApprovalPolicyBox index={idx} />
                                    </div>
                                ))}

                                <div>
                                    <div className='flex items-center gap-4'>
                                        <ApprovalPolicyCondition className='opacity-50' />

                                        <button
                                            type='button'
                                            onClick={() =>
                                                append({
                                                    comparison: ApporvalConditionOperatorEnum.LESS_THAN,
                                                })
                                            }
                                            className='flex gap-2 bg-brand/10 text-brand text-xs font-medium p-2 px-3 items-center rounded-md'
                                        >
                                            <PlusSquare className='text-brand rounded-md overflow-hidden' />
                                            Add condition
                                        </button>
                                    </div>
                                </div>

                                <div className='!mb-14 !mt-6 ml-1'>
                                    <span className='w-fit flex gap-2 bg-green/10 border border-green text-green text-sm font-medium p-1.5 px-3 items-center rounded-md mb-2'>
                                        <ApprovalPolicyHierarchy className='text-green rounded-md overflow-hidden' />
                                        If no condition is met
                                    </span>
                                    <div className='flex items-end gap-3 '>
                                        <ApprovalPolicyArrow className='ml-1.5' />

                                        <div className='flex lg:items-center gap-3 relative -bottom-1.5 flex-col lg:flex-row'>
                                            <span className='text-errorRed font-medium text-sm border-r border-gray/50 pr-5 '>
                                                Require receipt
                                            </span>
                                            <Controller
                                                name='defaultReceipt'
                                                rules={{
                                                    required: true,
                                                    minLength: 2,
                                                }}
                                                control={methods.control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        {...field}
                                                        onValueChange={field.onChange}
                                                        className='grid grid-cols-1 lg:grid-cols-3 gap-6'
                                                    >
                                                        <label htmlFor={'yes'} className={`flex gap-2 items-center`}>
                                                            <RadioGroupItem type='button' value={'yes'} id={'yes'} />
                                                            Yes
                                                        </label>
                                                        <label htmlFor={'no'} className={`flex gap-2 items-center`}>
                                                            <RadioGroupItem type='button' value={'no'} id={'no'} />
                                                            No
                                                        </label>
                                                    </RadioGroup>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='border-t pt-4 border-gray5 fixed bottom-5 px-4 pb-2  w-full z-10 bg-white'>
                                <Button type='submit' className='ml-auto' disabled={!methods.formState.isValid}>
                                    {edit ? 'Save' : 'Create'} policy
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </section>
            </div>
        </ModalWrapper>
    )
}
