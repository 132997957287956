import { toastHandler } from '@/components/utils/Toast'

export enum ModuleNameEnum {
    EXPENSES = 'expenses',
    INVOICE = 'invoices',
    KYB = 'kyb',
    LEAVE = 'leave',
    VAULT = 'vault',
    TRANSACTION = 'transaction',
}

export type FileUploadFormatProps = {
    file: unknown
    fileBeforeFormData: unknown
    fileUrl: string
    size: string
    file_name: string
    fileLoading: boolean
    fileType: string
}

type FileuploadProps = {
    setFileUpload: React.Dispatch<React.SetStateAction<FileUploadFormatProps | undefined>>
    fileUpload?: FileUploadFormatProps | undefined
}

//empty state for the hooks data
export const fileEmptyState = {
    file: null,
    fileUrl: '',
    size: '',
    file_name: '',
    fileLoading: false,
    fileBeforeFormData: null,
    fileType: '',
}

export const sizeInKilobytes = (size: number) => {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`
}

export default function useSingleFileUpload({ setFileUpload }: FileuploadProps) {
    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        let fileType = ''
        const selectedFiles = e.target?.files?.[0]

        //======checks for the type of fie
        if (selectedFiles?.type.includes('image')) {
            fileType = 'image'
        } else if (selectedFiles?.type.includes('pdf')) {
            fileType = 'pdf'
        } else if (selectedFiles?.name.includes('xlsx')) {
            fileType = 'xlsx'
        } else if (selectedFiles?.name.includes('csv')) {
            fileType = 'csv'
        } else {
            fileType = 'invalid'
            toastHandler({ message: 'Can only be Image or Pdf', state: 'error' })
            return
        }
        //========================

        //==========checks if there is a file uploaded and then transform to usabe format
        if (selectedFiles) {
            if (selectedFiles.size > 2000000) {
                toastHandler({ message: 'File cannot be greater than 2MB', state: 'error' })
            } else {
                const payload = new FormData()
                payload.append('file', selectedFiles)
                const fileUrl = URL.createObjectURL(selectedFiles)
                const newFile = {
                    file: payload,
                    fileUrl: fileUrl,
                    size: sizeInKilobytes(selectedFiles.size),
                    file_name: selectedFiles.name,
                    fileLoading: true,
                    fileBeforeFormData: selectedFiles,
                    fileType,
                }

                setFileUpload(newFile)
            }
        }

        return
    }

    return { handleFile }
}

export const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fileType = ''
    const selectedFiles = e.target?.files?.[0]

    //======checks for the type of fie
    if (selectedFiles?.type.includes('image')) {
        fileType = 'image'
    } else if (selectedFiles?.type.includes('pdf')) {
        fileType = 'pdf'
    } else if (selectedFiles?.name.includes('xlsx')) {
        fileType = 'xlsx'
    } else if (selectedFiles?.name.includes('csv')) {
        fileType = 'csv'
    } else {
        fileType = 'invalid'
        toastHandler({ message: 'Can only be Image or Pdf', state: 'error' })
        return
    }
    //========================

    //==========checks if there is a file uploaded and then transform to usabe format
    if (selectedFiles) {
        if (selectedFiles.size > 2000000) {
            toastHandler({ message: 'File cannot be greater than 2MB', state: 'error' })
        } else {
            const payload = new FormData()
            payload.append('file', selectedFiles)
            const fileUrl = URL.createObjectURL(selectedFiles)
            return {
                file: payload,
                fileUrl: fileUrl,
                size: sizeInKilobytes(selectedFiles.size),
                file_name: selectedFiles.name,
                fileLoading: true,
                fileBeforeFormData: selectedFiles,
                fileType,
            }
        }
    }

    return
}

export function useSinglePicUpload({ setFileUpload }: FileuploadProps) {
    const handleDrop = (acceptedFiles: File[]) => {
        const selectedFiles = acceptedFiles[0]
        let fileType = ''

        // Check the type of file
        if (selectedFiles.type.includes('image')) {
            fileType = 'image'
        } else if (selectedFiles.type.includes('pdf')) {
            fileType = 'pdf'
        } else if (selectedFiles.name.includes('xlsx')) {
            fileType = 'xlsx'
        } else {
            fileType = 'invalid'
            toastHandler({ message: 'Can only be Image or Pdf', state: 'error' })
            return
        }

        // Check file size
        if (selectedFiles.size > 2000000) {
            toastHandler({ message: 'File cannot be greater than 2MB', state: 'error' })
        } else {
            const payload = new FormData()
            payload.append('file', selectedFiles)
            const fileUrl = URL.createObjectURL(selectedFiles)
            const newFile = {
                file: payload,
                fileUrl: fileUrl,
                size: sizeInKilobytes(selectedFiles.size),
                file_name: selectedFiles.name,
                fileLoading: true,
                fileBeforeFormData: selectedFiles,
                fileType,
            }

            setFileUpload(newFile)
        }
    }

    return { handleDrop }
}

type MultipleFileuploadProps = {
    setFilesUpload: React.Dispatch<React.SetStateAction<FileUploadFormatProps[] | undefined>>
}
export function useMultipleFileUpload({ setFilesUpload }: MultipleFileuploadProps) {
    const handleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(e.target?.files || [])
        const validFiles: FileUploadFormatProps[] = []

        selectedFiles.forEach(file => {
            let fileType = ''

            //======checks for the type of file
            if (file.type.includes('image')) {
                fileType = 'image'
            } else if (file.type.includes('pdf')) {
                fileType = 'pdf'
            } else if (file.name.includes('xlsx')) {
                fileType = 'xlsx'
            } else if (file.name.includes('csv')) {
                fileType = 'csv'
            } else {
                fileType = 'invalid'
                toastHandler({ message: 'Can only be Image, Pdf, xlsx, or csv', state: 'error' })
                return
            }
            //========================

            //==========checks if the file size is valid
            if (file.size > 2000000) {
                toastHandler({ message: `File ${file.name} cannot be greater than 2MB`, state: 'error' })
            } else {
                const payload = new FormData()
                payload.append('file', file)
                const fileUrl = URL.createObjectURL(file)
                const newFile = {
                    file: payload,
                    fileUrl: fileUrl,
                    size: sizeInKilobytes(file.size),
                    file_name: file.name,
                    fileLoading: true,
                    fileBeforeFormData: file,
                    fileType,
                }

                validFiles.push(newFile)
            }
        })

        if (validFiles.length > 0) {
            setFilesUpload(prevFiles => [...(prevFiles ?? []), ...validFiles])
        }

        return
    }

    return { handleFiles }
}

export const generateFileName = ({
    module,
    org_id,
    unique_identifier,
    selectedFileName,
}: {
    module: ModuleNameEnum
    org_id: string
    unique_identifier: string
    selectedFileName: string
}) => {
    const fileType = selectedFileName?.split('.').pop()
    return `${module}_${org_id}_${unique_identifier}.${fileType}`
}
