import { DedicatedBankAccountType } from '@/types/accounts'

export type QueryBankAccountsRes = {
    accounts: DedicatedBankAccountType[]
    totalBalance: number
    totalCreatedAccountsBalance: number
    totalIncomingTransactionsAmount: number
    totalLinkedAccountsBalance: number
    totalOutgoingTransactionsAmount: number
}

export enum BankAccountConnectionTypeProps {
    CREATE = 'create',
    LINK = 'link',
    ALL = 'all',
}
