import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext, useCurrencyContext } from '@/context'
import { useUpdateCompanyProfile } from '@/services/auth/mutations'
import { OrgType, UserOrgAccount } from '@/types/org'
import React, { useState } from 'react'

const OrgCurrencyEdit = ({ setEditCurrency }: { setEditCurrency: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { selectedAccount, setSelectedAccount } = useAuthContext()
    const { currencies } = useCurrencyContext()
    const [selectedCurrency, setSelectedCurrency] = useState(selectedAccount?.org?.currency?.id)
    const [isOpen, setIsOpen] = useState(false)

    const { mutate: updateCompanyFn, isLoading } = useUpdateCompanyProfile({
        onSuccess: data => {
            setEditCurrency(false)
            setSelectedAccount(prev => ({ ...(prev as UserOrgAccount), org: data as unknown as OrgType }))
            toastHandler({ state: 'success', message: 'Changes saved' })
        },
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })
    const handleEditCurrency = () => {
        updateCompanyFn({
            currency: selectedCurrency,
        })
    }

    return (
        <section className='py-5 px-6 bg-white mt-5 rounded-2xl'>
            <p className='text-[#838383] font-medium mb-2'>Currency</p>
            <fieldset className='mb-4 flex flex-col  flex-1 '>
                <Select
                    value={selectedCurrency}
                    onValueChange={value => {
                        setSelectedCurrency(value)
                    }}
                    name='currency'
                    onOpenChange={() => {
                        setIsOpen(!isOpen)
                    }}
                >
                    <SelectTrigger className='font-medium text-xs h-[3rem] text-[#5E5E5E]  border-gray4/60 w-[18rem]'>
                        <SelectValue className='' placeholder={'Select currency'} />
                    </SelectTrigger>
                    <SelectContent className='z-50'>
                        {currencies?.map(currency => (
                            <SelectItem className='text-xs' value={currency?.id}>
                                <span>{currency?.symbol}</span> - {currency?.name}({currency?.currency_code})
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </fieldset>
            <div className='flex items-center gap-3 mt-12'>
                <Button
                    disabled={isLoading || isOpen}
                    className='bg-transparent disabled:opacity-40 disabled:bg-transparent text-gray7 hover:bg-transparent border border-transparent hover:border-gray7 px-0'
                    onClick={() => setEditCurrency(false)}
                >
                    Cancel
                </Button>
                <Button disabled={isOpen || isLoading} loading={isLoading} onClick={() => handleEditCurrency()}>
                    Save changes
                </Button>
            </div>
        </section>
    )
}

export default OrgCurrencyEdit
