import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { useAuthContext, useCurrencyContext } from '@/context'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { Switch } from '@headlessui/react'
import { Link, Percent, Unlink } from 'lucide-react'
import React, { useState } from 'react'
import { TfiAngleUp } from 'react-icons/tfi'
import { FormStateType, PaymentMethod, paymentOptions, paymentPrecessors, PaymentPrecessorsEnum } from './utils'
import { PAYMENT_GATEWAY } from '@/types/invoice'
import { stripeConnectLinks } from '@/utils/string'
import { useDisconnectConnectPaystack, useDisconnectConnectStripe } from '@/services/auth/mutations'
import ConfirmationPrompt from '@/components/Modals/ConfirmationPrompt'
import { useGetListOfBanks } from '@/services/payout/queries'
import AddPayStackIntegrationModal from '@/components/Modals/Invoice/AddPaystackIntegration'
import { isStagingEnvironment } from '@/utils/utils'

type Props = {
    setFormState: React.Dispatch<React.SetStateAction<FormStateType>>
    formState: FormStateType
    sub_total: number
}

export const InvoiceSettingsForm = ({ formState, setFormState, sub_total }: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const { selectedAccount } = useAuthContext()
    const [openConfirmationPrompt, setOpenConfirmtionPrompt] = useState(false)
    const [connectPaystack, setConnectPaystack] = useState(false)

    const { mutate: disconnectStripe, isLoading: isDisconnectingStripe } = useDisconnectConnectStripe({
        onSuccess: () => setOpenConfirmtionPrompt(false),
    })

    const { mutate: disconnectPaystack, isLoading: isDisconnectingPaystack } = useDisconnectConnectPaystack({
        onSuccess: () => setOpenConfirmtionPrompt(false),
    })
    const { data } = useGetListOfBanks({
        enabled: !selectedAccount?.org?.is_paystack_set,
    })

    const allBankList = data ?? []

    const [selectedGateway, setSelectedGateway] = useState<PAYMENT_GATEWAY>()

    const { currencies } = useCurrencyContext()
    const total = Number(sub_total) + Number(formState?.tax ?? 0)
    const partPaymentAmount = Number((Number(formState?.part_payment_percent) / 100) * (total / 100))

    const connectedGateWays = {
        [PAYMENT_GATEWAY.stripe]: {
            connected: !!selectedAccount?.org?.stripe_user_id,
            connectFn: () => (window.location.href = stripeConnectLinks),
            loading: isDisconnectingStripe,
            disconnectFn: () => disconnectStripe({ stripe_user_id: selectedAccount?.org?.stripe_user_id }),
        },
        [PAYMENT_GATEWAY.paystack]: {
            disconnectFn: () => disconnectPaystack({}),
            loading: isDisconnectingPaystack,
            connected: selectedAccount?.org?.is_paystack_set,
            connectFn: () => setConnectPaystack(true),
        },
        [PAYMENT_GATEWAY.paypal]: { connectFn: () => null, connected: null, disconnectFn: () => {}, loading: false },
    }

    const handleDisconnect = (gateway: PAYMENT_GATEWAY) => {
        setSelectedGateway(gateway)
        setOpenConfirmtionPrompt(true)
    }

    return (
        <div>
            <Accordion type='single' defaultValue='other-options' className='w-full' collapsible>
                <AccordionItem
                    value='payment-method'
                    className='w-full h-fit border border-[#DADCE0] rounded-xl p-3  shadow-sm '
                >
                    <AccordionTrigger
                        righticon={
                            <TfiAngleUp
                                className={`w-3 h-3 transition-all ${isOpen ? 'rotate-180' : ''}`}
                                fontSize={10}
                            />
                        }
                        className='w-full flex items-center justify-between'
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span className='text-[#202020] text-base  font-medium flex items-center gap-1'>
                            Payment method
                        </span>
                    </AccordionTrigger>
                    <AccordionContent className='mt-3'>
                        <RadioGroup
                            value={formState.payment_method}
                            className='flex flex-wrap gap-3  flex-row'
                            onValueChange={(value: string) => {
                                setFormState({
                                    ...formState,
                                    payment_method: value,
                                    paymentProcessor:
                                        value === PaymentMethod.OFFLINE ? '' : formState?.paymentProcessor,
                                })
                            }}
                        >
                            {paymentOptions.map(options => (
                                <label
                                    htmlFor={options.value}
                                    key={options.name}
                                    className={`border border-[#DADCE0] rounded-xl w-full p-3 cursor-pointer`}
                                >
                                    <div className={`flex gap-2 items-center cursor-pointer`}>
                                        <RadioGroupItem type='button' value={options.value} id={options.value} />
                                        <p className='text-sm text-[#5E5E5E]'>{options.name}</p>
                                    </div>

                                    {options.value === PaymentMethod.OFFLINE && (
                                        <span className='flex items-center gap-4 text-xs font-medium text-black/90 mt-1 mx-1'>
                                            <span className='w-1.5 h-1.5 inline-block bg-gray7/80 rounded-full' />
                                            Cash only
                                        </span>
                                    )}
                                </label>
                            ))}

                            <>
                                <h3 className='font-medium mt-3'>Select a payment processor</h3>

                                <RadioGroup
                                    className='flex flex-col gap-3 w-full'
                                    value={formState.paymentProcessor}
                                    disabled={formState.payment_method === PaymentMethod.OFFLINE}
                                    onValueChange={(value: string) => {
                                        setFormState({
                                            ...formState,
                                            paymentProcessor: value,
                                        })
                                    }}
                                >
                                    {paymentPrecessors?.map(option => {
                                        const gateWayDetails = connectedGateWays[option.id]
                                        if (option.name === PaymentPrecessorsEnum.paypal && !isStagingEnvironment())
                                            return
                                        return (
                                            <label
                                                htmlFor={option.id}
                                                key={option.id}
                                                className={`flex items-center justify-between border rounded-lg border-gray7/20 p-2 cursor-pointer ${gateWayDetails?.loading ? 'opacity-50' : ''}`}
                                            >
                                                <div>
                                                    <div className='flex items-center gap-2'>
                                                        <option.icon />
                                                        <div className='flex flex-col text-xs'>
                                                            <span className='flex items-center gap-1.5'>
                                                                <span className=' font-medium'>{option.name}</span>
                                                                <span className='w-1.5 h-1.5 inline-block bg-gray7/40 rounded-full' />

                                                                <span
                                                                    className={`font-medium ${gateWayDetails?.connected ? 'text-green' : 'text-gray7/50'}`}
                                                                >
                                                                    {gateWayDetails?.connected
                                                                        ? 'Connected'
                                                                        : ' Not connected'}
                                                                </span>
                                                            </span>
                                                            <span className=' text-gray7/70'>
                                                                {selectedAccount?.org?.org_name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <button
                                                        aria-label={`Link ${option.name}`}
                                                        onClick={() => {
                                                            if (!gateWayDetails?.connected) {
                                                                gateWayDetails?.connectFn?.()
                                                                return
                                                            }

                                                            handleDisconnect(option.id)
                                                        }}
                                                        className='hover:opacity-80 transition-all hover:scale-105 mt-2 text-brand text-xs flex items-center gap-2 font-medium'
                                                    >
                                                        {gateWayDetails?.connected ? (
                                                            <Unlink size={15} />
                                                        ) : (
                                                            <Link className=' rotate-45' size={15} />
                                                        )}
                                                        {gateWayDetails?.connected ? 'Disconnect now' : 'Connect now'}
                                                    </button>
                                                </div>
                                                <RadioGroupItem
                                                    disabled={!gateWayDetails?.connected}
                                                    type='button'
                                                    value={option.id}
                                                    id={option.id}
                                                />
                                            </label>
                                        )
                                    })}
                                </RadioGroup>
                            </>
                        </RadioGroup>

                        <div className='hidden'>
                            <label
                                htmlFor='part_payment'
                                className='cursor-pointer flex items-center text-sm gap-3 mt-3 text-gray7'
                            >
                                <input
                                    type='checkbox'
                                    name='part_payment'
                                    id='part_payment'
                                    checked={formState?.part_payment}
                                    onChange={e =>
                                        setFormState(prev => ({
                                            ...prev,
                                            part_payment: e.target.checked,
                                        }))
                                    }
                                />
                                Accept part payment
                            </label>
                        </div>
                    </AccordionContent>
                </AccordionItem>

                <AccordionItem
                    value='other-options'
                    className='w-full h-fit border border-[#DADCE0] rounded-xl p-3  shadow-sm mt-4'
                >
                    <AccordionTrigger
                        righticon={
                            <TfiAngleUp
                                className={`w-3 h-3 transition-all ${isOpen ? 'rotate-180' : ''}`}
                                fontSize={10}
                            />
                        }
                        className='w-full flex items-center justify-between'
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span className='text-[#202020] text-base  font-medium flex items-center gap-1'>Settings</span>
                    </AccordionTrigger>
                    <AccordionContent className='mt-3'>
                        <div className='border border-gray4 rounded-md p-3'>
                            <fieldset className='mb-4 flex flex-col  flex-1 '>
                                <label className='mb-1.5  text-sm opacity-80' htmlFor='currency'>
                                    Currency
                                </label>
                                <Select
                                    value={formState.currency}
                                    onValueChange={value => {
                                        setFormState({ ...formState, currency: value })
                                    }}
                                    name='currency'
                                >
                                    <SelectTrigger className='font-medium text-xs h-[3rem] text-[#5E5E5E]  border-gray4/60 '>
                                        <SelectValue className='' placeholder={'Select currency'} />
                                    </SelectTrigger>
                                    <SelectContent className=''>
                                        {currencies?.map(currency => (
                                            <SelectItem className='text-xs' value={currency?.id}>
                                                <span>{currency?.symbol}</span> - {currency?.name}(
                                                {currency?.currency_code})
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </fieldset>
                            <div className='flex justify-between items-center'>
                                <h2 className='font-medium text-sm opacity-70'>Allow partial payment</h2>
                                <div>
                                    <div className='flex justify-between text-xs font-medium text-gray7 gap-4 mb-1'>
                                        <span>No</span>
                                        <span>Yes</span>
                                    </div>
                                    <Switch
                                        type='button'
                                        onChange={e =>
                                            setFormState(prev => ({
                                                ...prev,
                                                part_payment: e,
                                            }))
                                        }
                                        checked={formState?.part_payment}
                                        className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                                            formState?.part_payment ? 'bg-brand' : 'bg-[#EFEFF9]'
                                        }`}
                                    >
                                        <span
                                            className={`${
                                                !formState?.part_payment
                                                    ? 'translate-x-1 bg-brand'
                                                    : 'translate-x-6 bg-white'
                                            } inline-block h-4 w-4 transform rounded-full  transition`}
                                        />
                                    </Switch>
                                </div>
                            </div>

                            {formState.part_payment && (
                                <div className='mt-3 hidden'>
                                    <h2 className='font-medium text-sm opacity-70 mb-1.5'>Minimum part payment</h2>
                                    <div className='flex gap-3 items-center'>
                                        <Input
                                            rightIcon={<Percent />}
                                            className='h-9 border-gray8'
                                            bodyClassName='!min-w-[6.5rem] !max-w-[6.5rem]'
                                            min={0}
                                            value={formatAmount(formState?.part_payment_percent).replaceAll('₦', '')}
                                            onChange={e => {
                                                const value_entered = formatAmountWithoutSymbols(e.target.value)
                                                if (Number(value_entered) > 10000) return
                                                setFormState(prevState => ({
                                                    ...prevState,
                                                    part_payment_percent: Number(value_entered),
                                                }))
                                            }}
                                        />
                                        {partPaymentAmount > 0 && (
                                            <p className='text-brand text-xs font-medium'>
                                                Customer to pay not less than {formatAmount(partPaymentAmount)}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
            <div className='text-sm font-medium  text-[#5E5E5E] mt-4'>
                <span>Add footnote to invoice PDF</span>
                <Input
                    className={`min-w-full h-12 w-full border-gray/60 rounded-md mt-2`}
                    placeholder={`Enter footnote text`}
                    bodyClassName='!min-w-full'
                    type='text'
                    value={formState?.footer}
                    onChange={e => {
                        setFormState(prev => ({
                            ...prev,
                            footer: e.target.value,
                        }))
                    }}
                />
            </div>

            {openConfirmationPrompt && (
                <ConfirmationPrompt
                    closeModal={() => setOpenConfirmtionPrompt(false)}
                    action={() => connectedGateWays?.[selectedGateway as PAYMENT_GATEWAY]?.disconnectFn?.()}
                    loading={isDisconnectingStripe || isDisconnectingPaystack}
                />
            )}
            {connectPaystack && (
                <AddPayStackIntegrationModal closeModal={() => setConnectPaystack(false)} allBankList={allBankList} />
            )}
        </div>
    )
}
