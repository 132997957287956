import React, { useRef, useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { BizpendAccounts, CancelIcon } from '@/assets/assets.export'
import { BillType } from '@/services/bill-payment/types'
import { differenceInCalendarDays, format } from 'date-fns'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { Button } from '@/components/Button'
import DatePicker from 'react-datepicker'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { useMutationPayBill, useMutationScheduleBill } from '@/services/bill-payment/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { calculateTransferFee, formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { useGetAccounts } from '@/services/bill-payment/queries'
import { useAuthContext } from '@/context'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { AlertCircleIcon } from 'lucide-react'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    bill: BillType
    schedule: boolean
}
export const SettleBillModal = ({ closeModal, bill, schedule }: Props) => {
    const calendarRef1 = useRef<DatePicker>(null)
    const { selectedAccount } = useAuthContext()

    const [formState, setFormState] = useState({
        account: '',
        payment_date: '',
        payment_time: '',
    })
    const { mutate: payBillFn, isLoading: isPayingBill } = useMutationPayBill({
        onSuccess: () => {
            toastHandler({ message: 'Bill paid successfully', state: 'success' })
            closeModal(false)
        },
    })
    const { mutate: scheduleBillFn, isLoading: isSchedulingBill } = useMutationScheduleBill({
        onSuccess: () => {
            toastHandler({ message: 'Bill scheduled successfully', state: 'success' })
            closeModal(false)
        },
    })
    const { data: accounts } = useGetAccounts({
        queryParams: {
            entity_id: selectedAccount?.org?.id as string,
        },
    })

    const handleBillPayment = () => {
        if (!schedule) {
            payBillFn({
                id: bill._id ?? bill.id,
                source_type: formState.account === 'Wallet' ? 'Wallet' : 'DepositAccount',
                source_id: formState.account === 'Wallet' ? '' : formState.account,
            })
            return
        }
        if (schedule) {
            const p_date =
                format(formState.payment_date as unknown as Date, 'yyyy-MM-dd') +
                'T' +
                formState.payment_time +
                ':00:00.000Z'
            scheduleBillFn({
                module_type: 'Bill',
                module_item: bill._id ?? bill.id,
                payment_date: p_date,
                payment_method_type: formState.account === 'Wallet' ? 'Wallet' : 'BankAccount',
                payment_method_item: formState.account === 'Wallet' ? undefined : formState.account,
            })
            return
        }
    }
    const disableButton = () => {
        const res = Object.values({ ...formState })
            .map((value, key) => (key > 0 && !schedule ? true : !!value))
            .some(data => data === false)

        return res
    }

    return (
        <ModalWrapper variants={undefined}>
            <main className='max-h-[80vh] h-fit w-screen lg:w-[36rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium'>{schedule ? 'Schedule payment' : 'Pay bill'} </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='p-7 pt-5 max-h-[calc(80vh-148px)] h-fit overflow-y-scroll'>
                    <div className='mb-2 lg:mb-6 hidden'>
                        <h1 className='text-dark font-semibold text-lg'>Settle bill now</h1>
                    </div>
                    <div className='rounded-2xl p-5 bg-[#EFEFF9] flex flex-col gap-2 mb-6'>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Bill ID</p>
                            <p className='font-semibold'>{bill.bill_id}</p>
                        </div>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Vendor</p>{' '}
                            <p className='font-semibold'>{capitalizeText(bill.vendor.name)}</p>
                        </div>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Issue date</p>{' '}
                            <p className='font-semibold'>{format(new Date(bill.createdAt), 'dd/MM/yyyy')}</p>
                        </div>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Due date</p>{' '}
                            <p className='font-semibold'>
                                {format(new Date(bill.due_date), 'dd/MM/yyyy')} (Due in{' '}
                                {differenceInCalendarDays(
                                    new Date(bill?.due_date as string),
                                    new Date(bill?.createdAt as string)
                                )}{' '}
                                days )
                            </p>
                        </div>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Total amount due</p>{' '}
                            <p className='font-semibold'>{formatAmount(bill.amount)}</p>
                        </div>
                    </div>

                    <div className='mt-5 flex flex-col flex-1 '>
                        <label className='mb-3 text-sm' htmlFor='funding_method'>
                            Pay with this account
                        </label>
                        <Select
                            name='fund_source'
                            onValueChange={value => {
                                setFormState(prevState => ({
                                    ...prevState,
                                    account: value,
                                }))
                            }}
                            value={formState.account}
                        >
                            <SelectTrigger className=' text-base h-[3.3rem]  text-[#5E5E5E] border-gray3 flex '>
                                {formState?.account ? <SelectValue /> : <span>Select account</span>}
                            </SelectTrigger>
                            <SelectContent className='max-h-[12rem] overflow-y-scroll'>
                                <SelectItem value={'Wallet'} className='pl-3'>
                                    <div className='flex items-center gap-3'>
                                        <BizpendAccounts
                                            className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`}
                                        />
                                        <div className='flex flex-col items-start'>
                                            <h6 className='text sm'>Main Wallet</h6>
                                        </div>
                                    </div>
                                </SelectItem>
                                {accounts?.accounts
                                    ?.filter(acct => acct?.connection_type === 'create')
                                    ?.map((acct: any) => (
                                        <div className='flex justify-between items-center'>
                                            <SelectItem
                                                value={acct?._id}
                                                className='pl-3 block'
                                                key={acct.id}
                                                disabled={bill.amount > acct.balance}
                                            >
                                                <div className='flex items-center gap-3'>
                                                    <BizpendAccounts
                                                        className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`}
                                                    />
                                                    <div className='flex flex-col justify-start items-start'>
                                                        <h6 className='text sm'>
                                                            {acct.nickname ?? acct.account_name}
                                                        </h6>
                                                        <p className='text-xs text-gray1 font-medium'>
                                                            {formatAmount(acct.balance)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                            <TooltipProvider delayDuration={10}>
                                                <Tooltip>
                                                    <TooltipTrigger className='mr-2' type='button'>
                                                        <AlertCircleIcon size={15} />
                                                    </TooltipTrigger>

                                                    <TooltipContent>
                                                        <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                            Insufficient balance to process this payment
                                                        </p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                    ))}
                            </SelectContent>
                        </Select>
                    </div>
                    {schedule && (
                        <>
                            <div className='flex flex-col gap-5 mt-6'>
                                <div className='flex flex-col gap-3'>
                                    <label className='flex items-center gap-1 text-sm'>
                                        Set payment date <span className='text-[red]'>*</span>
                                    </label>

                                    <CustomDatePicker
                                        ref={calendarRef1}
                                        placeholderText='DD/MM/YYYY'
                                        className='w-[70%]'
                                        // minDate={(new Date()).toISOString().split('T')[0] as unknown as Date}
                                        onChange={date =>
                                            setFormState(prev => ({
                                                ...prev,
                                                payment_date: date! as unknown as string,
                                            }))
                                        }
                                        wrapperClassName='border-[1px]'
                                        selected={formState.payment_date as unknown as Date}
                                    />
                                </div>
                                <div className='flex flex-col gap-3'>
                                    <label className='flex items-center gap-1 text-sm'>Set time</label>
                                    <Select
                                        name='vendor'
                                        onValueChange={value => {
                                            setFormState(prevState => ({
                                                ...prevState,
                                                payment_time: value,
                                            }))
                                        }}
                                        value={formState.payment_time}
                                    >
                                        <SelectTrigger
                                            className='h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px] w-full'
                                            id='customer'
                                        >
                                            {formState?.payment_time ? (
                                                <SelectValue />
                                            ) : (
                                                <span>Select payment time</span>
                                            )}
                                        </SelectTrigger>
                                        <SelectContent
                                            className='pb-3 max-h-[220px] overflow-scroll'
                                            id={`customer_content`}
                                        >
                                            {timeList.map((time, i) => (
                                                <SelectItem key={i} value={`${i > 9 ? i : '0' + i}`}>
                                                    {time}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </>
                    )}
                    <div className='rounded-2xl p-5 bg-[#EFFFED] flex flex-col gap-2 mb-2 mt-6'>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Fee</p>
                            <p className='font-semibold'>2% + N100</p>
                        </div>
                        <div className='flex justify-between'>
                            <p className='text-gray7'>Total debit amount</p>{' '}
                            <p className='font-semibold'>
                                {formatAmount(calculateTransferFee(`${bill.amount}`) + bill.amount)}
                            </p>
                        </div>
                    </div>
                </div>
                <section className='flex justify-end gap-6 border-t-[1px] border-[#DADCE0] py-4 pr-7 h-[83px]'>
                    <Button
                        className='bg-[#19C37D] hover:bg-[#255541] min-w-[100px] px-4'
                        loading={isPayingBill || isSchedulingBill}
                        type='submit'
                        disabled={disableButton()}
                        onClick={() => {
                            handleBillPayment()
                        }}
                    >
                        {schedule ? 'Schedule payment' : 'Pay bill'}
                    </Button>
                </section>
            </main>
        </ModalWrapper>
    )
}

const timeList = [
    '12:00 am',
    '1:00 am',
    '2:00 am',
    '3:00 am',
    '4:00 am',
    '5:00 am',
    '6:00 am',
    '7:00 am',
    '8:00 am',
    '9:00 am',
    '10:00 am',
    '11:00 am',
    '12:00 pm',
    '1:00 pm',
    '2:00 pm',
    '3:00 pm',
    '4:00 pm',
    '5:00 pm',
    '6:00 pm',
    '7:00 pm',
    '8:00 pm',
    '9:00 pm',
    '10:00 pm',
    '11:00 pm',
]
