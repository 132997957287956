import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { emailPattern } from '@/const/regex'
import { useCreateNewAdminUser } from '@/services/auth/mutations'
import { useState } from 'react'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { CheckIcon } from 'lucide-react'
import { kyb } from '@/modules/dashboard/Settings/partials/CompanyCompliance/utils'

interface Step1Props {
    toggleStep: (step: number) => void
    setEmailForOtp: React.Dispatch<React.SetStateAction<string>>
}
type FormValues = {
    first_name: string
    last_name: string
    email: string
    password: string
    org_name: string
    phone: string
    industry: string
    website: string
    description: string
}

const Step1 = ({ toggleStep, setEmailForOtp }: Step1Props) => {
    const {
        handleSubmit,
        formState: { isValid },
        control,
        watch,
    } = useForm<FormValues>()

    const { mutate: createNewAdminUser, isLoading } = useCreateNewAdminUser({
        onSuccess: () => {
            // setLoggedIn(true)
            toggleStep(2)
        },
    })
    const [showPassword, setShowPassword] = useState(false)

    const err_msg = 'Must be between (6-20) characters and should include at least one uppercase and one numeric value'

    const handleShowPassword = () => {
        setShowPassword(prev => !prev)
    }
    const onSubmit = async (values: FormValues) => {
        setEmailForOtp(values.email)
        createNewAdminUser(values)
    }
    const checkPassword = watch('password')
    const checkIfUpperCase =
        checkPassword === undefined || checkPassword.length < 1 ? false : /[A-Z]|[\u0080-\u024F]/.test(checkPassword)
    const checkIfLowerCase =
        checkPassword === undefined || checkPassword.length < 1 ? false : /[a-z]|[\u0080-\u024F]/.test(checkPassword)
    const checkIfHasNumber =
        checkPassword === undefined || checkPassword.length < 1 ? false : /[0123456789]/.test(checkPassword)

    const watchDescription = watch('description')

    const disableBtn = checkIfUpperCase && checkIfLowerCase && checkIfHasNumber && checkPassword.length > 7 && isValid

    return (
        <div className='w-[90%] md:w-[85%] max-w-[1100px] mx-auto my-12'>
            <div className='flex justify-end mb-4'>
                <span className='py-2 px-4 bg-[#EFEFF9] rounded-2xl flex items-center gap-2'>
                    1 / 2 <span className='font-semibold'> steps</span>
                </span>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className='text-[#31254B] text-3xl mb-4 font-semibold'>Join us today</h2>
                <p className='text-[#202020] mb-6 text-[18px]'>Begin your journey to easy financial management.</p>

                <div className='flex flex-col md:flex-row gap-[35px] mb-6'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='firstName' className='text-[#202020] text-[14px]'>
                            First name <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='first_name'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    className='h-[3.5rem]'
                                    placeholder='Enter your first name'
                                    value={field.value as string}
                                />
                            )}
                        />
                    </div>

                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='lastName' className='text-[#202020] text-[14px]'>
                            Last name <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='last_name'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input {...field} className='h-[3.5rem]' placeholder='Enter your last name' />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-[35px] mb-6'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='OrganizationName' className='text-[#202020] text-[14px]'>
                            Business name <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='org_name'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    className='h-[3.5rem]'
                                    placeholder='Enter the name of your business'
                                />
                            )}
                        />
                    </div>{' '}
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Industry <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='industry'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Select {...field} name={'industry'} onValueChange={field.onChange}>
                                    <SelectTrigger className='h-12  text-sm' data-testid='industry'>
                                        <SelectValue placeholder='Select Industry' />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        {kyb.industries.map(industry => (
                                            <SelectItem value={industry.title}>{industry.title}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </div>
                </div>

                <div className='flex flex-col md:flex-row gap-[35px] mb-6'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Work email <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='email'
                            rules={{
                                required: true,
                                validate: value => {
                                    return emailPattern.test(value)
                                },
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    className='h-[3.5rem]'
                                    type='email'
                                    placeholder='Enter your work email address'
                                />
                            )}
                        />
                    </div>

                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Work Phone Number <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='phone'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    className='h-[3.5rem]'
                                    type='number'
                                    placeholder='Enter your work phone number'
                                />
                            )}
                        />
                    </div>
                </div>

                <div className='flex flex-col md:flex-row gap-[35px] mb-0'>
                    <div className='flex flex-col flex-1 gap-3 '>
                        <label htmlFor='OrganizationName' className='text-[#202020] text-[14px]'>
                            Business Website <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='website'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    data-testid='website'
                                    className='h-[3.5rem]'
                                    placeholder='Enter your business website'
                                />
                            )}
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-sm flex justify-between items-center'>
                            <span>
                                Company description <span className='text-[red]'>*</span>
                            </span>
                            <span className='text-xs font-semibold opacity-50'>
                                {watchDescription?.length || 0}/120
                            </span>
                        </label>
                        <Controller
                            name='description'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Textarea
                                    {...field}
                                    data-testid='description'
                                    className='w-full min-w-full resize-none h-12 placeholder:pl-2 border-black/40'
                                    placeholder={`Enter company description`}
                                    name={'description'}
                                    maxLength={120}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className='flex flex-col w-1/2 pr-4 gap-3 mb-6'>
                    <label htmlFor='password' className='text-[#202020] text-[14px]'>
                        Password <span className='text-[red]'>*</span>
                    </label>
                    <Controller
                        name='password'
                        rules={{
                            required: true,
                            minLength: { value: 6, message: err_msg },
                            maxLength: { value: 20, message: err_msg },
                            pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                                message: err_msg,
                            },
                        }}
                        control={control}
                        render={({ field }) => (
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Enter your password'
                                className='h-[3rem]'
                                rightIcon={
                                    showPassword ? (
                                        <EyeOffIcon onClick={handleShowPassword} className='cursor-pointer' />
                                    ) : (
                                        <EyeIcon onClick={handleShowPassword} className='cursor-pointer' />
                                    )
                                }
                                {...field}
                            />
                        )}
                    />

                    <ul className='text-[14px] text-[#838383] flex flex-col gap-2 mt-4'>
                        <li className='flex gap-2 items-center font-medium'>
                            <CheckIcon
                                className={`rounded-full border-2 p-1 scale-75 ${
                                    checkPassword?.length > 7 ? 'text-[#19C37D]' : 'text-[#838383]'
                                }`}
                                strokeWidth={4}
                            />
                            <span className={`${checkPassword?.length > 7 ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                Your password must be a minimum of 8 characters long
                            </span>
                        </li>
                        <li className='flex gap-2 items-center font-medium'>
                            <CheckIcon
                                className={`rounded-full border-2 p-1 scale-75 ${
                                    checkIfUpperCase ? 'text-[#19C37D]' : 'text-[#838383]'
                                }`}
                                strokeWidth={4}
                            />
                            <span className={`${checkIfUpperCase ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                Your password must contain at least one uppercase letter (A-Z)
                            </span>
                        </li>
                        <li className='flex gap-2 items-center font-medium'>
                            <CheckIcon
                                className={`rounded-full border-2 p-1 scale-75 ${
                                    checkIfLowerCase ? 'text-[#19C37D]' : 'text-[#838383]'
                                }`}
                                strokeWidth={4}
                            />
                            <span className={`${checkIfLowerCase ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                Your password must contain at least one lowercase letter (a-z)
                            </span>
                        </li>
                        <li className='flex gap-2 items-center font-medium'>
                            <CheckIcon
                                className={`rounded-full border-2 p-1 scale-75 ${
                                    checkIfHasNumber ? 'text-[#19C37D]' : 'text-[#838383]'
                                }`}
                                strokeWidth={4}
                            />
                            <span className={`${checkIfHasNumber ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                Your password must contain at least one numeric character (0-9)
                            </span>
                        </li>
                    </ul>
                </div>

                <div className='flex flex-col-reverse md:flex-row gap-4 md:gap-0 items-center justify-between mt-12'>
                    <p className='flex items-center gap-2 text-[#202020] font-medium'>
                        Already have an account?
                        <Link to='/' className='text-[#454ADE] font-semibold'>
                            Log in
                        </Link>
                    </p>

                    <Button loading={isLoading} disabled={!disableBtn} variant='default' type='submit'>
                        Create account
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default Step1
