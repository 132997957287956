import React from 'react'
import ModalWrapper from '../../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { Button } from '@/components/Button'
import { useGetParentAccounts } from '@/services/accounting/queries'

type Props = {
    closeModal: () => void
    selectedAccount: any
}

const SelectParentAccount = ({ closeModal, selectedAccount }: Props) => {
    const { data: parentAccounts, isLoading } = useGetParentAccounts({})

    return (
        <ModalWrapper>
            <section className='min-h-[15rem] w-[25rem]  overflow-scroll '>
                <div className='flex items-center border-b border-[#DADCE0] p-2 px-4 justify-between'>
                    <h2 className='text-center text-lg font-semibold'>Select parent account</h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='my-6 px-9 mb-9'>
                    <p className='font-medium mb-1 text-base'>Account name</p>
                    <p className='text-2xl font-semibold text-gray7'>{selectedAccount?.account_name}</p>

                    <div className='mt-5'>
                        <p className='text-base font-medium mb-3'>Parent account *</p>
                        <Select>
                            <SelectTrigger className='text-base h-[3rem] border-gray3 flex '>
                                <SelectValue placeholder={'Select parent account'} />
                            </SelectTrigger>
                            <SelectContent className='h-56'>
                                {parentAccounts?.results.map((item, idx) => (
                                    <SelectItem value={item?.id} key={idx}>
                                        <div className='flex items-center gap-2'>{item?.account_name}</div>
                                    </SelectItem>
                                ))}
                                {parentAccounts?.results?.length === 0 && !isLoading && (
                                    <p className='py-1 pl-2'>There are no parents account</p>
                                )}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className='border-t border-gray8 pt-3 pb-4 px-6 flex justify-end'>
                    <Button className='min-h-[44px] text-xs px-4'>Save parent account</Button>
                </div>
            </section>
        </ModalWrapper>
    )
}

export default SelectParentAccount
