import { EfficiencyTracker, EmptySuggestions, Suggestions } from '@/assets/assets.export'
import { Tabs, TabsList, TabsTrigger, TabsContent, TabTriggersContainer } from '@/components/Tabs/Tabs'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { ChevronRight } from 'lucide-react'
import EfficiencyChart from './EfficiencyChart'

const options = {
    invoices: 'invoices',
    reimbursements: 'reimbursements',
    vendors: 'vendors',
}

export const EfficiencyRating = () => {
    const [selected, setSelected] = useState(options?.invoices)
    const [showEfficiency /*setShowEfficiency*/] = useState(true)
    return (
        <div className='bg-white border border-gray8 rounded-[20px] py-5 px-3 lg:px-6 flex flex-col lg:flex-row gap-6 min-h-[432px]'>
            <div className='w-full lg:w-[37%] space-y-6'>
                <p className='my-4 text-lg font-semibold text-center w-full'>Efficiency rating - This week</p>
                {showEfficiency ? (
                    <>
                        <div className='border-b border-gray8'>
                            <EfficiencyChart />
                        </div>
                        <div className=''>
                            <p className='font-medium'>Avg. workflow and approval completion time</p>
                            <div className='mt-4 bg-offwhite border border-gray8 p-3 rounded-[8px] text-sm font-semibold w-full space-y-3'>
                                <p className='flex items-center justify-between'>
                                    <span className=''>Invoice cycle</span>
                                    <span className='text-gray7 font-medium'>2.5 days</span>
                                </p>
                                <p className='flex items-center justify-between'>
                                    <span>Reimbursement cycle</span>
                                    <span className='text-gray7 font-medium'>1.8 days</span>
                                </p>
                                <p className='flex items-center justify-between'>
                                    <span>Payroll cycle</span>
                                    <span className='text-gray7 font-medium'>1 days</span>
                                </p>
                                <p className='flex items-center justify-between'>
                                    <span>Others</span>
                                    <span className='text-gray7 font-medium'>1.6 days</span>
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className='flex items-center justify-center flex-col text-gray7 h-[350px]'>
                        <EfficiencyTracker />
                        <h2 className='text-gray7 font-medium mt-5'>No rating here yet</h2>
                    </div>
                )}
            </div>
            <p className='h-[1px] lg:h-auto lg:min-h-full bg-gray8 w-full lg:w-[1px]'>&nbsp;</p>

            {/* Suggestions section */}
            <div className='w-full lg:w-[61%]'>
                <p className='mt-2 flex gap-1 items-center'>
                    <Suggestions />{' '}
                    <span className='text-lg font-semibold'>Suggestions to improve efficiency rating</span>
                </p>
                <div className=''>
                    <Tabs defaultValue={selected} value={selected}>
                        <TabsList className=' flex items-center gap-6 w-full border-b border-gray8 '>
                            <TabTriggersContainer className='mx-0 lg:mx-auto lg:w-full pl-2'>
                                <TabsTrigger
                                    value={options.invoices}
                                    id='invoices'
                                    onClick={() => setSelected(options.invoices)}
                                    className={`relative border-none text-sm whitespace-nowrap`}
                                >
                                    {options.invoices === selected && (
                                        <motion.div
                                            layoutId='org_view_tab'
                                            className='absolute bottom-0 w-full h-0.5 text-sm bg-[#313131]'
                                        />
                                    )}
                                    <motion.span layout='position' className='z-20 w-full'>
                                        Invoices
                                    </motion.span>
                                </TabsTrigger>
                                <TabsTrigger
                                    value={options.reimbursements}
                                    id='personal'
                                    onClick={() => setSelected(options.reimbursements)}
                                    className={`relative border-none text-sm whitespace-nowrap`}
                                >
                                    {options.reimbursements === selected && (
                                        <motion.div
                                            layoutId='org_view_tab'
                                            className='absolute bottom-0 w-full h-0.5 text-sm bg-[#313131]'
                                        />
                                    )}
                                    <motion.span layout='position' className='z-20 w-full'>
                                        Reimbursements
                                    </motion.span>
                                </TabsTrigger>
                                <TabsTrigger
                                    value={options.vendors}
                                    id='personal'
                                    onClick={() => setSelected(options.vendors)}
                                    className={`relative border-none text-sm whitespace-nowrap`}
                                >
                                    {options.vendors === selected && (
                                        <motion.div
                                            layoutId='org_view_tab'
                                            className='absolute bottom-0 w-full h-0.5 text-sm bg-[#313131]'
                                        />
                                    )}
                                    <motion.span layout='position' className='z-20 w-full'>
                                        Vendor payment
                                    </motion.span>
                                </TabsTrigger>
                            </TabTriggersContainer>
                        </TabsList>
                        {showEfficiency ? (
                            <>
                                <TabsContent value={options?.invoices}>
                                    <TabDetails selected={options?.invoices} />
                                </TabsContent>
                                <TabsContent value={options?.reimbursements}>
                                    <TabDetails selected={options?.reimbursements} />
                                </TabsContent>
                                <TabsContent value={options?.vendors}>
                                    <TabDetails selected={options?.vendors} />
                                </TabsContent>
                            </>
                        ) : (
                            <div className='flex items-center justify-center flex-col text-gray7 h-[350px]'>
                                <EmptySuggestions />
                                <h2 className='text-gray7 font-medium mt-5'>Nothing to see here yet</h2>
                            </div>
                        )}
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

const TabDetails = ({ selected }: { selected: string }) => {
    return (
        <div className='space-y-5 lg:space-y-4 mt-4'>
            {deets?.[selected as keyof typeof deets]?.map(detail => (
                <div className='flex flex-col lg:flex-row gap-2 lg:gap-0 lg:items-center justify-between'>
                    <p className='text-gray7 font-medium'>
                        {detail?.heading}{' '}
                        {!!detail?.alert && (
                            <>
                                <span>-</span> <span className='text-errorRed'>{detail?.alert}</span>
                            </>
                        )}
                    </p>
                    <Link
                        className='text-brand text-sm font-medium flex items-center whitespace-nowrap'
                        to={detail?.action}
                    >
                        {detail?.subText} <ChevronRight className='ml-2' />
                    </Link>
                </div>
            ))}
        </div>
    )
}

const deets = {
    invoices: [
        {
            heading: ' Automate invoice approvals for amounts below N10,000.00',
            subText: 'Edit approval policy',
            action: '',
            alert: '',
        },
        {
            heading: 'Follow up on overdue invoices',
            subText: 'See all overdue invoices',
            alert: '10 overdue invoices',
            action: '',
        },
    ],
    reimbursements: [
        {
            heading: 'Automate reimbursements after final expense approval for amounts below N10,000.00',
            subText: 'Edit approval policy',
            action: '',
            alert: '',
        },
        {
            heading: 'Follow up on pending reimbursements',
            subText: 'See all pending reimbursements',
            alert: '10 pending ',
            action: '',
        },
        {
            heading: 'Follow up on missing receipts',
            subText: 'See expenses with missing receipts',
            alert: '8 missing receipts',
            action: '',
        },
    ],
    vendors: [
        {
            heading: 'Follow up on overdue payments',
            subText: 'See all overdue payments',
            alert: '10 pending ',
            action: '',
        },
    ],
}
