import { CancelIcon, Trash2 } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { ChartOfAccountType, ChartOfAccountTypeImport } from '@/types/accounting'
import { useUploadCOA } from '@/services/accounting/mutations'
import {
    Controller,
    FieldArrayWithId,
    useFieldArray,
    UseFieldArrayRemove,
    UseFieldArrayUpdate,
    useForm,
    UseFormReturn,
} from 'react-hook-form'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectSeparator,
    SelectTrigger,
    SelectValue,
} from '@/components/Select'
import { useAccountingContext } from '@/context'
import { ChevronDown, LucideEdit } from 'lucide-react'
import { Switch } from '@headlessui/react'
import { cn } from '@/lib/styling'
import { Accordion, AccordionItem, AccordionTrigger } from '@/components/Accordion/index2'
import { useState } from 'react'
import SelectParentAccount from './ImportCOA/SelectParentAccount'
import AddNewAccountModal from '../Accounts/AddNewAccountModal'
import { mapSubAccountToParentType } from '@/modules/dashboard/Accounting/utils'
import { useGetParentAccounts } from '@/services/accounting/queries'

type Props = {
    closeModal: () => void
    uploadedCOA: ChartOfAccountTypeImport[]
    nextStep?: () => void
    prevStep?: () => void
    overwriteData: boolean
}

type FormType = {
    rows: {
        account_name: string
        account_type: string
        description: string
        code: string
        is_subaccount: boolean
        parent_type?: string
        parent_account?: string
    }[]
}

const PreviewUploadedCOAModal = ({ closeModal, nextStep, uploadedCOA, prevStep, overwriteData }: Props) => {
    const { accounttingTypes } = useAccountingContext()

    const form = useForm<FormType>({
        defaultValues: {
            rows: uploadedCOA?.map(coa => ({
                account_name: coa?.Account_Name,
                // account_type: accounttingTypes?.find(type => type?.name === coa?.Account_Type)?._id,
                account_type: accounttingTypes
                    ?.flatMap(type => type?.children ?? [])
                    ?.find(type => type?.name?.toLowerCase() == coa?.Sub_Account_Type?.toLowerCase())?._id,
                description: coa?.Description,
                code: coa?.Code,
                is_subaccount: false,
            })),
        },
    })

    const { fields, remove, update } = useFieldArray<FormType>({
        name: 'rows',
        control: form.control,
    })

    const { mutate: uploadChartOfAccountFn, isLoading } = useUploadCOA({
        onSuccess: () => {
            nextStep?.()
        },
    })

    const handleImport = (values: FormType) => {
        uploadChartOfAccountFn({
            data: values?.rows?.map(coa => ({
                code: coa.code,
                account_name: coa.account_name,
                account_type: coa.account_type,
                description: coa.description,
            })),
            override_duplicate: overwriteData,
        })
    }

    return (
        <section className='min-h-[15rem] w-screen lg:w-auto overflow-scroll '>
            <div className='flex items-center border-b border-[#DADCE0] p-2 px-4 justify-between'>
                <h2 className='text-center text-base font-medium opacity-80'>Preview uploaded Chart of accounts</h2>
                <CancelIcon className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer' onClick={() => closeModal()} />
            </div>
            <div className='p-5 max-h-[70vh] overflow-y-scroll'>
                <div className='mt-2'>
                    <div className='rounded-lg border border-gray5 overflow-hidden mt-2'>
                        <table className='w-full leading-normal border-collapse text-xs'>
                            <thead>
                                <tr className='grid grid-cols-10'>
                                    {['Account name', 'ID', 'Type', 'Description'].map((col, i) => (
                                        <th
                                            key={i}
                                            className={cn(
                                                'flex items-center border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90',
                                                col === 'ID' ? 'col-span-1' : 'col-span-2'
                                            )}
                                        >
                                            {col}
                                        </th>
                                    ))}
                                    <th className='flex col-span-2 items-center border-l border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                        Sub-account?
                                    </th>
                                    <th className='flex col-span-1 items-center border-l border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {fields.map((field, i) => (
                                    <SingleParentAccountAccordion
                                        field={field}
                                        key={i}
                                        i={i}
                                        form={form}
                                        remove={remove}
                                        update={update}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className='border-t border-gray4 flex mt-6 justify-end items-center p-4 gap-4'>
                <Button onClick={prevStep} variant={'outline'} size={'lg'} className='text-xs min-w-[100px] px-3 '>
                    back to upload page
                </Button>
                <Button
                    onClick={form.handleSubmit(handleImport)}
                    loading={isLoading}
                    size={'lg'}
                    disabled={!form.formState.isValid}
                    className='text-xs px-4'
                >
                    Confirm and import
                </Button>
            </div>
        </section>
    )
}

export const SingleParentAccountAccordion = ({
    field,
    i,
    form,
    remove,
    update,
}: {
    field: FieldArrayWithId<FormType, 'rows', 'id'>
    i: number
    form: UseFormReturn<FormType, any, undefined>
    remove: UseFieldArrayRemove
    update: UseFieldArrayUpdate<FormType, 'rows'>
}) => {
    const [show, setShow] = useState(false)
    const [showParent, setShowParent] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const { accounttingTypes } = useAccountingContext()
    const { data: parentAccounts, isLoading: isLoadingParents } = useGetParentAccounts({})

    // console.log(
    //     accounttingTypes,
    //     field?.account_type,
    //     accounttingTypes?.flatMap(type => type?.children ?? [])?.find(type => type?._id == field?.account_type)
    // )

    return (
        <>
            <tr>
                <td colSpan={6}>
                    <Accordion type='single' collapsible>
                        <AccordionItem value='item-1' className='border-none'>
                            <AccordionTrigger
                                className={cn('w-full grid grid-cols-12 gap-0', show && 'bordr-b border-gray5')}
                                onClick={() => setShow(!show)}
                            >
                                <>
                                    <td className='border-gray5 col-span-2 px-5 h-12 border-t font-medium text-gray7 flex items-center justify-between'>
                                        {field.account_name}
                                        <ChevronDown
                                            color='#5E5E5E'
                                            size={18}
                                            className={cn('transition-all hidden', show && 'rotate-180')}
                                        />
                                    </td>
                                    <td className='border-gray5 col-span-1 px-5 h-12 border-t  font-medium text-gray7 flex items-center'>
                                        {field.code}
                                    </td>
                                    <td className='border-gray5 col-span-1 px-5 h-12 border-t  font-medium text-gray7 flex items-center'>
                                        {field?.parent_type}
                                    </td>

                                    <td className='col-span-2  border-gray5 px-5 h-12 border-t  font-medium text-gray7 flex items-center'>
                                        <Controller
                                            name={`rows.${i}.account_type`}
                                            control={form.control}
                                            render={({ field: insideField }) => (
                                                <Select
                                                    onValueChange={e => {
                                                        insideField.onChange(e)
                                                        update(i, {
                                                            ...field,
                                                            account_type: e,
                                                            parent_type: mapSubAccountToParentType(accounttingTypes, e),
                                                        })
                                                    }}
                                                    value={insideField.value}
                                                >
                                                    <SelectTrigger className=' text-xs text-gray7  h-10  border-gray8 flex disabled:opacity-70'>
                                                        <SelectValue placeholder={'Select account type'} />
                                                    </SelectTrigger>
                                                    <SelectContent className='w-60 h-56'>
                                                        {accounttingTypes?.map(type => (
                                                            <SelectGroup className='my-1'>
                                                                <SelectLabel className='text-black pl-2'>
                                                                    {type?.name}
                                                                </SelectLabel>
                                                                {type?.children.map(child => (
                                                                    <SelectItem
                                                                        className='text-gray7 pl-6'
                                                                        value={child?._id ?? child?.id}
                                                                    >
                                                                        {child?.name}
                                                                    </SelectItem>
                                                                ))}
                                                                <SelectSeparator />
                                                            </SelectGroup>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            )}
                                        />
                                    </td>
                                    <td className='col-span-1 min-w-[130px] border-gray5 px-5 h-12 border-t border-l font-medium text-gray7 flex items-center'>
                                        <Controller
                                            name={`rows.${i}.is_subaccount`}
                                            rules={{
                                                required: false,
                                            }}
                                            control={form.control}
                                            render={({ field: insideField }) => (
                                                <div className='flex gap-3 justify-between items-center'>
                                                    <p className='text-sm text-gray7 font-medium py-1'>No</p>
                                                    <Switch
                                                        type='button'
                                                        onChange={e => {
                                                            insideField.onChange(e)
                                                            // if (!e) {
                                                            //     update(i, { ...field, parent_account: undefined })
                                                            // }
                                                        }}
                                                        checked={insideField.value}
                                                        className={cn(
                                                            `relative inline-flex h-6 w-11 items-center rounded-full`,
                                                            insideField.value ? 'bg-brand' : 'bg-[#EFEFF9]'
                                                        )}
                                                    >
                                                        <span
                                                            className={`${
                                                                insideField.value
                                                                    ? 'translate-x-6 bg-[#D5D5F5]'
                                                                    : 'translate-x-1 bg-brand'
                                                            } inline-block h-4 w-4 transform rounded-full transition`}
                                                        />
                                                    </Switch>
                                                    <p className='text-sm text-gray7 font-medium py-1'>Yes</p>
                                                </div>
                                            )}
                                        />
                                    </td>
                                    <td className='border-gray5 col-span-2 px-5 h-12 border-t border-x  font-medium text-gray7 flex items-center'>
                                        <Controller
                                            name={`rows.${i}.parent_account`}
                                            control={form.control}
                                            render={({ field: insideField }) => (
                                                <Select
                                                    onValueChange={e => {
                                                        insideField.onChange(e)
                                                    }}
                                                    value={insideField.value}
                                                >
                                                    <SelectTrigger
                                                        // disabled={!field?.is_subaccount}
                                                        className=' text-xs text-gray7  h-10  border-gray8 flex disabled:opacity-70'
                                                    >
                                                        <SelectValue
                                                            placeholder={
                                                                field?.is_subaccount
                                                                    ? 'Select parent'
                                                                    : 'Not applicable'
                                                            }
                                                        />
                                                    </SelectTrigger>
                                                    <SelectContent className='w-60 h-56'>
                                                        {parentAccounts?.results.map((type, idx) => (
                                                            <SelectItem value={type?.id} key={idx}>
                                                                <div className='flex items-center gap-2'>
                                                                    {type?.account_name}
                                                                </div>
                                                            </SelectItem>
                                                        ))}
                                                        {parentAccounts?.results?.length === 0 && !isLoadingParents && (
                                                            <p className='py-1 pl-2 text-sm'>
                                                                There are no parents account
                                                            </p>
                                                        )}
                                                    </SelectContent>
                                                </Select>
                                            )}
                                        />
                                    </td>
                                    <td className='col-span-2 border-gray5 px-5 h-12 border-t  font-medium text-gray7 flex items-center'>
                                        {field.description}
                                    </td>

                                    <td
                                        className='border-gray5 col-span-1 px-5 h-12 border-t border-l  font-medium text-gray7 flex items-center'
                                        onClick={e => e.preventDefault()}
                                    >
                                        <div className='flex items-center gap-7 justify-end'>
                                            <LucideEdit
                                                size={18}
                                                color='#5E5E5E'
                                                onClick={() => {
                                                    setShowEdit(true)
                                                }}
                                            />{' '}
                                            <Trash2
                                                className='w-5 h-5'
                                                onClick={() => {
                                                    remove(i)
                                                }}
                                            />
                                        </div>
                                    </td>
                                </>
                            </AccordionTrigger>
                            {/* <AccordionContent className='border-none'>
                                {[1, 2, 3].map(id => (
                                    <div className='w-full grid grid-cols-10' key={id}>
                                        <td className='border-gray5 col-span-2 px-5 h-12 border-t font-medium text-gray7 flex items-center relative pl-12'>
                                            <p className='absolute left-5 -top-8 h-14 border-l border-b border-gray5 rounded-bl-md bg-transparent w-4 mr-2'></p>{' '}
                                            {field.account_name}
                                        </td>
                                        <td className='border-gray5 px-5 h-12 border-t  font-medium text-gray7 flex items-center'>
                                            {field.code}
                                        </td>

                                        <td className=' border-gray5 col-span-2 px-5 h-12 border-t  font-medium text-gray7 flex items-center'>
                                            <Controller
                                                name={`rows.${i}.account_type`}
                                                control={form.control}
                                                render={({ field }) => (
                                                    <Select onValueChange={field.onChange} value={field.value}>
                                                        <SelectTrigger className=' text-xs text-gray7  h-10  border-gray8 flex disabled:opacity-70'>
                                                            <SelectValue placeholder={'Select account type'} />
                                                        </SelectTrigger>
                                                        <SelectContent className='w-60 h-56'>
                                                            {accounttingTypes?.map(type => (
                                                                <SelectGroup className='my-1'>
                                                                    <SelectLabel className='text-black pl-2'>
                                                                        {type?.name}
                                                                    </SelectLabel>
                                                                    {type?.children.map(child => (
                                                                        <SelectItem
                                                                            className='text-gray7 pl-6'
                                                                            value={child?._id ?? child?.id}
                                                                        >
                                                                            {child?.name}
                                                                        </SelectItem>
                                                                    ))}
                                                                    <SelectSeparator />
                                                                </SelectGroup>
                                                            ))}
                                                        </SelectContent>
                                                    </Select>
                                                )}
                                            />
                                        </td>
                                        <td className='border-gray5 col-span-2 px-5 h-12 border-t  font-medium text-gray7 flex items-center'>
                                            {field.description}
                                        </td>
                                        <td className='border-gray5 col-span-2 px-5 h-12 border-t border-l font-medium text-gray7 flex items-center'>
                                            <Controller
                                                name={`rows.${i}.is_subaccount`}
                                                rules={{
                                                    required: false,
                                                }}
                                                control={form.control}
                                                render={({ field }) => (
                                                    <div className='flex gap-3 justify-between items-center'>
                                                        <p className='text-sm text-gray7 font-medium py-1'>No</p>
                                                        <Switch
                                                            type='button'
                                                            onChange={field.onChange}
                                                            checked={field.value}
                                                            className={cn(
                                                                `relative inline-flex h-6 w-11 items-center rounded-full`,
                                                                !field.value ? 'bg-brand' : 'bg-[#EFEFF9]'
                                                            )}
                                                        >
                                                            <span
                                                                className={`${
                                                                    !field.value
                                                                        ? 'translate-x-6 bg-[#D5D5F5]'
                                                                        : 'translate-x-1 bg-brand'
                                                                } inline-block h-4 w-4 transform rounded-full transition`}
                                                            />
                                                        </Switch>
                                                        <p className='text-sm text-gray7 font-medium py-1'>Yes</p>
                                                    </div>
                                                )}
                                            />
                                        </td>
                                        <td className='border-gray5 px-5 h-12 border-t border-l  font-medium text-gray7 flex items-center'>
                                            <div className='flex items-center gap-7 justify-end'>
                                                <LucideEdit size={18} color='#5E5E5E' onClick={() => {}} />{' '}
                                                <Trash2 className='w-5 h-5' onClick={() => {}} />
                                            </div>
                                        </td>
                                    </div>
                                ))}
                            </AccordionContent> */}
                        </AccordionItem>
                    </Accordion>
                </td>
            </tr>
            {showParent && <SelectParentAccount selectedAccount={field} closeModal={() => setShowParent(false)} />}
            {showEdit && (
                <AddNewAccountModal
                    closeModal={() => setShowEdit(false)}
                    isEdit={true}
                    selectedCoa={
                        {
                            ...field,
                            account_type: accounttingTypes
                                ?.flatMap(type => type?.children ?? [])
                                ?.find(type => type?._id == field?.account_type),
                        } as unknown as ChartOfAccountType
                    }
                />
            )}
        </>
    )
}
export default PreviewUploadedCOAModal
