import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import PageContent from '@/layout/PageContent'
import { motion } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import filter from '@/assets/filter.svg'
import { noop } from '@tanstack/react-table'
import EmptyStates from '@/components/EmptyStates'
import BudgetEmpty from '@/assets/budget-empty-state.svg'
import BudgetCards from './components/BudgetCards'
import CreateBudgetModal from '@/components/Modals/Budget/CreateBudgetRoot'
import { useAuthContext, useBudgetContext, useCurrencyContext } from '@/context'
import BudgetRequest from '@/components/Modals/Budget/BudgetRequests'
import { useGetOrgBudget } from '@/services/budget/queries'
import ProgressBar from '@/components/ProgressBar'
import { formatAmountNoSymbol } from '@/utils/money'
import { debounce } from 'lodash'
import notFound from '@/assets/not-found.svg'
import { prodEnvironment } from '@/utils/utils'
import V2Pagination from '@/components/Table/V2Pagination'
import { Pagination } from '@/types/utils'
import useAccessToModule from '@/hooks/useAccessToModule'
import { ModuleNameEnum } from '@/types/billing'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { budgetSidebarPermissions } from '@/layout/LayoutComponents/utils'
import NotAuthorized from '@/layout/NotAuthorized'
import { InfoIcon } from 'lucide-react'
import { subMonths, startOfMonth, startOfYear, subYears, format, isBefore, startOfDay, isAfter } from 'date-fns'
import { cn } from '@/lib/styling'

const Budgets = () => {
    // const [masterPopup, setMasterPopup] = useState(false)
    const { selectedCurrencyInfo, currencies } = useCurrencyContext()
    const [createModal, setCreateModal] = useState(false)
    const [currency, setCurrency] = useState(selectedCurrencyInfo()?.id)
    const [tab, setTab] = useState('Active')
    const [requestsModal, setRequestsModal] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [dateFilter, setDateFilter] = useState(format(new Date(0), 'dd/MM/yyyy'))
    const [backspaceClicked, setBackSpaceClicked] = useState(false)

    const { selectedAccount, setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const currencySymbol = selectedCurrencyInfo?.(currency !== 'all' ? currency : undefined)?.symbol

    const permission = useHasAccessPermisson(budgetSidebarPermissions)
    const { pageNumber, setPaginationDetails, setPageNumberClicked, pageNumberClicked, setPageNumber } =
        useBudgetContext()

    const { enabled: isBudgetsEnabled } = useAccessToModule({ module: ModuleNameEnum.BUDGETS })

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
        }
    }
    const [day, month, year] = dateFilter.split('/').map(Number)
    const jsDate = new Date(year, month - 1, day)

    const {
        data: org_budget,
        isLoading,
        isRefetching,
        refetch: refetchAllOrgBudget,
    } = useGetOrgBudget({
        enabled: permission,
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            search: searchQuery,
            page: pageNumber.toString(),
            currency: currency === 'all' ? '' : (currency as string),
            start_date: jsDate as unknown as string,
        },
        onSuccess: data => {
            if (data?.pagination) {
                setPaginationDetails(data?.pagination)
            }
        },
    })

    const debouncedSearch = useRef(
        debounce(() => {
            refetchAllOrgBudget()
        }, 300)
    ).current

    useEffect(() => {
        if (backspaceClicked) {
            refetchAllOrgBudget()
            setBackSpaceClicked(false)
        }
        if (searchQuery) {
            debouncedSearch()
        }
        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, debouncedSearch, refetchAllOrgBudget, searchQuery])

    useEffect(() => {
        if (pageNumber && pageNumberClicked) {
            refetchAllOrgBudget()
            setPageNumberClicked(false)
        }
    }, [pageNumber, pageNumberClicked])
    useEffect(() => {
        if (currency) {
            refetchAllOrgBudget()
        }
    }, [currency])
    useEffect(() => {
        if (dateFilter) {
            refetchAllOrgBudget()
        }
    }, [dateFilter])

    useEffect(() => {
        if (location.pathname.includes('quick_actions')) {
            setCreateModal(true)
        }
    }, [])

    const orgBudget = useMemo(() => org_budget?.budgets ?? [], [org_budget])
    const orgBudgetSummary = useMemo(() => org_budget?.summary, [org_budget])
    const orgBudgetPagination = useMemo(() => org_budget?.pagination, [org_budget]) as Pagination

    const activeBudget = orgBudget.filter(
        item => item.reset !== 'one-off' || (item.end_date && isAfter(new Date(item.end_date), startOfDay(new Date())))
    )
    const inactiveBudget = orgBudget.filter(
        item => item.end_date && isBefore(new Date(item.end_date), startOfDay(new Date()))
    )

    if (isLoading) return <ProgressBar />

    const tablePaginationNo = Array.from({ length: orgBudgetPagination?.totalPages ?? 0 }, (_, idx) => idx)

    if (!permission) return <NotAuthorized />
    return (
        <section className='bg-offwhite pb-[7rem] min-h-[100vh] px-2 md:px-0'>
            <PageContent>
                <div className='w-full py-6 block'>
                    <div className='my-1 mb-4 flex flex-col md:flex-row justify-between md:items-end mx-2'>
                        <div>
                            <h1 className='text-dark font-medium text-lg w-full'>Budget lists</h1>
                            <p className='text-gray7 text-sm'>Create new budgets, view and manage existing budgets</p>
                        </div>
                        <Button
                            className='min-w-full lg:min-w-[140px]'
                            onClick={() => {
                                if (!isBudgetsEnabled) {
                                    setShowUpgradeModal(true)
                                    setCurrentModule(ModuleNameEnum.BUDGETS)
                                    return
                                }
                                setCreateModal(true)
                            }}
                        >
                            Create Budget
                        </Button>
                    </div>
                    <div className='bg-white border border-[#DADCE0] rounded-2xl p-6'>
                        <div className='flex justify-between items-center'>
                            <div className='flex sm:gap-2 w-[8rem] sm:w-[10.7rem] rounded-lg text-xs sm:text-base'>
                                {currencies.map(curr => (
                                    <div
                                        className={`${'bg-#F9F9F9 text-white'}bg-[#5E5E5E] px-2 py-1 rounded cursor-pointer relative w-full`}
                                        onClick={() => setCurrency(curr.id)}
                                    >
                                        {currency == curr.id && (
                                            <motion.span
                                                layoutId='expense_timeline__animation'
                                                className='bg-gradient-to-bl from-[#5E5E5E] to-[#5E5E5E] rounded-md z-0 text-center text-white inset-0 absolute inline-block w-full h-full '
                                            />
                                        )}
                                        <motion.span
                                            layout='position'
                                            className={`z-20 sticky ${currency === curr.id ? 'text-white' : 'text-black'}`}
                                        >
                                            {curr.currency_code}
                                        </motion.span>
                                    </div>
                                ))}
                            </div>
                            <div className='bg-[#fff] border border-[#DADCE0] rounded-lg'>
                                <Select onValueChange={setDateFilter} value={dateFilter}>
                                    <SelectTrigger className='w-full border-none'>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-[9rem]'>
                                        <SelectItem value={format(startOfMonth(new Date()), 'dd/MM/yyyy')}>
                                            This month
                                        </SelectItem>
                                        <SelectItem value={format(subMonths(new Date(), 3), 'dd/MM/yyyy')}>
                                            3 months ago
                                        </SelectItem>
                                        <SelectItem value={format(subMonths(new Date(), 6), 'dd/MM/yyyy')}>
                                            6 months ago
                                        </SelectItem>
                                        <SelectItem value={format(startOfYear(new Date()), 'dd/MM/yyy')}>
                                            This year
                                        </SelectItem>
                                        <SelectItem value={format(subYears(startOfYear(new Date()), 1), 'dd/MM/yyyy')}>
                                            Last year
                                        </SelectItem>
                                        <SelectItem value={format(new Date(0), 'dd/MM/yyyy')}>All time</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        <div className='flex justify-between items-center mt-4'>
                            <div className='flex items-center gap-2 relative'>
                                <h1 className='font-semibold text-black'>Total</h1>
                                <InfoIcon className='rotate-180 cursor-pointer' color='#838383' size={16} />
                            </div>
                        </div>

                        <div
                            className={`flex items-center gap-10 gap-y-5 mt-3 flex-wrap ${isRefetching ? 'opacity-50' : ''}`}
                        >
                            <div className=''>
                                <h2 className='text-[#5E5E5E] mb-2 font-medium'>Allocated</h2>
                                <p className='font-semibold text-lg break-words max-w-[308px] lg:max-w-max'>
                                    {currencySymbol}
                                    {formatAmountNoSymbol(orgBudgetSummary?.total_allocated)}
                                </p>
                            </div>
                            <div className=''>
                                <h2 className='text-[#5E5E5E] mb-2 font-medium'>Spent</h2>
                                <p className='text-lg break-words max-w-[308px] lg:max-w-max'>
                                    {currencySymbol}
                                    {formatAmountNoSymbol(
                                        Number(orgBudgetSummary?.total_allocated) -
                                            Number(orgBudgetSummary?.total_available)
                                    )}
                                </p>
                            </div>
                            <div className=''>
                                <h2 className='text-[#5E5E5E] mb-2 font-medium'>Available</h2>
                                <p className='text-lg break-words max-w-[308px] lg:max-w-max'>
                                    {currencySymbol}
                                    {formatAmountNoSymbol(orgBudgetSummary?.total_available)}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='border border-gray8 bg-white mt-6 p-6 rounded-[20px]'>
                        <div className='flex sm:gap-2 w-[8rem] sm:w-[10.7rem] rounded-lg text-xs sm:text-base mb-5'>
                            {['Active', 'expired'].map(tabOption => (
                                <div
                                    className={`${'bg-#F9F9F9 text-white'}bg-[#5E5E5E] px-2 py-1 rounded cursor-pointer relative w-full`}
                                    onClick={() => setTab(tabOption)}
                                >
                                    {tabOption === tab && (
                                        <motion.span
                                            layoutId='budget_active'
                                            className='bg-gradient-to-bl from-[#EFEFF9] to-[#EFEFF9] rounded-lg z-0 text-center text-white inset-0 absolute inline-block w-full h-full '
                                        />
                                    )}
                                    <motion.span
                                        layout='position'
                                        className={`z-20 sticky ${tabOption === tab ? 'text-brand' : 'text-[#5E5E5E]'} flex items-center justify-center gap-2.5 py-1 px-2 font-medium`}
                                    >
                                        {tabOption}{' '}
                                        <p
                                            className={cn(
                                                'flex h-5 w-8 items-center justify-center rounded-xl text-white text-xs',
                                                tab !== tabOption ? 'bg-gray7' : 'bg-brand'
                                            )}
                                        >
                                            {tabOption === 'Active' ? activeBudget.length : inactiveBudget.length}
                                        </p>
                                    </motion.span>
                                </div>
                            ))}
                        </div>
                        <div className='flex items-center justify-between gap-y-4 mb-6 flex-wrap'>
                            <div className='flex items-center gap-5'>
                                <div className='border border-[#DADCE0] h-[44px] w-[200px] lg:w-[314px] rounded-lg flex items-center overflow-hidden '>
                                    <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full bg-white'>
                                        <CiSearch fontSize={20} stroke='2' color='#A8A8A8' />
                                    </div>
                                    <input
                                        type='text'
                                        placeholder='Search by budget name'
                                        className='h-full w-[90%] outline-none text-sm'
                                        onChange={e => setSearchQuery(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                {!prodEnvironment() && (
                                    <div className='hidden'>
                                        <Select onValueChange={noop} value='all_budgets'>
                                            <SelectTrigger className='w-[8rem] lg:w-[10rem] h-[55px] border-none bg-[#F9F9F9] text-[#5E5E5E]'>
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent className='w-[10rem]'>
                                                <SelectItem value='all_budgets'>All budgets</SelectItem>
                                                <SelectItem value='1 month'>Last Month</SelectItem>
                                                <SelectItem value='3 months'>Last 3 Months</SelectItem>
                                                <SelectItem value='6 months'>Last 6 Months</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                )}
                            </div>
                            <div className='flex items-center gap-6'>
                                {!prodEnvironment() && (
                                    <div className='hidden h-[50px] w-[50px] border border-[#DADCE0] rounded-lg  items-center justify-center p-3 cursor-pointer'>
                                        <img src={filter} alt='button' />
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* <CurrencySelectFilter currency={currency} setCurrency={setCurrency} /> */}

                        {orgBudget.length <= 0 && searchQuery.length <= 0 && !isRefetching && (
                            <div className='mx-auto text-sm mt-3'>
                                <EmptyStates
                                    description='Budgets help to monitor and control the spending activities of your users'
                                    title='Create budgets and control spending'
                                    image={BudgetEmpty}
                                />
                            </div>
                        )}
                        {orgBudget.length <= 0 && isRefetching ? (
                            <ProgressBar />
                        ) : (
                            orgBudget.length <= 0 &&
                            (searchQuery.length > 0 || isRefetching) && (
                                <div
                                    className={`'h-[450px] flex flex-col text-center justify-center items-center pb-10 ${
                                        isRefetching ? 'opacity-30' : ''
                                    }`}
                                >
                                    <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                    <span className='text-xl font-bold text-[#5E5E5E] mt-3'>
                                        Sorry! No result found :(
                                    </span>
                                    <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                        Oops! It seems we couldn't find any matching results for your search. Please try
                                        again with different keywords or refine your search fiter for better results.
                                    </span>
                                </div>
                            )
                        )}

                        {orgBudget?.length > 0 && (
                            <div
                                className={`budgets-list mt-3 grid grid-cols-1 lg:grid-cols-2 gap-6 ${isRefetching ? 'opacity-50' : 'opacity-100'}`}
                            >
                                {tab === 'Active' &&
                                    activeBudget?.map(budget => <BudgetCards budget={budget} key={budget._id} />)}
                                {tab === 'expired' &&
                                    inactiveBudget?.map(budget => <BudgetCards budget={budget} key={budget._id} />)}
                            </div>
                        )}
                        {((tab === 'Active' && activeBudget.length <= 0) ||
                            (tab === 'expired' && inactiveBudget.length <= 0)) &&
                            searchQuery.length === 0 && (
                                <div
                                    className={`h-[350px] flex flex-col text-center justify-center items-center pb-10 ${
                                        isRefetching ? 'opacity-30' : ''
                                    }`}
                                >
                                    <span className='text-xl font-bold text-[#5E5E5E] mt-3'>
                                        There are no {tab.toLowerCase()} budgets.
                                    </span>
                                </div>
                            )}
                    </div>
                </div>
            </PageContent>
            {createModal && <CreateBudgetModal closeModal={setCreateModal} />}
            {requestsModal && <BudgetRequest closeModal={setRequestsModal} />}
            <div className='px-5 py-3'>
                <V2Pagination
                    setCurrentPageNumber={setPageNumber}
                    paginationOption={tablePaginationNo}
                    pagination={orgBudgetPagination}
                />
            </div>
        </section>
    )
}

export default Budgets
