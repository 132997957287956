import { ApprovalLevel } from '@/services/taxes/types'
import { EmployeeType, OrgType } from './org'

export enum ApporvalConditionOperatorEnum {
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN',
    EQUAL = 'EQUAL',
    NOT_EQUAL = 'NOT_EQUAL',
    BETWEEN = 'BETWEEN',
}

export enum ApprovalPolicyConsts {
    AUTOAPPROVE = 'auto-approve',
}
export enum SupportedPolicyConsts {
    expense = 'expense',
    invoice = 'invoice',
    bill = 'bill',
    leave = 'leave',
    accounting = 'accounting',
}

export const approversCount = {
    1: 'st',
    2: 'nd',
    3: 'rd',
}

export type ApprovalPolicy = {
    createdAt?: string
    _id: string
    id?: string
    org?: OrgType
    approval_levels: ApprovalLevel[]
    fallback_approval_level: {
        auto_approval: boolean
        approvers: EmployeeType[]
    }
    policy_name: string
    updatedAt?: string
    version: number
    approval_policy: string
    module: 'expense' | 'billing'
    active: boolean
}
