import React, { useEffect, useMemo, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { formatAmountNoSymbol } from '@/utils/money'
import { useGetTopInvoiceCustomers } from '@/services/invoice/queries'
import { useAuthContext, useCurrencyContext } from '@/context'
import { QueryTopCustomers } from '@/services/invoice/types'
import { cn } from '@/lib/styling'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { RecentExpensesIcon } from '@/assets/assets.export'

export const CustomerRevenue = ({ currency }: { currency?: string }) => {
    const thisYear = new Date().getFullYear()
    const [transYear, setTransYear] = useState(thisYear + '')
    const { selectedAccount } = useAuthContext()
    const { selectedCurrencyInfo } = useCurrencyContext()

    const {
        data: topInvoiceCustomers,
        isLoading,
        refetch,
        isRefetching,
    } = useGetTopInvoiceCustomers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            year: transYear.toString() as string,
            currency: currency as string,
        },
        // enabled: !!selectedCurrencyInfo()?.id,
    })
    const topCustomers = useMemo(() => topInvoiceCustomers ?? ({} as QueryTopCustomers), [topInvoiceCustomers])

    useEffect(() => {
        refetch()
    }, [transYear, currency])

    if (isLoading) {
        return (
            <section className='border border-gray8 p-6 rounded-[20px] w-full lg:w-[43%] bg-white'>
                <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={'100%'}>
                    <Skeleton />
                </SkeletonTheme>
            </section>
        )
    }

    return (
        <section className='border border-gray8 p-6 rounded-[20px] w-full lg:w-[43%] bg-white'>
            <div className='flex items-center justify-between'>
                <p className='font-semibold'>Revenue by customers</p>
                <Select
                    name='invoiceYear'
                    onValueChange={data => {
                        setTransYear(data)
                    }}
                    value={transYear}
                >
                    <SelectTrigger className='w-[7.5rem] bg-offwhite border border-gray8 text-gray7'>
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent className='w-[8rem]'>
                        <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                        <SelectItem value={`${thisYear}`}>This year</SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <div className={cn('', isRefetching && 'opacity-40')}>
                <div className='mt-6'>
                    <p className='mb-2 font-semibold text-sm text-gray7'>Total revenue</p>
                    <p className='mb-2 leading-[40px] text-[32px] font-semibold'>
                        {selectedCurrencyInfo(currency)?.symbol}
                        {formatAmountNoSymbol(topCustomers?.total_payments_received)}
                    </p>
                </div>
                <p className='h-[1px] my-6 w-full bg-gray8'></p>
                <div className='space-y-10'>
                    {topCustomers?.customers
                        ?.sort((a, b) => b?.total_payout - a?.total_payout)
                        ?.map((revenue, i) => (
                            <div key={i}>
                                <div className='flex items-center justify-between gap-3 mb-3'>
                                    <p className='text-gray7 font-medium'>
                                        {revenue?.customer?.name}[{revenue?.percentage}%]
                                    </p>
                                    <p className='font-bold'>
                                        {selectedCurrencyInfo(currency)?.symbol}
                                        {formatAmountNoSymbol(revenue?.total_payout)}
                                    </p>
                                </div>
                                <div className='bg-[#F2F2F2] h-3 w-full rounded-xl relative overflow-clip'>
                                    <div
                                        className='bg-[#E17323] absolute inset-0'
                                        style={{ width: `${revenue?.percentage}%` }}
                                    ></div>
                                </div>
                            </div>
                        ))}
                    {topCustomers?.total_payments_received === 0 && !isLoading && (
                        <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                            <RecentExpensesIcon />
                            <h2 className='text-gray7 font-medium mt-5'>No paying customers yet</h2>
                            {/* <p className='text-sm'>No transaction has been made yet</p> */}
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}
