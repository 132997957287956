// import CheckBox from '@/components/Table/CheckBox'
import TableActions from '@/components/Table/TableActions'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import React, { useState } from 'react'
import Table from '@/components/Table'
import { useInvoiceContext } from '@/context'
import { formatAmountNoSymbol } from '../../../../utils/money'
import { ColumnSort, Pagination } from '@/types/utils'
import InvoiceEmptyState from '@/assets/invoice-empty-state.svg'
import { InvoiceItemType } from '@/types/invoice'
import DeleteItemPrompt from '@/components/Modals/Invoice/DeleteItemPrompt'
import { Delete } from 'lucide-react'
import { capitalizeText } from '@/utils/string'

type InvoiceTableProps = {
    data: InvoiceItemType[]
    pagination: Pagination
}
const InvoiceItemTable = ({ data, pagination }: InvoiceTableProps) => {
    const columnHelper = createColumnHelper<InvoiceItemType>()
    const { setSort, setItemPageNumber, setItemPageNumberClicked, itemPageNumber } = useInvoiceContext()

    const [promptModal, setPromptModal] = useState(false)
    const [itemDetails, setItemDEtails] = useState<InvoiceItemType>()

    const [rowSelection, setRowSelection] = React.useState({})

    const handleSort = (sort: ColumnSort) => {
        setSort?.(sort)
    }

    const columns: ColumnDef<InvoiceItemType, any>[] = [
        columnHelper.accessor('name', {
            header: () => <p>Title</p>,
            cell: cell => <span>{cell.renderValue()}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('unit_cost', {
            header: () => <p>Unit Cost</p>,
            cell: cell => {
                return (
                    <>
                        <span className=''>{cell.row.original?.currency?.symbol ?? '₦'}</span>
                        {formatAmountNoSymbol(cell.renderValue()?.$numberDecimal)}
                    </>
                )
            },
            enableSorting: true,
        }),

        columnHelper.accessor('description', {
            header: () => <p>Description</p>,
            cell: cell => {
                return <>{cell.renderValue() ?? 'N/A'}</>
            },
            enableSorting: true,
        }),
        columnHelper.accessor('type', {
            header: () => <p>Type</p>,
            cell: cell => {
                return <>{capitalizeText(cell.renderValue()) ?? 'N/A'}</>
            },
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            cell: row => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'Delete Item',
                                icon: Delete,
                                method: () => {
                                    setPromptModal(true)
                                    setItemDEtails(row?.row.original)
                                    handleRowClick(row?.row.original)
                                },
                                actionColor: 'text-[#CF491E]',
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    const handleRowClick = (invoiceItem: InvoiceItemType) => {
        // navigate(`/dashboard/invoice/${invoice_item_id}/${invoiceItem._id}`)

        setItemDEtails(invoiceItem)
        // console.warn(invoiceItem + 'here')
    }
    return (
        <section className='h-full'>
            <Table
                data={data}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                handleSort={handleSort}
                emptyStateDescription='Create and issue invoices to your customers by clicking the “New invoice item” button at the top-right corner'
                emptyStateTitle='No invoice item created or issued yet'
                emptyStateImg={InvoiceEmptyState}
                pagination_data={pagination}
                currentPageNumber={itemPageNumber}
                setCurrentPageNumber={setItemPageNumber}
                setIsPageNumberClicked={setItemPageNumberClicked}
            />
            {promptModal && <DeleteItemPrompt closeModal={setPromptModal} itemDetails={itemDetails} />}
        </section>
    )
}

export default InvoiceItemTable
