import PrivateRouteWrapper from '@/hoc/PrivateRouteWrapper'
import PublicRouteWrapper from '@/hoc/PublicRouteWrapper'
import Logout from '@/layout/Logout'
import EmployeeSignUp from '@/modules/auth/EmployeeSignup/EmployeeSignup'
import ForgotPasswordRoot from '@/modules/auth/ForgotPassword/ForgotPasswordRoot'
import Login from '@/modules/auth/Login'
import RequestAccessRoot from '@/modules/auth/RequestAccess/RequestAccessRoot'
import SetPassword from '@/modules/auth/RequestAccess/SetPassword'
import SignUpRoot from '@/modules/auth/SignUp/SignUpRoot'
import SwitchAccount from '@/modules/auth/SwitchAccount'
import VerifyEmail from '@/modules/auth/VerifyEmail'
import { Route } from 'react-router-dom'

export const authRouteConst = {
    login: '/login',
    verifyEmail: '/verify-email',
    requestAccess: '/request-access',
    forgotPassword: '/forgot-password',
    setPassword: '/auth/set-password',
    acceptInvite: '/accept-invite',
    chooseAccount: '/choose-account',
    logout: '/logout',
    signup: '/signup',
}

export const useAuthRoutes = () => {
    return (
        <>
            <Route path='/' element={<PublicRouteWrapper component={<Login />} />} />
            <Route path={authRouteConst.login} element={<PublicRouteWrapper component={<Login />} />} />
            <Route path={authRouteConst.verifyEmail} element={<PublicRouteWrapper component={<VerifyEmail />} />} />
            <Route
                path={authRouteConst.requestAccess}
                element={<PublicRouteWrapper component={<RequestAccessRoot />} />}
            />
            <Route
                path={authRouteConst.forgotPassword}
                element={<PublicRouteWrapper component={<ForgotPasswordRoot />} />}
            />
            <Route path={authRouteConst.setPassword} element={<PublicRouteWrapper component={<SetPassword />} />} />

            <Route path={authRouteConst.signup} element={<PublicRouteWrapper component={<SignUpRoot />} />} />

            {/* Employee Sign Up */}
            <Route path={authRouteConst.acceptInvite} element={<PublicRouteWrapper component={<EmployeeSignUp />} />} />

            {/* regular user */}
            <Route
                path={authRouteConst.chooseAccount}
                element={<PrivateRouteWrapper component={<SwitchAccount />} />}
            />

            <Route path={authRouteConst.logout} element={<Logout />} />
        </>
    )
}
