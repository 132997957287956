import { AiOutlineClose } from 'react-icons/ai'
import { Controller, useForm } from 'react-hook-form'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { useMutateSaveAccountDetails, useResolveBankMutation } from '@/services/payout/mutations'
import ModalWrapper from '../ModalWrapper'
import { QueryGetAllBanks } from '@/services/payout/types'
import { useAuthContext } from '@/context'
import { useEffect } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    closeModal: () => void
    allBankList: QueryGetAllBanks
}
type FormTypes = {
    bankCode: string
    accountNumber: number
}

const AddPayStackIntegrationModal = ({ closeModal, allBankList }: Props) => {
    const form = useForm<FormTypes>({})
    const { selectedAccount } = useAuthContext()

    const { mutate: addBankFn, isLoading } = useMutateSaveAccountDetails({
        onSuccess: () => {
            closeModal()
            toastHandler({
                message: 'Bank details added successfully',
                state: 'success',
            })
        },
    })

    const {
        mutate: confirmBankFn,
        data: resolvedBankDetails,
        isLoading: isBankResolving,
        isSuccess,
    } = useResolveBankMutation({})
    const accountNumber = form.watch('accountNumber')
    const bankCode = form.watch('bankCode')

    const handleSubmit = (values: FormTypes) => {
        const nipCode = allBankList.find(bank => bank.code === values.bankCode)?.nipCode
        addBankFn({
            account_number: values.accountNumber,
            bank_code: values.bankCode,
            account: selectedAccount?.id,
            account_name: resolvedBankDetails?.account_name,
            nipCode: nipCode,
            is_paystack_invoice_bank: true,
            account_type: 'Org',
        })
    }

    const isValidToResolve = String(accountNumber).length === 10 && bankCode

    useEffect(() => {
        if (isValidToResolve) {
            confirmBankFn({
                account_number: accountNumber,
                bank_code: bankCode,
            })
        }
    }, [bankCode, accountNumber])

    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[28rem] max-h-[90vh] overflow-scroll pb-8 bg-gray8/10'>
                <div className='border-b border-gray5/80 py-2 px-5  flex justify-between items-center'>
                    <h2 className='font-semibold opacity-80'>Add bank details to integrate with paystack</h2>
                    <button
                        title='Close'
                        className='bg-gray6 rounded-full flex items-center justify-center text-gray7/80 h-7 w-7 p-1.5'
                        onClick={closeModal}
                    >
                        <AiOutlineClose fontSize={25} />
                    </button>
                </div>

                <form onSubmit={form.handleSubmit(handleSubmit)} className='mt-6 mx-4 space-y-4 mb-10'>
                    <div className='flex flex-col flex-1 gap-1.5 relative'>
                        <span className='opacity-80 text-sm font-medium'>Bank name</span>
                        <Controller
                            name='bankCode'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={form.control}
                            render={({ field }) => (
                                <Select name='accnt_number' onValueChange={field.onChange} value={field?.value}>
                                    <SelectTrigger className='w-full h-[45px] border-[#DADCE0] bg-base_gray_100 placeholder:text-[base_gray_100]  text-[14px]'>
                                        <SelectValue placeholder={'Select bank name'} className='' />
                                    </SelectTrigger>
                                    <SelectContent className='w-[27rem] h-[20rem] overflow-scroll'>
                                        {allBankList.map(bank => (
                                            <SelectItem value={bank.code} key={bank.code} className='cursor-pointer'>
                                                {bank.name}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-1.5 relative'>
                        <span className='opacity-80 text-sm font-medium'>Account number</span>
                        <Controller
                            name='accountNumber'
                            rules={{
                                required: true,
                                minLength: 2,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: 'Account number must be exactly 10 digits',
                                },
                            }}
                            control={form.control}
                            render={({ field, fieldState }) => (
                                <>
                                    <Input
                                        {...field}
                                        type='number'
                                        onChange={e => {
                                            const val = e.target.value
                                            if (val.length > 10) return
                                            field.onChange(e.target.value)
                                        }}
                                        className='border border-gray5 rounded-md h-12 placeholder:text-xs '
                                        placeholder='Enter account number'
                                    />
                                    {fieldState.error?.message && (
                                        <span className='text-red-500 text-xs animate-bounce font-medium'>
                                            {fieldState.error?.message}
                                        </span>
                                    )}
                                </>
                            )}
                        />
                    </div>
                    {isBankResolving && isValidToResolve && (
                        <div className='flex gap-3 text-[#838383] text-sm items-center'>
                            <span>Fetching bank name</span>
                            <LoaderIcon className='animate-spin w-4 h-4' />
                        </div>
                    )}
                    {isValidToResolve && isSuccess && (
                        <div className='flex items-center gap-2 text-[#19C37D]'>
                            <IoIosCheckmarkCircle className='' />
                            <label htmlFor='' className='text-sm font-medium'>
                                {resolvedBankDetails?.account_name}
                            </label>
                        </div>
                    )}
                    <div className='flex flex-col flex-1 gap-1.5 relative !mb-16'>
                        <span className='opacity-80 text-sm font-medium'>Account name</span>

                        <Input
                            type='text'
                            className='border border-gray5 rounded-md h-12 placeholder:text-xs disabled:bg-gray8/40'
                            placeholder='Account holder name will show here'
                            value={isValidToResolve ? resolvedBankDetails?.account_name : ''}
                            disabled
                        />
                    </div>

                    <div className='flex pt-2 items-center justify-end gap-4 px-6 pb-2 py-2  absolute bottom-0 right-0 left-0 bg-white w-full rounded-b-lg border-t border-gray5'>
                        <Button
                            disabled={!form.formState.isValid}
                            size={'lg'}
                            className='bg-brand text-white px-8 py-4 rounded-md w-full text-xs'
                            loading={isLoading}
                        >
                            Add bank details
                        </Button>
                    </div>
                </form>
            </section>
        </ModalWrapper>
    )
}

export default AddPayStackIntegrationModal
