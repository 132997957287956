import { useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion'
import { ChevronDown } from 'lucide-react'
import { formatAmount } from '@/utils/money'
import { Button } from '@/components/Button'
import ConfirmMatching from '@/components/Modals/Invoice/InvoiceMatching/ConfirmMatching'
import { TransactionType } from '@/types/transactions'
import { format } from 'date-fns'
import { Invoice } from '@/types/invoice'

type Props = {
    closeModal: () => void
    invoice?: Invoice
    matchedTransactions: TransactionType[]
}
const MatchInvoiceTransactions = ({ closeModal, matchedTransactions, invoice }: Props) => {
    const [transactionDetails, setTransactionDetails] = useState<TransactionType>()
    const [confirmMatch, setConfirmMatch] = useState(false)

    // console.log({ matchedTransactions })
    return (
        <ModalWrapper variants={undefined}>
            {!confirmMatch && (
                <main className='h-[40rem] lg:h-fit lg:max-h-[86vh] w-screen lg:w-[30rem]'>
                    <section className='flex items-center justify-between border-b border-gray4  p-3 '>
                        <span className='font-medium'>Review and match transaction(s)</span>
                        <CancelIcon
                            className='rounded-full bg-gray5 p-1.5 w-6 h-6 cursor-pointer'
                            onClick={() => closeModal()}
                        />
                    </section>
                    <section className='p-4 max-h-[36rem] lg:max-h-[78vh] overflow-y-scroll'>
                        <Accordion type='single' collapsible className='space-y-3' defaultValue='item-0'>
                            {matchedTransactions?.map((transaction, idx) => (
                                <AccordionItem
                                    value={`item-${idx}`}
                                    className='border-gray3 border rounded-lg bg-gray6/50 p-2'
                                >
                                    <AccordionTrigger righticon={<ChevronDown className='w-4 h-4' />} className='group'>
                                        <button className='grid grid-cols-3 w-full'>
                                            <span className='flex gap-3.5'>
                                                <span>{idx + 1}</span>
                                                <span className='text-gray7'>{transaction?.transaction_id}</span>
                                            </span>
                                            <span className='group-[&[data-state=open]]:hidden'>
                                                {formatAmount(transaction?.credit?.amount)}
                                            </span>
                                        </button>
                                    </AccordionTrigger>
                                    <AccordionContent>
                                        <div className='bg-darkbrand/10 p-3  rounded-md m-4 mt-6'>
                                            <p className='text-center text-sm font-medium opacity-90'> Amount </p>
                                            <h3 className='text-center font-bold text-2xl text-gray7 mt-1'>
                                                {formatAmount(transaction?.credit?.amount)}
                                            </h3>
                                        </div>
                                        <ul className='font-medium space-y-3 border-b border-gray4 mx-5 pb-3'>
                                            <li className='flex justify-between '>
                                                <span className='text-gray7 text-[0.8rem] '>Date</span>
                                                <span>
                                                    {' '}
                                                    {transaction?.createdAt &&
                                                        format(new Date(transaction?.createdAt), 'PP hh:mm aa')}
                                                </span>
                                            </li>
                                            <li className='flex justify-between '>
                                                <span className='text-gray7 text-[0.8rem] '>From</span>
                                                <span>
                                                    {transaction?.debit?.financial_account_details?.account_name ??
                                                        'N/A'}
                                                </span>
                                            </li>
                                            <li className='flex justify-between '>
                                                <span className='text-gray7 text-[0.8rem]'>Recipient Account</span>
                                                <span>
                                                    {transaction?.credit?.financial_account_details?.account_name ??
                                                        'N/A'}
                                                </span>
                                            </li>
                                        </ul>
                                        <div className='mx-5 flex justify-end mt-4 gap-3 mb-1'>
                                            <Button
                                                variant={'outline'}
                                                className='border-none text-xs min-w-[100px] px-2.5 min-h-[20px]'
                                            >
                                                Dismiss match
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setConfirmMatch(true)
                                                    setTransactionDetails(transaction)
                                                }}
                                                className='text-xs min-w-[100px] px-2.5 min-h-[20px]'
                                            >
                                                Match to invoice
                                            </Button>
                                        </div>
                                    </AccordionContent>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </section>
                </main>
            )}

            {confirmMatch && (
                <ConfirmMatching
                    invoice={invoice}
                    goBack={() => setConfirmMatch(false)}
                    transaction={transactionDetails}
                />
            )}
        </ModalWrapper>
    )
}

export default MatchInvoiceTransactions
