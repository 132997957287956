import { MoveRight } from '@/assets/assets.export'
import { Input } from '@/components'
import { Select, SelectContent, SelectTrigger, SelectValue, SelectItem } from '@/components/Select'
import { useAccountingContext, useCategoriesContext } from '@/context'
import { useUpsertCategoriesMapping } from '@/services/categories/mutations'
import { Search } from 'lucide-react'
import { useState } from 'react'

export default function CategoryMapping() {
    const { categories } = useCategoriesContext()
    const { chartOfAccountData, categoriesMappedToAccountData } = useAccountingContext()
    const [activeMappedCategory, setActiveMappedCategory] = useState('')

    const { mutate, isLoading } = useUpsertCategoriesMapping({})

    const handleMapCategory = (account: string, category: string) => {
        setActiveMappedCategory(category)
        mutate({
            category,
            account,
        })
    }

    return (
        <section className='mb-5 bg-white rounded-xl p-7 flex-1'>
            <div>
                <h2 className='font-medium'>Category mapping</h2>
                <p className='text-sm text-gray7'>
                    Match Bizpend categories to your Chart of Accounts to automate category matching
                </p>
            </div>
            <div className='max-w-xl'>
                <table className='table-auto  border-collapse mt-8 '>
                    <thead>
                        <tr>
                            <th className='font-medium text-sm text-left'>Bizpend categories </th>
                            <th className='font-medium text-sm w-28'>{''}</th>
                            <th className='font-medium text-sm text-left min-w-[15rem]'>Chart of Accounts</th>
                        </tr>
                    </thead>
                    <tbody className=''>
                        <tr>
                            <td colSpan={3}>
                                <Input
                                    className='border-gray4 h-10 my-4'
                                    placeholder='Search category'
                                    leftIcon={<Search className='text-gray' />}
                                />
                            </td>
                        </tr>
                        {categories.map(category => {
                            const foundCategoryAccountMapped = categoriesMappedToAccountData?.filter(
                                mappedCategory => mappedCategory.category.id === category.id
                            )?.[0]

                            return (
                                <tr
                                    key={category.id}
                                    className={`${isLoading && category?.id === activeMappedCategory ? 'opacity-50' : ''}`}
                                >
                                    <td>
                                        <span className='inline-block bg-gray7/10 text-gray7 rounded p-1.5 px-2  text-xs font-medium '>
                                            {category.label}
                                        </span>
                                    </td>
                                    <td className='text-center'>
                                        <MoveRight strokeWidth={1.5} color='#838383' className='mx-auto' />
                                    </td>
                                    <td>
                                        <Select
                                            name='category'
                                            onValueChange={account => handleMapCategory(account, category.id)}
                                            value={foundCategoryAccountMapped?.account?.id}
                                        >
                                            <SelectTrigger className='font-medium text-xs h-10 border border-gray2/50 text-gray7 my-1'>
                                                <SelectValue className='' placeholder={'Select account'} />
                                            </SelectTrigger>
                                            <SelectContent className='w-56 h-80'>
                                                {chartOfAccountData.map(account => (
                                                    <SelectItem value={account.id}>
                                                        {account.code} - {account.account_name}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </section>
    )
}
