import { useAuthContext } from '@/context'
import { useQueryMe } from '@/services/auth/queries'

const SignupSuccess = () => {
    const { setLoggedIn } = useAuthContext()

    useQueryMe({
        enabled: true,
        onSuccess: () => {
            redirectAfterTwoSeconds()
        },
    })

    const redirectAfterTwoSeconds = () => {
        const redirectTimeout = setTimeout(() => {
            setLoggedIn(true)
        }, 2000)
        return () => {
            clearTimeout(redirectTimeout)
        }
    }

    return (
        <div className='w-full h-full min-h-[100vh] max-w-[100%] overflow-hidden flex flex-col justify-center items-center px-5'>
            <h2 className='text-[#202020] text-2xl font-semibold mb-4'>Creating and Verifying Your Account</h2>
            <p className='text-[#202020] text-[16px] text-center'>
                We're setting up your account . This won’t take long, we're working diligently to get you started.
            </p>

            <div className='h-[250px] border-b-2 border-[#A8A8A8] w-[350px] lg:w-[600px] my-8 flex items-center gap-4 overflow-hidden justify-center '>
                <div className='w-[90px] h-[255px] rounded-t-[1rem] mb-auto bg-[#F5D484] bar-1'></div>
                <div className='w-[90px] h-[255px] rounded-t-[1rem] mb-auto bg-[#7E82FB] bar-2'></div>
                <div className='w-[90px] h-[255px] rounded-t-[1rem] mb-auto bg-[#FFAB90] bar-3'></div>
                <div className='w-[90px] h-[255px] rounded-t-[1rem] mb-auto bg-[#9FDA96] bar-4'></div>
            </div>

            <p className='text-center'>
                We're excited to have you onboard and help you take control of your finances....
            </p>
        </div>
    )
}

export default SignupSuccess
