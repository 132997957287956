import { BizpendAccounts, CancelIcon } from '@/assets/assets.export'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/Tooltips'
import { useAuthContext } from '@/context'
import { useGeneratePaymentOTP } from '@/services/auth/mutations'
import { useGetAccounts } from '@/services/bill-payment/queries'
import { useGetOrgBudget } from '@/services/budget/queries'
import { useMilestonePaymentOtp } from '@/services/payroll/mutations'

import { ContractPayrollType } from '@/types/payroll'
import { SourceType } from '@/utils/enum'
import { formatAmount, formatAmountNoSymbol } from '@/utils/money'
import { Switch } from '@headlessui/react'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import { AlertCircleIcon } from 'lucide-react'
import React, { useMemo, useState } from 'react'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    setSteps: React.Dispatch<React.SetStateAction<number>>
    totalPayrollAmount: number
    isMileStone?: boolean
    contract?: ContractPayrollType
    setFormState: (data: { account: string; budgetId: string }) => void
    formState: {
        account: string
        budgetId: string
    }
}
export default function PayrollProcess1({
    closeModal,
    totalPayrollAmount,
    setSteps,
    isMileStone,
    contract,
    formState,
    setFormState,
}: Props) {
    const { mutate: generateOtp } = useGeneratePaymentOTP({})

    const { selectedAccount } = useAuthContext()
    const [linkBudget, setLinkBudget] = useState(false)

    const { data: accounts } = useGetAccounts({
        queryParams: {
            entity_id: selectedAccount?.org?.id as string,
        },
    })

    const {
        data: org_budget,
        isLoading,
        isFetched,
    } = useGetOrgBudget({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            account: contract?.employee?._id ?? (contract?.employee?.id as string),
        },
    })

    const orgBudget = useMemo(() => org_budget?.budgets ?? [], [org_budget])
    const { mutate: mileStoneOtpFn } = useMilestonePaymentOtp({})

    return (
        <section className='w-screen lg:w-[35rem] py-3'>
            <div className='flex items-center justify-between border-b  border-gray/50 py-3 px-5 lg:px-7'>
                <h4 className='font-medium text-xl'>Process payroll</h4>
                <CancelIcon className='w-8 h-8 bg-gray2/20 rounded-full p-1.5' onClick={() => closeModal(false)} />
            </div>
            <div className='mt-8 flex flex-col flex-1 px-5 lg:px-7'>
                <label className='mb-2' htmlFor='funding_method'>
                    Disburse payout with this account
                </label>
                <Select
                    name='fund_source'
                    onValueChange={account => setFormState({ ...formState, account })}
                    value={formState?.account}
                >
                    <SelectTrigger className=' text-base h-[3.3rem]  text-[#5E5E5E] border-gray3 flex '>
                        {formState?.account ? <SelectValue /> : <span>Select account</span>}
                    </SelectTrigger>
                    <SelectContent className='max-h-[12rem] overflow-y-scroll'>
                        <SelectItem value={SourceType.Wallet} className='pl-3'>
                            <div className='flex items-center gap-3'>
                                <BizpendAccounts className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`} />
                                <div className='flex flex-col items-start'>
                                    <h6 className='text sm'>Main Wallet</h6>
                                </div>
                            </div>
                        </SelectItem>
                        {accounts?.accounts?.map(acct => (
                            <SelectItem value={acct?._id} className='pl-3' key={acct.id}>
                                <div className='flex items-center gap-3'>
                                    <BizpendAccounts className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`} />
                                    <div className='flex flex-col justify-start items-start'>
                                        <h6 className='text sm'>{acct.nickname ?? acct.account_name}</h6>
                                        <p className='text-xs text-gray1 font-medium'>{formatAmount(acct.balance)}</p>
                                    </div>
                                </div>
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            <div className='bg-gray5/20 p-3 rounded-lg border-gray1/50 border mt-10 mx-5 lg:mx-7'>
                <label className='py-1 flex justify-between items-center' htmlFor='switch'>
                    Link this payout to a budget
                    <Switch
                        checked={linkBudget}
                        onChange={setLinkBudget}
                        className={`relative inline-flex h-6 w-11 items-center rounded-full border-gray1/50 border ${
                            linkBudget ? 'bg-brand' : 'bg-white'
                        }`}
                    >
                        <span
                            className={`${
                                !linkBudget ? 'translate-x-1 bg-brand' : 'translate-x-6 bg-white'
                            } inline-block h-4 w-4 transform rounded-full  transition`}
                        />
                    </Switch>
                </label>
                {linkBudget && (
                    <div className='mt-4'>
                        <Select name='budget' onValueChange={val => setFormState({ ...formState, budgetId: val })}>
                            <SelectTrigger className=' border-[#DADCE0]  h-11 rounded-lg  text-sm  mt-2'>
                                <SelectValue placeholder={'Select your preferred budget'} />
                            </SelectTrigger>
                            <SelectContent className='w-96 h-64 overflow-scroll'>
                                {isLoading
                                    ? 'Please wait...'
                                    : orgBudget?.map(budget => (
                                          <div className='flex justify-between items-center'>
                                              <SelectItem
                                                  value={budget?._id}
                                                  disabled={
                                                      Number(totalPayrollAmount) > Number(budget?.available_amount)
                                                  }
                                                  className='block'
                                              >
                                                  {budget.name} (Balance: {budget?.currency?.symbol ?? '₦'}
                                                  {formatAmountNoSymbol(budget?.available_amount)})
                                              </SelectItem>
                                              {Number(totalPayrollAmount) > Number(budget?.available_amount) && (
                                                  <TooltipProvider delayDuration={10}>
                                                      <Tooltip>
                                                          <TooltipTrigger className='mr-2'>
                                                              <AlertCircleIcon size={15} />
                                                          </TooltipTrigger>

                                                          <TooltipContent>
                                                              <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                                  Insufficient budget balance
                                                              </p>
                                                          </TooltipContent>
                                                      </Tooltip>
                                                  </TooltipProvider>
                                              )}
                                          </div>
                                      ))}
                                {isFetched && orgBudget?.length === 0 && 'No budget affiliated with the user'}
                            </SelectContent>
                        </Select>
                    </div>
                )}
            </div>

            <div className='flex gap-4 justify-end mt-8 border-t border-gray/50 pt-3 px-7'>
                <Button
                    onClick={() => closeModal(false)}
                    className='border-none min-w-[120px] bg-transparent text-[black] hover:bg-transparent'
                >
                    back
                </Button>
                <Button
                    className='min-w-[120px]'
                    onClick={() => {
                        generateOtp({})
                        if (isMileStone) {
                            setSteps(4)
                            mileStoneOtpFn({})
                            return
                        }
                        setSteps(2)
                    }}
                    disabled={(linkBudget && !formState?.budgetId) || !formState?.account}
                >
                    Proceed
                </Button>
            </div>
        </section>
    )
}
