import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { ModuleEnum, TimeLineType } from '@/utils/timeline'

export const activityDescription = (event: TimeLineType) => {
    const eventToShow = event?.events?.[event?.events?.length - 1]

    const nameArray = eventToShow?.description?.split(' ')
    const name = nameArray[nameArray?.length - 2] + ' ' + nameArray[nameArray?.length - 1]

    let text = ''
    switch (event.module_type) {
        case ModuleEnum.BILL:
            if (eventToShow?.event_type === 'APPROVERS ASSIGNED') {
                text = `${event.module?.approvers?.length > 1 ? capitalizeText(eventToShow?.event_type) : 'Approver assigned'} to a bill of <span class='font-semibold text-black'>${formatAmount(event?.module?.amount)}</span>. ${eventToShow?.description}`
                return text
            }
            if (eventToShow?.event_type === 'APPROVED' || eventToShow?.event_type === 'DECLINED') {
                text = `A bill of <span class='font-semibold text-black'>${formatAmount(event?.module?.amount)}</span> was ${eventToShow?.description === 'AUTO_APPROVED' ? `<span class='text-brand font-semibold'>Auto Approved</span>` : nameArray?.[0] + ' ' + nameArray?.[1] + ' ' + `<span class='text-brand font-semibold'>${name}</span>`}`
                return text
            }
            text = `A ${formatAmount(event?.module?.amount)} bill was created. ${eventToShow?.description}`
            break

        case ModuleEnum.EXPENSE:
            if (eventToShow?.event_type === 'APPROVERS ASSIGNED') {
                text = `${event.module?.approvers?.length > 1 ? capitalizeText(eventToShow?.event_type) : 'Approver assigned'} to an expense of <span class='font-semibold text-black'>${formatAmount(event?.module?.amount)}</span>. ${eventToShow?.description}`
                return text
            }
            if (
                eventToShow?.event_type === 'APPROVED' ||
                eventToShow?.event_type === 'DENIED' ||
                eventToShow?.event_type === 'REIMBURSED'
            ) {
                text = `An expense of <span class='font-semibold text-black'>${formatAmount(event?.module?.amount)}</span> was ${eventToShow?.description === 'AUTO_APPROVED' ? `<span class='text-brand font-semibold'>Auto Approved</span>` : nameArray?.[0] + ' ' + nameArray?.[1] + ' ' + `<span class='text-brand font-semibold'>${name}</span>`} `
                return text
            }
            text = `A ${formatAmount(event?.module?.amount)} expense was created. ${eventToShow?.description}`
            break

        case ModuleEnum.INVOICE:
            if (eventToShow?.event_type === 'APPROVERS ASSIGNED') {
                text = `${event.module?.approvers?.length > 1 ? capitalizeText(eventToShow?.event_type) : 'Approver assigned'} to an invoice of <span class='font-semibold text-black'>${formatAmount(event?.module?.total_amount)}</span>. ${eventToShow?.description}`
                return text
            }
            if (eventToShow?.event_type === 'APPROVED' || eventToShow?.event_type === 'DECLINED') {
                text = `An invoice of <span class='font-semibold text-black'>${formatAmount(event?.module?.total_amount)}</span> was ${eventToShow?.description === 'AUTO_APPROVED' ? `<span class='text-brand font-semibold'>Auto Approved</span>` : nameArray?.[0] + ' ' + nameArray?.[1] + ' ' + `<span class='text-brand font-semibold'>${name}</span>`}`
                return text
            }
            text = `A ${formatAmount(event?.module?.total_amount)} invoice was created. ${eventToShow?.description}`
            break

        default:
            break
    }

    return text
}
