import { isDevelopmentEnvironment, prodEnvironment } from './utils'

export const capitalizeText = (text?: string) => {
    if (!text) return
    const textToBeTransformed = text.toLowerCase()
    return textToBeTransformed[0].toUpperCase() + textToBeTransformed.slice(1, textToBeTransformed.length)
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const checkifReceiptIsPdf = (receipt?: string) => {
    return !!receipt?.split('.').slice(-1)[0]
}

export const stagingLink = 'https://staging-app.bizpend.com/dashboard/overview'
export const prodLink = 'https://app.bizpend.com/dashboard/overview'
export const devLink = 'http://localhost:3000/dashboard/overview'

export const stripeConnectLinks = `https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_RrC8SpB0QZofx4axIhytQiRL2xvR4CO0&scope=read_write&redirect_uri=${prodEnvironment() ? prodLink : isDevelopmentEnvironment() ? devLink : stagingLink}`
export const quickBooksConnectLinks = (orgId: string) =>
    `https://appcenter.intuit.com/connect/oauth2?client_id=ABviy2kZDdOL5WhPKYXx7zS3wqSP1j891QAmP8SQG0p5wc5rRA&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=https://core-staging-api.bizpend.com/healthz&state=${orgId}`

export const stripeDisconnectLink = null
