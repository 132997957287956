export const subRouteHelper = {
    profile: 0,
    security: 1,
    // payment: 2,
    help: 3,
    // notification: 4,
    terms: 4,
    policy: 5,
}

export const UserSettingsHeading = {
    profile: 'My Profile',
    security: 'Security',
    payment: 'Payment Method',
    help: 'Help',
    // notification: 'Notification',
    terms: 'Terms',
    policy: 'Policy',
}

export const account_settings = [
    {
        title: 'My profile',
        enableforAdmin: true,
        title_exp: 'Explore your  profile, edit your details!',
        type: 'profile',
        to: 'profile',
    },
    {
        title: 'Security',
        title_exp: 'Manage security: change your passwords.',
        type: 'security',
        to: 'security',
        enableforAdmin: true,
    },
    // {
    //     title: 'Payout method',
    //     title_exp: 'Connect multiple bank accounts to get reimbursed directly to your bank.',
    //     type: 'payment',
    //     to: 'payment',
    //     enableforAdmin: true,
    // },
    {
        title: 'Help & support',
        title_exp: 'Reach out to our support team. Customer support is up 24/7.',
        type: 'help',
        to: 'help',
    },
    // {
    //     title: 'Notification',
    //     title_exp: 'Stay updated: Personalize your notifications.',
    //     type: 'notification',
    //     to: 'notification',
    // },
    {
        title: 'Terms of service',
        title_exp: 'Review our terms of service.',
        type: 'terms_of_service',
        to: 'terms',
    },
    {
        title: 'Privacy policy',
        title_exp: 'Review our privacy policy',
        type: 'policy',
        to: 'policy',
    },
]
