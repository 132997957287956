import { useEffect, useMemo, useRef, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import downArrow from '@/assets/down-arrow.svg'
import filter from '@/assets/filter.svg'
import { useGetAllBills } from '@/services/bill-payment/queries'
import { useAuthContext, useBillPaymentContext } from '@/context'
import ProgressBar from '@/components/ProgressBar'
import BillPaymentListTable from '../components/BillPaymentTableList'
import notFound from '@/assets/not-found.svg'
import { formatAmount } from '@/utils/money'
import { BillType } from '@/services/bill-payment/types'
import { CreateBillFlow } from '@/components/Modals/BillPayments/CreateBillFlow'
import { Pagination } from '@/types/utils'
import FilterBill from '@/components/Modals/BillPayments/AdvancedFIlterModal'
import FilterBillChip from '@/components/Modals/BillPayments/FilterBillChip'
import { useGetOrgVendors } from '@/services/vendors/queries'
import { useGetOrgMembers } from '@/services/employees/queries'
import { filter_consts } from '@/components/Modals/BillPayments/utils'
import { debounce } from 'lodash'
import { useSearchParams } from 'react-router-dom'

export const BillPaymentLists = () => {
    const [showNewBillModal, setShowNewBillModal] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [filterBillsModal, setFilterBillsModal] = useState(false)
    const { selectedAccount, setShowUpgradeModal } = useAuthContext()
    const { filterBillRequest, setFilterBillRequest, pageNumber, pageNumberClicked, setPageNumberClicked } =
        useBillPaymentContext()
    const { filterBillRequest: filterFormState } = useBillPaymentContext()

    const [queryParameter] = useSearchParams()
    const status = queryParameter.get('status') as string

    const isAdvanceFiltered =
        Object.values({ ...filterFormState.period }).some(value => !!value) || !!filterFormState.status

    const {
        data: bills_data,
        isLoading,
        isRefetching,
        refetch: refetchAllBills,
    } = useGetAllBills({
        queryParams: {
            page: pageNumber as unknown as string,
            limit: '12',
            org: selectedAccount?.org?.id as string,
            // sort: getSortString(sort as ColumnSort),
            search: searchQuery,
            status: filterBillRequest?.status?.toUpperCase(),
            start_date: (filterBillRequest?.period?.from ??
                filterBillRequest?.period?.time_in_words) as unknown as string,
            end_date: (filterBillRequest?.period?.to ?? new Date()) as unknown as string,
            due_time: filterBillRequest?.due_time as unknown as string,
            min_amount: filterBillRequest?.amount?.min?.replaceAll(',', ''),
            max_amount: filterBillRequest?.amount?.max?.replaceAll(',', ''),
            approver: filterBillRequest?.approver !== filter_consts.initial ? filterBillRequest?.approver : '',
            vendor: filterBillRequest?.vendor,
        },

        onSuccess: () => {
            setFilterBillRequest(prev => ({ ...prev, filter_now: false }))
        },
    })
    const billsData = useMemo(() => bills_data?.results ?? [], [bills_data])
    const billStats = [
        {
            desc: 'Total pending payments',
            count: bills_data?.summary?.totalPendingCount ?? 0,
            totalAmount: bills_data?.summary?.totalPendingAmount ?? 0,
        },
        {
            desc: 'Overdue payments',
            count: bills_data?.summary?.overdueCount ?? 0,
            totalAmount: bills_data?.summary?.overdueAmount ?? 0,
        },
        {
            desc: 'Due in next 7 days',
            count: bills_data?.summary?.dueIn7daysCount ?? 0,
            totalAmount: bills_data?.summary?.dueIn7DaysAmount ?? 0,
        },
        {
            desc: 'Scheduled',
            count: bills_data?.summary?.scheduledCount ?? 0,
            totalAmount: bills_data?.summary?.scheduledAmount ?? 0,
        },
    ]

    const { data } = useGetOrgVendors({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })
    const orgVendors = useMemo(() => data?.vendors ?? [], [data])
    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    const orgMembers = useMemo(() => employees ?? [], [employees])

    //=======making a query search after every 3 seconds provided there's a searched query======//
    const debouncedSearch = useRef(
        debounce(() => {
            refetchAllBills()
        }, 300)
    ).current

    //=====refetches the expense when the advanced filter is used
    useEffect(() => {
        if (filterBillRequest.filter_now) {
            refetchAllBills()
        }
        if (searchQuery) {
            debouncedSearch()
        }

        return () => {
            debouncedSearch.cancel()
        }
    }, [filterBillRequest.filter_now, refetchAllBills, searchQuery, debouncedSearch])

    useEffect(() => {
        //===========without this if statement, network calls happen about 5 times for same request=========
        if (pageNumber && pageNumberClicked) {
            refetchAllBills()
            setPageNumberClicked(false)
        }
    }, [pageNumber, pageNumberClicked])

    useEffect(() => {
        if (status) {
            setFilterBillRequest(prev => ({ ...prev, status: status?.toUpperCase(), filter_now: true }))
        }
    }, [queryParameter])

    const showUpgradeModal = Number(selectedAccount?.org?.usage_record?.modules?.vendors?.allocated) === 0 //checking allocated because an already created vendor should be allowed

    if (isLoading) return <ProgressBar />
    return (
        <section className={`overflow-scroll px-2 lg:px-0`}>
            <div className='block lg:flex justify-between mb-6 lg:mb-0'>
                <div className='mb-2 lg:mb-6'>
                    <h1 className='text-dark font-medium text-lg'>Payment lists</h1>
                    <p className='text-gray7 text-sm'>Create, approve or decline vendor payments here.</p>
                </div>
                <button
                    className='bg-[#454ADE] text-white py-2 px-3 h-[40px] rounded-md text-sm flex items-center justify-center gap-2 min-w-full lg:min-w-[115px] text-center'
                    onClick={() => {
                        if (showUpgradeModal) {
                            setShowUpgradeModal(true)
                            return
                        }
                        setShowNewBillModal(true)
                    }}
                >
                    Create payment
                </button>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-5 mb-5'>
                {billStats.map((stat, idx) => (
                    <div key={idx} className='bg-white px-4 py-3 rounded-xl'>
                        <h2 className='text-gray7 text-sm font-medium'>{stat.desc}</h2>
                        <p className='text-lg font-semibold text-dark'>{stat.count}</p>
                        <p className='text-2xl font-semibold text-gray7 mt-3'>{formatAmount(stat.totalAmount)}</p>
                    </div>
                ))}
            </div>
            <div className='w-full py-4 block bg-white rounded-2xl p-4 '>
                <div className='flex items-center justify-between mb-6'>
                    <div className='border border-[#DADCE0] h-[45px] w-[60%] lg:w-[30%] rounded-lg flex items-center overflow-hidden'>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mx-1 lg:mx-0'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            className='h-full w-[60%] outline-none placeholder:text-xs'
                            onChange={e => setSearchQuery(e.target.value)}
                            //   onKeyDown={handleKeyDown}
                            placeholder='Search by user, purpose'
                        />
                    </div>
                    <div className='flex items-center gap-2'>
                        <div
                            className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1.5 cursor-pointer'
                            onClick={() => setFilterBillsModal(true)}
                        >
                            <img src={filter} alt='button' />
                        </div>
                        <div className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1.5 cursor-pointer'>
                            <img src={downArrow} alt='button' />
                        </div>
                    </div>
                </div>
                <section className='my-4'>
                    <FilterBillChip vendors={orgVendors} orgMembers={orgMembers!} />
                </section>
                <div className={isRefetching ? 'opacity-30' : 'opacity-100'}>
                    {billsData?.length === 0 && isRefetching ? (
                        <ProgressBar />
                    ) : (billsData?.length === 0 && searchQuery?.length < 1 && !isAdvanceFiltered) ||
                      (billsData?.length > 0 && searchQuery?.length >= 0) ? (
                        <div className='overflow-x-scroll'>
                            <BillPaymentListTable
                                bills={billsData as BillType[]}
                                pagination_data={bills_data?.pagination as Pagination}
                            />
                        </div>
                    ) : (searchQuery.length > 0 || isAdvanceFiltered) && billsData?.length === 0 ? (
                        <div className='h-450px] flex flex-col text-center justify-center items-center pb-10'>
                            <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                            <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                            <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                {billsData &&
                                    'Invoices not found in this record. Please try a different filter or contact support for assistance.'}
                            </span>
                        </div>
                    ) : (
                        isRefetching && <ProgressBar />
                    )}
                </div>
            </div>
            {filterBillsModal && (
                <FilterBill closeModal={setFilterBillsModal} vendors={orgVendors} orgMembers={orgMembers} />
            )}
            <CreateBillFlow closeModal={setShowNewBillModal} setUploadBillModal={showNewBillModal} />
        </section>
    )
}
