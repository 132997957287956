import { ReactNode, createContext, useEffect, useMemo, useState } from 'react'

import { TransactionType } from '@/types/transactions'
import { useGetAccountingTypes, useGetCOA } from '@/services/accounting/queries'
import { ChartOfAccountingAccountType, ChartOfAccountType, ChartOfAccountTypeImport } from '@/types/accounting'
import { useCategoriesContext } from '.'
import { columnHelperInContext } from '@/modules/dashboard/Accounting/utils'
import { useGetCategoriesMapping } from '@/services/categories/queries'
import { QueryOrgMappedCategoryRes } from '@/services/categories/types'

export type ToggledColumn = {
    id: string
    label: string
    isCompulsory: boolean
    editable: boolean
    type?: 'select' | 'text'
    data?: {
        id: string
        label: string
    }[]
}

export interface ExtendedRow extends TransactionType {
    account?: string
    toggledData?: Record<string, string>
    selected?: boolean
    ready?: boolean
}

export type AccountingContextDefaultTypes = {
    pageNumberClicked: boolean
    setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
    rows: ExtendedRow[]
    setRows: React.Dispatch<React.SetStateAction<ExtendedRow[]>>
    columns: ToggledColumn[]
    addColumn: (column: ToggledColumn) => void
    accounttingTypes: ChartOfAccountingAccountType[]
    chartOfAccountData: ChartOfAccountType[]
    categoriesMappedToAccountData: QueryOrgMappedCategoryRes
    uploadedCOA: ChartOfAccountTypeImport[]
    setUploadedCoa: React.Dispatch<React.SetStateAction<ChartOfAccountTypeImport[]>>
    overwriteData: boolean
    setOverwriteData: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultValues: AccountingContextDefaultTypes = {
    pageNumberClicked: false,
    setPageNumberClicked: () => undefined,
    pageNumber: 1,
    setPageNumber: () => undefined,
    columns: [],
    rows: [],
    setRows: () => undefined,
    addColumn: () => undefined,
    accounttingTypes: [],
    chartOfAccountData: [],
    categoriesMappedToAccountData: [],
    uploadedCOA: [],
    setUploadedCoa: () => undefined,
    overwriteData: false,
    setOverwriteData: () => {},
}

export const AccountingContext = createContext(defaultValues)

export default function AccountingContextProvider({ children }: { children: ReactNode }) {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageNumberClicked, setPageNumberClicked] = useState(false)
    const [uploadedCOA, setUploadedCoa] = useState(defaultValues?.uploadedCOA)
    const [overwriteData, setOverwriteData] = useState(defaultValues?.overwriteData)

    const { data } = useGetAccountingTypes()
    const { data: coaData } = useGetCOA()
    const { data: categoriesMappedToAccount } = useGetCategoriesMapping({})

    const categoriesMappedToAccountData = useMemo(() => categoriesMappedToAccount ?? [], [categoriesMappedToAccount])

    const chartOfAccountData = useMemo(() => coaData?.results ?? [], [coaData])
    const { categories } = useCategoriesContext()

    const accounttingTypes = useMemo(() => data?.hierarchy ?? [], [data])

    const [rows, setRows] = useState<ExtendedRow[]>([])

    const [columns, setColumns] = useState<ToggledColumn[]>([...columnHelperInContext])

    useEffect(() => {
        setColumns(prev =>
            prev.map(column => {
                if (column.id === 'category') {
                    return {
                        ...column,
                        data: categories?.map(cat => ({ id: cat?.id, label: cat?.label })) ?? [],
                    }
                }
                // `${coa?.code ? coa?.code  } ${coa.account_name}
                if (column.id === 'affected_account') {
                    return {
                        ...column,
                        data:
                            chartOfAccountData?.map(coa => ({
                                id: coa?._id ?? coa?.id,
                                label: (coa?.code ? `${coa?.code} -` : '') + ' ' + coa?.account_name,
                            })) ?? [],
                    }
                }
                return column
            })
        )
    }, [categories, chartOfAccountData])
    const addColumn = (column: ToggledColumn) => {
        setColumns(prev => [...prev, column])
        setRows(prev =>
            prev.map(row => ({
                ...row,
                [column.id]: '',
            }))
        )
    }

    const values = {
        pageNumber,
        setPageNumber,
        pageNumberClicked,
        setPageNumberClicked,
        rows,
        setRows,
        columns,
        addColumn,
        accounttingTypes,
        chartOfAccountData,
        categoriesMappedToAccountData,
        uploadedCOA,
        setUploadedCoa,
        overwriteData,
        setOverwriteData,
    }
    return <AccountingContext.Provider value={values}>{children}</AccountingContext.Provider>
}
