import React, { useEffect, useState } from 'react'
import stripeImg from '@/assets/v2dashboard/Stripe.svg'
import paypalImg from '@/assets/v2dashboard/Paypal.svg'
import paystackImg from '@/assets/v2dashboard/Paystack.svg'
import { Disconnect, MoveRight } from '@/assets/assets.export'
import { useAuthContext } from '@/context'
import { useConnectStripe, useDisconnectConnectPaystack, useDisconnectConnectStripe } from '@/services/auth/mutations'
import { useSearchParams } from 'react-router-dom'
import { stripeConnectLinks } from '@/utils/string'
import AddPayStackIntegrationModal from '@/components/Modals/Invoice/AddPaystackIntegration'
import { useGetListOfBanks } from '@/services/payout/queries'
import ConfirmationPrompt from '@/components/Modals/ConfirmationPrompt'
import { PAYMENT_GATEWAY } from '@/types/invoice'
import { isStagingEnvironment } from '@/utils/utils'

export const PaymentProcessor = () => {
    const { selectedAccount } = useAuthContext()
    const [connectPaystack, setConnectPaystack] = useState(false)
    const [openConfirmationPrompt, setOpenConfirmtionPrompt] = useState(false)
    const [selectedGateway, setSelectedGateway] = useState<PAYMENT_GATEWAY>()

    const [searchParams] = useSearchParams()
    const { mutate: sendStripeCode, isLoading: isSendingStripeCode } = useConnectStripe({})
    const { mutate: disconnectStripe, isLoading: isDisconnectingStripe } = useDisconnectConnectStripe({})
    const { mutate: disconnectPaystack, isLoading: isDisconnectingPaystack } = useDisconnectConnectPaystack({
        onSuccess: () => setOpenConfirmtionPrompt(false),
    })
    const handleDisconnect = (gateway: PAYMENT_GATEWAY) => {
        setSelectedGateway(gateway)
        setOpenConfirmtionPrompt(true)
    }

    const { data } = useGetListOfBanks({
        enabled: !selectedAccount?.org?.is_paystack_set,
    })

    const allBankList = data ?? []
    const stripeCode = searchParams.get('code')

    useEffect(() => {
        if (stripeCode) {
            sendStripeCode({ code: stripeCode })
        }
    }, [stripeCode, sendStripeCode])

    const platforms = [
        {
            logo: stripeImg,
            name: 'Stripe',
            id: PAYMENT_GATEWAY.stripe,
            desc: 'Choose how you want to receive invoice payments',
            connected: !!selectedAccount?.org?.stripe_user_id,
            loading: isSendingStripeCode || isDisconnectingStripe,
            connectFn: () => (window.location.href = stripeConnectLinks),
            disconnectFn: () => disconnectStripe({ stripe_user_id: selectedAccount?.org?.stripe_user_id }),
        },
        {
            logo: paypalImg,
            name: 'Paypal',
            desc: 'Trusted worldwide for secure online payments',
            connected: false,
            id: PAYMENT_GATEWAY.paypal,
        },
        {
            logo: paystackImg,
            name: 'Paystack',
            id: PAYMENT_GATEWAY.paystack,
            desc: 'Secure and reliable payments for African businesses',
            connected: selectedAccount?.org?.is_paystack_set,
            connectFn: () => setConnectPaystack(true),
            disconnectFn: () => disconnectPaystack({}),
            loading: isDisconnectingPaystack,
        },
    ]
    const activePlatform = platforms?.find(platform => platform?.id === selectedGateway)

    return (
        <div className='p-6 border border-gray8 rounded-[20px] bg-white w-full lg:w-2/3'>
            <div className='mb-8'>
                <p className='text-[20px] leading-[27px] text-[#202020] font-semibold mb-2'>
                    Connect a payment processor
                </p>
                <p className='text-gray7 font-medium'>Connect a payment processor to receive invoice payments</p>
            </div>
            <div className='space-y-4'>
                {platforms?.map(platform => {
                    if (platform.name === 'Paypal' && !isStagingEnvironment()) return
                    return (
                        <div
                            onClick={() => {
                                if (!platform?.connected) {
                                    platform?.connectFn?.()
                                    return
                                }
                                handleDisconnect(platform.id)
                            }}
                            className={`p-3 border border-gray8 rounded-2xl flex items-center justify-between  cursor-pointer ${platform?.loading ? 'opacity-50' : ''}`}
                        >
                            <div className='flex items-center gap-4'>
                                <img src={platform?.logo} alt='' className='h-16 w-12 lg:w-16' />
                                <div className=''>
                                    <p className='font-semibold mb-1 flex items-center gap-2'>
                                        {platform?.name}{' '}
                                        {platform?.connected && (
                                            <>
                                                <span className='bg-gray8 h-2 w-2 rounded-full'>&nbsp;</span>{' '}
                                                <span className='text-xs text-[#19C37D] font-semibold'>
                                                    {platform?.connected ? 'Connected' : ''}
                                                </span>
                                            </>
                                        )}
                                    </p>
                                    <p className='text-gray7 text-sm font-medium'>{platform?.desc}</p>
                                </div>
                            </div>
                            <div className='flex items-center justify-center pt-3'>
                                <div className='flex items-center gap-1 text-xs text-brand font-semibold cursor-pointer'>
                                    {platform?.connected ? (
                                        <>
                                            <Disconnect /> Disconnect now
                                        </>
                                    ) : (
                                        <MoveRight className='rotate-[-45deg] bg-[#EFEFF9] p-3 rounded-full h-10 w-10' />
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {openConfirmationPrompt && (
                <ConfirmationPrompt
                    closeModal={() => setOpenConfirmtionPrompt(false)}
                    action={() => activePlatform?.disconnectFn?.()}
                    loading={activePlatform?.loading || false}
                />
            )}
            {connectPaystack && (
                <AddPayStackIntegrationModal closeModal={() => setConnectPaystack(false)} allBankList={allBankList} />
            )}
        </div>
    )
}
