import { useEffect, useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { Controller, useForm } from 'react-hook-form'
import { Input } from '@/components/Input'
import { AlertCircleIcon, Minus, Plus } from 'lucide-react'
import { Button } from '@/components/Button'
import { formatAmountNoSymbol, formatAmountWithoutSymbols } from '@/utils/money'
import { useMutateCreateINvoiceItem } from '@/services/invoice/mutations'
import { useAuthContext, useCurrencyContext } from '@/context'
import { AddItemProps, InvoiceItemType } from '@/types/invoice'
import { FormStateType } from '@/modules/dashboard/Invoices/components/utils'
import { v4 } from 'uuid'
import { CurrencySelectFilter } from '@/components/CurrencyFilter'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'

type Props = {
    closeModal: () => void
    invoiceItem?: AddItemProps
    setFormState?: (data: Partial<FormStateType>) => void
    formState?: FormStateType
    orgItems?: InvoiceItemType[]
    createItem?: boolean
}

type FormValues = {
    itemName: string
    itemType?: string
    description: string
    quantity?: number
    unitPrice?: string
    currency?: string
    totalCost: string
    uniq_id: string
    id?: string
    _id?: string
}

const CreateInvoiceItem = ({ closeModal, invoiceItem, setFormState, formState, orgItems, createItem }: Props) => {
    const form = useForm<FormValues>({
        defaultValues: {
            quantity: Number(invoiceItem?.quantity ?? 1),
            description: invoiceItem?.description,
            itemName: invoiceItem?.name,
            unitPrice: String(Number(invoiceItem?.unit_cost ?? '0')),
            uniq_id: invoiceItem?.uniq_id,
            itemType: invoiceItem?.type,
        },
    })

    const { selectedAccount } = useAuthContext()
    const { selectedCurrencyInfo } = useCurrencyContext()

    const [showDropdown, setShowDropdown] = useState(false)

    const { mutate, isLoading } = useMutateCreateINvoiceItem({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: closeModal,
    })

    const watchItemName = form.watch('itemName')
    const watchCurrency = form.watch('currency')

    const quantity = form.watch('quantity') || 0
    const unitPrice = form.watch('unitPrice') || 0

    useEffect(() => {
        const quantityTotal = Number(quantity) * Number(unitPrice)
        form.setValue('totalCost', quantityTotal.toString())
    }, [form, quantity, unitPrice])

    const handleSubmit = (values: FormValues) => {
        const hasItemNameMatch = orgItems?.some(item =>
            item?.name?.toLowerCase().startsWith(watchItemName?.toLowerCase())
        )
        const isUpdate = formState?.item?.some(item => item.uniq_id === values.uniq_id)

        const updatedItem = {
            description: values?.description,
            name: values?.itemName,
            unit_cost: Number(values?.unitPrice),
            quantity: values?.quantity ?? 1,
            uniq_id: values?.uniq_id || v4(),
            type: values?.itemType,
        }

        setFormState?.({
            ...formState,
            item: isUpdate
                ? formState?.item?.map(item => (item.uniq_id === values.uniq_id ? { ...item, ...updatedItem } : item))
                : [...(formState?.item ?? []), updatedItem],
        })

        if (!hasItemNameMatch) {
            mutate({
                description: values?.description,
                name: values?.itemName,
                unit_cost: Number(values?.unitPrice) / 100,
                quantity: values?.quantity,
                currency: values?.currency,
                type: values?.itemType,
            })
            return
        }
        closeModal()
    }

    const filteredItems =
        watchItemName?.length > 0
            ? orgItems?.filter(item => item?.name?.toLowerCase().startsWith(watchItemName?.toLowerCase()))
            : orgItems

    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[30rem] max-h-[90vh] overflow-scroll pb-8 bg-gray8/10'>
                <div className='border-b border-gray5/80 py-2 px-5  flex justify-between items-center'>
                    <h2 className='font-semibold opacity-80'>Add Item</h2>
                    <button
                        title='Close'
                        className='bg-gray6 rounded-full flex items-center justify-center text-gray7/80 h-7 w-7 p-1.5'
                        onClick={closeModal}
                    >
                        <AiOutlineClose fontSize={25} />
                    </button>
                </div>

                <form onSubmit={form.handleSubmit(handleSubmit)} className='mt-6 mx-4 space-y-4 mb-10'>
                    <div className='flex flex-col flex-1 gap-1.5 relative'>
                        <span className='opacity-80 text-sm font-medium'>
                            Type <span className='text-errorRed'>*</span>
                        </span>
                        <Controller
                            name='itemType'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={form.control}
                            render={({ field }) => (
                                <RadioGroup
                                    {...field}
                                    className='flex gap-3 flex-row'
                                    onValueChange={e => field.onChange(e)}
                                >
                                    {['Goods', 'Service'].map(option => (
                                        <label
                                            htmlFor={option}
                                            key={option}
                                            className={`border border-[#DADCE0] rounded-lg w-1/2 p-2 cursor-pointer`}
                                        >
                                            <div className={`flex gap-2 items-center cursor-pointer`}>
                                                <RadioGroupItem
                                                    type='button'
                                                    value={option.toLowerCase()}
                                                    id={option}
                                                    className='w-[16px] flex items-center justify-center'
                                                />
                                                <p className='text-sm text-[#5E5E5E] font-medium'>{option}</p>
                                            </div>
                                        </label>
                                    ))}
                                </RadioGroup>
                            )}
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-1.5 relative'>
                        <span className='opacity-80 text-sm font-medium'>
                            Item name <span className='text-errorRed'>*</span>
                        </span>
                        <Controller
                            name='itemName'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={form.control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type='text'
                                    className='border border-gray5 rounded-md h-10 placeholder:text-xs '
                                    placeholder='Enter item name'
                                    onFocus={() => setShowDropdown(true)}
                                />
                            )}
                        />

                        {showDropdown && Number(filteredItems?.length) > 0 && (
                            <div className='max-h-48 overflow-scroll absolute top-full z-10 bg-white shadow-sm p-2 rounded-lg w-full text-xs text-black/80 space-y-2.5'>
                                {filteredItems?.map((item, idx) => (
                                    <div className='flex justify-between items-center gap-2'>
                                        <button
                                            className='flex items-center justify-between w-full disabled:opacity-40'
                                            key={`${item.name}-${idx}`}
                                            onClick={() => {
                                                form.setValue('itemName', item.name)
                                                form.setValue('description', item.description)
                                                form.setValue('unitPrice', item.unit_cost?.$numberDecimal.toString())
                                                setShowDropdown(false)
                                            }}
                                            disabled={item?.currency?._id !== formState?.currency}
                                        >
                                            <span className='flex flex-col flex-1'>
                                                <span className='font-medium text-sm text-left '>{item.name}</span>
                                                <span className='text-xs text-gray7 text-left'>{item.description}</span>
                                            </span>
                                            <span>
                                                <span className='text-gray7'>
                                                    {item?.currency?.symbol}
                                                    {formatAmountNoSymbol(item.unit_cost?.$numberDecimal)}
                                                </span>
                                            </span>
                                        </button>
                                        {item?.currency?._id !== formState?.currency && (
                                            <div className=''>
                                                <TooltipProvider delayDuration={10}>
                                                    <Tooltip>
                                                        <TooltipTrigger className='mr-2'>
                                                            <AlertCircleIcon size={15} />
                                                        </TooltipTrigger>

                                                        <TooltipContent>
                                                            <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                                The currency for this invoice item doesn't match the
                                                                invoice
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col flex-1 gap-1.5'>
                        <span className='opacity-80 text-sm font-medium'>Description</span>
                        <Controller
                            name='description'
                            rules={{
                                required: false,
                                minLength: 2,
                            }}
                            control={form.control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type='text'
                                    className='border border-gray5 rounded-md h-10 placeholder:text-xs '
                                    placeholder='Describe item or service'
                                />
                            )}
                        />
                    </div>
                    <div className='flex gap-3 '>
                        {!createItem && (
                            <div className='flex flex-col flex-1 gap-1.5 max-w-[6rem] h-full '>
                                <span className='opacity-80 text-sm font-medium'>Quantity</span>
                                <Controller
                                    name='quantity'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={form.control}
                                    render={({ field }) => (
                                        <span className='text-gray7/80 flex items-center justify-between p-1.5 border border-gray5 rounded-md h-10'>
                                            <button
                                                title='Decrease quantity'
                                                disabled={field.value === 1 || !field?.value}
                                                onClick={() => field.onChange(Number(field.value) - 1)}
                                                className='flex-1 disabled:opacity-50'
                                                type='button'
                                            >
                                                <Minus size={20} />
                                            </button>
                                            <input
                                                aria-label='Quantity'
                                                value={field.value ?? 1}
                                                onChange={e => field.onChange(Number(e.target.value))}
                                                type='number'
                                                className='text-gray7 flex-1 text-center w-full focus:outline-none text-xs'
                                            />

                                            <button
                                                onClick={() => field.onChange(Number(field.value) + 1)}
                                                title='Increase quantity'
                                                type='button'
                                                className='flex-1 inline-flex justify-end'
                                            >
                                                <Plus size={20} />
                                            </button>
                                        </span>
                                    )}
                                />
                            </div>
                        )}
                        <div className='flex flex-col flex-1 gap-1.5'>
                            <span className='opacity-80 text-sm font-medium'>
                                Unit cost <span className='text-errorRed'>*</span>
                            </span>
                            <Controller
                                name='unitPrice'
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={form.control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        type='text'
                                        leftIcon={
                                            <span className='text-gray7'>
                                                {
                                                    selectedCurrencyInfo(
                                                        createItem ? watchCurrency : formState?.currency
                                                    )?.symbol
                                                }
                                            </span>
                                        }
                                        className='border border-gray5 rounded-md h-10 placeholder:text-xs w-full '
                                        placeholder='Enter unit cost'
                                        bodyClassName='flex-1 !min-w-full'
                                        min={0}
                                        value={formatAmountNoSymbol(field.value)}
                                        onChange={e => {
                                            const value_entered = formatAmountWithoutSymbols(e.target.value)
                                            field.onChange(value_entered)
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className='grid grid-cols-2 gap-6'>
                        {!createItem && (
                            <div className='flex flex-col flex-1 gap-1.5'>
                                <span className='opacity-80 text-sm font-medium'>Total cost</span>
                                <Controller
                                    name='totalCost'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={form.control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type='text'
                                            leftIcon={
                                                <span className='text-gray7'>
                                                    {
                                                        selectedCurrencyInfo(
                                                            createItem ? watchCurrency : formState?.currency
                                                        )?.symbol
                                                    }
                                                </span>
                                            }
                                            className='border-none bg-gray/30 rounded-md h-10 placeholder:text-xs w-full '
                                            placeholder='0.00'
                                            disabled
                                            bodyClassName='flex-1 !min-w-full'
                                            value={formatAmountNoSymbol(field.value)}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {createItem && (
                            <div className='flex flex-col flex-1 gap-1.5'>
                                <span className='opacity-80 text-sm font-medium'>
                                    Currency <span className='text-errorRed'>*</span>
                                </span>

                                <Controller
                                    name='currency'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={form.control}
                                    render={({ field }) => (
                                        <CurrencySelectFilter
                                            hideAllFilter
                                            currency={field.value}
                                            setCurrency={field.onChange}
                                            triggerClassName='flex-1 w-full py-2.5'
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>

                    <div className='flex pt-2 items-center justify-end gap-4 px-6 pb-2 py-2  absolute bottom-0 right-0 left-0 bg-white w-full rounded-b-lg border-t border-gray5'>
                        <Button
                            loading={isLoading}
                            size={'lg'}
                            className='bg-brand text-white px-8 rounded-md min-w-[90px] text-xs'
                        >
                            Add item
                        </Button>
                    </div>
                </form>
            </section>
        </ModalWrapper>
    )
}

export default CreateInvoiceItem
