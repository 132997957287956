import { AddBankEmptyState } from '@/assets/assets.export'
import { Button } from '@/components'
import AddNewBankContainer from '@/components/Modals/UserSettings/AddNewBank/AddNewBankContainer'
import DeleteBankAccount from '@/components/Modals/UserSettings/DeleteBankAccount/DeleteBankAccount'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { QueryBankAccountsRes } from '@/services/accounts/types'
import { keyGetBankDetails, useDeleteUserAccountById, useUpdateAccountDetails } from '@/services/payout/mutations'
import { useGetAccountsForUser, useGetListOfBanks } from '@/services/payout/queries'
import { UserAccount } from '@/services/payout/types'
import { useQueryClient } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { useMemo, useState } from 'react'

import { MdOutlineDelete } from 'react-icons/md'

export default function UserBankSettings() {
    const query_client = useQueryClient()
    const [openAddBank, setOpenAddBank] = useState(false)
    const { selectedAccount } = useAuthContext()

    const { data } = useGetListOfBanks()
    const allBankList = useMemo(() => data ?? [], [data])

    const { data: userAccount, isLoading: isLoadingUsersAccount } = useGetAccountsForUser({
        queryParams: {
            account: selectedAccount?.id as string,
        },
    })

    const fetcheduserBankDetails = (query_client.getQueryCache().find(keyGetBankDetails)?.state?.data ??
        userAccount?.accounts) as QueryBankAccountsRes

    //=====sorts the fetched details to make the primary bank appear top======
    const userBankDetails = useMemo(
        () =>
            fetcheduserBankDetails?.accounts?.sort(
                (bank_1, bank_2) => (bank_2.is_primary as any) - (bank_1.is_primary as any)
            ) ?? [],
        [fetcheduserBankDetails]
    )

    return (
        <>
            <section className='font-medium mr-6 mt-5 text-xl'>
                <h3>Payout method </h3>
                <p className='text-[#838383] text-sm mt-1'>
                    Connect multiple bank accounts to get reimbursed directly to your bank.
                </p>
            </section>
            <section className='mr-6 mt-10 border rounded-lg px-4 border-[#DADCE0] pt-4 '>
                <div className='flex justify-between items-center'>
                    <div>
                        <h3 className='font-medium text-xl'> Bank accounts </h3>
                        <p className='text-[#5E5E5E] text-sm mt-2'>
                            Connect multiple banks, but set one as primary for reimbursements.{' '}
                        </p>
                    </div>
                    <Button
                        className='bg-transparent border border-[#DADCE0] min-w-[120px] text-[#5E5E5E] hover:bg-transparent'
                        onClick={() => setOpenAddBank(true)}
                    >
                        Add new bank
                    </Button>
                </div>
                <section className='my-6 flex flex-col gap-5'>
                    {!userBankDetails && !isLoadingUsersAccount && (
                        <div className='flex flex-col items-center justify-center mt-7'>
                            <h3 className='text-[#5E5E5E] font-semibold text-xl'>No bank account(s) linked yet</h3>
                            <p className='w-auto lg:w-[34rem] text-sm text-center mt-2'>
                                You have not linked any bank to this account. Linking your bank account(s) will allow
                                you get reimbursed directly to your desired bank account.
                            </p>
                            <AddBankEmptyState className='mt-7' />
                        </div>
                    )}
                    {isLoadingUsersAccount && (
                        <div className='flex items-center justify-center h-24'>
                            <Loader2 className='animate-spin h-5 w-5' />
                        </div>
                    )}
                    {userBankDetails &&
                        userBankDetails.map(user_bank => <AddBankCardSection bank_details={user_bank} />)}
                </section>
            </section>
            {openAddBank && (
                <AddNewBankContainer
                    closeModal={setOpenAddBank}
                    allBankList={allBankList}
                    userBankDetails={userBankDetails}
                />
            )}
        </>
    )
}

const AddBankCardSection = ({ bank_details }: { bank_details: UserAccount }) => {
    const { selectedAccount } = useAuthContext()

    const [openDeleteModal, setDeleteModal] = useState(false)

    const { mutate: deleteAcctByIdFn, isLoading: isDeletingAcct } = useDeleteUserAccountById({
        queryParams: {
            account_id: bank_details.id,
        },
        onSuccess: () => {
            setDeleteModal(false)
            toastHandler({ message: 'Deleted Successfully', state: 'success' })
        },
    })
    const { mutate: makeAccountPrimaryFn, isLoading: isMakingAccountPrimary } = useUpdateAccountDetails({
        queryParams: {
            account_id: bank_details.id,
        },
        onSuccess: () => {
            toastHandler({ message: 'Bank Made Primary', state: 'success' })
        },
    })

    const handlePrimaryAcct = () => {
        makeAccountPrimaryFn({
            is_primary: true,
            account_number: bank_details.account_number,
            bank_code: bank_details.bank_code,
        })
    }
    const handlePrimaryAcctDelete = () => {
        // if (bank_details.is_primary) {
        //     toastHandler({ message: 'Primary account cannot be deleted', state: 'error' })
        //     return
        // }
        setDeleteModal(true)
    }

    const handleDeleteAcct = () => {
        deleteAcctByIdFn({})
    }

    return (
        <>
            <div
                className={`border-[#DADCE0] rounded-lg p-2 lg:p-4 border w-full ${
                    isMakingAccountPrimary || isDeletingAcct ? 'opacity-40' : 'opacity-95'
                }`}
            >
                <div className='flex text-[#838383] items-center text-[16px] font-normal'>
                    <div className='flex flex-1'>
                        <h5>Bank account </h5>
                        {bank_details.is_primary && (
                            <span className='flex-1 ml-5'>
                                <span className=' bg-[#EBF5FF] text-[#0F3D6F] rounded-xl px-4 p-1 text-[14px]'>
                                    Primary
                                </span>
                            </span>
                        )}
                    </div>
                    <MdOutlineDelete
                        className='w-6 h-6 text-[#CF491E] cursor-pointer'
                        onClick={handlePrimaryAcctDelete}
                    />
                </div>
                <div className='flex justify-between mt-5 lg:mt-3'>
                    <span className='font-medium'>
                        Connected{' '}
                        <span className='font-normal text-sm'>
                            ({bank_details.bank_name} -{bank_details?.account_name} -{' '}
                            {bank_details?.account_number.slice(6, 10)})
                        </span>
                    </span>
                    {!bank_details.is_primary && (
                        <span className='text-[#454ADE] text-sm cursor-pointer' onClick={handlePrimaryAcct}>
                            Set as primary bank
                        </span>
                    )}
                </div>
            </div>
            {openDeleteModal && (
                <DeleteBankAccount
                    closeModal={setDeleteModal}
                    handleDeleteAcct={handleDeleteAcct}
                    bankDetails={bank_details!}
                    user={selectedAccount!}
                />
            )}
        </>
    )
}
