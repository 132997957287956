import { InvoiceContextProvider } from '@/context/InvoiceReminder'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { invoiceSidebarPermissions } from '@/layout/LayoutComponents/utils'
import NotAuthorized from '@/layout/NotAuthorized'
import Customers from '@/modules/dashboard/Customers/Customers'
import CustomerDetails from '@/modules/dashboard/Customers/partials/CustomerDetails'
import Invoices from '@/modules/dashboard/Invoices/Invoices'
import CreateInvoice from '@/modules/dashboard/Invoices/partials/CreateInvoice/CreateInvoice'
import InvoiceAppearanceSettings from '@/modules/dashboard/Invoices/partials/InvoiceAppearanceSettings'
import InvoiceDetailsWrapper from '@/modules/dashboard/Invoices/partials/InvoiceDetails/InvoiceDetailsWrapper'
import InvoicePaymentSettings from '@/modules/dashboard/Invoices/partials/InvoicePaymentSettings'
import InvoicePolicy from '@/modules/dashboard/Invoices/partials/InvoicePolicy'
import InvoiceTaxItemsSettings from '@/modules/dashboard/Invoices/partials/InvoiceTaxItemsSettings'
import { Outlet, Route } from 'react-router-dom'

export const useInvoiceRoutes = () => {
    const hasPermission = useHasAccessPermisson(invoiceSidebarPermissions)

    if (!hasPermission) return <Route path='not-authorized' element={<NotAuthorized />} />

    return (
        <Route
            element={
                <InvoiceContextProvider>
                    <Outlet />
                </InvoiceContextProvider>
            }
        >
            <Route path='invoice/:invoice_subRoute' element={<Invoices />} />
            <Route path='invoice/customers/quick_actions' element={<Customers />} />
            <Route path='invoice/customers/:customer_id' element={<CustomerDetails />} />
            <Route path='invoice/settings/appearance' element={<InvoiceAppearanceSettings />} />
            <Route path='invoice/settings/policy' element={<InvoicePolicy />} />
            <Route path='invoice/settings/payment' element={<InvoicePaymentSettings />} />
            <Route path='invoice/settings/tax-items' element={<InvoiceTaxItemsSettings />} />
            <Route path='invoice/create_invoice' element={<CreateInvoice />} />
            <Route path='invoice/update_invoice/:invoice_id' element={<CreateInvoice />} />
            <Route path='invoice/:invoice_subRoute/:invoice_id' element={<InvoiceDetailsWrapper />} />
        </Route>
    )
}
