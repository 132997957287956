import { Button } from '@/components'
import { SingleParentAccountAccordion } from '@/components/Modals/Accounting/PreviewUploadedCOAModal'
import { useAccountingContext } from '@/context'
import { cn } from '@/lib/styling'
import { useUploadCOA } from '@/services/accounting/mutations'
import { ArrowLeft } from 'lucide-react'
import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

type FormType = {
    rows: {
        account_name: string
        account_type: string
        description: string
        code: string
        is_subaccount: boolean
        parent_type?: string
        parent_account?: string
    }[]
}

const PreviewImportedCOA = () => {
    const navigate = useNavigate()

    const { accounttingTypes, uploadedCOA, overwriteData } = useAccountingContext()

    const form = useForm<FormType>({
        defaultValues: {
            rows: uploadedCOA?.map(coa => ({
                account_name: coa?.Account_Name,
                // account_type: accounttingTypes?.find(type => type?.name === coa?.Account_Type)?._id,
                account_type: accounttingTypes
                    ?.flatMap(type => type?.children ?? [])
                    ?.find(type => type?.name?.toLowerCase() == coa?.Sub_Account_Type?.toLowerCase())?._id,
                description: coa?.Description,
                code: coa?.Code,
                is_subaccount: false,
                // parent_type: mapSubAccountToParentType(accounttingTypes, coa?.Sub_Account_Type ?? '')
            })),
        },
    })

    const { fields, remove, update } = useFieldArray<FormType>({
        name: 'rows',
        control: form.control,
    })

    const { mutate: uploadChartOfAccountFn, isLoading } = useUploadCOA({
        onSuccess: () => {
            // nextStep?.()
        },
    })

    const handleImport = (values: FormType) => {
        uploadChartOfAccountFn({
            data: values?.rows?.map(coa => ({
                code: coa.code,
                account_name: coa.account_name,
                account_type: coa.account_type,
                description: coa.description,
            })),
            override_duplicate: overwriteData,
        })
    }
    return (
        <section className='mx-[1.4rem] md:mx-[2.7rem] py-3 pt-2 mb-6 pb-10 lg:pb-24 md:pb-3'>
            <div className='flex gap-1 my-6 cursor-pointer' onClick={() => navigate(-1)}>
                <ArrowLeft />
                back
            </div>
            <div className='mt-8'>
                <div className='flex flex-col lg:flex-row gap-5 justify-between'>
                    <div>
                        <h2 className='text-lg font-semibold'>Import preview</h2>
                        <p className='text-sm text-gray7'>Preview and confirm before importing into the system.</p>
                    </div>
                    <div className='flex items-center gap-3'>
                        <Button
                            className='text-sm min-h-[44px] bg-transparent border-[#A8A8A8] border text-gray7'
                            onClick={() => {}}
                        >
                            Change CSV upload
                        </Button>
                        <Button
                            className='text-sm min-h-[44px]'
                            onClick={() => handleImport(form?.getValues())}
                            loading={isLoading}
                        >
                            Confirm CoA import
                        </Button>
                    </div>
                </div>
                <div className='mt-5 bg-white rounded-[20px] py-3 px-4'>
                    <div>
                        <h2 className='text-base font-medium'>Preview CoA csv import</h2>
                        <p className='text-xs text-gray7'>
                            Categorize accounts and sub-accounts or change names and ID of any account before import.
                        </p>
                    </div>
                    <div className='mt-5'>
                        <div className='rounded-lg border border-gray5 overflow-x-scroll mt-2 flex-grow  scrollbar-thumb-gray7 scrollbar-thin'>
                            <table className='min-w-[1800px] leading-normal border-collapse text-xs'>
                                <thead>
                                    <tr className='grid grid-cols-12'>
                                        {['Account name', 'ID', 'Type', 'Sub-type', 'Sub-account?'].map((col, i) => (
                                            <th
                                                key={i}
                                                className={cn(
                                                    'flex items-center border-gray5 px-5 h-10 whitespace-nowrap bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90',
                                                    col === 'Account name' || col === 'Sub-type'
                                                        ? 'col-span-2'
                                                        : 'col-span-1'
                                                )}
                                            >
                                                {col}
                                            </th>
                                        ))}
                                        <th className='flex col-span-2 items-center border-l border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                            Set parent
                                        </th>
                                        <th className='flex col-span-2 items-center border-l border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                            Description
                                        </th>
                                        <th className='flex col-span-1 items-center border-l border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((field, i) => (
                                        <SingleParentAccountAccordion
                                            field={field}
                                            key={i}
                                            i={i}
                                            form={form}
                                            remove={remove}
                                            update={update}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='mt-5 border-t border-gray8 px-7 py-4 flex gap-2 justify-end'>
                        <Button
                            className='text-sm min-h-[44px] bg-transparent border-[#A8A8A8] border text-gray7'
                            onClick={() => {}}
                        >
                            Change CSV upload
                        </Button>
                        <Button
                            className='text-sm min-h-[44px]'
                            onClick={() => handleImport(form?.getValues())}
                            loading={isLoading}
                        >
                            Confirm CoA import
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PreviewImportedCOA
