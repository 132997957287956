import { Button, Input } from '@/components'
import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import { motion } from 'framer-motion'
import { Search } from 'lucide-react'
import AccountingTableComponent from '../components/Accounting-Table/Accounting-Table'
import { useAccountingContext, useAuthContext } from '@/context'
import { useGetTransactions } from '@/services/transactions/queries'
import { ACCOUNTING_STATUS } from '@/types/accounting'
import ProgressBar from '@/components/ProgressBar'
import { useEffect, useMemo, useState } from 'react'
import { ExtendedRow } from '@/context/accounting'
import { Pagination } from '@/types/utils'
import { useGetAccountingTransactionAnalytics } from '@/services/accounting/queries'
import { ExportTransactions } from '@/components/Modals/Accounting/ExportTransactions/ExportTransactions'

const filterType = {
    all: 'all',
    review: ACCOUNTING_STATUS.NEEDS_REVIEW,
    ready: ACCOUNTING_STATUS.READY,
}
export type FormType = {
    rows: ExtendedRow[]
}

export default function AccountingTransactions() {
    const { all, ready, review } = filterType
    const [setAccoutingFilter, searchParamDetails] = useAddQueryParamsToURL<{ type: keyof typeof filterType }>()
    const subRoute = searchParamDetails.get('type') ?? all
    const { selectedAccount } = useAuthContext()
    const { rows: transactions, setRows, categoriesMappedToAccountData } = useAccountingContext()
    const [pageNumber, setPageNumber] = useState(1)
    const [pageNumberClicked, setPageNumberClicked] = useState(false)

    const { data, isLoading: isFetchingAnalytics } = useGetAccountingTransactionAnalytics()
    const [showExport, setShowExport] = useState(false)
    const [prevSubRoute, setPrevSubRoute] = useState(subRoute)

    const {
        data: trasactiondata,
        isLoading,
        refetch,
        isRefetching,
    } = useGetTransactions({
        queryParams: {
            limit: '12',
            page: pageNumber.toString(),
            account_type: 'Org',
            id: selectedAccount?.org?.id as string,
            status: 'COMPLETED',
            accounting_status: subRoute == 'all' ? '' : subRoute,
        },
        onSuccess: data => {
            const allTransaction = data?.results?.map(transaction => {
                const category = transaction?.category?.id ?? transaction?.category?._id
                const foundCategoryAccountMapped = categoriesMappedToAccountData?.filter(
                    mappedCategory => mappedCategory.category.id === category
                )?.[0]

                const affected_account =
                    transaction.ownerTransactionType === 'DEBIT'
                        ? transaction?.accounting_entry?.debit
                        : transaction?.accounting_entry?.credit
                const neededTransactionData = {
                    credit: transaction?.credit,
                    id: transaction?.id ?? transaction?._id,
                    type: transaction?.type,
                    recipient_type: transaction?.recipient_type,
                    owner: transaction?.owner,
                    metadata: transaction?.metadata,
                    createdAt: transaction?.createdAt,
                    ownerTransactionType: transaction?.ownerTransactionType,
                    debit: transaction?.debit,
                    accounting_status: transaction?.accounting_status,
                    receipt_urls: transaction?.receipt_urls,
                    affected_account: affected_account ?? foundCategoryAccountMapped?.account?.id,
                    category: transaction?.category?.id ?? transaction?.category?._id,
                }

                return {
                    ...neededTransactionData,
                }
            }) as unknown as ExtendedRow[]
            setRows(allTransaction)
        },
    })

    useEffect(() => {
        if (pageNumber && pageNumberClicked) {
            refetch()
            setPageNumberClicked(false)
        }
    }, [pageNumber, pageNumberClicked])

    useEffect(() => {
        if (subRoute !== prevSubRoute) {
            refetch()
            setPrevSubRoute(subRoute)
        }
    }, [subRoute, prevSubRoute, refetch])

    const orgTransactions = useMemo(() => transactions, [transactions]) as ExtendedRow[]

    const pagination = useMemo(() => trasactiondata?.pagination ?? ({} as Pagination), [trasactiondata])

    const filterDetailsBtn = [
        {
            desc: 'All',
            id: all,
            filterVal: data?.total_count,
        },
        {
            desc: 'Needs review',
            filterVal: data?.needs_review_count,
            id: review,
        },
        {
            desc: 'Ready for export',
            filterVal: data?.ready_count,
            id: ready,
        },
    ]

    if (isLoading) return <ProgressBar />
    return (
        <section className='mt-8'>
            <div className='flex items-center justify-between'>
                <div>
                    <h2 className='text-xl font-semibold'>Transactions</h2>
                    <p className='text-sm text-gray7'>
                        Track, manage, and export your financial transactions seamlessly
                    </p>
                </div>
                {subRoute === ready && (
                    <Button className='min-h-6' onClick={() => setShowExport(true)}>
                        Sync transactions
                    </Button>
                )}
            </div>
            <section className='mt-8 mb-4 bg-white p-4 rounded-xl'>
                <div className='overflow-x-scroll'>
                    <ul className={`flex items-center gap-4 mb-5 ${isFetchingAnalytics ? 'opacity-50' : ''}`}>
                        {filterDetailsBtn.map(btnDetails => (
                            <li
                                key={btnDetails.id}
                                className={`relative ${btnDetails.id === subRoute ? 'text-brand ' : 'bg-transparent text-[#5E5E5E]'} text-sm rounded-md p-2 px-3`}
                                onClick={() => setAccoutingFilter('type', btnDetails.id)}
                            >
                                <button className='flex gap-2 whitespace-nowrap'>
                                    {btnDetails.desc}{' '}
                                    {btnDetails?.filterVal && (
                                        <span
                                            className={`${btnDetails.id === subRoute ? 'bg-brand ' : ' bg-gray7 '} text-white text-xs px-3 p-0.5 rounded-xl`}
                                        >
                                            {btnDetails?.filterVal}
                                        </span>
                                    )}
                                </button>
                                {btnDetails.id === subRoute && (
                                    <motion.div
                                        layoutId='accounting-filter'
                                        className={
                                            'bg-brand/10 rounded  text-sm  w-full left-[1px] top-0  bottom-0 absolute'
                                        }
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <Input
                    placeholder='Search'
                    className='max-w-xs h-11 border-gray4'
                    leftIcon={<Search className='text-gray' />}
                />
                <div className={`mt-5 ${isRefetching ? 'opacity-50' : ''}`}>
                    <AccountingTableComponent
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        setPageNumberClicked={setPageNumberClicked}
                        pagination={pagination}
                        rows={orgTransactions}
                        refetchTransactions={() => refetch()}
                    />
                </div>
            </section>
            {showExport && (
                <ExportTransactions
                    closeModal={() => setShowExport(false)}
                    transactions={orgTransactions}
                    pagination={pagination}
                />
            )}
        </section>
    )
}
