import { BackIcon, BusinessDirectoryIcon, PersonalCardIcon } from '@/assets/assets.export'
import companylogo from '@/assets/logo.jpg'
import Logo from '@/assets/bizpendlogo.png'
import { useAuthContext } from '@/context'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import useFeatureFlag from '@/lib/feature-flags/useFeatureFlag'
import { cn } from '@/lib/styling'
import { useLogoutUser } from '@/services/auth/mutations'
import { useCheckIfAdminBizpendEmail } from '@/utils/bizpend'
import { isAdmin } from '@/utils/permissions'
import { ACCESS_PERMISSIONS } from '@/utils/permisson-helper'
import { isStagingEnvironment, prodEnvironment } from '@/utils/utils'
import { motion } from 'framer-motion'
import { ChevronDown, LogOutIcon } from 'lucide-react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import {
    SideBarProps,
    SidebarItemType,
    accountSidebarPermissions,
    contractSidebarPermissions,
    dashboardRoute,
    payrollSidebarPermissions,
    settingsRoute,
    sidebarRoute,
    vaultPermissons,
} from './utils'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'

const NewSidebar = ({ onRouteChange, setShowSidebar, isMobile, showSidebar }: SideBarProps) => {
    const { logoutUser, selectedAccount } = useAuthContext()
    const accessPermission = [
        ...((selectedAccount?.role?.permissions ?? []) as ACCESS_PERMISSIONS[]),
        ACCESS_PERMISSIONS.ACCESS_DASHBOARD,
        ACCESS_PERMISSIONS.ACCESS_SETTINGS,
    ]

    const navigate = useNavigate()
    const hasAdminPerm = isAdmin(selectedAccount!)
    const internalUseFeatureFlag = useFeatureFlag

    // const accountingEnabled = useFeatureFlag('show-accounting-module')

    const hasAccessToAccounts = useHasAccessPermisson(accountSidebarPermissions)
    const hasAccessToOrgPayroll = useHasAccessPermisson(payrollSidebarPermissions)
    const hasAccessToOrgContract = useHasAccessPermisson(contractSidebarPermissions)
    const hasAccessToVault = useHasAccessPermisson(vaultPermissons)

    const isBizpendAdmin = useCheckIfAdminBizpendEmail(selectedAccount?.user?.email)

    const pathname = window.location.pathname

    const { mutate: logoutFn } = useLogoutUser({
        onSuccess: () => {
            navigate('/')
            logoutUser()
        },
    })

    const handleLogOut = () => {
        logoutUser()
        logoutFn({})
    }

    const showOnlyIcon = !showSidebar && !isMobile

    return (
        <TooltipProvider delayDuration={0}>
            <motion.div
                className={`border-r border-[#DADCE0] h-screen min-h-screen bg-[#ffffff] flex flex-col overflow-scroll `}
                initial={{ width: showOnlyIcon ? 64 : 240 }}
                animate={{ width: showOnlyIcon ? 64 : 240 }}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
                <div className='flex-1'>
                    <motion.div
                        className={`px-2 py-3.5 relative border-b border-[#DADCE0] flex items-center ${showOnlyIcon ? 'flex-col-reverse' : 'flex-row justify-between'} h-[81px]`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        {!showOnlyIcon ? (
                            <motion.img
                                src={Logo}
                                alt='logo'
                                className='w-32'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.3 }}
                            />
                        ) : (
                            <motion.img
                                src={companylogo}
                                alt='logo'
                                className='object-cover w-6 mb-2'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.3 }}
                            />
                        )}
                        <button
                            aria-label='close-sidebar'
                            className={cn(
                                'bg-slate-50 rounded-full border border-gray3 p-0.5',
                                showOnlyIcon ? 'hidden' : ''
                            )}
                        >
                            <BackIcon
                                strokeWidth={2}
                                className={`cursor-pointer ${showOnlyIcon ? 'w-3 h-3' : 'w-5 h-5'} stroke-2`}
                                onClick={() => setShowSidebar?.(!showSidebar)}
                            />
                        </button>
                    </motion.div>

                    <div className='py-3 text-sm flex flex-col gap-1 px-2 '>
                        <SidebarLink {...dashboardRoute} showOnlyIcon={showOnlyIcon} onRouteChange={onRouteChange} />

                        {sidebarRoute.map(route => {
                            if (route?.enabled && !internalUseFeatureFlag(route?.enabled)) return
                            if (!hasAccessToAccounts && route?.key === 'accounts') return
                            if (route?.key === 'accounting' && !isStagingEnvironment()) return

                            if (!hasAccessToVault && route?.key === 'vault') return
                            if (route.key === 'vault' && prodEnvironment()) return

                            if (route?.permission?.some(perm => accessPermission.includes(perm))) {
                                // changing the route based on the permissions as either an admin or user
                                if (!hasAccessToOrgPayroll && route.key === 'payroll') {
                                    route.children = route?.children?.map(child => ({
                                        ...child,
                                        route:
                                            child.key === 'payroll'
                                                ? 'payroll/pay_history?accountType=personal'
                                                : 'payroll/user-leave?accountType=personal',
                                    }))
                                }
                                if (!hasAccessToOrgContract && route.key === 'contracts')
                                    route.route = 'contract/contract_list?accountType=personal'

                                return (
                                    <div className='mt-4' key={route.key}>
                                        <div className='flex gap-3 flex-col '>
                                            {route.key === 'payroll' && (
                                                <Accordion type='single' collapsible>
                                                    <AccordionItem value='item-1' className='border-none'>
                                                        <AccordionTrigger
                                                            className={cn(
                                                                'font-normal  py-2 px-2 ',
                                                                showOnlyIcon ? 'justify-center' : 'justify-between'
                                                            )}
                                                            righticon={
                                                                <ChevronDown
                                                                    className={`${showOnlyIcon ? 'hidden' : ''} w-4 h-4 chevron`}
                                                                />
                                                            }
                                                        >
                                                            <span className='flex items-center gap-3'>
                                                                {route.icon && (
                                                                    <route.icon
                                                                        className={'fill-white stroke-black/90'}
                                                                    />
                                                                )}
                                                                <span className={`${showOnlyIcon ? 'hidden' : ''}`}>
                                                                    Payroll
                                                                </span>
                                                            </span>
                                                        </AccordionTrigger>
                                                        <AccordionContent>
                                                            <div className=' ml-2.5 pl-2 border-l border-gray8/60 w-4/5'>
                                                                {route?.children?.map(routeChild => (
                                                                    <SidebarLink
                                                                        showOnlyIcon={showOnlyIcon}
                                                                        child
                                                                        onRouteChange={onRouteChange}
                                                                        {...routeChild}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </AccordionContent>
                                                    </AccordionItem>
                                                </Accordion>
                                            )}
                                            {route.key !== 'payroll' && (
                                                <SidebarLink
                                                    {...route}
                                                    showOnlyIcon={showOnlyIcon}
                                                    onRouteChange={onRouteChange}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>

                <div className='mt-2 '>
                    <div className='flex flex-col gap-3 px-3 py-5 border-t border-b border-[#DADCE0] text-sm mb-3'>
                        <SidebarLink
                            {...settingsRoute}
                            showOnlyIcon={showOnlyIcon}
                            route={!hasAdminPerm ? 'settings/user' : settingsRoute.route}
                            parentRoute={['settings']}
                            onRouteChange={onRouteChange}
                        />
                        <div
                            className={' py-2 px-2 flex items-center gap-3 cursor-pointer hover:bg-[#F9F6FF]'}
                            onClick={handleLogOut}
                        >
                            <Tooltip>
                                <TooltipTrigger type='button'>
                                    <LogOutIcon strokeWidth={1.2} size={19} />
                                </TooltipTrigger>

                                <TooltipContent
                                    side='right'
                                    align='center'
                                    hidden={!showOnlyIcon}
                                    className='bg-white text-gray7 rounded border-gray4 border shadow-sm'
                                >
                                    Log out
                                </TooltipContent>
                            </Tooltip>

                            <span className={`${showOnlyIcon ? 'hidden' : ''}`}>Log out</span>
                        </div>
                        {isBizpendAdmin && (
                            <div className='relative '>
                                {pathname.includes('/dashboard/business-directory') ? (
                                    <div className='w-full h-[60px] bg-[#f3eefb]'></div>
                                ) : (
                                    <BusinessDirectoryIcon
                                        className={`${showOnlyIcon ? 'hidden ' : ''} rounded-2xl w-full mx-auto`}
                                    />
                                )}
                                <button
                                    className={cn(
                                        location.pathname.includes(`/dashboard/business-directory`)
                                            ? `text-brand`
                                            : 'text-white',
                                        ' inset-1 flex items-center justify-center font-medium gap-3',
                                        showOnlyIcon ? 'block px-1.5' : 'absolute'
                                    )}
                                    onClick={() => {
                                        onRouteChange('Business Directory')
                                        navigate(`/dashboard/business-directory/manage_business`, {
                                            state: { show: isBizpendAdmin },
                                        })
                                    }}
                                >
                                    <Tooltip>
                                        <TooltipTrigger type='button'>
                                            <PersonalCardIcon
                                                className={cn(
                                                    location.pathname.includes('/dashboard/business-directory')
                                                        ? `fill-[#454ADE]`
                                                        : '',
                                                    showOnlyIcon ? 'stroke-gray7' : 'stroke-gray6'
                                                )}
                                            />
                                        </TooltipTrigger>

                                        <TooltipContent
                                            side='right'
                                            align='center'
                                            hidden={!showOnlyIcon}
                                            className='bg-white text-gray7 rounded border-gray4 border shadow-sm'
                                        >
                                            Business directory
                                        </TooltipContent>
                                    </Tooltip>

                                    <span className={`${showOnlyIcon ? 'hidden' : ''}`}> Business directory</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </motion.div>
        </TooltipProvider>
    )
}
export default NewSidebar

const SidebarLink = ({
    onRouteChange,
    showOnlyIcon,
    ...item
}: SidebarItemType & { onRouteChange: (a: string) => void; child?: boolean; showOnlyIcon: boolean }) => {
    const location = useLocation()
    const handleRouteSwitch = () => {
        onRouteChange(item.name)
    }

    const isActive = (route: string[] = []) => {
        return route.some(r => location.pathname.startsWith(`/dashboard/${r}`))
    }

    return (
        <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.3 }}>
            <Link
                to={item.route}
                className={cn(
                    isActive(item?.parentRoute) ? `text-[#454ADE] font-semibold` : ' text-[#202020]',
                    'flex items-center hover:bg-[#F9F6FF] relative px-2',
                    showOnlyIcon ? 'justify-center' : 'justify-start'
                )}
                onClick={handleRouteSwitch}
                id={`${item.name.toLowerCase()}-sidebar`}
            >
                {isActive(item?.parentRoute) && (
                    <motion.span
                        className={'absolute inset-0  bg-[#F9F6FF] rounded-lg text-[#454ADE]'}
                        layoutId='sidebar__links'
                    />
                )}
                <span
                    className={`py-2  ${showOnlyIcon ? '' : 'gap-3 flex items-center'} cursor-pointer hover:bg-[#F9F6FF] z-10 ${item?.child ? (!isActive(item?.parentRoute) ? 'text-gray7/90 !pl-4' : '!pl-4') : ''}`}
                >
                    <Tooltip>
                        <TooltipTrigger type='button'>
                            {item.icon && (
                                <item.icon
                                    className={cn(
                                        isActive(item?.parentRoute)
                                            ? ` fill-[#454ADE] stroke-[#fff]`
                                            : 'fill-[#fff] stroke-[#202020]',
                                        'z-10 w-5 h-5'
                                    )}
                                />
                            )}
                        </TooltipTrigger>

                        <TooltipContent
                            side='right'
                            align='center'
                            hidden={!showOnlyIcon}
                            className='bg-white text-gray7 rounded border-gray4 border shadow-sm'
                        >
                            {item.name}
                        </TooltipContent>
                    </Tooltip>

                    <span className={`${showOnlyIcon ? 'hidden' : ''}`}>{item.name}</span>
                </span>
            </Link>
        </motion.div>
    )
}
