import { UserOrgAccount } from '@/types/org'
import { createMutation } from '../api/mutation'
import { keyUser, orgKYBkey, orgRequestAccess } from './queries'
import { LoginResponse } from './types'

export const useCreateNewAdminUser = createMutation({
    url: '/auth/register-admin',
    method: 'POST',
})

export const useLoginUser = createMutation<LoginResponse>({
    url: '/auth/login',
    method: 'POST',
})

export const useVerifyAdminEmail = createMutation({
    url: '/auth/verify-email',
    method: 'POST',
})

export const useForgotPassword = createMutation({
    url: '/auth/forgot-password',
    method: 'POST',
})

export const useResetPassword = createMutation({
    url: '/auth/reset-password',
    method: 'POST',
})

export const useChangePassword = createMutation({
    url: '/auth/change-password',
    method: 'POST',
})

export const useCreateNewEmployeeUser = createMutation({
    url: '/accept-invite',
    method: 'POST',
})

export const useLogoutUser = createMutation({
    url: '/auth/logout',
    method: 'POST',
})

export const useUpdateProfile = createMutation({
    url: '/update-profile',
    method: 'PATCH',
    keysToRefetch: [keyUser],
})

export const useResendOtp = createMutation({
    url: '/auth/resend-otp',
    method: 'POST',
})

export const useMutateKYC = createMutation({
    url: '/auth/complete-kyc',
    method: 'POST',
    keysToRefetch: [keyUser],
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})

export const useMutateProfileImg = createMutation({
    url: '/upload-profile-image',
    method: 'POST',
    keysToRefetch: [keyUser],
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})

export const useAddNewBusiness = createMutation<UserOrgAccount>({
    url: '/auth/new-business',
    method: 'POST',
    keysToRefetch: [keyUser],
})

export const useUpdateCompanyProfile = createMutation({
    url: '/update-company-profile',
    method: 'PATCH',
    keysToRefetch: [keyUser],
})

export const useCompleteCompanyKYB = createMutation({
    url: '/auth/update-kyb',
    method: 'POST',
    keysToRefetch: [keyUser, orgKYBkey],
})

export const useCreateExternalCustomer = createMutation({
    url: '/external-customer',
    method: 'POST',
})

export const useConfirmToken = createMutation({
    url: '/auth/compare-reset-token',
    method: 'POST',
    keysToRefetch: [keyUser],
})

export const useSwitchAccount = createMutation({
    url: '/auth/switch-account',
    method: 'POST',
    keysToRefetch: [keyUser],
})

export const useAuthorizeCompanyKYB = createMutation({
    url: '/auth/complete-kyb',
    method: 'POST',
    keysToRefetch: [keyUser, orgKYBkey],
})

export const useRequestAccess = createMutation({
    url: '/access-request',
    method: 'POST',
})

export const useApproveRequestAccess = createMutation({
    url: '/approve-request',
    method: 'POST',
    keysToRefetch: [orgRequestAccess],
})
export const useDeclineRequestAccess = createMutation({
    url: '/access-request',
    method: 'DELETE',
    keysToRefetch: [orgRequestAccess],
})
export const useResendAccessrequest = createMutation({
    url: '/resend-access-request-email',
    method: 'POST',
    keysToRefetch: [orgRequestAccess],
})

export const useUploadCompanyLogo = createMutation({
    url: '/upload-logo',
    method: 'POST',
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
    keysToRefetch: [keyUser],
})

export const useGeneratePaymentOTP = createMutation({
    url: '/auth/generate-payment-otp',
    method: 'POST',
})

export const useVerifyPaymentOTP = createMutation({
    url: '/auth/verify-payment-otp',
    method: 'POST',
})
export const useConnectStripe = createMutation({
    url: '/auth/stripe-connect',
    method: 'POST',
    keysToRefetch: [keyUser],
})

export const useDisconnectConnectStripe = createMutation({
    url: '/auth/stripe-disconnect',
    method: 'POST',
    keysToRefetch: [keyUser],
})

export const useDisconnectConnectPaystack = createMutation({
    url: '/auth/paystack-disconnect',
    method: 'POST',
    keysToRefetch: [keyUser],
})
