import { transactionDestination } from '@/modules/dashboard/Transactions/components/utils'
import { TransactionType } from '@/types/transactions'
import { formatAmountNoSymbol } from '@/utils/money'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import Table from '@/components/Table'
import TransactionEmptyStateIcon from '@/assets/v2dashboard/recent-transactions.svg'
import { Pagination } from '@/types/utils'
import CheckBox from '@/components/Table/CheckBox'
import { useAccountingContext } from '@/context'

interface TransactionHistoryProps {
    transactions: TransactionType[]
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
    setPageNoClicked: React.Dispatch<React.SetStateAction<boolean>>
    pagination: Pagination | undefined
    setSelectedTransactions: React.Dispatch<React.SetStateAction<TransactionType[]>>
}

export const ExportTransactionsTable = ({
    transactions,
    pageNumber,
    setPageNumber,
    setPageNoClicked,
    pagination,
    setSelectedTransactions,
}: TransactionHistoryProps) => {
    const columnHelper = createColumnHelper<TransactionType>()
    const [rowSelection, setRowSelection] = useState({})
    const { chartOfAccountData } = useAccountingContext()

    useEffect(() => {
        if (Object.keys(rowSelection).length) {
            setSelectedTransactions(
                transactions.filter((_, index) => {
                    return rowSelection[index as keyof typeof rowSelection]
                })
            )
        }
    }, [rowSelection, transactions, setSelectedTransactions])
    const columns: ColumnDef<TransactionType, any>[] = [
        columnHelper.accessor('credit.account.user.first_name', {
            header: () => <p>Destination</p>,
            cell: cell => {
                const transaction = cell?.row?.original
                return <span className='font-normal '>{transactionDestination(transaction)?.destination}</span>
            },
            enableSorting: false,
        }),
        columnHelper.accessor('affected_account', {
            header: () => <p>Account</p>,
            cell: cell => {
                const accountName = chartOfAccountData.find(
                    coa => (coa._id ?? coa?.id) === cell.renderValue()
                )?.account_name
                return (
                    <span className='whitespace-nowrap text-xs font-medium opacity-80'>
                        {accountName?.split(' - ')[0] ?? accountName}
                    </span>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('debit.amount', {
            header: () => <p>Amount</p>,
            cell: cell => {
                const debitAmt = cell.renderValue()
                return (
                    <span className='font-normal whitespace-nowrap'>
                        {cell.row.original.ownerTransactionType === 'DEBIT' ? (
                            <span className='text-[#CF491E]'>
                                <span className='font-medium'>{cell.row.original?.currency?.symbol ?? '₦'}</span>
                                {formatAmountNoSymbol(debitAmt)}
                            </span>
                        ) : (
                            <span className='text-[#19C37D]'>
                                <span className='font-medium'>{cell.row.original?.currency?.symbol ?? '₦'}</span>

                                {formatAmountNoSymbol(cell.row.original.credit.amount)}
                            </span>
                        )}
                    </span>
                )
            },
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'select',
            header: ({ table }) => (
                <CheckBox
                    {...{
                        checked: table?.getIsAllRowsSelected(),
                        indeterminate: table?.getIsSomeRowsSelected(),
                        onChange: table?.getToggleAllRowsSelectedHandler(),
                    }}
                />
            ),
            cell: ({ row }) => (
                <CheckBox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />
            ),
            enableSorting: false,
        }),
    ]
    // const handleRowCheck = (transaction: TransactionType) => {}

    return (
        <div className=''>
            <Table
                data={transactions}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                rowClickable={true}
                emptyStateTitle='No transactions'
                emptyStateDescription='No transaction is ready for export yet'
                emptyStateImg={TransactionEmptyStateIcon}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                pagination_data={pagination}
                setIsPageNumberClicked={setPageNoClicked}
            />
        </div>
    )
}
