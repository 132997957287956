import CheckoutImg from '@/assets/new-sidebar-img.png'
import Logo from '@/assets/bizpendlogo.png'
// import { InvoiceCheckoutSVG } from '@/assets/assets.export'
import { Button } from '@/components'
import { Link, useNavigate } from 'react-router-dom'

const InvoicePaymentSidebar = () => {
    const navigate = useNavigate()
    return (
        <div className='bg-[#EFEFF9] h-full'>
            <div className={` border-r border-[#DADCE0] h-full  bg-[#EFEFF9]`}>
                <div className='p-3 lg:p-8 py-3 lg:py-4 border-b border-[#DADCE0]'>
                    <div className=''>
                        <img src={Logo} alt='logo' className='w-[137px]' />
                    </div>
                </div>

                <div className='p-4 lg:p-8 pt-8 flex flex-col justify-between text-2xl xl:text-3xl leading-[2.5rem]'>
                    <p>
                        Businesses of all sizes use <span className='text-brand'>Bizpend</span> for their invoice
                        issuing
                    </p>

                    <div className='mt-8 relative'>
                        {/* <InvoiceCheckoutSVG className='w-full max-h-[40rem]' /> */}
                        <img src={CheckoutImg} alt='' className='max-h-[33rem] 3xl:max-h-[40rem]' />
                        <div className='absolute w-full  flex flex-col lg:flex-row -bottom-40 xl:-bottom-20 gap-6 flex-wrap'>
                            <Button variant={'outline'}>
                                <Link target='_blank' to={'https://bizpend.com'}>
                                    Learn more
                                </Link>
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate('/request-access')
                                }}
                            >
                                Start using Bizpend
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoicePaymentSidebar
