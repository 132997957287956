import { ChevronRight, Link2 } from 'lucide-react'
import PageContent from '@/layout/PageContent'
import { useNavigate } from 'react-router-dom'
import stripeImg from '@/assets/v2dashboard/Stripe.svg'
import paypalImg from '@/assets/v2dashboard/Paypal.svg'
import paystackImg from '@/assets/v2dashboard/Paystack.svg'
import { useState } from 'react'
import { cn } from '@/lib/styling'
import { Disconnect } from '@/assets/assets.export'
import { toastHandler } from '@/components/utils/Toast'
import { isStagingEnvironment } from '@/utils/utils'

export default function InvoicePaymentSettings() {
    const navigate = useNavigate()
    const [paymentPlatforms, setPaymentPlatforms] = useState(platforms)

    return (
        <PageContent>
            <div className='flex items-center gap-2 mt-5'>
                <button onClick={() => navigate(-1)} className='font-medium '>
                    Settings
                </button>
                <ChevronRight size={18} className='text-gray7' />
                <span className='text-sm text-gray1 '>Online payment</span>
            </div>
            <section className='px-4 lg:px-0 mt-10'>
                <div className='mb-2 lg:mb-6'>
                    <h1 className='text-dark font-medium text-lg'>Online payment</h1>
                    <p className='text-gray7 text-sm'>
                        Connect and manage payment processors to receive payments for your invoices
                    </p>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                    {paymentPlatforms?.map((platform, idx) => {
                        if (platform.name === 'Paypal' && !isStagingEnvironment()) return
                        return (
                            <div key={idx} className='bg-white p-3 rounded-xl'>
                                <div className='flex items-center gap-3 border-b border-gray8 pb-3'>
                                    <img src={platform?.logo} alt={`${platform?.name} logo`} />
                                    <div className='py-1'>
                                        <p className='flex items-center gap-2 text-gray7 font-semibold text-sm'>
                                            {platform?.name}{' '}
                                            <span className='bg-gray8 h-2 w-2 rounded-full'>&nbsp;</span>{' '}
                                            <span
                                                className={cn(
                                                    'text-xs',
                                                    platform?.connected
                                                        ? 'text-[#19C37D] font-semibold'
                                                        : 'text-[#838383] font-medium'
                                                )}
                                            >
                                                {platform?.connected ? 'Connected' : 'Not connected'}
                                            </span>
                                        </p>
                                        <p
                                            className={cn(
                                                'text-xs font-medium',
                                                platform?.connected ? 'text-gray7' : 'text-[#A8A8A8]'
                                            )}
                                        >
                                            {platform?.connected
                                                ? 'Covercorp solution - STRP1709'
                                                : `${platform?.name} ID will show here`}
                                        </p>
                                    </div>
                                </div>
                                <div className='flex items-center justify-center pt-3'>
                                    <div
                                        className='flex items-center gap-1 text-xs text-brand font-semibold cursor-pointer'
                                        onClick={() => {
                                            setPaymentPlatforms(field => {
                                                return field.map(data => {
                                                    if (data.name === platform.name) {
                                                        return { ...data, connected: !data?.connected }
                                                    }
                                                    return data
                                                })
                                            })
                                            toastHandler({
                                                state: 'success',
                                                message: `${platform?.name} is ${platform?.connected ? 'disconnected' : 'connected'} successfully`,
                                            })
                                        }}
                                    >
                                        {platform?.connected ? (
                                            <>
                                                <Disconnect /> Disconnect{' '}
                                            </>
                                        ) : (
                                            <>
                                                <Link2 /> Connect
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
        </PageContent>
    )
}

const platforms = [
    {
        logo: stripeImg,
        name: 'Stripe',
        connected: true,
    },
    {
        logo: paypalImg,
        name: 'Paypal',
        connected: false,
    },
    {
        logo: paystackImg,
        name: 'Paystack',
        connected: false,
    },
]
