import { ArrowRight } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

const InvoiceSettings = () => {
    const navigate = useNavigate()
    return (
        <section className='px-4 lg:px-0'>
            <div className='mb-2 lg:mb-6'>
                <h1 className='text-dark font-medium text-lg'>Settings</h1>
                <p className='text-gray7 text-sm'>Manage appearance and approval policies for invoice issuing</p>
            </div>

            <ul className='flex gap-4 lg:gap-8 mt-10 lg:mt-20 flex-wrap'>
                {navOptions.map(option => (
                    <li
                        onClick={() => navigate(option?.id as string)}
                        className='cursor-pointer bg-white p-4 rounded-xl flex justify-between items-center gap-4 flex-1 min-w-full md:min-w-[45%]'
                    >
                        <div>
                            <h3 className='text-dark font-medium text-base'>{option?.heading}</h3>
                            <p className='text-gray7 text-[0.8rem]'>{option?.description}</p>
                        </div>
                        <ArrowRight />
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default InvoiceSettings

const navOptions = [
    {
        id: 'policy',
        heading: 'Approval Policy',
        description: 'Set admins to approve invoices before they are sent to customers',
    },
    {
        id: 'appearance',
        heading: 'Appearance',
        description: 'Customize the look of the invoices you want to issue',
    },
    {
        id: 'payment',
        heading: 'Online payment',
        description: 'Connect a payment processor to receive invoice payments',
    },
    {
        id: 'tax-items',
        heading: 'Tax items',
        description: 'Create and manage invoice taxes',
    },
]
