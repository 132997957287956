import { createQuery } from '../api/query'
import { QueryOrgCategoryRes, QueryOrgMappedCategoryRes } from './types'

export const orgCategoryKey = ['/org-category']
export const orgCategoryMapping = ['category-mapping']

export const useGetOrgCategories = createQuery<QueryOrgCategoryRes>({
    url: '/categories',
    key: orgCategoryKey,
})

export const useGetCategoriesMapping = createQuery<QueryOrgMappedCategoryRes>({
    url: '/category-mappings',
    key: orgCategoryMapping,
})
