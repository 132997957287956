import { QueryBankAccountsRes } from '../accounts/types'
import { createQuery } from '../api/query'
import { keyGetBankDetails } from './mutations'
import { QueryGetAllBanks } from './types'

export const useGetListOfBanks = createQuery<QueryGetAllBanks>({
    key: ['/bank-list'],
    url: '/get-list-of-banks',
})

export const useGetAccountsForUser = createQuery<QueryBankAccountsRes>({
    url: '/get-accounts-for-user',
    key: keyGetBankDetails,
})
