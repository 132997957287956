import { cn } from '@/lib/styling'
import { ArrowRight, TrendingDown, TrendingUp } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { Link } from 'react-router-dom'
import { useState } from 'react'

type CardsType = {
    card: {
        heading: string
        amount: string
        action?: string
        change?: string
        subText: string
        options: string[]
        miniText?: string
    }
    idx: number
}
export const OverviewCards = ({ card, idx }: CardsType) => {
    const [option, setOption] = useState(card?.options?.[0] ?? '')
    return (
        <div className='bg-white border border-gray8 rounded-[12px] p-4'>
            <div
                className={cn(
                    '',
                    idx === 0 && 'border-[#A8A8A8]',
                    idx === 1 && 'border-[#19C37D]',
                    idx === 2 && 'border-[#CF491E]',
                    idx < 3 ? 'border-l-2 pl-3' : 'border-none'
                )}
            >
                <div className='flex justify-between items-start'>
                    <p className='text-gray7 font-semibold mb-1'>{card?.heading}</p>
                    {card?.options?.length > 0 && (
                        <div>
                            <Select onValueChange={setOption} value={option}>
                                <SelectTrigger
                                    className='min-w-[88px] bg-offwhite border-gray8 text-[#5E5E5E]'
                                    id='transactionYear'
                                >
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className='h-fit max-h-[10rem] w-[7rem]'>
                                    {card?.options?.map((option: any) => (
                                        <SelectItem value={option} className='pl-3' key={option}>
                                            <h6 className='text-sm'>{option}</h6>
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    )}
                </div>
                <p className='text-[2rem] text-black font-medium'>
                    {card?.amount} <span className='text-xs font-bold text-[#A8A8A8]'>{card?.miniText}</span>
                </p>
            </div>
            <div className='border-t border-gray8 mt-3 pt-4'>
                {card?.change ? (
                    <p className='text-sm text-gray7 font-semibold flex gap-2 items-center'>
                        <span
                            className={cn(
                                'mr-2 flex items-center gap-0.5',
                                card?.change?.[0] !== '-' ? 'text-[#19C37D]' : 'text-errorRed'
                            )}
                        >
                            {card?.change?.[0] !== '-' ? (
                                <TrendingUp size={18} />
                            ) : (
                                <TrendingDown className='transform scale-x-[-1]' size={18} />
                            )}
                            {card?.change?.[0] === '-' ? card?.change?.slice(1) : card.change}
                        </span>
                        {card?.subText}
                    </p>
                ) : (
                    <Link
                        to={card?.action ? card?.action : ''}
                        className='flex w-full justify-between items-center text-sm font-semibold cursor-pointer'
                    >
                        <p className='text-brand'>View accounts</p>
                        <ArrowRight color='#454ade' />
                    </Link>
                )}
            </div>
        </div>
    )
}
