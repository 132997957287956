import { Button, Input } from '@/components'
import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import { motion } from 'framer-motion'
import { Search, Upload } from 'lucide-react'
import { Menu } from '@headlessui/react'

import { useGetCOA } from '@/services/accounting/queries'
import { useEffect, useMemo, useRef, useState } from 'react'
import ImportChartOfAccount from '@/components/Modals/Accounting/ImportChartOfAccount'
import ModalWrapper from '@/components/Modals/ModalWrapper'
import { Pagination } from '@/types/utils'
import AddNewAccountModal from '@/components/Modals/Accounts/AddNewAccountModal'
import { useAccountingContext } from '@/context'
import { exportCOAFormats } from '../utils'
import { CgSpinner } from 'react-icons/cg'
import useClickOutside from '@/hooks/useClickOutside'
import { downloadCSV } from '@/utils/utils'
import { createApiRequest } from '@/services/api/createApiRequest copy'
import NewChartsOfAccountTable from '../components/NewChartsOfAccountTable'
import { Quickbooks } from '@/assets/assets.export'
import QuickbooksIntegration from '@/components/Modals/Accounting/QuickbooksIntegration/QuickbooksIntegration'
import ProgressBar from '@/components/ProgressBar'
import { useSearchParams } from 'react-router-dom'

export default function AccountingChartOfAccount() {
    const [setAccoutingFilter, searchParamDetails] = useAddQueryParamsToURL<{ account_type: string }>()
    const { accounttingTypes } = useAccountingContext()
    const subRoute = searchParamDetails.get('account_type') ?? accounttingTypes?.[0]?._id
    const [openImportModal, setOpenImportModal] = useState(false)
    const [openIntegrationModal, setOpenIntegrationModal] = useState(false)
    const [openAddNewAccount, setOpenAddNewAccount] = useState(false)
    const [activeTab, setActiveTab] = useState('all')
    const [uploadDropdown, setUploadDropdown] = useState(false)
    const [isExporting, setIsExporting] = useState(false)

    const uploadDropdownRef = useRef<HTMLDivElement>(null)
    useClickOutside(uploadDropdownRef, () => setUploadDropdown(false))
    const [params] = useSearchParams()

    const { data: coaData, isLoading } = useGetCOA()

    const chartOfAccountData = useMemo(() => coaData?.results ?? [], [coaData, subRoute])
    const chartOfAccountDataToDisplay = useMemo(
        () =>
            coaData?.results
                ?.filter(coa => subRoute === 'all' || coa?.account_type?.parent?.id === subRoute)
                ?.sort((a, b) => Number(a.is_mutable) - Number(b.is_mutable)) ?? [],
        [coaData, subRoute]
    )

    const pagination = useMemo(() => coaData?.pagination ?? ({} as Pagination), [coaData])
    const analytics = useMemo(() => coaData?.analytics ?? [], [coaData])

    const filterDetailsBtn = [
        { desc: 'All', id: 'all', filterVal: chartOfAccountData?.length },
        ...accounttingTypes.map(type => ({
            desc: type?.name,
            id: type?._id,
            filterVal: analytics.find(data => data.account_type === type?._id)?.count,
        })),
    ]

    const handleExportCOA = async (format: string) => {
        try {
            setIsExporting(true)
            let response: Blob

            if (activeTab === 'all') {
                response = await createApiRequest<Blob>({
                    url: '/accounting/export-accounts',
                    method: 'POST',
                    data: { file_type: format },
                    apiOptions: { responseType: 'blob' },
                })
            } else {
                response = await createApiRequest<Blob>({
                    url: '/accounting/export-current-page',
                    method: 'POST',
                    data: { file_type: format, ids: chartOfAccountDataToDisplay },
                    apiOptions: { responseType: 'blob' },
                })
            }

            setUploadDropdown(false)
            downloadCSV(response, 'accounts.csv')
        } catch (error) {
            console.error('Export failed:', error)
        } finally {
            setIsExporting(false)
        }
    }
    useEffect(() => {
        if (params.get('step')) {
            setOpenIntegrationModal(true)
        }
    }, [])

    if (isLoading)
        return (
            <div>
                <ProgressBar />
            </div>
        )

    return (
        <section className='mt-8'>
            <div className='flex flex-col lg:flex-row gap-5 justify-between'>
                <div>
                    <h2 className='text-xl font-semibold'>Chart of accounts</h2>
                    <p className='text-sm text-gray7'>Organize and manage your financial accounts seamlessly</p>
                </div>
                <div className='flex items-center gap-3'>
                    <Button className='text-sm min-h-[35px]' onClick={() => setOpenAddNewAccount(true)}>
                        Add new account
                    </Button>
                    <Menu as='div' id='create-expense'>
                        <Menu.Button className='bg-transparent text-[#5E5E5E] font-medium border rounded-md p-0 mt-2 md:mt-0 px-4 py-2  text-sm min-w-full text-center'>
                            Import Chart of Accounts
                        </Menu.Button>
                        <Menu.Items className='w-[226px] flex flex-col absolute p-3 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'>
                            <p className='text-sm font-medium mb-3'>Import via</p>
                            <Menu.Item>
                                <span
                                    className='manual-expense font-medium px-0 py-2 text-gray7 text-sm cursor-pointer flex justify-between items-center'
                                    onClick={() => {
                                        setOpenIntegrationModal(true)
                                    }}
                                >
                                    Quickbooks
                                    <Quickbooks />
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    className='text-sm flex-1 text-gray7 flex items-center gap-2 border-t border-gray5 mt-2 pt-2 font-medium cursor-pointer'
                                    onClick={() => {
                                        setOpenImportModal(true)
                                    }}
                                >
                                    <Upload size={16} />
                                    Manually upload as CSV
                                </span>
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>
                </div>
            </div>
            <section className='mt-8 bg-white p-4 rounded-xl mb-16'>
                <div className='flex items-center justify-between mb-9 gap-4'>
                    <div className='overflow-x-scroll'>
                        <ul className='flex items-center gap-4 bg-gray5/20 w-fit p-2 rounded-xl flex-wrap'>
                            {filterDetailsBtn.map(btnDetails => (
                                <li
                                    className={`relative ${btnDetails.id === subRoute ? 'text-brand ' : 'bg-transparent text-[#5E5E5E]'} text-sm rounded-md p-2 px-3`}
                                    onClick={() => setAccoutingFilter('account_type', btnDetails?.id)}
                                >
                                    <button className='flex gap-2'>
                                        <span className='z-10 text-gray7'>{btnDetails.desc} </span>
                                        {/* {btnDetails?.filterVal && ( */}
                                        <span
                                            className={`${btnDetails.id === subRoute ? 'bg-brand ' : ' bg-gray7 '} text-white text-xs px-3 p-0.5 rounded-xl z-10`}
                                        >
                                            {btnDetails?.filterVal}
                                        </span>
                                        {/* )} */}
                                    </button>
                                    {btnDetails.id === subRoute && (
                                        <motion.div
                                            layoutId='accounting-coa-filter'
                                            className={
                                                'bg-white rounded  text-sm  w-full left-[1px] top-0  bottom-0 absolute z-0'
                                            }
                                        />
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='flex items-center justify-end gap-3'>
                        <Input
                            placeholder='Search'
                            className='max-w-xs h-11 border-gray4'
                            bodyClassName='w-fit'
                            leftIcon={<Search className='text-gray' />}
                        />

                        <div className='relative' ref={uploadDropdownRef}>
                            <button
                                aria-label='Upload'
                                onClick={() => setUploadDropdown(prev => !prev)}
                                className='rounded border-gray1/50 h-11 px-3 border'
                            >
                                <Upload className='text-gray7' size={20} />
                            </button>

                            {uploadDropdown && (
                                <div className='w-52 flex flex-col gap-3 absolute right-0 p-4 rounded-lg justify-center border border-gray/30 bg-white mt-2 z-10'>
                                    {isExporting ? (
                                        <CgSpinner
                                            className='animate-spin text-gray7 self-center'
                                            strokeWidth={1.2}
                                            size={40}
                                        />
                                    ) : (
                                        <>
                                            <div className='flex gap-4 mb-2'>
                                                {['All', 'Current page'].map(data => (
                                                    <div
                                                        className={`relative text-sm rounded-md p-1.5 px-3`}
                                                        onClick={() => setActiveTab(data.toLowerCase())}
                                                    >
                                                        {activeTab == data.toLowerCase() && (
                                                            <motion.span
                                                                layoutId='exports_animation'
                                                                className='bg-brand/20 rounded-md  text-sm  w-full left-[1px] top-0  bottom-0 absolute z-0'
                                                            />
                                                        )}
                                                        <button className='flex gap-2'>
                                                            <span
                                                                className={`z-10 text-sm ${activeTab == data.toLowerCase() ? 'font-medium' : 'text-gray7'}`}
                                                            >
                                                                {data}{' '}
                                                            </span>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                            {exportCOAFormats.map(format => (
                                                <div>
                                                    <span
                                                        onClick={() => handleExportCOA(format.id)}
                                                        className='cursor-pointer text-gray7 font-medium text-sm'
                                                    >
                                                        {format.name}
                                                    </span>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={`mt-5 ${isLoading ? 'opacity-30' : ''}`}>
                    {/* <ChartOfAccountsTable coa={chartOfAccountDataToDisplay} pagination={pagination} /> */}
                    <NewChartsOfAccountTable coa={chartOfAccountDataToDisplay} pagination={pagination} />
                </div>
            </section>

            {openImportModal && (
                <ModalWrapper>
                    <ImportChartOfAccount closeModal={() => setOpenImportModal(false)} />
                </ModalWrapper>
            )}
            {openAddNewAccount && <AddNewAccountModal closeModal={() => setOpenAddNewAccount(false)} />}
            {openIntegrationModal && <QuickbooksIntegration closeModal={() => setOpenIntegrationModal(false)} />}
        </section>
    )
}
