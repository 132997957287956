import { toastHandler } from '@/components/utils/Toast'
import { useCreateSignedUrl } from '@/services/file-upload/mutation'
import { useState } from 'react'
import { generateFileName, ModuleNameEnum } from './useSingleFileUpload'
import { useAuthContext } from '@/context'

type Props = {
    name: string
    onUploadComplete: (urls: { url: string; name: string; size: number }[]) => void
    maxFiles?: number
}

export const useSignedUrlUpload = ({ name, maxFiles = 5, onUploadComplete }: Props) => {
    const [files, setFiles] = useState<File[]>([])
    const [receiptUrls, setReceiptUrls] = useState<{ url: string; name: string; size: number }[]>([])

    const { selectedAccount } = useAuthContext()
    const { mutate, isLoading } = useCreateSignedUrl({
        onSuccess: async dataFromSer => {
            const newUploadedReceipts: typeof receiptUrls = []

            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                const signedUrl = dataFromSer[i]?.signedUrl

                if (signedUrl) {
                    await fetch(signedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'Content-Type': file.type,
                            'Content-Length': String(file.size),
                        },
                    })

                    newUploadedReceipts.push({
                        url: dataFromSer[i]?.url,
                        name: file.name,
                        size: file.size,
                    })
                }
            }

            setReceiptUrls(prev => [...prev, ...newUploadedReceipts])
            onUploadComplete([...receiptUrls, ...newUploadedReceipts])
            setFiles([])
        },
    })

    const handleGetSignedURLs = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files ? Array.from(e.target.files) : []

        if (selectedFiles.length > maxFiles) {
            toastHandler({ message: `You can only upload up to ${maxFiles} files.`, state: 'error' })
            return
        }

        if (receiptUrls.length + selectedFiles.length > maxFiles) {
            toastHandler({ message: `You can only upload up to ${maxFiles} files in total.`, state: 'error' })
            return
        }

        setFiles(selectedFiles)

        mutate({
            files: selectedFiles.map(file => ({
                fileName: generateFileName({
                    module: ModuleNameEnum.TRANSACTION,
                    unique_identifier: `${name}-${file.name.split('.').join('-')}`,
                    org_id: selectedAccount?.org?.id ?? '',
                    selectedFileName: file.name,
                }),
                contentType: file.type,
            })),
            module: ModuleNameEnum.TRANSACTION,
        })
    }

    return { files, receiptUrls, isLoading, handleGetSignedURLs }
}
