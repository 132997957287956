export const subRouteHelper = {
    profile: 0,
    // kyb: 1,
    // security: 2,
    // 'plan-pricing': 3,
    // 'plan-usage': 3,
    help: 4,
    notification: 5,
    terms: 6,
    policy: 7,
}

export const CompanySettingsHeading = {
    profile: 'Company Profile',
    kyb: 'Compliance',
    security: 'Security',
    'plan-pricing': 'Subscription',
    'plan-usage': 'Subscription',
    help: 'Help',
    notification: 'Notification',
    terms: 'Terms',
    policy: 'Policy',
}

export const account_settings = [
    {
        title: 'Company profile',
        title_exp: "Explore your company's  profile, edit your details!",
        type: 'profile',
        to: 'profile',
    },
    // {
    //     title: 'Compliance',
    //     title_exp: 'Manage KYB documents and legal business details',
    //     type: 'kyb',
    //     to: 'kyb',
    // },
    // {
    //     title: 'Permissions',
    //     title_exp: 'Control and manage access to specific modules.',
    //     // title_exp: 'Manage Security: change company password, enable 2FA!',
    //     type: 'security',
    //     to: 'security',
    // },
    // {
    //     title: 'Subscription',
    //     title_exp: 'Manage your subscriptions',
    //     type: 'payment',
    //     to: 'plan-pricing',
    // },
    {
        title: 'Notification',
        title_exp: 'Stay updated: Personalize your notifications.',
        type: 'notification',
        to: 'notification',
    },
    {
        title: 'Help & support',
        title_exp: 'Reach out to our support team. Customer support is up 24/7.',
        type: 'help',
        to: 'help',
    },
    {
        title: 'Terms of service',
        title_exp: 'Review our terms of service.',
        type: 'terms_of_service',
        to: 'terms',
    },
    {
        title: 'Privacy policy',
        title_exp: 'Review our privacy policy.',
        type: 'policy',
        to: 'policy',
    },
]
export const spend_control = [
    {
        title: 'Expenses',
        title_exp: "Explore your company's  profile, edit your details!",
        type: 'profile',
        to: 'profile',
    },
]
