import { useCurrencyContext } from '@/context'
import { findMonthWithHighestTotal } from '@/modules/dashboard/Transactions/components/utils'
import { NewMonthlySummary } from '@/services/transactions/types'
import { formatAmount } from '@/utils/money'
import { AbbrvFormatNumber } from '@/utils/utils'
import React, { useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer } from 'recharts'
import { defaultHighest } from './MinimalAreaChat'
import { OverAPeriodIcon } from '@/assets/assets.export'

export type CustomBarTooltipProps = {
    active?: any
    payload?: any
    label?: any
}

type Props = {
    chartData: NewMonthlySummary[]
    currency: string
    size: string
    type: string
    emptyText?: string
}

const MinimalBarChart = ({ chartData, currency, type, size, emptyText }: Props) => {
    const [activeIndex, setActiveIndex] = useState(null)
    const { selectedCurrencyInfo } = useCurrencyContext()
    const total = chartData.reduce(
        (accumulator, currentValue) => accumulator + (Number(currentValue?.total ?? 0) ?? 0),
        0
    )

    const highestMonth = total > 0 ? findMonthWithHighestTotal(chartData) : defaultHighest

    const CustomTooltip = ({ active, payload, label }: CustomBarTooltipProps) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className='bg-offwhite p-3 shadow-md rounded-xl'
                    style={{ boxShadow: '0px 2px 100px 0px #2F2F2F6B ' }}
                >
                    <p className='text-center text-xs font-bold text-[#838383]'>{label} 2025</p>
                    <p className='text-center font-semibold text-sm text-[#202020] mt-1'>
                        {type === 'count'
                            ? payload?.[0]?.payload?.total
                            : type === 'amount'
                              ? formatAmount(payload[0]?.payload?.total)
                              : `${payload?.[0]?.payload?.total}%`}
                    </p>
                </div>
            )
        }
        return null
    }

    const handleMouseOver = (_: any, index: any) => {
        setActiveIndex(index)
    }

    const handleMouseLeave = () => {
        setActiveIndex(null)
    }

    return (
        <>
            {total === 0 && emptyText ? (
                <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                    <OverAPeriodIcon />
                    <p className='text-sm mt-6'>{emptyText}</p>
                </div>
            ) : (
                <ResponsiveContainer height={size === 'L' ? 281 : 221} width={'100%'} className={'mx-auto'}>
                    <BarChart
                        data={chartData}
                        margin={{ left: size === 'L' ? 40 : 15 }}
                        onMouseLeave={handleMouseLeave}
                    >
                        <defs>
                            <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                                <stop offset='5%' stopColor='#3C26B5' stopOpacity={1} />
                                <stop offset='95%' stopColor='#8583E1' stopOpacity={0.9} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke='#DADCE0' vertical={false} strokeDasharray='5 5' fill='white' />
                        <XAxis dataKey='name' axisLine={false} tickLine={false} tick={{ fill: '#666' }} fontSize={12} />
                        <YAxis
                            tickFormatter={total =>
                                type === 'amount'
                                    ? `${selectedCurrencyInfo(currency)?.symbol}${AbbrvFormatNumber(total)}`
                                    : total
                            }
                            axisLine={false}
                            tickLine={false}
                            tick={{ fill: '#666' }}
                            domain={[0, Number(highestMonth?.monthWithHighestIncomingTotal?.incomingTotal)]}
                            label={{
                                value:
                                    type === 'amount'
                                        ? selectedCurrencyInfo(currency)?.name
                                        : type === 'count'
                                          ? 'Count'
                                          : 'Percentage',
                                angle: -90,
                                position: 'insideRight',
                                offset: type === 'amount' ? 90 : 60,
                            }}
                            offset={10}
                            hide={false}
                            stroke='#888888'
                            fontSize={12}
                        />
                        <Tooltip content={<CustomTooltip />} cursor={false} />
                        <Bar
                            barSize={size === 'L' ? 32 : 27}
                            dataKey='total'
                            radius={[4, 4, 0, 0]}
                            onMouseOver={handleMouseOver}
                        >
                            {chartData.map((_, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={index === 3 || index === activeIndex ? 'url(#colorUv)' : '#F2F2F2'}
                                    className='cursor-pointer'
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            )}
        </>
    )
}

export default MinimalBarChart
