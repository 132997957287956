import { createMutation } from '../api/mutation'
import { keyQueryOrgTaxes } from './queries'

export const useMutationCreateTax = createMutation({
    url: '/tax',
    keysToRefetch: [keyQueryOrgTaxes],
    method: 'POST',
})

export const useMutationUpdateTax = createMutation({
    url: '/tax',
    keysToRefetch: [keyQueryOrgTaxes],
    method: 'PATCH',
})

export const useMutationDeleteTax = createMutation({
    url: '/tax',
    keysToRefetch: [keyQueryOrgTaxes],
    method: 'DELETE',
})
