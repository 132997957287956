import { createMutation } from '../api/mutation'
import { orgCategoryKey, orgCategoryMapping } from './queries'

export const useMutateConfigureCategory = createMutation({
    url: '/categories',
    keysToRefetch: [orgCategoryKey],
    method: 'PATCH',
})
export const useUpsertCategoriesMapping = createMutation({
    url: '/category-mapping',
    method: 'POST',
    keysToRefetch: [orgCategoryMapping],
})
