import React, { useState } from 'react'
import logo from '@/assets/quickbooks-logo.png'
import { Button } from '@/components/Button'
import { useAuthContext } from '@/context'
import { quickBooksConnectLinks } from '../utils'

const IntegrateStepOne = ({ closeModal }: { closeModal: () => void }) => {
    const [isLoading, setIsLoading] = useState(false)
    const { selectedAccount } = useAuthContext()
    const connectFn = () => (window.location.href = quickBooksConnectLinks(selectedAccount?.org?.id as string))
    return (
        <div>
            <div className='w-full pt-8 flex flex-col items-center px-5'>
                <img src={logo} alt='' className='w-20' />
                <div className='mt-4 text-center'>
                    <p className='text-lg font-semibold mb-1'>QuickBooks Integration Required</p>
                    <p className='text-sm font-medium text-gray7'>
                        Before importing your charts of accounts, please integrate your QuickBooks account. This will
                        allow secure access to your financial data.
                    </p>
                </div>
            </div>
            <div className='mt-4 px-5'>
                <p className='text-sm font-medium pt-1 pb-2 border-b border-[#A8A8A8]'>Integration Benefits:</p>
                <div className='mt-3 text-sm text-gray7 font-medium space-y-0.5'>
                    <p>1. Automatic sync of chart of accounts</p>
                    <p>2. Real-time financial data updates</p>
                    <p>3. Secure OAuth 2.0 connection</p>
                </div>
            </div>
            <div className='mt-4 border-t border-gray8 py-4 px-7 flex items-center justify-end gap-3'>
                <Button
                    className='bg-transparent hover:bg-transparent disabled:opacity-40 disabled:bg-transparent text-gray7 p-0 min-w-fit px-4'
                    onClick={() => {
                        closeModal()
                    }}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
                <Button
                    loading={isLoading}
                    className='rounded-[8px] px-4 min-h-6'
                    onClick={() => {
                        setIsLoading(true)
                        connectFn()
                    }}
                >
                    Procees with integration
                </Button>
            </div>
        </div>
    )
}

export default IntegrateStepOne
