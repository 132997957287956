import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { OverAPeriodIcon } from '@/assets/assets.export'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Tabs, TabsList, TabsTrigger, TabTriggersContainer } from '@/components/Tabs/Tabs'
import { ReactComponent as LineChart } from '@/assets/linechart.svg'
import { ReactComponent as BarChartIcon } from '@/assets/barchart.svg'

import { useEffect, useMemo, useState } from 'react'
import { formatAmountNoSymbol } from '@/utils/money'
import { useAuthContext, useCurrencyContext } from '@/context'
import { useGetSpecificMonthTransact } from '@/services/transactions/queries'
import { cn } from '@/lib/styling'
import MinimalBarChart from './MinimalBarChart'
import { MinimalAreaChart } from './MinimalAreaChat'

const chartType = {
    barChart: 'bar-chart',
    lineChart: 'line-chart',
}

export const RevenueCharts = ({ currency }: { currency?: string }) => {
    const [chartTypeVal, setChartTypeVal] = useState(chartType?.barChart)
    const { selectedAccount } = useAuthContext()
    const [isSummaryYearClicked, setIsSummaryYearClicked] = useState(false)
    const { selectedCurrencyInfo } = useCurrencyContext()

    const thisYear = new Date().getFullYear()
    const [transYear, setTransYear] = useState(thisYear + '')
    const { data, refetch, isLoading, isRefetching } = useGetSpecificMonthTransact({
        queryParams: {
            owner: selectedAccount?.org?.id as string,
            year: transYear as string,
            currency: currency as string,
        },
    })
    const chartData1 = useMemo(() => data ?? [], [data])
    const chartData = chartData1?.map(data => ({
        name: data?.name,
        total: data?.incomingTotal ?? 0,
        incomingTotal: data?.incomingTotal,
        outgoingTotal: data?.outgoingTotal,
    }))

    // const {
    //     data: invoicesByMonth,
    //     refetch: refetchInvoicesByMonth,
    //     isLoading: isLoadingInvoicesByMonth,
    //     isRefetching: isRefetchingInvoicesByMonth,
    // } = useGetInvoiceByMonth({
    //     queryParams: {
    //         org_id: selectedAccount?.org?.id as string,
    //         year: transYear as string,
    //         currency: selectedCurrencyInfo()?.id as string,
    //     },
    //     enabled: !!selectedCurrencyInfo()?.id,
    // })

    // const chartData = useMemo(() => invoicesByMonth ?? [], [invoicesByMonth])

    const totalAmount = chartData?.reduce((acc, current) => {
        return acc + Number(current?.total)
    }, 0)

    useEffect(() => {
        if (isSummaryYearClicked) {
            refetch()
            // refetchInvoicesByMonth()
        }
    }, [transYear])

    useEffect(() => {
        refetch()
    }, [currency])

    return (
        <div className='border border-gray8 rounded-[20px] px-3 lg:px-6 p-6 bg-white w-full lg:w-2/3'>
            <section className=''>
                <div className='flex flex-col lg:flex-row gap-3 lg:gap-0 items-start justify-between mb-6'>
                    <div className=''>
                        <p className='mb-1 text-gray7 font-medium'>Total Revenue</p>
                        <p className={cn('text-[32px] leading-[40px] font-semibold', isRefetching && 'opacity-40')}>
                            {selectedCurrencyInfo(currency)?.symbol}
                            {formatAmountNoSymbol(totalAmount)}
                        </p>
                    </div>
                    <div className='flex items-center h-full gap-3'>
                        {!isLoading && (
                            <>
                                <Select
                                    name='invoiceYear'
                                    onValueChange={data => {
                                        setTransYear(data)
                                        setIsSummaryYearClicked(true)
                                    }}
                                    value={transYear}
                                >
                                    <SelectTrigger className='w-full bg-offwhite border border-gray8 text-gray7'>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-[8rem]'>
                                        <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                                        <SelectItem value={`${thisYear}`}>This year</SelectItem>
                                    </SelectContent>
                                </Select>
                                <Tabs value={chartTypeVal} className='border bg-offwhite border-gray8 rounded-md'>
                                    <TabsList className='border-none pt-0 h-full flex items-center px-2'>
                                        <TabTriggersContainer className='border-none justify-center gap-4 p-1'>
                                            <TabsTrigger
                                                onClick={() => setChartTypeVal(chartType.barChart)}
                                                value={chartType.barChart}
                                                className='border-none group data-[state=active]:bg-black h-fit py-0 p-1 rounded'
                                            >
                                                <BarChartIcon className='stroke-gray7/60 group-data-[state=active]:stroke-white' />
                                            </TabsTrigger>
                                            <TabsTrigger
                                                onClick={() => setChartTypeVal(chartType.lineChart)}
                                                value={chartType.lineChart}
                                                className='border-none group data-[state=active]:bg-black h-fit py-0 p-1 rounded'
                                            >
                                                <LineChart className='stroke-gray7/60 group-data-[state=active]:stroke-white' />
                                            </TabsTrigger>
                                        </TabTriggersContainer>
                                    </TabsList>
                                </Tabs>
                            </>
                        )}
                    </div>
                </div>
                {isLoading ? (
                    <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={278}>
                        <Skeleton className='mt-5' />
                    </SkeletonTheme>
                ) : (
                    <div className={cn(isRefetching && 'opacity-30')}>
                        {/*==== Bar chart ====*/}
                        {totalAmount > 0 ? (
                            <>
                                <div className={`bg-white p-1 md:p-4 md:pl-0 rounded-md mt-6`}>
                                    {chartTypeVal === chartType?.barChart && (
                                        <MinimalBarChart
                                            chartData={chartData}
                                            currency={currency as string}
                                            size='L'
                                            type='amount'
                                        />
                                    )}
                                    {chartTypeVal === chartType?.lineChart && (
                                        <MinimalAreaChart
                                            chartData={chartData}
                                            currency={currency as string}
                                            size='L'
                                            colors={{ thick: '#165DFF33', light: '#165DFF00', stroke: '#4951e1' }}
                                        />
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[300px]'>
                                    <OverAPeriodIcon />
                                    {/* <h2 className='text-gray7 font-medium mt-5'>No transactions</h2> */}
                                    <p className='text-sm mt-6'>Nothing to see here yet</p>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </section>
        </div>
    )
}
