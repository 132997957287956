import { useAuthContext } from '@/context'
import React from 'react'
import { MinimalDashboardActive } from './partials/MinimalDashboardActive'
import { MinimalDashboard } from './partials/MinimalDashboard'

const DashbordFlow = () => {
    const { selectedAccount } = useAuthContext()

    return <div>{selectedAccount?.org?.hasInvoices ? <MinimalDashboardActive /> : <MinimalDashboard />}</div>
}

export default DashbordFlow
