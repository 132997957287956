import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { Invoice } from '@/types/invoice'
import { formatAmountNoSymbol, formatAmountWithoutSymbols } from '@/utils/money'
import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../ModalWrapper'

type Props = {
    closeModal: () => void
    invoiceDetails?: Invoice
    handleBtnClick?: () => void
    setAmount: React.Dispatch<React.SetStateAction<string>>
    amount?: string
    loading?: boolean
}
const CheckoutPaymentModal = ({ closeModal, invoiceDetails, handleBtnClick, setAmount, amount, loading }: Props) => {
    const [paymentOption, setPaymentOption] = useState<'full_payment' | 'part_paryment'>('full_payment')

    useEffect(() => {
        const fullAmount = (Number(invoiceDetails?.total_amount ?? 0) - Number(invoiceDetails?.paid ?? 0)).toString()
        setAmount(() => (paymentOption === 'full_payment' ? fullAmount : amount ?? '0'))
    }, [amount, paymentOption])

    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[26rem] min-h-[20vh] max-h-[90vh] overflow-scroll relative'>
                <div className='border-b border-gray5 py-3 pb-2 px-2  flex justify-between sticky top-0 bg-white rounded-t-lg z-50 pl-5'>
                    <h2 className='font-medium text-gray7 '>Make payment</h2>
                    <span
                        className='bg-[#F2F2F2] w-7 h-7 p-2 rounded-full flex items-center justify-center text-gray7 cursor-pointer'
                        onClick={() => closeModal()}
                    >
                        <AiOutlineClose fontSize={25} />
                    </span>
                </div>
                <div className='bg-darkbrand/10 p-3  rounded-md m-4 mt-6'>
                    <p className='text-center text-sm font-medium opacity-90'>Total amount due</p>
                    <h3 className='text-center font-bold text-2xl text-gray7 mt-1'>
                        {invoiceDetails?.currency?.symbol}
                        {formatAmountNoSymbol(Number(invoiceDetails?.total_amount) - Number(invoiceDetails?.paid))}
                    </h3>
                </div>
                <div className='m-4'>
                    <div className=' flex-col gap-2 mt-4 '>
                        <label htmlFor='sorting' className='text-sm font-medium'>
                            Payment option
                        </label>
                        <Select
                            name='payment option'
                            value={paymentOption}
                            onValueChange={val => setPaymentOption(val as typeof paymentOption)}
                        >
                            <SelectTrigger className='text-xs h-11 border-gray4 mt-1'>
                                <SelectValue placeholder={'Full or part payment'} />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value='full_payment'>Full payment </SelectItem>
                                <SelectItem value='part_paryment' disabled={!invoiceDetails?.accept_part_payment}>
                                    Part payment{' '}
                                </SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    {invoiceDetails?.accept_part_payment && (
                        <div className='flex flex-col gap-2 mt-4 '>
                            <label htmlFor='sorting' className='text-sm font-medium '>
                                Amount to pay*
                            </label>

                            <Input
                                leftIcon={invoiceDetails?.currency?.symbol}
                                className='text-xs h-11 border-gray4 disabled:bg-gray5/70 disabled:cursor-not-allowed'
                                min={0}
                                disabled={paymentOption === 'full_payment'}
                                value={formatAmountNoSymbol(amount)}
                                onChange={e => {
                                    const value_entered = formatAmountWithoutSymbols(e.target.value)
                                    if (
                                        Number(value_entered) >
                                        Number(invoiceDetails?.total_amount) - Number(invoiceDetails?.paid)
                                    )
                                        return
                                    setAmount?.(value_entered)
                                }}
                            />
                        </div>
                    )}
                    <div className='mt-8'>
                        <Button
                            onClick={() => handleBtnClick?.()}
                            disabled={invoiceDetails?.status === 'PAID'}
                            loading={loading}
                            className={`${invoiceDetails?.accept_part_payment ? 'ml-auto' : 'mx-auto'} bg-brightGreen disabled:bg-brightGreen disabled:opacity-40 hover:bg-brightGreen/70`}
                        >
                            Pay amount
                        </Button>
                    </div>
                </div>
            </section>
        </ModalWrapper>
    )
}

export default CheckoutPaymentModal
