import { Tabs, TabsContent, TabsList, TabsTrigger, TabTriggersContainer } from '@/components/Tabs/Tabs'
import { motion } from 'framer-motion'
import { Button } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { useCreateInvoice, useCreateInvoiceDraft, useUpdateInvoice } from '@/services/invoice/mutations'
import { Customers } from '@/types/customers'
import { InvoiceStatus } from '@/types/invoice'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormStateType, PaymentMethod } from './utils'
import moment from 'moment'
import { InvoiceDetailsForm } from './InvoiceDetailsForm'
import { InvoiceSettingsForm } from './InvoiceSettingsForm'
import { useIsMobile } from '@/hooks/useIsMobile'

export type CreateInvoiceProps = {
    setFormState: React.Dispatch<React.SetStateAction<FormStateType>>
    formState: FormStateType
    logoFile?: FileUploadFormatProps
    setLogoFile: React.Dispatch<React.SetStateAction<FileUploadFormatProps | undefined>>
    orgCustomers: Customers[]
    sub_total: number
}
export default function CreateInvoiceForm({
    formState,
    setFormState,
    logoFile,
    setLogoFile,
    orgCustomers,
    sub_total,
}: CreateInvoiceProps) {
    const navigate = useNavigate()
    const { invoice_id } = useParams()
    const { mutate: createInvoiceFn, isLoading: isCreating } = useCreateInvoice({
        onSuccess() {
            navigate('/dashboard/invoice/invoice_list')
            toastHandler({ message: 'Invoice created', state: 'success' })
        },
    })
    const { mutate: createInvoiceDraftFn, isLoading: isCreatingDraft } = useCreateInvoiceDraft({
        onSuccess() {
            navigate(-1)
            toastHandler({ message: 'Invoice draft creeated', state: 'success' })
        },
    })

    const { mutate: updateInvoiceFn, isLoading: isUpdating } = useUpdateInvoice({
        onSuccess() {
            navigate('/dashboard/invoice/invoice_list')
            toastHandler({ message: 'Invoice updated', state: 'success' })
        },
        queryParams: {
            invoice_id: invoice_id as string,
        },
    })

    const disableBtn = () => {
        let btnState = false
        if (
            formState.customer &&
            formState.issue_date &&
            formState.due_date &&
            formState.item?.length > 0 &&
            formState?.payment_method
        ) {
            btnState = true
        }
        return btnState
    }
    const handleSubmit = (status: InvoiceStatus) => {
        const { customer, due_date, message, item, tax, part_payment, payment_method, footer, issue_date } = formState

        const payload = {
            customer,
            due_date: moment(due_date).utc(),
            issue_date: moment(issue_date).utc(),
            message,
            items: item.map(item_detials => ({
                name: item_detials?.name,
                quantity: item_detials?.quantity,
                unit_cost: Number(item_detials?.unit_cost) / 100,
                type: item_detials?.type,
                description: item_detials?.description,
            })),
            taxes: tax?.map(tax => tax?.id),
            payment_method,
            accept_part_payment: part_payment,
            payout_bank_account: formState?.account,
            footer: footer,
            currency: formState?.currency,
            ...(payment_method !== PaymentMethod.OFFLINE && {
                payment_gateway: formState?.paymentProcessor,
            }),
            bear_application_fee:
                payment_method !== PaymentMethod.OFFLINE ? formState?.bear_application_fee : undefined,
        }

        if (invoice_id && status === InvoiceStatus.PENDING) {
            updateInvoiceFn(payload)
            return
        }

        if (status === InvoiceStatus.PENDING) {
            createInvoiceFn(payload)
            return
        }
        if (status === InvoiceStatus.DRAFT) {
            createInvoiceDraftFn(payload)
            return
        }
    }

    const showDraftBtn = window.location.pathname.includes('update_invoice')
    const tab_options = {
        details: 'details',
        settings: 'settings',
        default: '',
    }

    const queryParams = new URLSearchParams(location.search)
    const isMobile = useIsMobile({ MOBILE_BREAKPOINT: 1280 })
    const paramDetails = queryParams.get('path') ?? tab_options.details

    const navigateToPath = (path: string) => {
        queryParams.set('path', path)
        navigate(`?${queryParams.toString()}`)
    }

    return (
        <div className='w-full'>
            <Tabs defaultValue={tab_options.default} value={isMobile ? paramDetails : 'both'} className='px-2 xl:px-0'>
                {isMobile && (
                    <TabsList className=' flex items-center gap-6 border-b mb-1 pt-0 '>
                        <TabTriggersContainer className='mx-1 lg:mx-auto'>
                            <TabsTrigger
                                value={tab_options.details}
                                onClick={() => navigateToPath('details')}
                                className={`relative border-none text-xs whitespace-nowrap`}
                            >
                                {tab_options.details === paramDetails && (
                                    <motion.div
                                        layoutId='org_view_tab'
                                        className='absolute bottom-0 w-full h-0.5 text-xs bg-[#313131]'
                                    />
                                )}
                                <motion.span layout='position' className='z-20 w-full'>
                                    Details
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={tab_options.settings}
                                id='personal'
                                onClick={() => navigateToPath('settings')}
                                className={`relative border-none text-xs whitespace-nowrap`}
                            >
                                {tab_options.settings === paramDetails && (
                                    <motion.div
                                        layoutId='org_view_tab'
                                        className='absolute bottom-0 w-full h-0.5 text-xs bg-[#313131]'
                                    />
                                )}
                                <motion.span layout='position' className='z-20 w-full'>
                                    Settings
                                </motion.span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>
                )}
                <div className='grid grid-cols-6 gap-5 '>
                    <TabsContent value={isMobile ? tab_options.details : 'both'} className='col-span-6 xl:col-span-4'>
                        <InvoiceDetailsForm
                            formState={formState}
                            orgCustomers={orgCustomers}
                            setFormState={setFormState}
                            setLogoFile={setLogoFile}
                            sub_total={sub_total}
                            logoFile={logoFile}
                        />
                    </TabsContent>
                    <TabsContent value={isMobile ? tab_options.settings : 'both'} className='col-span-6 xl:col-span-2'>
                        <InvoiceSettingsForm formState={formState} setFormState={setFormState} sub_total={sub_total} />
                    </TabsContent>
                </div>
            </Tabs>

            <div className='mt-7 border-t border-[#DADCE0] py-2 px-3 pb-0'>
                <div className='flex items-center justify-end gap-4 pt-2'>
                    {!showDraftBtn && (
                        <Button
                            className='bg-transparent text-[#202020] px-5 h-[3.5rem] rounded-lg text-xs hover:bg-transparent min-w-[100px] disabled:bg-transparent disabled:opacity-80 disabled:cursor-not-allowed'
                            type='submit'
                            disabled={isCreating || isCreatingDraft || !disableBtn() || isUpdating}
                            onClick={() => handleSubmit(InvoiceStatus.DRAFT)}
                            loading={isCreatingDraft}
                        >
                            Save as draft
                        </Button>
                    )}

                    <Button
                        disabled={isCreatingDraft || isCreating || !disableBtn() || isUpdating}
                        className=' px-5 h-10 rounded-lg text-xs  min-w-[100px]'
                        type='submit'
                        onClick={() => handleSubmit(InvoiceStatus.PENDING)}
                        loading={isCreating || isUpdating}
                    >
                        Submit invoice
                    </Button>
                </div>
            </div>
        </div>
    )
}
