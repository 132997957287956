import { Button, Textarea } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext, useCurrencyContext } from '@/context'
import { useSendinvoice } from '@/services/invoice/mutations'
import { Customers } from '@/types/customers'
import { formatAmount } from '@/utils/money'
import { format, formatDistance } from 'date-fns'
import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import ModalWrapper from '../ModalWrapper'
import { Invoice } from '@/types/invoice'

type Props = {
    closeModal: () => void
    invoice?: Invoice
    orgCustomers: Customers[]
}
export enum PaymentMethod {
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE',
    BOTH = 'BOTH',
}

export enum CreateInvoiceActions {
    SAVE_ONLY = 'SAVE_ONLY',
    SAVE_AND_SEND = 'SAVE_AND_SEND',
}

export default function SaveSendModal({ closeModal, invoice, orgCustomers }: Props) {
    const { selectedCurrencyInfo } = useCurrencyContext()
    const currencySymbol = selectedCurrencyInfo?.(invoice?.currency?.id)?.symbol

    const { selectedAccount } = useAuthContext()
    const navigate = useNavigate()

    const { mutate: sendInvoiceFn, isLoading: isSending } = useSendinvoice({
        onSuccess() {
            navigate(-1)
            toastHandler({ message: 'Invoice sent', state: 'success' })
        },
    })

    const selectedCustomer = orgCustomers?.find(customer => invoice?.customer?.id === customer?.id)
    const sub_total = invoice?.items?.reduce((acc, cur) => acc + Number(cur?.quantity) * Number(cur?.unit_cost), 0)
    const total = Number(sub_total) + Number(invoice?.total_tax_amount ?? 0)

    const save_data = [
        { name: 'From', desc: selectedAccount?.user?.email },
        { name: 'To', desc: selectedCustomer?.email },

        {
            name: 'Customer',
            desc: selectedCustomer?.name,
        },
        {
            name: 'Issue date',
            desc: format(new Date(invoice?.issue_date as Date), 'MMM dd, yyyy'),
        },
        {
            name: 'Due date',
            desc: (
                <span>
                    <span>{invoice?.due_date && format(new Date(invoice?.due_date), 'MMM dd, yyyy')}</span>
                    <span className='text-[#5E5E5E] text-sm'>
                        {invoice?.due_date &&
                            invoice?.issue_date &&
                            `(${formatDistance(new Date(invoice?.due_date), new Date(invoice?.issue_date))})`}
                    </span>
                </span>
            ),
        },

        { name: 'Sub-total', desc: `${currencySymbol} ${formatAmount(sub_total).replace('₦', '')}` },
        { name: 'Tax(N)', desc: `${currencySymbol} ${formatAmount(invoice?.total_tax_amount).replace('₦', '')}` },

        { name: 'Total amount due', desc: `${currencySymbol} ${formatAmount(total).replace('₦', '')}` },
    ]

    const [formState, setFormState] = useState({
        message: invoice?.message ?? '',
        asPDF: false,
        copy: false,
    })
    const disableButton = () => {
        const res = Object.values({ ...formState })
            .map((value, key) => (key !== 3 ? true : !!value))
            .some(data => data === false)
        return res
    }

    const handleSubmit = () => {
        const payload = {
            invoice_id: invoice?._id ?? invoice?.id,
            send_admin_email: formState?.copy,
            attach_pdf: formState?.asPDF,
            message: formState?.message,
            // footer: ?.,
        }

        sendInvoiceFn(payload)
    }
    return (
        <ModalWrapper variants={undefined}>
            <section className='w-screen lg:w-[30rem]  overflow-scroll pb-8 min-h-[69vh] max-h-[90vh]'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end '>
                    <div className='flex items-center w-[65%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B]'>Send invoice</h2>
                        <div className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'>
                            <AiOutlineClose fontSize={25} onClick={() => closeModal()} />
                        </div>
                    </div>
                </div>
                <div className={`m-6 mt-0 pt-6 overflow-y-scroll h-[35rem] ${isSending ? 'pointer-events-none' : ''} `}>
                    <div className='bg-[#EFEFF9] rounded-xl p-5'>
                        {save_data.map((data, idx) => (
                            <p className='mb-1' key={idx}>
                                {' '}
                                <span className='font-medium text-base'>{data.name}</span>
                                {': '}
                                <span>{data.desc}</span>
                            </p>
                        ))}
                    </div>
                    <div className='my-5'>
                        <label
                            htmlFor='message'
                            className='text-[#202020] text-sm mb-3 font-medium flex items-center gap-1'
                        >
                            Message <span className='text-[#5E5E5E]'>(optional)</span>
                            <span className='ml-auto text-xs'>{formState.message?.length ?? '0'}/120</span>
                        </label>
                        <Textarea
                            className='border border-[#DADCE0] pt-4 min-h-[5.6rem] text-18px resize-none'
                            maxLength={120}
                            value={formState.message}
                            placeholder='Enter message to customer'
                            onChange={e =>
                                setFormState(prev => ({
                                    ...prev,
                                    message: e.target.value! as unknown as string,
                                }))
                            }
                        />
                    </div>
                    <div className=''>
                        <>
                            <div className={`flex gap-2 item-center text-[#5E5E5E]`}>
                                <input
                                    type='checkbox'
                                    name='copy'
                                    id='copy'
                                    className='accent-[#454ADE] cursor-pointer'
                                    onChange={e =>
                                        setFormState(prev => ({
                                            ...prev,
                                            copy: e.target.checked,
                                        }))
                                    }
                                    checked={formState.copy}
                                />
                                <label htmlFor='copy' className='text-sm font-medium cursor-pointer'>
                                    Send a copy to myself at {selectedAccount?.user?.email}
                                </label>
                            </div>

                            <div className={`flex gap-2 item-center text-[#5E5E5E] mt-3`}>
                                <input
                                    type='checkbox'
                                    name='asPDF'
                                    id='asPDF'
                                    className='accent-[#454ADE] cursor-pointer'
                                    onChange={e =>
                                        setFormState(prev => ({
                                            ...prev,
                                            asPDF: e.target.checked,
                                        }))
                                    }
                                    checked={formState.asPDF}
                                />
                                <label htmlFor='asPDF' className='text-sm font-medium cursor-pointer'>
                                    Attach invoice as PDF
                                </label>
                            </div>
                        </>
                    </div>
                </div>

                <div className='flex pt-5 items-center justify-end gap-4 px-6 py-2  absolute bottom-0 right-0 left-0 bg-white w-full rounded-b-lg'>
                    <Button
                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                        loading={isSending}
                        onClick={handleSubmit}
                        disabled={disableButton()}
                    >
                        Send invoice
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
