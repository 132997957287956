import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useEmployeesContext, useRolesContext } from '@/context'
import { EmployeeStatus } from '@/types/employee'
import { RoleEnum } from '@/types/org'
import { AnimatePresence, motion } from 'framer-motion'

export default function InviteMembersModal() {
    const { setInviteQueryText, inviteQueryText, orgMembers } = useEmployeesContext()
    const { roles } = useRolesContext()

    const isFormerEmployee = orgMembers
        ?.filter(member => member?.status === EmployeeStatus.DEACTIVATED)
        ?.map(member => member?.email)
        .includes(inviteQueryText.email_query)

    return (
        <section className=' p-5 pb-0 text-base marker mt-3 w-[98%] mx-auto'>
            <h2 className='text-base font-normal text-[#202020]/90 mb-4'>Enter email address and assign role</h2>
            <div className='border border-[#DADCE0] h-[56px]  rounded-lg flex items-center overflow-hidden px-4'>
                <input
                    type='email'
                    placeholder='Enter invitee email address'
                    aria-label='enter invite email'
                    className='flex-1 outline-none mr-2 text-sm'
                    onChange={e => setInviteQueryText({ ...inviteQueryText, email_query: e.target.value })}
                />
                {
                    <Select
                        onValueChange={e => {
                            setInviteQueryText({ ...inviteQueryText, role: e })
                        }}
                    >
                        {
                            <SelectTrigger
                                variant='lg'
                                className={`w-fit h-0 py-5 border-0 bg-[#F9F9F9]  ${
                                    !inviteQueryText?.role ? 'text-[#A8A8A8]' : 'text-[#202020]'
                                }`}
                            >
                                {<SelectValue placeholder='role' />}
                            </SelectTrigger>
                        }

                        {
                            <SelectContent className='w-fit'>
                                {roles
                                    .filter(role => role.name !== 'OWNER' && role.name !== RoleEnum.CONTRACTOR)
                                    .map(role => (
                                        <SelectItem value={role.id} key={role.id}>
                                            {role.name === 'EMPLOYEE' ? (
                                                'User'
                                            ) : (
                                                <>
                                                    {role.name[0]}
                                                    {role.name.toLowerCase().slice(1)}
                                                </>
                                            )}
                                        </SelectItem>
                                    ))}
                            </SelectContent>
                        }
                    </Select>
                }
            </div>
            <AnimatePresence>
                {isFormerEmployee && (
                    <motion.p
                        initial={{ opacity: 0, y: 10, x: 10 }}
                        animate={{ opacity: 1, y: 0, x: 0 }}
                        exit={{ opacity: 0, y: -10, x: -10 }}
                        transition={{ ease: 'easeInOut', type: 'tween' }}
                        className='text-xs font-medium text-black/80 bg-[#D5D5F5] rounded-xl p-3 mt-5 leading-5'
                    >
                        This email address belongs to a deactivated user. Inviting them will reactivate their account
                        and they will have access to their financial records based on their previously assigned roles
                    </motion.p>
                )}
            </AnimatePresence>
        </section>
    )
}
