export enum EventEnum {
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    REIMBURSED = 'REIMBURSED',
    CREATED = 'CREATED',
    DRAFTED = 'DRAFTED',
    PAID = 'PAID',
    SCHEDULED = 'SCHEDULED',
    PROCESSING = 'PROCESSING',
    DENIED = 'DENIED',
    CANCELLED = 'CANCELLED',
    APPROVERS_ASSIGNED = 'APPROVERS ASSIGNED',
}

export enum SourceType {
    Wallet = 'Wallet',
    DepositAccount = 'DepositAccount',
}

export enum Accounting_Account_Type {
    Asset = 'Assets',
    Expense = 'Expenses',
    Revenue = 'Revenue',
    Equity = 'Equity',
    Liability = 'Liabilities',
}

export type SortColumn = { id: string | undefined; desc: boolean | undefined }
