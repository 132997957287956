import React from 'react'
import { AddItemProps, FormStateType } from './utils'
import { capitalizeText } from '@/utils/string'
import { formatAmount, formatAmountNoSymbol, formatAmountWithoutSymbols } from '@/utils/money'
import { ProfileEditIcon, TrashIcon } from '@/assets/assets.export'
import { useCurrencyContext } from '@/context'
import { Minus, Plus } from 'lucide-react'
import { Input } from '@/components'

type Props = {
    item: AddItemProps
    setFormState: React.Dispatch<React.SetStateAction<FormStateType>>
    formState: FormStateType
    setEditItem: React.Dispatch<React.SetStateAction<boolean>>
    setEditItemProps: React.Dispatch<React.SetStateAction<AddItemProps | undefined>>
}
export const SingleAddedItem = ({ item, setFormState, formState, setEditItem, setEditItemProps }: Props) => {
    const { selectedCurrencyInfo } = useCurrencyContext()
    const currencySymbol = selectedCurrencyInfo?.(formState?.currency)?.symbol
    return (
        <>
            <div className='mt-3'></div>
            <tr>
                <td className='pt-[7px] pl-[10px]'>
                    <div>
                        <p className='font-semibold mb-1'>{item?.name}</p>
                        <p className='text-[#5E6470] w-[90%]'>{item?.description}</p>
                    </div>
                </td>
                <td className='pt-[7px] pr-4'>{capitalizeText(item?.type)}</td>
                <td className='pt-[7px] text-center'>
                    <span className='text-gray7/80 flex items-center gap-2 mr-3 p-1.5 border border-gray5 rounded-md h-8'>
                        <button
                            title='Decrease quantity'
                            disabled={item?.quantity === 1}
                            onClick={() =>
                                setFormState?.({
                                    ...formState,
                                    item: formState?.item?.map(itemCheck =>
                                        item.uniq_id === itemCheck.uniq_id
                                            ? { ...itemCheck, quantity: Number(itemCheck?.quantity) - 1 }
                                            : itemCheck
                                    ),
                                })
                            }
                            className='disabled:opacity-50 w-fit'
                            type='button'
                        >
                            <Minus size={20} />
                        </button>
                        <input
                            aria-label='Quantity'
                            value={item?.quantity}
                            onChange={e =>
                                setFormState?.({
                                    ...formState,
                                    item: formState?.item?.map(itemCheck =>
                                        item.uniq_id === itemCheck.uniq_id
                                            ? { ...itemCheck, quantity: e.target.value }
                                            : itemCheck
                                    ),
                                })
                            }
                            type='number'
                            className='text-gray7 flex-1 text-center w-[14px] focus:outline-none text-xs'
                        />

                        <button
                            onClick={() =>
                                setFormState?.({
                                    ...formState,
                                    item: formState?.item?.map(itemCheck =>
                                        item.uniq_id === itemCheck.uniq_id
                                            ? { ...itemCheck, quantity: Number(itemCheck?.quantity) + 1 }
                                            : itemCheck
                                    ),
                                })
                            }
                            title='Increase quantity'
                            type='button'
                            className='w-fit'
                        >
                            <Plus size={20} />
                        </button>
                    </span>
                </td>
                <td className='text-right pt-[7px]'>
                    <Input
                        name='service_fee'
                        type='text'
                        className='border border-gray5 rounded-md h-8 text-right placeholder:text-xs w-full bg-white'
                        placeholder='Enter unit cost'
                        bodyClassName='flex-1 !min-w-[3rem] !w-[8rem] ml-auto mr-3'
                        min={0}
                        value={formatAmountNoSymbol(item?.unit_cost)}
                        onChange={e =>
                            setFormState?.({
                                ...formState,
                                item: formState?.item?.map(itemCheck =>
                                    item.uniq_id === itemCheck.uniq_id
                                        ? { ...itemCheck, unit_cost: formatAmountWithoutSymbols(e.target.value) }
                                        : itemCheck
                                ),
                            })
                        }
                    />
                    {/* {currencySymbol} {formatAmount(item?.unit_cost).replaceAll('₦', '')} */}
                </td>
                <td className='text-right pt-[7px]'>
                    {currencySymbol}{' '}
                    {formatAmount(Number(item?.unit_cost) * Number(item?.quantity)).replaceAll('₦', '')}
                </td>
                <td className='pt-[7px]'>
                    <div className='flex items-center gap-5 ml-3'>
                        <ProfileEditIcon
                            className='h-5 cursor-pointer'
                            onClick={() => {
                                setEditItem(true)
                                setEditItemProps(item)
                            }}
                        />
                        <TrashIcon
                            className='cursor-pointer'
                            onClick={() => {
                                setFormState(field => {
                                    return {
                                        ...field,
                                        item: field.item.filter(
                                            data =>
                                                (data?._id ?? data?.id ?? data?.uniq_id) !==
                                                (item?._id ?? item?.id ?? item?.uniq_id)
                                        ),
                                    }
                                })
                            }}
                        />
                    </div>
                </td>
            </tr>
        </>
    )
}
