import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import { useGetCheckoutInvoiceByID } from '@/services/invoice/queries'
import { formatAmountNoSymbol } from '@/utils/money'
import { format } from 'date-fns'
import { motion } from 'framer-motion'
import { Download } from 'lucide-react'
import { useMemo } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import InvoicePaymentSidebar from './partials/InvoicePaymentSidebar/InvoicePaymentSidebar'
import OfflineInvoiceCheckout from './partials/OfflineInvoiceCheckout'
import OnlineInvoiceCheckout from './partials/OnlineInvoiceCheckout'

import Logo from '@/assets/bizpendlogo.png'

import { PaymentMethod } from '@/components/Modals/Invoice/SaveSendModal'
import { CgSpinner } from 'react-icons/cg'

const tab_values = {
    online: 'ONLINE',
    offline: 'OFFLINE',
}
export default function InvoiceCheckout() {
    const { invoice_id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const currentMode = new URLSearchParams(location.search).get('mode') as string

    const { data: invoice_details, isLoading } = useGetCheckoutInvoiceByID({
        queryParams: {
            invoice_id: invoice_id as string,
        },
        onSuccess: data => {
            if (currentMode === 'ONLINE' || currentMode === 'OFFLINE') return
            navigate(`${location.pathname}?mode=${data?.payment_method === 'BOTH' ? 'ONLINE' : data?.payment_method}`)
        },
    })

    const invoiceDetails = useMemo(() => invoice_details, [invoice_details])

    const [searchParams] = useSearchParams()

    const clickedTab = searchParams.get('mode') as string

    return (
        <main className='w-full h-full min-h-[100vh] grid grid-cols-3'>
            <div className='p-4 block md:hidden'>
                <img src={Logo} alt='logo' className='w-[137px]' />
            </div>
            <section className='col-span-0 md:col-span-1 h-full hidden md:block'>
                <InvoicePaymentSidebar />
            </section>
            <section className='bg-[#F9F9F9] col-span-3 md:col-span-2 flex justify-center pt-5 md:pt-14  px-2 md:px-6 lg:mx-0'>
                <section className='w-[37rem]'>
                    <div>
                        <h1 className='font-medium texg-lg md:text-xl'>Make payment</h1>
                        <p className='text-[#5e5e5e] text-sm md:text-base'>
                            Pay and settle this invoice from
                            <span className='text-brand'> {invoiceDetails?.org?.org_name}</span>
                        </p>
                    </div>
                    <div className='bg-[#fff] p-4 md:p-6 rounded-2xl mt-7'>
                        <div className='border border-[#DADCE0] grid-cols-2 p-4 rounded-2xl text-sm md:text-base'>
                            <div className='flex justify-between items-center'>
                                <div>
                                    <p className='text-[#5E5E5E]'>Amount due</p>
                                    <h2 className='font-medium'>
                                        {isLoading ? (
                                            <CgSpinner className='animate-spin' />
                                        ) : (
                                            <>
                                                {invoiceDetails?.currency?.symbol}
                                                {formatAmountNoSymbol(
                                                    Number(invoiceDetails?.total_amount) - Number(invoiceDetails?.paid)
                                                )}
                                            </>
                                        )}
                                    </h2>
                                </div>
                                <div>
                                    <a
                                        className='text-brand flex items-center gap-1 bg-[#EFEFF9] rounded-md p-1 text-sm px-2'
                                        href={invoiceDetails?.invoice_attachment_url as string}
                                        download={`bizpend_transaction.pdf`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <Download size={15} /> Download
                                    </a>
                                </div>
                            </div>
                            <hr className='text-[#DADCE0] mt-2' />
                            <div className='flex justify-between items-center mt-2 '>
                                <div>
                                    <p className='text-[#5E5E5E]'>Issue date</p>
                                </div>
                                <div className='font-medium'>
                                    {isLoading ? (
                                        <CgSpinner className='animate-spin' />
                                    ) : (
                                        invoiceDetails?.issue_date &&
                                        format(new Date(invoiceDetails?.issue_date), 'dd/MM/yyyy')
                                    )}
                                </div>
                            </div>
                            <div className='flex justify-between items-center mt-2 '>
                                <div>
                                    <p className='text-[#5E5E5E]'>Due date</p>
                                </div>
                                <div className='font-medium'>
                                    {isLoading ? (
                                        <CgSpinner className='animate-spin' />
                                    ) : (
                                        invoiceDetails?.due_date &&
                                        format(new Date(invoiceDetails?.due_date), 'dd/MM/yyyy')
                                    )}
                                </div>
                            </div>
                            <div className='flex justify-between items-center mt-2 '>
                                <div>
                                    <p className='text-[#5E5E5E]'> To</p>
                                </div>
                                <div className='font-medium'>
                                    {isLoading ? (
                                        <CgSpinner className='animate-spin' />
                                    ) : (
                                        invoiceDetails?.customer?.name
                                    )}
                                </div>
                            </div>
                            <div className='flex justify-between items-center mt-2 '>
                                <div>
                                    <p className='text-[#5E5E5E]'>From</p>
                                </div>
                                <div className='font-medium'>
                                    {isLoading ? <CgSpinner className='animate-spin' /> : invoiceDetails?.org?.org_name}
                                </div>
                            </div>
                        </div>
                        <hr className='text-[#DADCE0] mt-5' />
                        <div>
                            <Tabs defaultValue={clickedTab} value={clickedTab}>
                                <TabsList className='border-none bg-[#F9F9F9] rounded-full mt-5 py-3 gap-8 w-fit px-4'>
                                    <TabTriggersContainer>
                                        {(invoiceDetails?.payment_method === PaymentMethod.ONLINE ||
                                            invoiceDetails?.payment_method === PaymentMethod.BOTH) && (
                                            <TabsTrigger
                                                className={`relative border-none w-[6.8rem] text-sm `}
                                                value={tab_values.online}
                                                onClick={() =>
                                                    navigate(`${location.pathname}?mode=${tab_values.online}`)
                                                }
                                            >
                                                {clickedTab === tab_values.online && (
                                                    <motion.div
                                                        layoutId='selected_invoice'
                                                        className={
                                                            'absolute inset-0   bg-black rounded-full -z-1 w-[6.8rem]'
                                                        }
                                                    />
                                                )}
                                                <span
                                                    className={`${
                                                        clickedTab === tab_values.online
                                                            ? 'text-[#fff]'
                                                            : '!text-[#000]'
                                                    } z-20 relative `}
                                                >
                                                    Pay online
                                                </span>
                                            </TabsTrigger>
                                        )}
                                        {(invoiceDetails?.payment_method === PaymentMethod.OFFLINE ||
                                            invoiceDetails?.payment_method === PaymentMethod.BOTH) && (
                                            <TabsTrigger
                                                className='relative border-none w-[6.8rem]'
                                                value={tab_values.offline}
                                                onClick={() =>
                                                    navigate(`${location.pathname}?mode=${tab_values.offline}`)
                                                }
                                            >
                                                <span
                                                    className={`${
                                                        clickedTab === tab_values.offline
                                                            ? 'text-[#fff]'
                                                            : '!text-[#000]'
                                                    } z-20 relative `}
                                                >
                                                    Pay offline
                                                </span>
                                                {clickedTab === tab_values.offline && (
                                                    <motion.div
                                                        layoutId='selected_invoice'
                                                        className={
                                                            'absolute inset-0   bg-black rounded-full -z-1 w-[6.8rem]'
                                                        }
                                                    />
                                                )}
                                            </TabsTrigger>
                                        )}
                                    </TabTriggersContainer>
                                </TabsList>
                                <TabsContent value={tab_values.online}>
                                    <OnlineInvoiceCheckout invoiceDetails={invoiceDetails} />
                                </TabsContent>
                                <TabsContent value={tab_values.offline}>
                                    <OfflineInvoiceCheckout invoiceDetails={invoiceDetails} />
                                </TabsContent>
                            </Tabs>
                        </div>
                    </div>
                </section>
            </section>
        </main>
    )
}
