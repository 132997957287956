import PageContent from '@/layout/PageContent'
import React from 'react'
import { PaymentProcessor } from '../components/PaymentProcessor'
import { MoveRight } from '@/assets/assets.export'
import { LiaUserPlusSolid } from 'react-icons/lia'
import avatar from '@/assets/v2dashboard/invite-avatar.svg'
import { Link } from 'react-router-dom'
import { Button } from '@/components'
import { Plus } from 'lucide-react'
import { useAuthContext, useCurrencyContext } from '@/context'

export const MinimalDashboard = () => {
    const { selectedAccount } = useAuthContext()
    const { selectedCurrencyInfo } = useCurrencyContext()

    return (
        <PageContent>
            <section className='w-full pb-[6rem] py-6'>
                <div className='px-4 lg:px-6 py-6 rounded-[20px] border border-brand bg-[#EFEFF9] space-y-2 flex flex-col lg:flex-row lg:items-center justify-between gap-4'>
                    <div className=''>
                        <p className='text-[#838383] text-2xl'>🎉 Welcome on board</p>
                        <h1 className='text-5xl text-[#202020] font-extrabold'>{selectedAccount?.org?.org_name}!</h1>
                    </div>
                    <div className='bg-white py-3 px-4 font-medium text-sm w-full lg:w-1/3 rounded-xl'>
                        Your business's primary currency is currently set to{' '}
                        <span className='text-brand font-semibold'>
                            {selectedCurrencyInfo()?.name} ({selectedCurrencyInfo()?.symbol})
                        </span>
                        . You can{' '}
                        <Link
                            to={'/dashboard/settings/company/profile'}
                            className='text-[#E17323] hover:underline cursor-pointer'
                        >
                            update it here
                        </Link>{' '}
                        if needed.
                    </div>
                </div>
                <div className='w-full h-px bg-gradient-to-r from-transparent from-20% via-[#5C5C5C] to-80% to-transparent my-4'></div>
                <div className='py-2'>
                    <p className='text-2xl text-[#202020] font-semibold mb-2'>Let’s get you up and running!</p>
                    <p className='text-gray7 text-lg'>Here are a list of things you can do right now:</p>
                </div>
                <div className='mt-4 flex gap-4 flex-wrap md:flex-nowrap'>
                    <PaymentProcessor />
                    <div className='border min-h-[400px] w-full lg:w-1/3 overflow-hidden bg-gradient-to-br from-[#131326] from-[20%] relative  to-[#4C4187] to-[90%] rounded-[24px]'>
                        <div className='absolute -bottom-16 -right-24 h-[364px] w-[364px] rounded-full bg-gradient-to-br from-[#5E51A9] from-[40%] to-[#232574] to-[90%]'></div>
                        <div className='p-6 flex justify-between flex-col h-full empty-invoice-bg'>
                            <div className='text-white'>
                                <p className='text-lg font-semibold mb-3'>Streamline your invoicing process</p>
                                <p className='text-offwhite text-sm font-medium'>
                                    Create and send professional invoices in seconds
                                </p>
                            </div>
                            <Link to={'/dashboard/invoice/create_invoice'}>
                                <Button className='text-brand bg-white w-full hover:!bg-white'>
                                    <Plus /> Create your first invoice
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='mt-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div className='border border-[#5C9BFF] bg-[#EBF5FF] rounded-xl p-4'>
                        <div className='mb-6'>
                            <p className='text-[20px] leading-[27px] text-[#202020] font-semibold mb-2'>
                                Setup Approval policy
                            </p>
                            <p className='text-gray7 font-medium'>
                                Set people to approve invoices before they are sent to customers
                            </p>
                        </div>
                        <div className='border-t border-[#5C9BFF] pt-4 flex justify-between items-center'>
                            <p className='text-[#1B1F3B] font-semibold'>Set approval policy</p>
                            <Link to={'/dashboard/invoice/settings/policy'}>
                                <MoveRight stroke='#ffffff' className='bg-black p-3 rounded-full h-14 w-14' />
                            </Link>
                        </div>
                    </div>
                    <div className='border border-gray8 bg-white rounded-xl p-4 relative overflow-hidden'>
                        <div className='mb-8'>
                            <p className='text-[20px] leading-[27px] text-[#202020] font-semibold mb-2'>Invite users</p>
                            <p className='text-gray7 font-medium'>
                                Invite users and create teams to <br className='sm:hidden' /> improve{' '}
                                <br className='hidden md:block xl:hidden' /> efficiency
                            </p>
                        </div>
                        <div className='border-t border-gray8 pt-4 flex justify-between items-center'>
                            <Link
                                to={'/dashboard/employees/members?from=get_started'}
                                className='flex items-center justify-center gap-3 text-sm bg-brand py-3 px-6 rounded-lg text-white'
                            >
                                {' '}
                                <LiaUserPlusSolid size={24} /> Invite a user
                            </Link>
                            <img src={avatar} alt='' className='absolute -right-10 -bottom-2' />
                        </div>
                    </div>
                </div>
            </section>
        </PageContent>
    )
}
