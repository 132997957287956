import { Button } from '@/components'
import PageContent from '@/layout/PageContent'
import { Plus } from 'lucide-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { Link } from 'react-router-dom'
import { useAuthContext, useCurrencyContext } from '@/context'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
// import { formatAmount } from '@/utils/money'
import { OverviewCards } from '../../components/new/OverviewCards'
import { RevenueCard } from '../components/RevenueCard'
import { RevenueCharts } from '../components/RevenueCharts'
import { CustomerRevenue } from '../components/CustomerRevenue'
import ProjectedRevenue from '../components/ProjectedRevenue'
import { useGetInvoiceSummary } from '@/services/invoice/queries'
import { InvoiceSummary } from '@/types/invoice'
import { useGetTransactionsSummary } from '@/services/transactions/queries'
import { cn } from '@/lib/styling'
import { formatAmountNoSymbol } from '@/utils/money'

export const MinimalDashboardActive = () => {
    const { currencies } = useCurrencyContext()
    const { selectedAccount } = useAuthContext()

    //pass option down to total revenue, revenue by customers and projected Revenue

    const [option, setOption] = useState(selectedAccount?.org?.currency?.id)
    const selectedCurrencyObj = currencies?.find(curr => curr?.id === option)

    const {
        data: invoiceData,
        isLoading: isLoadingInvoiceSummary,
        refetch: refetchInvoiceSummary,
        isRefetching: isRefetchingSummary,
    } = useGetInvoiceSummary({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            year: new Date().getFullYear().toString() as string,
            currency: option as string,
        },
    })
    const invoiceSummaryData = useMemo(() => invoiceData ?? ({} as InvoiceSummary), [invoiceData])

    const {
        data: transactionSummary,
        isLoading,
        refetch: refetchTransSummary,
    } = useGetTransactionsSummary({
        queryParams: {
            owner: selectedAccount?.org?.id as string,
            year: new Date().getFullYear().toString() as string,
            currency: option as string,
        },
    })

    const card_data = [
        {
            heading: 'Invoice issued',
            amount: `${invoiceSummaryData?.counts?.issued ?? 0}`,
            change: `${invoiceSummaryData?.comparisons?.issued_invoices_percentage ?? 0}%`,
            subText: 'Compared to last month',
            options: [],
        },
        {
            heading: 'Paid',
            amount: `${invoiceSummaryData?.counts?.paid ?? 0}`,
            change: `${invoiceSummaryData?.comparisons?.paid_invoices_percentage ?? 0}%`,
            subText: 'Compared to last month',
            options: [],
        },
        {
            heading: 'Outstanding',
            amount: `${invoiceSummaryData?.counts?.outstanding ?? 0}`,
            change: `${invoiceSummaryData?.comparisons?.outstanding_invoices_percentage ?? 0}%`,
            subText: 'Compared to last month',
            options: [],
        },
        {
            heading: 'Total revenue',
            amount: `${selectedCurrencyObj?.symbol}${formatAmountNoSymbol(transactionSummary?.incomingTotal)}`,
            change: `${invoiceSummaryData?.comparisons?.total_revenue_percentage ?? 0}%`,
            subText: 'Compared to last month',
            options: [],
        },
        {
            heading: 'Outstanding payments',
            amount: `${selectedCurrencyObj?.symbol}${formatAmountNoSymbol(invoiceSummaryData?.counts?.total_outstanding_payments)}`,
            change: `${invoiceSummaryData?.comparisons?.total_outstanding_payments_percentage ?? 0}%`,
            subText: 'Compared to last month',
            options: [],
        },
        {
            heading: 'Average payment time',
            amount: `${invoiceSummaryData?.counts?.average_payment_days?.toFixed(1) ?? 0}`,
            miniText: 'Days',
            change: `${invoiceSummaryData?.comparisons?.average_payment_days_percentage ?? 0}%`,
            subText: 'Compared to last month',
            options: [],
        },
    ]

    useEffect(() => {
        refetchInvoiceSummary()
        refetchTransSummary()
    }, [option])

    return (
        <PageContent>
            <section className='w-full pb-[6rem] py-6'>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between gap-5 mb-5'>
                    <div className='flex items-center justify-between lg:justify-normal gap-3'>
                        <p className='text-lg font-semibold'>Overview</p>
                        <div>
                            <Select onValueChange={setOption} value={option}>
                                <SelectTrigger
                                    className='min-w-[88px] bg-white border-gray8 text-[#5E5E5E]'
                                    id='currency'
                                >
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className='h-fit max-h-[10rem] w-[7rem]'>
                                    {currencies?.map(currency => (
                                        <SelectItem value={currency?.id} className='pl-3' key={currency?.id}>
                                            <h6 className='text-sm'>{currency?.currency_code}</h6>
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <Link to={'/dashboard/invoice/create_invoice'} className=''>
                        <Button className='w-full lg:w-auto'>
                            <Plus /> Create invoice
                        </Button>
                    </Link>
                </div>
                <div
                    className={cn(
                        'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4',
                        isRefetchingSummary && 'opacity-40'
                    )}
                >
                    {isLoadingInvoiceSummary || isLoading
                        ? [1, 2, 3].map(num => (
                              <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={175} key={num}>
                                  <Skeleton />
                              </SkeletonTheme>
                          ))
                        : card_data
                              .filter((_, idx) => idx < 3)
                              ?.map((card, idx) => <OverviewCards card={card} key={idx} idx={idx} />)}
                </div>
                <div className='w-full h-px bg-gradient-to-r from-transparent from-20% via-[#5C5C5C] to-80% to-transparent my-4'></div>
                <div
                    className={cn(
                        'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4',
                        isRefetchingSummary && 'opacity-40'
                    )}
                >
                    {isLoadingInvoiceSummary || isLoading ? (
                        [1, 2, 3].map(num => (
                            <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={175} key={num}>
                                <Skeleton />
                            </SkeletonTheme>
                        ))
                    ) : (
                        <>
                            <RevenueCard card={card_data[3]} />
                            {card_data
                                ?.filter((_, idx) => idx > 3)
                                ?.map((card, idx) => <OverviewCards idx={idx + 3} card={card} key={idx} />)}
                        </>
                    )}
                </div>
                <div className='mt-4 flex gap-4 flex-wrap md:flex-nowrap'>
                    <RevenueCharts currency={option} />
                    <div className='border min-h-[400px] w-full lg:w-1/3 overflow-hidden bg-gradient-to-br from-[#131326] from-[20%] relative  to-[#4C4187] to-[90%] rounded-[24px]'>
                        <div className='absolute -bottom-16 -right-24 h-[364px] w-[364px] rounded-full bg-gradient-to-br from-[#5E51A9] from-[40%] to-[#232574] to-[90%]'></div>
                        <div className='p-6 flex justify-between flex-col h-full empty-invoice-bg'>
                            <div className='text-white'>
                                <p className='text-lg font-semibold mb-3'>Streamline your invoicing process</p>
                                <p className='text-offwhite text-sm font-medium'>
                                    Create and send professional invoices in seconds
                                </p>
                            </div>
                            <Link to={'/dashboard/invoice/create_invoice'}>
                                <Button className='text-brand bg-white w-full hover:!bg-white'>
                                    <Plus /> Create invoice
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='mt-4 flex gap-4 flex-wrap md:flex-nowrap'>
                    <CustomerRevenue currency={option} />
                    <ProjectedRevenue currency={option} />
                </div>
            </section>
        </PageContent>
    )
}
