import bizpendlogo from '@/assets/bizpend.svg'
import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import CreateNewCustomer from '@/components/Modals/Vendors/CreateNewCustomer/CreateNewCustomer'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext, useCurrencyContext, useInvoiceContext, useInvoiceItemsContext } from '@/context'
import useSingleFileUpload from '@/hooks/useSingleFileUpload'
import { formatAmount, formatAmountNoSymbol } from '@/utils/money'
import { Check, ChevronDown, PlusSquare } from 'lucide-react'
import React, { useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { AddItemProps, PaymentMethod } from './utils'
import { useUploadCompanyLogo } from '@/services/auth/mutations'
import { CreateInvoiceProps } from './CreateInvoiceForm'
import { Popover, Switch } from '@headlessui/react'
import CreateNewTax from '@/components/Modals/Invoice/InvoiceItem/CreateNewTax'
import { CiSquarePlus } from 'react-icons/ci'
import CreateInvoiceItem from '@/components/Modals/Invoice/InvoiceItem/CreateInvoiceItem'
import { SingleAddedItem } from './SingleAddedItem'

export const InvoiceDetailsForm = ({
    setFormState,
    formState,
    orgCustomers,
    setLogoFile,
    logoFile,
    sub_total,
}: CreateInvoiceProps) => {
    const { selectedCurrencyInfo } = useCurrencyContext()
    const currencySymbol = selectedCurrencyInfo?.(formState?.currency)?.symbol
    const [openSelectContainer, setOpenSelectContainer] = useState(false)
    const [addCustomer, setAddCustomer] = useState(false)
    const [editItem, setEditItem] = useState(false)
    const [openAddItem, setOpenAddItem] = useState(false)
    const [openCreateTaxModal, setOpenCreateTaxModal] = useState(false)

    const [editItemProps, setEditItemProps] = useState<AddItemProps>()
    const calendarRef1 = useRef<DatePicker>(null)
    const calendarRef2 = useRef<DatePicker>(null)
    const logo = useRef<HTMLInputElement>(null)
    const { selectedAccount } = useAuthContext()

    const { invoiceItems } = useInvoiceItemsContext()
    const { orgTaxes } = useInvoiceContext()

    const { mutate: uploadLogoFn, isLoading: isUploading } = useUploadCompanyLogo({
        onSuccess: () => {
            toastHandler({ message: 'upload successful', state: 'success' })
        },
    })
    const { handleFile: uploadLogoDocFn } = useSingleFileUpload({
        setFileUpload: setLogoFile,
    })

    const upLoadLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formdata = new FormData()
        formdata.append('file', e.target.files?.[0] ?? '')
        uploadLogoFn(formdata)
    }

    const watchTax = formState?.tax ?? []
    const taxTotal = watchTax.reduce((acc, cur) => acc + (Number(cur?.tax_rate) / 100) * sub_total, 0)
    const serviceFee =
        formState?.payment_method !== PaymentMethod.OFFLINE
            ? !formState?.bear_application_fee
                ? (Number(sub_total) + Number(taxTotal ?? 0)) * 0.001
                : 0
            : 0

    return (
        <div className='border border-[#DADCE0] rounded-xl p-4  w-full overflow-y-scroll'>
            <div className='flex lg:items-stretch flex-col md:flex-row gap-5'>
                <div className='w-full lg:w-[55%]'>
                    <div className={`flex flex-col gap-x-5 gap-y-5`}>
                        <div className='flex flex-col gap-3'>
                            <label className='flex items-center gap-1 text-sm'>
                                Customer <span className='text-[red]'>*</span>
                            </label>
                            <Select
                                name='customer'
                                onValueChange={value => {
                                    setFormState(prevState => ({
                                        ...prevState,
                                        ['customer']: value,
                                    }))
                                }}
                                value={formState.customer}
                                onOpenChange={setOpenSelectContainer}
                                open={openSelectContainer}
                            >
                                <SelectTrigger
                                    className='h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px] w-full'
                                    id='customer-trigger'
                                >
                                    {formState?.customer ? <SelectValue /> : <span>Select customer</span>}
                                </SelectTrigger>
                                <SelectContent className='pb-3 max-h-[220px] overflow-scroll' id={`customer_content`}>
                                    <button
                                        className='flex items-center gap-1 pl-2 py-1.5 hover:bg-[#EFEFF9] w-full text-sm'
                                        onClick={() => {
                                            setAddCustomer(true)
                                            setOpenSelectContainer(false)
                                        }}
                                    >
                                        <PlusSquare size={15} /> Add new customer
                                    </button>

                                    {orgCustomers?.map((customer, idx) => (
                                        <SelectItem id={`customer-${idx}`} value={customer?.id}>
                                            {customer?.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className='flex flex-col md:flex-row gap-5'>
                            <div className='flex flex-col gap-3 flex-1'>
                                <label className='flex items-center gap-1 text-sm'>
                                    Issue Date <span className='text-[red]'>*</span>
                                </label>

                                <CustomDatePicker
                                    ref={calendarRef1}
                                    placeholderText='DD/MM/YYYY'
                                    className='w-[70%]'
                                    onChange={date => {
                                        setFormState(prev => ({
                                            ...prev,
                                            issue_date: date! as unknown as string,
                                        }))
                                    }}
                                    wrapperClassName='border-2'
                                    selected={formState.issue_date as unknown as Date}
                                />
                            </div>
                            <div className='flex flex-col gap-3 flex-1 '>
                                <label className='flex items-center gap-1 text-sm'>
                                    Due Date <span className='text-[red]'>*</span>
                                </label>
                                <CustomDatePicker
                                    ref={calendarRef2}
                                    className='w-[70%]'
                                    placeholderText='DD/MM/YYYY'
                                    onChange={date =>
                                        setFormState(prev => ({
                                            ...prev,
                                            due_date: date! as unknown as string,
                                        }))
                                    }
                                    wrapperClassName='border-2'
                                    selected={formState.due_date as unknown as Date}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`w-full lg:w-[45%] ${isUploading ? 'opacity-50' : ''}`}>
                    <input
                        type='file'
                        hidden
                        id='logo'
                        ref={logo}
                        accept='image/*'
                        onChange={e => {
                            uploadLogoDocFn(e)
                            upLoadLogo(e)
                        }}
                    />

                    <div
                        className='bg-[#F9F9F9] p-5 text-center rounded-2xl h-full max-h-[180px] flex flex-col justify-between cursor-pointer items-center'
                        onClick={() => logo.current?.click()}
                    >
                        <img
                            src={
                                logoFile && logoFile?.fileUrl.length > 0
                                    ? logoFile?.fileUrl
                                    : selectedAccount?.org?.logo ?? bizpendlogo
                            }
                            alt='Logo'
                            className='w-[8rem] h-[6rem] object-contain'
                        />

                        <button className='text-brand font-medium mt-1'>Change logo</button>
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <h2 className='text-sm text-[#5E5E5E] font-medium mb-3'>Item(s)</h2>
                <div className='border border-[#DADCE0] rounded-lg w-full overflow-y-scroll'>
                    <table className='text-sm text-[#202020] min-w-[40rem] md:w-full'>
                        <thead className='border-b-[0.5px] border-[#DADCE0] bg-[#F9F9F9]'>
                            <tr>
                                <th className='w-[45%] text-left py-3 pl-4'>Item(s)</th>
                                <th className='text-left py-3'>Type</th>
                                <th className='text-right py-3 pr-3'>Quantity</th>
                                <th className='text-right py-3 whitespace-nowrap pr-3'>Unit cost ({currencySymbol})</th>
                                <th className='text-right py-3 whitespace-nowrap'>Total cost ({currencySymbol})</th>
                                <th className='text-transparent'> action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {formState?.item?.map(item => (
                                <SingleAddedItem
                                    formState={formState}
                                    item={item}
                                    setEditItem={setEditItem}
                                    setEditItemProps={setEditItemProps}
                                    setFormState={setFormState}
                                />
                            ))}
                        </tbody>
                    </table>
                    <div className='p-4  flex items-center justify-center'>
                        <button
                            className='text-xs font-medium text-[#454ADE] bg-[#EFEFF9] text-center rounded-[20px]  px-3 py-1'
                            onClick={() => {
                                setEditItemProps(undefined)
                                setOpenAddItem(true)
                            }}
                        >
                            Add item
                        </button>
                    </div>
                    <div className='p-4 border-t border-b border-[#DADCE0]  mb-3 min-w-[40rem] lg:w-full'>
                        <p className='text-right text-sm font-medium mb-1'>Subtotal</p>
                        <p className='text-right text-sm font-semibold text-[#5E5E5E] mb-3'>
                            {currencySymbol} {formatAmount(sub_total).replaceAll('₦', '')}
                        </p>

                        <div className='flex justify-end'>
                            <div className='flex flex-col flex-1 gap-1.5 max-w-[12rem]'>
                                <span className='opacity-80 text-sm font-medium text-right'>Tax </span>

                                <Popover className='relative w-full'>
                                    <Popover.Button className=' text-sm text-gray7 border-gray4/60 border rounded-lg h-10 px-3  text-left flex items-center gap-2 w-full'>
                                        <span className='font-medium'>%</span>
                                        <span className='flex flex-1 items-center justify-between'>
                                            {watchTax?.length === 0 && (
                                                <span className='text-xs opacity-60'>Select tax</span>
                                            )}
                                            {watchTax?.length > 0 && (
                                                <span className='text-xs font-medium'>{watchTax?.length} Selected</span>
                                            )}
                                            <ChevronDown className='text-gray w-4 h-4' />
                                        </span>
                                    </Popover.Button>
                                    <Popover.Panel className='absolute bottom-full z-[10000] bg-white shadow-md rounded-lg  px-4 p-3  max-h-[15rem] overflow-y-scroll mt-1 border border-gray5 w-[15rem] -left-10'>
                                        <ul className='text-sm space-y-3'>
                                            {orgTaxes?.map(tax => (
                                                <li className='flex items-center justify-between' key={tax.id}>
                                                    <button
                                                        type='button'
                                                        onClick={() => {
                                                            const hasTax =
                                                                watchTax.filter(t => t.id === tax.id)?.length > 0
                                                            setFormState({
                                                                ...formState,
                                                                tax: hasTax
                                                                    ? watchTax.filter(t => t?.id !== tax.id)
                                                                    : [...watchTax, tax],
                                                            })
                                                        }}
                                                    >
                                                        {tax.tax_name} -{' '}
                                                        <span className='text-gray7/90 text-xs'>({tax.tax_rate}%)</span>
                                                    </button>

                                                    {watchTax.some(t => t.id === tax.id) && (
                                                        <Check
                                                            className='w-4 h-4 stroke-brand bg-brand/10 p-1 rounded-full'
                                                            strokeWidth={3}
                                                        />
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </Popover.Panel>
                                </Popover>
                                <div className='bg-gray6 shadow-sm rounded-md p-3 space-y-2'>
                                    {watchTax?.map(tax => (
                                        <div className='flex justify-between text-xs' key={tax.id}>
                                            <span>{tax?.tax_name}</span>
                                            <span className='text-gray7'>{tax?.tax_rate}%</span>
                                        </div>
                                    ))}
                                    {watchTax?.length > 0 && <hr className='border-gray/60 ' />}
                                    <button
                                        onClick={() => setOpenCreateTaxModal(true)}
                                        title='Add more'
                                        className='text-gray7 flex items-center gap-1 text-xs font-medium'
                                    >
                                        <CiSquarePlus className='text-gray7' size={20} />
                                        Add new tax
                                    </button>
                                </div>
                            </div>
                        </div>
                        {formState?.payment_method !== PaymentMethod.OFFLINE && (
                            <div className='flex justify-end mt-3'>
                                <div className='bg-[#EBF5FF] border border-[#5C9BFF] rounded-2xl p-3'>
                                    <div className='font-medium'>
                                        <p className='text-sm'>Service fee</p>
                                        <p className='text-gray7 text-xs mt-1 max-w-sm'>
                                            The service fee is what our platform charges for processing the invoice
                                            payment.
                                        </p>
                                    </div>
                                    <div className='my-4 flex items-center gap-4'>
                                        <div className='font-medium'>
                                            <p className='text-xs'>Fee amount</p>
                                            <p className='text-gray7 text-xs mt-1 max-w-sm'>
                                                Service fee is <span className='font-semibold text-black'>0.1%</span> of
                                                the total amount
                                            </p>
                                        </div>
                                        <Input
                                            name='service_fee'
                                            type='text'
                                            leftIcon={
                                                <span className='text-gray7'>
                                                    {selectedCurrencyInfo(formState?.currency)?.symbol}
                                                </span>
                                            }
                                            className='border border-gray5 rounded-md h-10 placeholder:text-xs w-full bg-white'
                                            placeholder='Enter unit cost'
                                            bodyClassName='flex-1 !min-w-[1rem] !w-[3rem]'
                                            min={0}
                                            value={formatAmountNoSymbol(
                                                (Number(sub_total) + Number(taxTotal ?? 0)) * 0.001
                                            )}
                                            disabled
                                        />
                                    </div>
                                    <div className='flex gap-3 items-center'>
                                        <Switch
                                            type='button'
                                            onChange={() =>
                                                setFormState(prev => ({
                                                    ...prev,
                                                    bear_application_fee: !prev?.bear_application_fee,
                                                }))
                                            }
                                            checked={!formState?.bear_application_fee}
                                            className={`relative inline-flex h-6 w-11 items-center rounded-full bg-[#1B1F3B]`}
                                        >
                                            <span
                                                className={`${
                                                    formState?.bear_application_fee
                                                        ? 'translate-x-1 bg-brand'
                                                        : 'translate-x-6 bg-white'
                                                } inline-block h-4 w-4 transform rounded-full  transition`}
                                            />
                                        </Switch>
                                        <p className='text-xs font-medium py-1'>
                                            Add service fee to invoice total (client pays)
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='px-4 mx-[10px] ml-0 lg:ml-[10px] mb-4  pt-2 border-[#DADCE0] min-w-[40rem] lg:w-full'>
                        <p className='text-right text-sm font-medium mb-1'>Total</p>
                        <p className='text-right text-sm font-semibold text-[#5E5E5E]'>
                            {currencySymbol}{' '}
                            {formatAmount(Number(sub_total) + Number(taxTotal ?? 0) + serviceFee).replaceAll('₦', '')}
                        </p>
                    </div>
                </div>
            </div>

            {(openAddItem || editItem) && (
                <CreateInvoiceItem
                    closeModal={() => {
                        setOpenAddItem(false)
                        setEditItem(false)
                    }}
                    formState={formState}
                    setFormState={data => setFormState(prev => ({ ...prev, ...data }))}
                    invoiceItem={editItemProps}
                    orgItems={invoiceItems}
                />
            )}
            {openCreateTaxModal && <CreateNewTax closeModal={() => setOpenCreateTaxModal(false)} />}

            {addCustomer && <CreateNewCustomer setCloseModal={setAddCustomer} />}
        </div>
    )
}
